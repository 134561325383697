import {FlatList, Linking, Pressable, View} from 'react-native';
import {ButtonType, cardWrapper, GButton} from '../molecules';
import C from 'consistencss';
import {GAvatar} from '../molecules/GAvatar';
import {
  Alignment,
  eColor,
  EeSize,
  eText,
  gImages,
  orangeGrad,
  reviewScale,
  TextType,
} from '../ui';
import {GIcon, GText} from '../atoms';
import QRCode from 'react-native-qrcode-svg';
import React from 'react';
import {GPill} from '../molecules/gPill';
import {GIconRow} from '../molecules/GIconRow';
import {nameInitial} from '../types/utils/dates';
import {useGNav} from '../routes/useGNav';
import {
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import {isArray} from 'lodash';

export const BASE_URL = 'https://www.oralify.com';

export const ShareQR = ({
  user,
  onPress,
}: {
  user?: UserType;
  onPress?: () => void;
}) => {
  const web = `${BASE_URL}/${user?.username}`;
  const nav = useGNav();
  const currSpeciality = isArray(user?.speciality)
    ? user?.speciality[0]
    : user?.speciality || SpecialityKey.marketing;
  const {name, emoji} = gSpecialities[currSpeciality as SpecialityKey];
  return (
    user && (
      <Pressable
        onPress={() => {
          if (user) nav(`/${user?.username || user.id}`);
          if (onPress) onPress();
        }}
        style={[cardWrapper, C.m10, C.maxw80, C.p4, C.top5, C.itemsCenter]}
      >
        <GAvatar
          image={user?.avatar}
          /*services={user?.services}*/
          wrapStyle={[
            C.absolute,
            C.top_12,
            C.bgWhite,
            C.radius20,
            eColor(palette.primaryMercuryGrey, 2).border,
          ]}
        />
        <View style={[C.absolute, C.right_14, C.top_4]}>
          <GPill
            selected
            long
            color={palette.lightGrey}
            icon={emoji}
            text={name}
          />
        </View>
        {user?.avgReview && (
          <View
            style={[
              C.absolute,
              C.left_6,
              C.top_4,
              C.px1,
              C.bgWhite,
              C.radius20,
              eColor(palette.primaryMercuryGrey).border,
            ]}
          >
            <GIconRow
              icon={'star-rate'}
              title={user.avgReview.toString()}
              color={reviewScale(user.avgReview).hex()}
            />
          </View>
        )}
        {/* <GText type={TextType.body} align={Alignment.Center} style={[C.my4, C.mt8, C.maxw60]}>
        {trans(`Contacta con `)}*/}
        <GText
          type={TextType.bodyBold}
          align={Alignment.Center}
          style={[C.mb1, C.mt8, C.maxw60]}
        >
          {user.nameShort || nameInitial(user.name)}
        </GText>
        {/* </GText>*/}
        {user?.education && (
          <GIconRow
            style={[C.mb1, C.maxw80]}
            icon={'graduation-cap'}
            text={user?.education}
          />
        )}

        <QRCode
          value={web}
          logo={gImages.oralifyWhite}
          logoBorderRadius={40}
          logoMargin={30}
          size={160}
          //quietZone={40}
          backgroundColor={palette.background}
          color={palette.orangeSec}
          enableLinearGradient
          linearGradient={orangeGrad}
          logoSize={50}
          logoBackgroundColor="transparent"
        />
        <View style={[C.row, C.itemsCenter]}>
          <GIcon
            icon={'web'}
            color={palette.redSec}
            size={EeSize.md}
            wrapStyle={C.mr2}
          />
          <GText
            onPress={() => Linking.openURL(web, '_blank')}
            type={TextType.label}
            style={[eText.underline, C.my4, eColor(palette.redSec).text]}
          >
            {web.split('www.')[1]}
          </GText>
        </View>
        {user?.services && (
          <View style={[C.overflowHidden]}>
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={[C.overflowHidden, C.selfStart]}
              data={Object.entries(user?.services).slice(0, 2)}
              keyExtractor={([serviceKey]) => serviceKey}
              renderItem={({item: [serviceKey, service], index}) => {
                if (service.serviceDetails?.length > 0) {
                  const {price, duration} = service.serviceDetails[0];
                  const {icon} =
                    serviceFormatMap[serviceKey as ServiceFormatKey];
                  return (
                    <GButton
                      key={serviceKey}
                      type={
                        index === 0 ? ButtonType.primary : ButtonType.secondary
                      }
                      text={`${price}€ - ${duration} min`}
                      icon={icon}
                    />
                  );
                }
              }}
            />
          </View>
        )}
      </Pressable>
    )
  );
};
