import React from 'react';
import {Linking, View} from 'react-native';
import {centerRow} from '../molecules/gInfoRow';
import GSpinner from '../atoms/GSpinner'; // Asegúrate de tener axios instalado o usa fetch.
import UserCard from './UserCard';
import {Api_Routes} from 'oralify-common';
import {ButtonType, GButton} from '../molecules';
import {oralifyBaseURL, useGetAPI} from '../api/api';
import {useParams} from 'react-router-dom';
import C from 'consistencss';
import {ShareQR} from './ShareQR';
import {useModal} from '../modals/useModal';
import {modalScreens} from '../routes/useGNav.web';

const EmbedUserCard = ({id}: {id?: string}) => {
  const {username, type = 'user'} = useParams();
  const currUserId = id || username;
  const {data: currUser} = useGetAPI({
    url: Api_Routes.user.publicProfile.replace(':id', currUserId || ''),
  });
  const {showModal: showLoginModal, ModalComponent} = useModal(
    modalScreens.login,
  );

  return !currUser ? (
    <GSpinner wrapStyle={[C.bgWhite, C.border50, C.selfCenter]} full={false} />
  ) : (
    <View style={[C.p4]}>
      {type === 'user' ? (
        <UserCard showRibbon key={currUser.id} showInitialTab user={currUser}>
          {/*<GText>{JSON.stringify(currUser)}</GText>*/}
          <View style={[centerRow]}>
            <GButton
              text={'¡Contratame!'}
              onPress={() => Linking.openURL(oralifyBaseURL + '/' + currUserId)}
            />
            <GButton
              type={ButtonType.secondary}
              text={'¡Únete como experto!'}
              onPress={() =>
                Linking.openURL(oralifyBaseURL + '/expert/landing ')
              }
            />
          </View>
        </UserCard>
      ) : (
        <ShareQR
          user={currUser}
          onPress={() => Linking.openURL(oralifyBaseURL + '/' + currUserId)}
        />
      )}
    </View>
  );
};

export default EmbedUserCard;
