import { useEffect, useState } from "react";
import { Dimensions, ScaledSize } from "react-native";

/**
 * Copy of react-native’s useWindowDimensions, but with `screen` instead.
 */
export function useScreenDimensions() {
  const [dimensions, setDimensions] = useState(() => Dimensions.get('screen'));

  useEffect(() => {
    function handleChange({screen}: {screen: ScaledSize}) {
      if (
        dimensions.width !== screen.width ||
        dimensions.height !== screen.height ||
        dimensions.scale !== screen.scale ||
        dimensions.fontScale !== screen.fontScale
      ) {
        setDimensions(screen);
      }
    }

    const subscription = Dimensions.addEventListener('change', handleChange);
    // We might have missed an update between calling `get` in render and
    // `addEventListener` in this handler, so we set it here. If there was
    // no change, React will filter out this update as a no-op.
    handleChange({screen: Dimensions.get('screen')});

    return () => {
      subscription.remove();
    };
  }, [dimensions]);

  return dimensions;
}

export default useScreenDimensions;
