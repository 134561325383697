import React from 'react';
import {useGetAPI} from '../api/api';
import {
  Api_Routes,
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ShownAppointmentType,
  SpecialityKey,
  statusInfoMapping,
  TimeslotStatus,
} from 'oralify-common';
import {currentUserAtom} from '../store/useUser';
import {FlatList, View} from 'react-native';
import {useRecoilState} from 'recoil';
import {GAvatar} from '../molecules/GAvatar';
import C from 'consistencss';
import {GIconRow} from '../molecules/GIconRow';
import dayjs from 'dayjs';
import {DATE_HOUR, DATE_USER} from '../types/utils/dates';
import {GCard} from '../molecules';
import {GPill} from '../molecules/gPill';
import {GIcon, GText} from '../atoms';
import {EeSize, gImages, TextType} from '../ui';
import {useTabs} from '../filters/useTabs';
import {FullIconType} from '../types/icon-types';
import GButton from '../molecules/GButton';
import {useNavigate} from 'react-router-dom';
import {headerScreens} from '../routes/useGNav.web';
import useResponsive from '../ui/helpers/useResponsive';

export const appointmentTabMapping: {
  [key: string]: {name: string; key: string; icon: FullIconType};
} = {
  appointmentsCurious: {
    name: 'Solicitudes Enviadas',
    key: 'appointmentsCurious', //Send Requests
    icon: 'send',
  },
  appointmentsExpert: {
    name: 'Peticiones Recibidas',
    key: 'appointmentsExpert', // Received Requests (only Experts)
    icon: 'receipt',
  },
};

export default () => {
  const [user] = useRecoilState(currentUserAtom);
  const navigate = useNavigate();
  const {isSmall} = useResponsive();
  const {data} = useGetAPI({
    url: Api_Routes.user.getAllAppointmentsForUser.replace(
      ':id',
      user?.id || '',
    ),
  });
  const {TabHeaders, selectedTab} = useTabs(
    user?.services
      ? appointmentTabMapping
      : {appointmentsCurious: appointmentTabMapping.appointmentsCurious},
    user?.services
      ? appointmentTabMapping.appointmentsExpert.key
      : appointmentTabMapping.appointmentsCurious.key,
    true,
  );
  const currList =
    data && data[selectedTab as keyof typeof appointmentTabMapping];
  return (
    <View>
      <TabHeaders valExtractor={item => appointmentTabMapping[item]} />
      {currList && (
        <FlatList
          data={currList}
          style={C.my6}
          ListEmptyComponent={
            <View style={[C.itemsCenter]}>
              <GText>Aún no tienes ninguna petición</GText>
              <GIcon
                icon={gImages.oralify404}
                size={isSmall ? EeSize.image : EeSize.imageBig}
                resizeMode={'contain'}
                wrapStyle={[C.radius4]}
              />
              <GButton
                icon={'search'}
                text={'Buscar Cita'}
                onPress={() => {
                  navigate(headerScreens.expertSearch.path);
                }}
              />
            </View>
          }
          renderItem={({item}: {item: ShownAppointmentType}) => {
            const {
              curiousUser,
              endTime,
              status,
              startTime,
              expertUser,
              serviceInfo: {service},
            } = item;
            const {color, icon, nameEs} =
              statusInfoMapping[status || TimeslotStatus.pending];
            const currSpeciality =
              gSpecialities[
                (service.speciality as SpecialityKey) || SpecialityKey.psycho
              ];
            const currFormat =
              serviceFormatMap[service.format as ServiceFormatKey];
            const currUser =
              selectedTab !== appointmentTabMapping.appointmentsCurious.key
                ? curiousUser
                : expertUser;
            return (
              <GCard style={[C.m6]}>
                <GPill
                  wrapStyle={[C.absolute, C.top_8, C.right_8]}
                  text={currSpeciality.name}
                  icon={currSpeciality.emoji}
                />
                <GPill
                  wrapStyle={[C.absolute, C.top_8, C.left_8]}
                  text={nameEs}
                  color={color}
                  icon={icon}
                />
                <View style={[C.row, C.itemsCenter, C.justifyStart, C.wFull]}>
                  <GAvatar
                    wrapStyle={[C.w40]}
                    showName
                    image={currUser.avatar}
                    name={currUser.nameShort}
                    subtitle={
                      currUser.avgReview &&
                      `⭐️ ${currUser.avgReview} (${currUser.nReviews})`
                    }
                  />
                  {/*<GAvatar
                    showName
                    image={expertUser.avatar}
                    name={expertUser.nameShort}
                    subtitle={
                      expertUser.avgReview &&
                      `⭐️ ${expertUser.avgReview} (${expertUser.nReviews})`
                    }
                  />*/}

                  <View style={[C.mx4, C.top_4]}>
                    <GText type={TextType.h3Orange}>{service.name}</GText>
                    {service.description && (
                      <GText type={TextType.tabSelected}>
                        {service.description}
                      </GText>
                    )}
                    {/*<GIconRow
                    selected
                    color={palette.purpleSec}
                    text={service.name}
                    descr={service.description}
                    icon={currFormat.icon}
                  />*/}

                    <GIconRow
                      icon={'calendar'}
                      text={dayjs(startTime).format(DATE_USER)}
                    />

                    <GIconRow
                      icon={'clock'}
                      text={`De ${dayjs(startTime).format(DATE_HOUR)} a ${dayjs(
                        endTime,
                      ).format(DATE_HOUR)}`}
                    />
                    <GIconRow
                      icon={currFormat.icon}
                      title={service.price}
                      color={palette.purpleSec}
                      secColor={palette.redSec}
                      selected
                      actionIcon={'euro-symbol'}
                      text={currFormat.name + ' (' + service.duration + 'min)'}
                    />
                    {/* <GIconRow
                      title={currFormat.name}
                      color={palette.purpleSec}
                      icon={currFormat.icon}
                      text={currFormat.descr}
                    />*/}
                    {/*<GIconRow
                      text={status}
                      color={statusColorMapping[status]}
                    />*/}
                  </View>
                  {/*<GAvatar
              showName
              image={item.expert_user.avatar}
              name={item.expert_user.name}
            />*/}
                  {/*<Text numberOfLines={1}>{JSON.stringify(data)}</Text>*/}
                </View>
              </GCard>
            );
          }}
          keyExtractor={item => item.id}
        />
      )}
    </View>
  );
};
