import React, {PropsWithChildren, useEffect, useState} from 'react';
import {FlatList, SafeAreaView, ScrollView} from 'react-native';
import C from 'consistencss';
import GSpinner, {EmptyResults} from '../atoms/GSpinner';
import UserCard from '../organisms/UserCard';
import useResponsive from '../ui/helpers/useResponsive';
import {useFilterContainer} from '../filters/FilterContainer';
import {useLocation} from 'react-router-dom';
import {
  gSpecialities,
  ServiceFormatKey,
  serviceFormatMap,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import {useSpecialityCategorySelector} from '../types/SpecialityProvider';
import {useFormatFilter} from '../filters/PillFilters';
import {apiCaller, expertFilterURL} from '../api/api';
import {useLangSelector} from '../FormSelector/LanguageSelector';

export const UserList: React.FC<
  PropsWithChildren<{
    title?: string;
  }>
> = () => {
  /* const nav = useGNav();*/
  const {resp, isHuge, isSmall} = useResponsive();
  //const {category, specialityKey, serviceFormat, keywords} = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extraer los valores de los queryParams
  const categoryKey = queryParams.get('category');
  const specialityKey = queryParams.get('specialityKey');
  const serviceFormat = queryParams.get('serviceFormat');
  const keywords = queryParams.get('keywords')?.split(',') || [];

  const [experts, setExperts] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    FilterComponents,
    matchingUsers,
    showConnected,
    showAvailableSoon,
    showReal,
    showTopRated,
    onReset,
  } = useFilterContainer();

  const [
    FormatSelector,
    selectedFormatKey,
    /*matchingFormatUsers,*/
    resetServices,
  ] = useFormatFilter({
    users: matchingUsers,
    showSummary: true,
    initialFormat: serviceFormat as ServiceFormatKey,
  });

  const numColumnsValue = /*Math.round(
    (deviceWidth - BASE_PIXEL * 6) / (BASE_PIXEL * 120),
  );*/ isHuge ? 3 : isSmall ? 1 : 2;
  const {
    selectedSpecialityKey,
    onResetSpecialities,
    currentCategoryKey,
    selectedKeywords,
    CategoriesGroupSelector,
  } = useSpecialityCategorySelector({
    storeChange: true,
    initialCategory: categoryKey || undefined,
    initialSpeciality: specialityKey as SpecialityKey,
    initialKeywords: keywords,
  });

  const {LanguageSelector, currLang} = useLangSelector({
    /*initialLang: editUser?.languages || [],*/
  });
  const getMatchingExperts = async () => {
    setLoading(true);
    console.log(
      'heora about to check filter',
      selectedSpecialityKey,
      currentCategoryKey,
      selectedKeywords,
    );
    const {data: filterUsersDB} = await apiCaller({
      url: expertFilterURL({
        speciality: selectedSpecialityKey,
        category: currentCategoryKey,
        isOnline: showConnected,
        languages: currLang.map(({value}) => value),
        availableSoon: showAvailableSoon,
        format: selectedFormatKey,
        topRated: showTopRated,
        showReal,
        keywords:
          selectedKeywords?.map(({value}) => value) || [] /*.join(',')*/,
      }),
    });
    //const combinedData = [...filterUsersDB, ...matchingSpecialityExperts];

    setExperts(filterUsersDB);
    setLoading(false);
  };
  /** Update on every change*/
  useEffect(() => {
    getMatchingExperts();
  }, [
    selectedFormatKey,
    showConnected,
    showAvailableSoon,
    selectedSpecialityKey,
    selectedKeywords,
    currLang,
    showReal,
    showTopRated,
    categoryKey,
    currentCategoryKey,
  ]);

  const resetFilters = async () => {
    if (onReset) {
      onReset();
    }
    if (resetServices) {
      resetServices();
    }
    onResetSpecialities();
    await getMatchingExperts();
  };

  return (
    <SafeAreaView
      style={[C.bgWhite]}
      /*collapsibleHeader
            contentColumnSpan={10}
            backgroundColor={speciality ? palette.white : palette.background}
            iconRight={'plus-circle'}
            title={(!IS_WEB && 'Expertos') || undefined}*/
    >
      {/** Matching Users */}
      {/*{!speciality && (
        <GText align={Alignment.Left} style={[C.my3]} type={TextType.h3Orange}>
          {trans(`Qué piensas?`)}
        </GText>
      )}*/}
      {/*{!speciality && <GSearchBar withAudio placeholder={'Comparte dudas, ideas...'} leftIcon={'microphone'} />}*/}
      {/*{!IS_WEB && <AudioRecordInput />}*/}
      {/*<GText>{JSON.stringify(matchingUsers)}</GText>*/}
      {/*<GText align={Alignment.Left} style={[C.mb3]} type={TextType.h3Orange}>
        {trans(
          `Comparte ${
            currService?.key ? serviceMap[currService.key as ServiceFormatKey].emoji + currService?.key : ''
          } con uno de los ${formatNumber(matchingUsers?.length)} ${
            matchingUsers?.length === combinedUsers?.length ? '' : ' de ' + formatNumber(combinedUsers?.length)
          }  expertos ${currSpeciality?.name ? 'en ' + currSpeciality?.emoji + currSpeciality?.name : ''}`,
        )}
      </GText>*/}
      {/*<View style={[C.row, C.justifyCenter, C.itemsCenter]}>*/}
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[
          C.row,
          C.selfCenter,
          C.itemsStart,
          C.mb4,
          C.justifyStart,
        ]}
      >
        <FormatSelector />
        {CategoriesGroupSelector}
        {LanguageSelector}
        {FilterComponents}
      </ScrollView>
      {/*</View>*/}

      {/*
      <Text>Me llamo maria {JSON.stringify(selectedSpecialityKey)}</Text>
*/}
      {loading ? (
        <GSpinner full={false} />
      ) : (
        <FlatList ///*<FlashList*/
          data={experts /*[...matchingSpecialityExperts, ...experts]*/}
          //estimatedItemSize={matchingUsers?.length || 13000}
          /*extraData={[currService, currSpeciality, isConnected]}*/
          keyExtractor={({id, username}: UserType) => id || '' + username}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={C.selfStart}
          style={[isSmall && C.pr4, resp(0.8).height, C.py3]}
          key={`flatlist-${numColumnsValue}`}
          numColumns={numColumnsValue}
          ListEmptyComponent={
            <EmptyResults
              text={`Aún no tenemos resultados para ${
                selectedSpecialityKey
                  ? gSpecialities[selectedSpecialityKey]?.name
                  : ' ninguna especialidad'
              } ${
                selectedFormatKey
                  ? serviceFormatMap[selectedFormatKey as ServiceFormatKey]
                      ?.name
                  : ''
              }`} /*estando ${isConnected ? 'online' : ''}`*/
              onPress={resetFilters}
            />
          }
          renderItem={({item: currUser}) => (
            <UserCard
              showInitialTab
              initialSpeciality={selectedSpecialityKey}
              user={currUser}
            />
          )}
        />
      )}
    </SafeAreaView>
  );
};
