import C from 'consistencss';
import React, {ReactNode} from 'react';
import {Pressable, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import GCard from '../molecules/GCard';
import EeSecondaryHeader from '../organisms/header/ee-secondary-header';

export type DialogProps = {
  children: ReactNode;
  title?: string;
  onClose?: () => void;
  cardStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  center?: boolean;
  hideHeader?: boolean;
};

export function GModalCenterMobile({
  children,
  title,
  onClose,
  containerStyle,
  center,
  hideHeader = false,
}: DialogProps) {
  return (
    <View style={[C.px4, C.justifyCenter, StyleSheet.absoluteFillObject, C.bgShadow, center && C.itemsCenter]}>
      <Pressable onPress={onClose} style={[C.absolute, C.bgLayout, C.flex, StyleSheet.absoluteFillObject]} />
      <GCard style={[C.minhHalf, C.itemsCenter, containerStyle]}>
        {!hideHeader && <EeSecondaryHeader title={title} isModal onPressRight={onClose} />}
        {children}
      </GCard>
    </View>
  );
}

export default GModalCenterMobile;
