import {apiCaller, TYPES_API} from './api';
import {CredentialResponse, GoogleLogin} from '@react-oauth/google';
import React, {useEffect, useState} from 'react';
import {
  currentUserAtom,
  deleteToken,
  setToken,
  useCurrentUser,
} from '../store/useUser';
import {currentLocale} from '../store/language/countryMap';
import {
  ConfirmationResult,
  getAuth,
  GoogleAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from 'firebase/auth';
import compatApp from 'firebase/compat/app';

import {
  ButtonSize,
  ButtonType,
  FormTypes,
  GButton,
  GFormInput,
} from '../molecules';
import {useRecoilState, useResetRecoilState} from 'recoil';
import {useGNav} from '../routes/useGNav';
import useResponsive from '../ui/helpers/useResponsive';
import {View} from 'react-native';
import C from 'consistencss';
import {StyledFirebaseAuth} from 'react-firebaseui';
import {useFirebaseApp} from 'reactfire';
import {Api_Routes} from 'oralify-common';
import Toast from 'react-native-toast-message';
import {headerScreens, modalScreens} from '../routes/useGNav.web';
import {SETTINGS_STEPS} from '../types/stepTypes';
import {useNavigate} from 'react-router-dom';
import {firebaseAuth, gAnalytics, gLogEvent} from '../store/firebaseInit';
import {setUserProperties} from 'firebase/analytics';
import {useModal} from '../modals/useModal';
/*import auth from '@react-native-firebase/auth';*/

/*export function authUserToUserType(authUser: AuthUser): UserType {
  const currName =
    authUser?.user_metadata?.full_name || authUser.email
      ? cleanMail(authUser.email)
      : '';
  return cleanObjDB({
    id: authUser.id, //auth: authUser,
    phone: authUser.phone,
    email: authUser.email,
    auth_id: authUser?.app_metadata?.provider || 'phone',
    creation_date: new Date(authUser.created_at),
    name: currName,
    username: generateUsername(currName),
    avatar: authUser.user_metadata?.avatar_url || '',
    is_connected: true,
    email_verified: !!authUser.email_confirmed_at,
    phone_verified: !!authUser.phone_confirmed_at,
  });
}*/

export const useGoogleDBLogin = (onSuccess?: () => void) => {
  const [currentUser, setUser] = useRecoilState(currentUserAtom);
  const navigate = useNavigate();
  const {closeModal} = useModal(modalScreens.login);

  const handleGoogleLogin = async (credential: CredentialResponse) => {
    if (credential.credential) {
      /**Supabase version
    const {data, error} = await supabaseWeb.auth.signInWithIdToken({
        provider: 'google',
        token: credential.credential,
      }); * */
      /**Firebase*/
      const googleCredential = GoogleAuthProvider.credential(
        credential.credential,
      );
      const firebaseResult = await signInWithCredential(
        firebaseAuth,
        googleCredential,
      );
      console.log('Firebase User: (Google Login) ', firebaseResult);

      const token = await firebaseResult.user.getIdToken();
      const uid = firebaseResult.user.uid;
      // Luego de autenticar con Firebase, enviamos el uid y el token al backend
      const {data: backendResponse} = await apiCaller({
        url: Api_Routes.auth.signInWithId,
        type: TYPES_API.POST,
        token: token,
        params: {
          tokenId: token,
          id: uid,
        },
      });

      if (backendResponse) {
        console.log('hore trying to seet:', backendResponse);
        if (backendResponse.token) await setToken(backendResponse.token);
        if (backendResponse.user) {
          setUser(backendResponse.user);
          /** Analytics*/
          setUserProperties(gAnalytics, backendResponse.user);
          gLogEvent('signIn', {provider: 'google', ...backendResponse.user});
          Toast.show({
            text1:
              'Login correcto, bienvenido ' +
              backendResponse.user.email +
              backendResponse?.message,
            type: 'success',
          });
        }
        if (onSuccess) onSuccess();
        if (backendResponse.message === 'User already exists') closeModal();
        navigate(
          headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
        );
      }
    }
  };
  const {isSmall} = useResponsive();

  /*const a = useGoogleOneTapLogin({
                      onSuccess: handleGoogleLogin,
                      onError: () => {
                        console.log('error On tap');
                      },
                    });*/

  const GoogleLoginComp = () => (
    <GoogleLogin
      onSuccess={handleGoogleLogin}
      size={'large'}
      text={'continue_with'}
      width={'240'}
      context={'use'}
      logo_alignment={'center'}
      locale={currentLocale}
      ux_mode={'popup'}
      shape={'pill'}
      onError={() => {
        console.log('Login Failed');
      }}
      auto_select={false}
      useOneTap={!currentUser && !isSmall}
    />
  );
  return {
    GoogleLoginComp,
    handleGoogleLogin,
  };
};
export const usePhoneLoginNew = () => {
  const [currentUser, setUser] = useRecoilState(currentUserAtom);
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const handleAuthResult = async (authResult: {user: any}) => {
    const user = authResult.user;

    if (user && user.phoneNumber) {
      console.log('welcome new user', user);
      const token = await user.getIdToken();
      /*await setToken(token);*/
      const {data: backendResult} = await apiCaller({
        url: Api_Routes.auth.signInWithId,
        type: TYPES_API.POST,
        params: {
          id: user.uid,
          token,
        },
      });

      if (backendResult?.user) setUser(backendResult?.user);
      if (backendResult?.message)
        Toast.show({text1: backendResult.message, type: 'success'});
      if (backendResult?.message === 'User already exists')
        navigate(
          headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
        );
    }
  };

  const PhoneVerificationCodeInput = () => (
    <View style={[C.itemsCenter]}>
      <StyledFirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          signInSuccessUrl: '/',
          popupMode: true,
          signInOptions: [PhoneAuthProvider.PROVIDER_ID],
          callbacks: {
            signInSuccessWithAuthResult: async authResult => {
              await handleAuthResult(authResult);
              return false; // Evita redirecciones después del inicio de sesión
            },
          },
        }}
        firebaseAuth={firebaseAuth}
      />
    </View>
  );

  return {
    PhoneVerificationCodeInput,
  };
};

export const usePhoneLoginCaptcha = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verifCode, setVerifCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [currUser, setUser] = useRecoilState(currentUserAtom);
  const navigate = useNavigate();

  /*const onSignInSubmit = () => {};*/

  // Inicializa el reCAPTCHA
  const recaptchaVerifier = new RecaptchaVerifier(
    getAuth(),
    'recaptcha-container',
    {
      /*size: 'invisible',
      callback: response => {
        // reCAPTCHA resuelto, permite signInWithPhoneNumber.
        onSignInSubmit();
      },*/
    },
  );
  // Función para enviar el código de verificación
  const requestVerify = async () => {
    try {
      // Solicita enviar el código
      const confirmationResult = await signInWithPhoneNumber(
        firebaseAuth,
        phoneNumber,
        recaptchaVerifier,
      );
      // Guarda el ID de verificación para usarlo luego
      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      // Manejo de errores, por ejemplo: mostrar mensaje al usuario
      console.error(error);
      // Restablece el reCAPTCHA si hay un error
      /*window.recaptchaVerifier.render().then(function(widgetId) {
        grecaptcha.reset(widgetId);
      });*/
    }
  };

  // Función para verificar el código ingresado por el usuario
  const verifyCode = async (code = verifCode) => {
    try {
      // Crea la credencial con el ID de verificación y el código
      const credential = PhoneAuthProvider.credential(verificationId, code);
      // Inicia sesión con la credencial
      const result = await signInWithCredential(firebaseAuth, credential);
      // Usuario ha iniciado sesión correctamente
      const user = result.user;
      if (user && user.phoneNumber && !currUser) {
        /*setNewUser(user);*/
        console.log('welcome new user', user);
        const token = await user.getIdToken();
        await setToken(token);
        const {data: authResult} = await apiCaller({
          url: Api_Routes.auth.signInWithId,
          type: TYPES_API.POST,
          params: {
            id: user.uid,
            token,
            /*phone: user.phoneNumber,*/
            /*token: user.getIdToken(),*/
          },
        });

        if (authResult?.user) {
          setUser(authResult?.user);
          /** Analytics*/
          setUserProperties(gAnalytics, authResult.user);
          gLogEvent('signIn', {provider: 'phone', ...user});
        }
        if (authResult?.message)
          Toast.show({text1: authResult.message, type: 'success'});
        if (authResult?.message === 'User already exists')
          navigate(
            headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
          );
      }
      // Continúa con la lógica post inicio de sesión (e.g. navegación o establecimiento de estado)
    } catch (error) {
      // Manejo de errores, por ejemplo: mostrar mensaje al usuario
      console.error(error);
    }
  };

  const PhoneLoginComp = () => (
    <View>
      <GFormInput
        formType={FormTypes.phone}
        onSetText={phone => {
          setPhoneNumber(phone);
        }}
      />
      <GButton onPress={() => requestVerify()} text={'Continuar'} />
      <GFormInput
        formType={FormTypes.phone}
        onSetText={phone => {
          setVerifCode(phone);
        }}
      />
      <GButton onPress={() => requestVerify()} text={'submit'} />
    </View>
  );

  // Devuelve las funciones y estados para que puedan ser utilizados fuera del hook
  return {
    setPhoneNumber,
    requestVerify,
    PhoneLoginComp,
    verifyCode,
  };
};

export const usePhoneLogin = (onSuccess?: () => void) => {
  const [phoneNumber, setPhoneNumber] = useState('692842705');
  const [verificationCode, setVerificationCode] =
    useState<ConfirmationResult>();
  const [currUser, setUser] = useRecoilState(currentUserAtom);
  const [stepVerify] = useState(false);
  const {closeModal} = useModal(modalScreens.login);

  const navigate = useNavigate();

  useEffect(() => {
    if (!currUser) {
      const unregisterAuthObserver = firebaseAuth.onAuthStateChanged(
        async user => {
          if (user && user.phoneNumber && !currUser) {
            /*setNewUser(user);*/
            console.log('welcome new user', user);
            const token = await user.getIdToken();
            /*await setToken(token);*/
            const {data: authResult} = await apiCaller({
              url: Api_Routes.auth.signInWithId,
              type: TYPES_API.POST,
              token,
              params: {
                id: user.uid,
                /*token,*/
                /*phone: user.phoneNumber,*/
                /*token: user.getIdToken(),*/
              },
            });

            if (authResult?.user) {
              setUser(authResult?.user);
              /** Analytics*/
              setUserProperties(gAnalytics, authResult.user);
              gLogEvent('signIn', {provider: 'phone', ...user});
            }
            if (authResult?.message)
              Toast.show({text1: authResult.message, type: 'success'});
            if (authResult.token) await setToken(authResult.token);
            if (onSuccess) onSuccess();
            if (authResult?.message === 'User already exists')
              navigate(
                headerScreens.profile.pathMain +
                  '/' +
                  SETTINGS_STEPS.VISIBILITY,
              );
            closeModal();

            /**TODO add token to ASYNC STORAGE*/
            /*const accessToken = await user.getIdTokenResult();
                                                                                  console.log('Supabase trying to log:', accessToken);
                                                                                  const {
                                                                                    data: {user: userSupabase},
                                                                                    error,
                                                                                  } = await supabaseWeb.auth.sig({
                                                                                    provider: accessToken.signInProvider,
                                                                                    token: accessToken.token,
                                                                                  });
                                                                                  console.log('Supabase signInWithIdToken:', userSupabase, error);
                                                                                  if (userSupabase) {*/
            /*handleAuthUser({...user, id: user.uid, phone: user.phoneNumber});*/
            /*}*/
          }
        },
      );
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }
  }, [currUser]);

  const PhoneVerificationCodeInput = () => (
    <View style={[C.itemsCenter]}>
      <StyledFirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          siteName: 'oralify',
          signInSuccessUrl: '/',
          popupMode: true,
          signInOptions: [PhoneAuthProvider.PROVIDER_ID],
          callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false,
            /* async (authResult, redirectUrl) => {*/
          },
        }}
        firebaseAuth={compatApp.auth().app.auth()}
      />
    </View>
  );

  return {
    phoneNumber,
    PhoneVerificationCodeInput,
    setPhoneNumber,
    verificationCode,
    setVerificationCode,
    submitted: stepVerify,
  };
};
export const usePhoneLoginNewProp = () => {
  const [phoneNumber, setPhoneNumber] = useState('692842705');
  const [verificationCode, setVerificationCode] =
    useState<ConfirmationResult>();
  const [currentUser, setUser] = useRecoilState(currentUserAtom);
  const [stepVerify, setStepVerify] = useState(false);
  const navigate = useNavigate();

  const handlePhoneLogin = async user => {
    console.log(' got this user: ', user);
    if (user && user.phoneNumber) {
      console.log('welcome new user', user);
      const token = await user.getIdToken();
      await setToken(token);
      const {data: backendResult, error} = await apiCaller({
        url: Api_Routes.auth.signInWithId,
        type: TYPES_API.POST,
        params: {
          id: user.uid,
          token,
        },
      });
      if (error) Toast.show({text1: 'Error al iniciar sesión', type: 'error'});

      if (backendResult?.user) {
        setUser(backendResult?.user);
        /** Analytics*/
        setUserProperties(gAnalytics, user);
        gLogEvent('signIn', {provider: 'phone', ...user});
      }
      if (backendResult?.message)
        Toast.show({text1: backendResult.message, type: 'success'});
      if (backendResult?.message === 'User already exists')
        navigate(
          headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
        );
    }
  };
  /*const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
    if (currentUser === undefined) {
      return;
    }

    if (user && user.phoneNumber) {
      /!*setNewUser(user);*!/
      console.log('welcome new user', user);
      const token = await user.getIdToken();
      await setToken(token);
      const {data: authResult} = await apiCaller({
        url: Api_Routes.auth.signInWithId,
        type: TYPES_API.POST,
        params: {
          id: user.uid,
          token,
          /!*phone: user.phoneNumber,*!/
          /!*token: user.getIdToken(),*!/
        },
      });

      if (authResult?.user) setUser(authResult?.user);
      if (authResult?.message)
        Toast.show({text1: authResult.message, type: 'success'});
      if (authResult?.message === 'User already exists')
        navigate(
          headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
        );
      /!**TODO add token to ASYNC STORAGE*!/
      /!*const accessToken = await user.getIdTokenResult();
                                                                                  console.log('Supabase trying to log:', accessToken);
                                                                                  const {
                                                                                    data: {user: userSupabase},
                                                                                    error,
                                                                                  } = await supabaseWeb.auth.sig({
                                                                                    provider: accessToken.signInProvider,
                                                                                    token: accessToken.token,
                                                                                  });
                                                                                  console.log('Supabase signInWithIdToken:', userSupabase, error);
                                                                                  if (userSupabase) {*!/
      /!*handleAuthUser({...user, id: user.uid, phone: user.phoneNumber});*!/
      /!*}*!/
    }
  });*/
  /*useEffect(() => {
    unregisterAuthObserver();
    return () => {
      unregisterAuthObserver();
    };
  }, []);*/

  const PhoneVerificationCodeInput = () => (
    <View style={[C.itemsCenter]}>
      <StyledFirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          siteName: 'oralify',
          signInSuccessUrl: '/',
          popupMode: true,
          signInOptions: [PhoneAuthProvider.PROVIDER_ID],
          callbacks: {
            signInSuccessWithAuthResult: async authResult => {
              console.log('hoer got this user: ', authResult);
              await handlePhoneLogin(authResult?.user);
              return false; // Evita redirecciones después del inicio de sesión
            },
          },
        }}
        firebaseAuth={firebaseAuth}
      />
    </View>
  );

  return {
    phoneNumber,
    PhoneVerificationCodeInput,
    setPhoneNumber,
    verificationCode,
    setVerificationCode,
    submitted: stepVerify,
  };
};

export const useGoogleDBLogout = (onSuccess?: () => void) => {
  const {signOut} = useCurrentUser();
  const navigate = useGNav();

  const resetUser = useResetRecoilState(currentUserAtom);
  const [loading, setLoading] = useState(false);

  const handleSignOut = async () => {
    setLoading(true);
    resetUser();
    signOut();
    await deleteToken();
    await firebaseAuth.signOut();
    /*if (firebaseUser) await firebaseSignOut();*/
    setLoading(false);
    navigate('/');
    gLogEvent('signOut');
    // haz cualquier otra tarea necesaria al cerrar la sesión
    onSuccess && onSuccess();
  };

  const GoogleLogoutComp = () => (
    <GButton
      text={'Cerrar Sesión'}
      icon={'log-out'}
      type={ButtonType.secondary}
      loading={loading}
      size={ButtonSize.medium}
      onPress={handleSignOut}
    />
  );

  return {
    GoogleLogoutComp,
    handleSignOut,
    loading,
  };
};
/*function useAuthUser() {
  throw new Error('Function not implemented.');
}*/
