import React from 'react';
import {FlatList, View} from 'react-native';
import {
  FilterType,
  ideaMap,
  palette,
  serviceFormatMap,
  ServiceType,
  specialityCategories,
} from 'oralify-common';
import {GPill} from '../molecules/gPill';
import C from 'consistencss';
import useResponsive from '../ui/helpers/useResponsive';
import {GIconRow} from '../molecules/GIconRow';
import {headerScreens, routeQueryParam} from '../routes/useGNav.web';
import {useNavigate} from 'react-router-dom';

export const HowToDiagram = ({transp = false}) => {
  const navigate = useNavigate();
  const handlePillPress = (name: string) => {
    console.log(`Pill pressed: ${name}`);
  };
  const {isSmall, resp} = useResponsive();

  const renderPills = (
    data: FilterType[] | ServiceType[],
    color?: string,
    currIndex?: number,
  ) => (
    <FlatList
      horizontal={isSmall}
      showsHorizontalScrollIndicator={false}
      data={isSmall ? data.slice(0, 5) : data}
      style={isSmall && resp().width}
      keyExtractor={item => item?.format || item.name}
      renderItem={({item, index}) => (
        <GPill
          key={item.name}
          color={color}
          long={!isSmall}
          transp={transp}
          wrapStyle={
            currIndex === 0
              ? C.selfEnd
              : currIndex === 2
              ? C.selfStart
              : C.selfCenter
          }
          text={item.name || item.format}
          selected /*={isSmall}*/
          icon={item.emoji || item.icon}
          onPress={() => {
            navigate(
              routeQueryParam(headerScreens.expertSearch.path, {
                category: 'all',
                specialityKey: item.specialityKey,
                serviceFormat: item.format,
              }),
            );
            handlePillPress(item.name);
          }}
        />
      )}
    />
  );

  return (
    <View style={[!isSmall ? C.row : C.selfStart, C.itemsCenter]}>
      <View style={[C.p4, isSmall && C.row]}>
        {renderPills(Object.values(ideaMap), palette.purpleSec, 0)}
      </View>
      <GIconRow
        horizontal={isSmall}
        descr={'Formato'}
        icon={isSmall ? 'arrow-down' : 'arrow-right'}
        color={transp ? palette.white : palette.redSec}
      />
      <View style={[C.p4, isSmall && C.row]}>
        {renderPills(Object.values(serviceFormatMap), palette.redSec, 1)}
      </View>
      <GIconRow
        horizontal={isSmall}
        descr={'Especialidad'}
        icon={isSmall ? 'arrow-down' : 'arrow-right'}
        color={transp ? palette.white : palette.orangeSec}
      />
      <View style={[C.p4, isSmall && C.row]}>
        {renderPills(
          Object.values(specialityCategories).slice(0, 8),
          palette.orangeSec,
          2,
        )}
      </View>
    </View>
  );
};
