import React, {createContext, ReactElement, useContext} from 'react';
import {atom, useRecoilState} from 'recoil';
import {recoilPersist} from 'recoil-persist';
import {generateUsername} from '../api/userApi';
import {Gender, SpecialityKey, UserType} from 'oralify-common';
import {expertFilterURL, useGetAPI} from '../api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {WeekViewEvent} from 'react-native-week-view';

export const {persistAtom} =
  recoilPersist(/*{key: 'user', storage: localStorage}*/);

export const currentUserAtom = atom<UserType | undefined>({
  key: 'currentUser',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const googleCalendarEvents = atom<WeekViewEvent[]>({
  key: 'googleCalendarEvents',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const userTokenState = atom<string | null>({
  key: 'userTokenState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

const TOKEN_STORAGE_KEY = 'USER_TOKEN';

export const getToken = async () => {
  try {
    return await AsyncStorage.getItem(TOKEN_STORAGE_KEY);
  } catch (error) {
    console.error('Error al obtener el token:', error);
    return null;
  }
};

export const setToken = async (token: string) => {
  try {
    await AsyncStorage.setItem(TOKEN_STORAGE_KEY, token);
  } catch (error) {
    console.error('Error al guardar el token:', error);
  }
};

export const deleteToken = async () => {
  try {
    await AsyncStorage.removeItem(TOKEN_STORAGE_KEY);
  } catch (error) {
    console.error('Error al eliminar el token:', error);
  }
};
/** @deprecated*/
/*export const useAuthUser = () => {
  const [loading, setLoading] = useState(false);
  const setRecoilUser = useSetRecoilState(currentUserSelector);

  const handleAuthUser = async (authUser: AuthUser) => {
    setLoading(true);
    const dbUser = authUserToUserType(authUser);
    console.log('About to add', dbUser, authUser);
    const result = await upsertUserToDatabase(dbUser);
    setRecoilUser(dbUser);
    console.log('Added current user: ', dbUser, ' from ', authUser, result);
    setLoading(false);
  };

  return {handleAuthUser, loading};
};*/

const UserContext = createContext<{
  currentUser?: UserType;
  setUser(user?: UserType): void;
}>({currentUser: undefined, setUser(user: UserType) {}});

export const useCurrentUser = () => {
  const context = useContext(UserContext);
  const signIn = (user: UserType) => {
    context.setUser(user);
  };
  const signOut = () => {
    context.setUser(undefined);
  };
  return {
    ...context,
    hasUser: !!context.currentUser,
    signIn,
    signOut,
  };
};

export const UserProvider = ({children}: {children: ReactElement}) => {
  const [currentUser, setCurrentUser] = useRecoilState<UserType | undefined>(
    currentUserAtom,
  );

  /*useEffect(() => {
        if (currentUser?.id !== undefined) {
          fetchUser({userId: currentUser.id}).then(() =>
            updateConnectionStatus(currentUser?.id, true),
          );
  
          return () => {
            if (currentUser?.id !== undefined) {
              updateConnectionStatus(currentUser.id, false).then(r =>
                console.log(' User disconnected ', r, currentUser),
              );
            }
          };
        } /!*else if (currentUser === undefined && setCurrentUser !== undefined) {
          setCurrentUser(undefined);
        }*!/
      }, [currentUser, setCurrentUser]);*/

  const setUser = (user: UserType | undefined) => {
    setCurrentUser(user);
  };
  return (
    <UserContext.Provider value={{currentUser: currentUser, setUser}}>
      {children}
    </UserContext.Provider>
  );
};

/*export const useAllUsers = () => {
  const {users: allMockUsers} = useUsersDB();
  const {data, status, isLoading} = useQuery(['allUsers'], fetchAllUsers);
  return {users: data || [], status, isLoading};
};*/

export const useUsersDB = () => {
  const {data: usersDB, isLoading} = useGetAPI({
    url: expertFilterURL({}),
  });
  return {
    users: usersDB /*? [...usersDB, ...mockUsers] : mockUsers*/,
    isLoading,
  };
};

export const createUser: (user?: UserType) => UserType = (
  user: UserType = {},
) => {
  const {
    id = '',
    languages = [],
    speciality = SpecialityKey.startupAdvisor,
    role /*timeslots = [],*/,
    name = '',
    creation_date = new Date(),
    email = '' /*speciality = SpecialityKey.listener,*/,
    avatar = '',
    username = generateUsername(name, speciality),
    services,
    reviews = 0,
    birthday = new Date(),
    education = '',
    phone = '',
    score = 0,
    is_connected = false,
    email_verified = false,
    phone_verified = false,
    sex = Gender.Female,
  } = user;

  return {
    id,
    creation_date,
    name,
    email,
    username,
    role,
    avatar,
    reviews,
    birthday,
    education,
    languages,
    phone,
    score,
    is_connected,
    email_verified,
    phone_verified,
    services,
    sex,
  };
};
