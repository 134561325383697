import {BASE_PIXEL, EeSize} from '../ui';
import C from 'consistencss';
import React, {useRef, useState} from 'react';
import {Animated, FlatList, View} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import {gSpecialities, palette, UserType} from 'oralify-common';
import {GIcon} from '../atoms';
import {GAvatar} from '../molecules/GAvatar';

export const GCarrousel = ({
  data,
  initialIndex = 0,
  withPagination = true,
  autoLoop = true,
  renderItem,
  onSnapToItem,
}: {
  data: any[];
  initialIndex?: number;
  withPagination?: boolean;
  autoLoop?: boolean;
  onSnapToItem?: (num: number) => void;
  renderItem: () => any;
}) => {
  const itemWidth = BASE_PIXEL * 140;
  const scrollX = useRef(new Animated.Value(0)).current;
  const carrouselRef = useRef(null);
  const flatlistRef = useRef(null);
  const [autoPlay, setAutoPlay] = useState(autoLoop);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handlePressAvatar = (index: number) => {
    carrouselRef.current?.scrollTo(index);
    setAutoPlay(false);
    /* flatlistRef.current?.scrollToIndex({
              index,
              viewOffset: index * itemWidth,
              animated: true,
            });*/
    /*{
            animated?: boolean | null | undefined;
            index: number;
            viewOffset?: number | undefined;
            viewPosition?: number | undefined;
          }*/
    setCurrentIndex(index);
  };

  const handleArrowPress = (direction: 'left' | 'right') => {
    const newIndex = direction === 'left' ? currentIndex - 3 : currentIndex + 3;
    if (newIndex >= 0 && newIndex < data.length) {
      carrouselRef.current?.scrollTo(newIndex);
      flatlistRef.current?.scrollToIndex({index: newIndex, animated: true});
      setCurrentIndex(newIndex);
    }
  };

  return (
    <View style={[C.itemsCenter, C.py4]}>
      {withPagination && (
        <View style={[C.selfCenter, C.py4]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {currentIndex > 2 && (
              <GIcon
                icon={'chevron-left'}
                color={palette.white}
                onPress={() => handleArrowPress('left')}
              />
            )}
            <FlatList
              ref={flatlistRef}
              horizontal
              contentContainerStyle={C.itemsCenter}
              style={C.maxw140}
              showsHorizontalScrollIndicator={false}
              data={data}
              renderItem={({item, index}: {item: UserType; index: number}) => (
                <GAvatar
                  wrapStyle={C.m2}
                  name={item.name}
                  online={item.is_connected}
                  badge={
                    item.speciality
                      ? gSpecialities[item.speciality]?.emoji
                      : undefined
                  }
                  onPress={() => handlePressAvatar(index)}
                  image={item.avatar}
                  size={index === currentIndex ? EeSize.avatar : EeSize.emoji}
                />
              )}
              keyExtractor={(item, index) => 'key-' + index}
              onScroll={Animated.event(
                [{nativeEvent: {contentOffset: {x: scrollX}}}],
                {useNativeDriver: false},
              )}
              scrollEventThrottle={16}
              pagingEnabled
              viewabilityConfig={{
                itemVisiblePercentThreshold: 50,
              }}
            />
            {currentIndex < data.length - 3 && (
              <GIcon
                icon={'chevron-right'}
                color={palette.white}
                onPress={() => handleArrowPress('right')}
              />
            )}
          </View>
        </View>
      )}
      <Carousel
        defaultIndex={currentIndex}
        ref={carrouselRef}
        onSnapToItem={index => {
          scrollX.setValue(index * itemWidth);
          setCurrentIndex(index);
          onSnapToItem && onSnapToItem(index);
        }}
        windowSize={5}
        snapEnabled={true}
        style={[C.pt2, C.pb5]}
        modeConfig={{showLength: 2, stackInterval: 30}}
        pagingEnabled
        loop
        mode="horizontal-stack"
        autoPlay={autoPlay}
        autoPlayInterval={3000}
        width={itemWidth}
        height={BASE_PIXEL * 130}
        data={data}
        scrollAnimationDuration={2000}
        renderItem={renderItem}
      />
    </View>
  );
};
