import {ButtonSize, FormWrapper, GButton} from '../molecules';
import React, {useState} from 'react';
import {FlatList, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import {currFormat} from '../types/units';
import dayjs from 'dayjs';
import {GText} from '../atoms';
import {Alignment, eColor, EeSize, eSizes, TextType} from '../ui';
import useResponsive from '../ui/helpers/useResponsive';
import {GIconRow} from '../molecules/GIconRow';
import {centerRow} from '../molecules/gInfoRow';
import GSlider from '../molecules/GSlider';
import {
  Api_Routes,
  AvailabilitySettingsType,
  DayKey,
  palette,
  RecurrenceKey,
  ServiceFormatKey,
  ServiceType,
} from 'oralify-common';
import {useRecoilState} from 'recoil';
import {
  currentStepAtom,
  newServiceAtom,
  SERVICE_STEPS,
} from '../types/stepTypes';
import {currentUserAtom} from '../store/useUser';
import {CheckboxGrad} from '../components/Cards';
import {GPill} from '../molecules/gPill';
import {useModal} from '../modals/useModal';

import {modalScreens} from '../routes/useGNav.web';
import {apiCaller, TYPES_API} from '../api/api';
import Toast from 'react-native-toast-message';
import {trans} from '../store/language';
import {BACKEND_DATE_HOUR} from '../types/utils/dates';
import {RecurrenceSpanish} from './CalendarAvailabilitySelector';

/*export const serviceFormFields = [
  {
    key: 'availability',
    formType: FormTypes.calendar,
    initialValue: 'Weekdays: 9am - 6pm',
    icon: 'calendar-range',
    max: 120,
    placeholder: 'Disponibilidad',
    isRequired: true,
  },
] as FormField[];*/

type ServiceFormProps = {
  onAddService?: (service: ServiceType) => void;
  service?: ServiceType;
};

export const weekDayNames = {
  [DayKey.Monday]: 'lunes',
  [DayKey.Tuesday]: 'martes',
  [DayKey.Wednesday]: 'miércoles',
  [DayKey.Thursday]: 'jueves',
  [DayKey.Friday]: 'viernes',
  [DayKey.Saturday]: 'sábado',
  [DayKey.Sunday]: 'domingo',
};
export const defaultWeek = {
  [DayKey.Monday]: true,
  [DayKey.Tuesday]: true,
  [DayKey.Wednesday]: true,
  [DayKey.Thursday]: true,
  [DayKey.Friday]: true,
  [DayKey.Saturday]: false,
  [DayKey.Sunday]: false,
};

function formatDays(days: DayKey[]) {
  if (!days.length) return '';
  if (days.length === 1) return weekDayNames[days[0]];

  let formatted = '';
  let startDay = days[0];
  let lastDay = days[0];

  for (let i = 1; i < days.length; i++) {
    if (
      Object.values(DayKey).indexOf(days[i]) -
        Object.values(DayKey).indexOf(lastDay) >
      1
    ) {
      formatted +=
        startDay !== lastDay
          ? `${weekDayNames[startDay]} a ${weekDayNames[lastDay]}, `
          : `${weekDayNames[startDay]}, `;
      startDay = days[i];
    }
    lastDay = days[i];
  }
  formatted +=
    startDay !== lastDay
      ? `${weekDayNames[startDay]} a ${weekDayNames[lastDay]}`
      : `${weekDayNames[startDay]}`;
  return formatted;
}

export function convertToWeekObject(
  dayOfWeek: DayKey[] | undefined,
): typeof defaultWeek {
  const weekObject = {...defaultWeek};

  if (dayOfWeek) {
    for (const day in weekObject) {
      weekObject[day as keyof typeof weekObject] = dayOfWeek.includes(
        day as DayKey,
      );
    }
  }

  return weekObject;
}

export const backendHourToNum = (hour: string) => {
  const dateHour = dayjs(hour, 'HH:mm').format('H');
  return Number(dateHour);
};
export const AvailabilitySelector = (
  {
    initialRecurrency,
    currAvailability,
    isEdit = false,
  }: {
    initialRecurrency?: RecurrenceKey;
    currAvailability?: AvailabilitySettingsType;
    isEdit?: boolean;
  } /*{
  onAddService,
  service,
}*/,
) => {
  const [newService, setNewService] = useRecoilState(newServiceAtom);
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  const {
    duration = 1,
    price = 1,
    name = '',
    format = ServiceFormatKey.audio,
  } = newService || {};
  const {closeModal} = useModal(modalScreens.addService);
  const [currStepKey, setCurrStepKey] = useRecoilState(currentStepAtom);
  const [withBreak, setBreak] = useState<boolean>(
    (currAvailability?.breakTime && currAvailability?.breakTime > 0) || true,
  );
  const [sliderSet, setSlider] = useState<boolean>(!!currAvailability);
  const [recurrency, setRecurrency] = useState(
    initialRecurrency || currAvailability?.recurrence,
  );
  const [selectedWeekDays, setSelectedWeekDays] = useState(
    currAvailability?.dayOfWeek
      ? convertToWeekObject(currAvailability?.dayOfWeek)
      : defaultWeek,
  );
  const [loading, setLoading] = useState(false);

  const [currStart, setCurrStart] = useState(
    currAvailability?.startTime
      ? backendHourToNum(currAvailability?.startTime)
      : 9,
  );
  const [currEnd, setCurrEnd] = useState(
    currAvailability?.endTime
      ? backendHourToNum(currAvailability?.endTime)
      : 18,
  );
  const {isSmall} = useResponsive();

  const workingDays = Object.entries(selectedWeekDays)
    .filter(([day, isSelected]) => isSelected)
    .map(([day]) => day as DayKey);
  const readableWorkingDays = formatDays(workingDays);

  const pricePerMin = price / duration;
  /*const pricePerMinOralify = pricePerMin * 0.15;
                                                                      const pricePerMinPaymentFees = pricePerMin * 0.15;*/
  const dailyHours = currEnd - currStart;
  const dailyEarn = dailyHours * pricePerMin * 60;
  const weeklyEarn = dailyEarn * workingDays.length; // TODO add according to selected days
  const monthlyEarn = weeklyEarn * 4.2;
  const dailyTimeSlots = Math.floor(
    (dailyHours * 60) / (duration + (withBreak ? 15 : 0)),
  );

  //console.log('hroee ahora son las ', dayjs().format('DD MM YYYY, HH:mm'));

  const onAddAvailability = async () => {
    setLoading(true);
    const newAvail: AvailabilitySettingsType = {
      expertId: currUser?.id,
      dayOfWeek: workingDays,
      isAvailable: false,
      breakTime: withBreak ? 15 : 0,
      startTime: /*`${currStart}:14`*/ dayjs()
        .hour(currStart)
        .minute(0)
        .second(0)
        .format(BACKEND_DATE_HOUR),
      endTime: dayjs()
        .hour(currEnd)
        .minute(0)
        .second(0)
        .format(BACKEND_DATE_HOUR),
      recurrence: RecurrenceKey.Weekly,
      repeatEvery: 1,
    };
    console.log('Adding new availability: ', newAvail);
    const {data} = await apiCaller({
      url: Api_Routes.availabilityExpert.setAvailabilityExpert,
      params: newAvail,
      type: TYPES_API.POST,
    });
    console.log('Response: ', data);
    setCurrUser(prevUser => ({
      ...prevUser,
      availability:
        prevUser?.availabilityexpert &&
        Array.isArray(prevUser?.availabilityexpert)
          ? [...prevUser.availabilityexpert, newAvail]
          : [newAvail],
    }));
    setLoading(false);
    Toast.show({
      text1: 'Availability added: ' + JSON.stringify(newAvail),
      type: 'success',
    });
    setCurrStepKey(SERVICE_STEPS.SPECIALITY);
    setNewService(undefined);
    closeModal();
  };

  return (
    <View style={[C.itemsCenter]}>
      <View style={[C.itemsCenter, C.top_4, C.flex /*!isSmall && C.row*/]}>
        {/*<GText style={[C.my3]} type={TextType.h3Orange}>
          {JSON.stringify(currStart)}
          {JSON.stringify(currEnd)}
          {JSON.stringify(
            dayjs()
              .hour(currStart)
              .minute(0)
              .second(0)
              .format(BACKEND_DATE_HOUR),
          )}
        </GText>*/}
        <View>
          {/** Recurrency */}
          <FlatList
            data={Object.values(RecurrenceKey)}
            horizontal
            /*numColumns={2}*/
            style={[C.mt4]}
            renderItem={({item: currRecurrency}) => {
              return (
                <GPill
                  key={currRecurrency}
                  selected={recurrency === currRecurrency}
                  wrapStyle={[C.px2, C.py1, C.mr2]}
                  onPress={() => {
                    setRecurrency(currRecurrency as RecurrenceKey);
                  }}
                  /*icon={serviceMap[currentTab].icon}*/
                  color={palette.purpleSec}
                  fontColor={palette.purpleSec}
                  text={RecurrenceSpanish[currRecurrency]}
                />
              );
            }}
          />
          {/** Week day selector*/}
          {recurrency && recurrency === RecurrenceKey.Weekly && (
            <FlatList
              data={Object.entries(selectedWeekDays)}
              /* numColumns={7}*/
              horizontal
              contentContainerStyle={[C.m2]}
              keyExtractor={([key]) => key}
              renderItem={({item: [dayKey, selected]}) => {
                const currColor = selected
                  ? palette.orangeSec
                  : palette.greyish;
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedWeekDays(prev => ({
                        ...prev,
                        [dayKey]: !prev[dayKey as DayKey],
                      }));
                      //showAvailabilityCalendar();
                    }}
                    style={[
                      C.itemsCenter,
                      C.justifyCenter,
                      C.radius10,
                      C.m2,
                      eSizes[EeSize.icon],
                      eColor(currColor).border,
                      C.shadowMd,
                    ]}
                  >
                    <GText
                      type={TextType.tabSelected}
                      color={currColor}
                      align={Alignment.Center}
                      style={[
                        C.capitalize,
                        /*C.py2,*/
                        C.m1,
                      ]}
                    >
                      {weekDayNames[dayKey as DayKey]?.slice(0, 3)}
                    </GText>
                  </TouchableOpacity>
                );
              }}
            />
          )}
        </View>
        {recurrency && (
          <View style={[C.itemsCenter]}>
            <GText style={[C.my3]} type={TextType.h3Orange}>
              {trans('En que horarios puedes ofrecer tus servicios?')}
            </GText>
            {/*<GText style={[C.my3]} type={TextType.h3Orange}>
              end: {JSON.stringify(currEnd)}
              {dayjs().hour(currEnd).minute(0).format(BACKEND_DATE_HOUR)}
            </GText>
            <GText style={[C.my3]} type={TextType.h3Orange}>
              start: {JSON.stringify(currStart)}{' '}
              {dayjs().hour(currStart).minute(0).format(BACKEND_DATE_HOUR)}
            </GText>*/}
            <FormWrapper
              placeholder={`Disp: ${dailyHours} h/día, ${
                workingDays.length > 1
                  ? workingDays.length + ' dias, de '
                  : 'los '
              } ${readableWorkingDays}`}
            >
              {/*<TimelineCalendar viewMode="week" />*/}
              <GSlider
                onSet={val => {
                  setSlider(true);
                  setCurrEnd(val);
                }}
                style={[C.mt4, C.minw140]}
                formatFunc={curr => dayjs().hour(curr).minute(0).format('H:mm')}
                onSetMinRange={val => {
                  setSlider(true);
                  setCurrStart(val);
                }}
                min={0}
                icon={'clock'}
                max={24}
                value={[currStart, currEnd]}
              />
            </FormWrapper>

            {/** Breaks*/}
            {sliderSet && (
              <FormWrapper
                placeholder={`Harías ${dailyTimeSlots} sesiones al día`}
              >
                <CheckboxGrad
                  wrapStyle={C.py2}
                  checked={withBreak}
                  onPress={() => setBreak(!withBreak)}
                  text={'Descansos de 15 min'}
                />
              </FormWrapper>
            )}
          </View>
        )}
      </View>
      {recurrency && sliderSet && (
        <View style={[!isSmall && centerRow]}>
          <GText style={[C.mt3, C.mr4]} type={TextType.h3Orange}>
            Podrías ganar:
          </GText>
          <GIconRow
            icon={'calendar'}
            text={'Día'}
            title={currFormat(dailyEarn * 0.85)}
            /*descr={currFormat(dailyEarn * 0.85)}*/
            secColor={palette.purpleSec}
            color={palette.orangeSec}
          />
          <GIconRow
            icon={'calendar-week'}
            text={'semana'}
            descr={currFormat(weeklyEarn * 0.85)}
            color={palette.orangeSec}
          />
          <GIconRow
            icon={'calendar-month'}
            text={'mes'}
            title={currFormat(monthlyEarn * 0.85)}
            color={palette.orangeSec}
          />
        </View>
      )}
      <View style={[centerRow]}>
        <GButton
          icon={isEdit ? 'pencil' : 'plus'}
          loading={loading}
          disabled={!sliderSet || loading || !recurrency}
          size={ButtonSize.medium} /*/*de ${currName}*/
          text={
            (isEdit ? 'Editar ' : 'Añadir ') +
            ` ${dailyTimeSlots} sesiones, ${workingDays.length} días, ${
              recurrency ? RecurrenceSpanish[recurrency] : ''
            }`
          }
          onPress={() => {
            onAddAvailability();
            closeModal();
          }}
        />
        {/*<GButton
          type={ButtonType.secondary}
          icon={'calendar-plus'}
          size={ButtonSize.medium}
          text={`Seguir añadiendo otros días`}
          onPress={() => onAddAvailability()}
        />*/}
      </View>
    </View>
  );
};
