import {FlatList, ImageBackground, Pressable, View} from 'react-native';
import {gSpecialities, palette, solutionsMap} from 'oralify-common';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import C from 'consistencss';
import {Alignment, eColor, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {centerRow} from '../molecules/gInfoRow';
import useResponsive from '../ui/helpers/useResponsive';
import {GPill} from '../molecules/gPill';
import {headerScreens, routeQueryParam} from '../routes/useGNav.web';

export const CategorySolutions = () => {
  const navigate = useNavigate();
  const {isHuge, resp, isSmall, isBig} = useResponsive();
  const numColumns = isSmall ? 1 : isBig ? 3 : 2;
  const maxWidth = resp(
    isSmall ? 0.84 : 1 / numColumns - 0.05 /*isHuge ? 0.15 : isBig ? 0.2 :
     isSmall ? 0.6 :
     0.3*/,
  ).width;
  return (
    <FlatList
      key={numColumns}
      data={solutionsMap}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={[C.my4, C.itemsCenter, C.selfCenter, resp().width]}
      renderItem={({item}) => (
        <Pressable
          style={[
            eColor(palette.border).border,
            C.overflowHidden,
            C.radius3,
            isHuge ? [C.mb6, C.mr6] : isSmall ? [C.mb4] : [C.mb4, C.mr4],
          ]}
          onPress={() =>
            navigate(
              routeQueryParam(headerScreens.expertSearch.path, {
                category: 'all',
                specialityKey: item.speciality,
              }),
            )
          }
        >
          <ImageBackground
            source={{uri: item.img}}
            /**@ts-ignore*/
            imageStyle={[C.radiustop3]}
            resizeMode="cover"
            resizeMethod={'resize'}
            style={[isHuge ? C.h80 : C.h60, maxWidth]}
          />
          <View style={[C.px4, C.py2, maxWidth]}>
            <View style={[centerRow, C.mt1]}>
              <GIcon icon={item.emoji} wrapStyle={C.mr2} />
              <h3 style={C.m0}>
                <GText numberOfLines={1} type={TextType.bodyBold}>
                  {item.name}
                </GText>
              </h3>
            </View>
            <GText
              numberOfLines={1}
              align={Alignment.Center}
              type={TextType.body}
            >
              {item.subtitle}
            </GText>

            {gSpecialities[item.speciality]?.keywords && (
              <FlatList
                showsHorizontalScrollIndicator={false}
                horizontal /*={!transparent && !isSmall}*/
                keyExtractor={item => item + numColumns}
                style={[C.my2, resp(isSmall ? 0.8 : 0.6).maxWidth]}
                data={gSpecialities[item.speciality]?.keywords}
                renderItem={({item}) => {
                  /*const selected = selectedKeywords.includes(item);*/
                  return (
                    <GPill
                      /*selected={selected}*/
                      long
                      /*transp={transparent}*/
                      text={item}
                      color={palette.purpleSec}
                      icon={'hashtag'}
                      /*onPress={() => handleKeywordPress(item)}*/
                    />
                  );
                }}
              />
            )}
          </View>
        </Pressable>
      )}
      keyExtractor={({speciality}) => speciality}
      numColumns={numColumns}
    />
  );
};
