import React from 'react';
import {GIcon, GText} from '../atoms';
import C from 'consistencss';
import {View} from 'react-native';
import {
  Api_Routes,
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ShownTimeSlotType,
  SpecialityKey,
  statusInfoMapping,
  TimeslotStatus,
  UserConciseType,
  UserType,
} from 'oralify-common';
import {Alignment, gImages, TextType} from '../ui';
import {GIconRow} from '../molecules/GIconRow';
import {apiCaller, TYPES_API, useGetAPI} from '../api/api';
import Toast from 'react-native-toast-message';
import dayjs from 'dayjs';
import {DATE_HOUR} from '../types/utils/dates';
import {useRecoilState} from 'recoil';
import {currentUserAtom} from '../store/useUser';
import {GButton} from '../molecules';
import {useLocation, useNavigate} from 'react-router-dom';
import {GAvatar} from '../molecules/GAvatar';
import GSpinner from '../atoms/GSpinner';
import {GPill} from '../molecules/gPill';
import {headerScreens} from '../routes/useGNav.web';

export const BookingSelect = (/*{timeSlot, currUser}*/) => {
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  //const {expertUsername, serviceId, timeslotId} = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extraer los valores de los queryParams
  /*const expertUsername = queryParams.get('expertUsername');*/
  /*const serviceId = queryParams.get('serviceId');*/
  const timeslotId = queryParams.get('timeslotId');

  const {data: timeslot, isLoading: loadingAppointment} = useGetAPI({
    url: Api_Routes.timeSlot.getTimeSlot.replace(':id', timeslotId || ''),
  });
  /*const {data: expertInfo, isLoading: loadingUser} = useGetAPI({
    url: Api_Routes.user.user.replace(':id', expertUsername),
  });*/
  const onConfirmBooking = async () => {
    try {
      const result = await apiCaller({
        url: Api_Routes.appointment.createAppointment,
        type: TYPES_API.POST,
        params: {
          curiousId: currUser?.id,
          timeSlotId: timeslotId,
        },
      });
      console.log(result);
      if (result.data)
        Toast.show({
          type: 'success',
          text1: 'Appointment created',
        });
      else if (result.error) {
        console.log('hore error', result.error);
        Toast.show({
          type: 'error',
          text1: result.error.message,
        });
      } else
        Toast.show({
          type: 'error',
          text1: 'Error' + ' creando cita',
        });
    } catch (e) {
      Toast.show({
        type: 'error',
        text1: 'Error conocido' + ' creando cita' + e.toString(),
      });
    }
  };
  const navigate = useNavigate();

  const checkShouldBook = () => {
    if (currUser) return onConfirmBooking();
    else navigate(headerScreens.accountLogin.path);
    /* else
             showLoginModal();
     }*/
  };
  return (
    <View style={[C.itemsCenter]}>
      <GText align={Alignment.Center} type={TextType.h3Orange}>
        Esta sería tu próxima cita
      </GText>
      {loadingAppointment ? (
        <GSpinner />
      ) : (
        <>
          {timeslot?.expertUser && (
            <ExpertInfo
              user={timeslot.expertUser}
              speciality={timeslot.service.speciality as SpecialityKey}
            />
          )}
          {timeslot && <CitaInfo appointment={timeslot} />}
        </>
      )}
      <GButton text={'Pedir Cita'} icon={'event'} onPress={checkShouldBook} />
    </View>
  );
};

export const ExpertInfo = ({
  user,
  speciality,
}: {
  user?: UserConciseType;
  speciality?: SpecialityKey;
}) => {
  const {name, emoji} = gSpecialities[speciality || SpecialityKey.psycho];
  return (
    <View style={[C.itemsCenter]}>
      {user && <GAvatar showName name={user.nameShort} image={user.avatar} />}
      <GPill text={name} icon={emoji} />
    </View>
  );
};
export const CitaInfo = ({appointment}: {appointment: ShownTimeSlotType}) => {
  const {color, icon, nameEs} =
    statusInfoMapping[appointment.status || TimeslotStatus.pending];
  /*const {name: specName, icon: specIcon} =
    gSpecialities[
      appointment.serviceInfo.service.speciality || SpecialityKey.psycho
    ];*/
  const currFormat =
    serviceFormatMap[appointment.service.format as ServiceFormatKey];
  return (
    appointment && (
      <View>
        <GText
          align={Alignment.Center}
          type={TextType.bodyBold}
          color={palette.purpleSec}
        >
          Nueva Cita {nameEs}: {appointment.service.name}
        </GText>
        <GIconRow
          icon={'clock-fast'}
          color={color}
          /*text={appointment}*/
          descr={
            dayjs(appointment.startTime).format(DATE_HOUR) +
            ' - ' +
            dayjs(appointment.endTime).format(DATE_HOUR)
          }
        />
        <GIconRow
          icon={'euro-symbol'}
          /*color={color}*/
          descr={appointment.price + ' €'}
        />
        <GIconRow
          icon={currFormat.icon}
          /*color={currFormat}*/
          text={currFormat.name}
        />
      </View>
    )
  );
};
export const BookingModal = ({
  timeslot,
  user,
}: {
  timeslot: ShownTimeSlotType;
  user: UserType;
}) => {
  const {color, icon, nameEs} =
    statusInfoMapping[timeslot.status || TimeslotStatus.pending];
  return (
    <View style={[C.wFull]}>
      <GIcon icon={icon} color={color} />
      <GText align={Alignment.Center} type={TextType.h3Orange}>
        Cita {nameEs}
      </GText>
      <GText align={Alignment.Center} type={TextType.h6BodySmall}>
        Email enviado a
      </GText>
      <CitaInfo appointment={timeslot} />
      <GText
        align={Alignment.Center}
        type={TextType.bodyBold}
        color={palette.purpleSec}
      >
        Cita {nameEs}
      </GText>
      <GIconRow
        icon={gImages.googleMeet}
        color={palette.secondaryGrey}
        /*format: actionIcon={timeslot}*/
        text="Únete con Google Meet"
        descr="Disponible 5 minutos antes de la cita..."
      />
    </View>
  );
};
