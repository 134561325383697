import {FullIconType} from './icon-types';
import {gImages} from '../ui';
import {ServiceFormatKey, UserType} from 'oralify-common';

const types = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  BODY: 'body',
  BULLET: 'bullet',
  LINK: 'link',
};
export const politica = [
  {
    type: types.TITLE,
    text: 'Oralify Privacy Policy',
  },
  {
    type: types.BODY,
    text: 'Oralify informs you about its Privacy Policy regarding the treatment and protection of personal data of users that may be collected during navigation through the website http://oralify.com',
  },
  {
    type: types.BODY,
    text: 'In this regard, Oralify complies with the General Data Protection Regulation (GDPR) (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016',
  },
  {
    type: types.BULLET,
    text: 'Identity: Oralify, Inc.',
  },
];

/** 📉 Keep track of your emotions
 🗣️ Share your feelings with people
 👂 Earn money listening to others
 */
export const oralifyLandingData: Record<
  string,
  {icon?: FullIconType; title?: string; descr?: string; tag?: string}[]
> = {
  highlights: [
    {
      icon: gImages.calendar,
      title: 'Elige tu categoría.',
      descr:
        'Para todos los gustos, desde amores hasta temas legales, habrá un experto para ti.',
    },
    {
      icon: gImages.reveiws,
      title: 'Encuentra a tu experto ideal.',
      descr:
        'Compara y elige al experto que más te guste basándote en su experiencia, disponibilidad y reviews.',
    },
    {
      icon: gImages.audioMessage,
      tag: '24/7',
      title: 'Conecta y triunfa.',
      descr:
        'Comunícate como quieras, desde audios a videollamadas. Comparte tu experiencia para ayudar a otros.',
    },
  ],
  checks: [
    {
      title:
        'Crea tu perfil gratis en minutos, ofrece tus servicios online y conecta con usuarios de todo el mundo.',
      icon: 'phone-iphone',
    },
    {
      title:
        'Empieza a monetizar tu tiempo y conocimientos desde cualquier lugar',
      icon: 'euro',
    },

    {
      title:
        'Permite a tus usuarios reservar fácil y rápidamente. ¡Optimiza la gestión de tu agenda!',
      icon: 'calendar-check',
    },
  ],
};

export const propuestaDeValor = {
  titulo: 'Para expertos & creadores',
  descripcion:
    'Conecta con personas virtualmente, da consejos 1-a-1 y gana hasta $500k',
  imagen: 'url_de_la_imagen_propuesta',
  botonTexto: 'Únete como Experto',
};
export const mision = {
  titulo: 'Da acceso a todos a cualquier persona',
  descripcion:
    'Nuestra misión es empoderar a cada uno para hacer un mundo mejor. Al desbloquear este tipo de acceso, más personas de todo el mundo tendrán las mismas oportunidades para conectarse, aprender y actuar.',
};

export const links = {
  instagram: 'https://www.instagram.com/oralifyapp/',
  liknedin: 'https://www.linkedin.com/company/oralify/',
};
export const docs = {
  legal:
    'https://drive.google.com/file/d/1cPbpG2dY_qU0K5DTO1azFhRDAUGLymd6/view?usp=sharing',
  privacy:
    'https://drive.google.com/file/d/1iLXs_w5YVhPtKdJzkXrJ7MIFhq46CMRH/view?usp=sharing',
  cookies:
    'https://drive.google.com/file/d/1kQWfMbpx7l05IpaPwwNeSbyNO5DzsSY2/view?usp=sharing',
};
export const novolabsExperts: UserType[] = [
  {
    id: '1',
    username: 'AlexDantart',
    name: 'Alex Dantart',
    phone: '+34 123 456 789',
    creation_date: new Date(),
    birthday: new Date('1985-06-15'),
    sex: 'male',
    languages: ['es', 'en'],
    score: 4.8,
    headline:
      'Fundó e invirtió +50 empresas. Vendió 4 por alrededor de 150M en total.',
    education: 'Universidad de Barcelona',
    job: {
      id: '1',
      specialityKey: 'startupAdvisor',
      web: 'https://startupadvisor.es',
      color: '#FF5733',
      icon: 'rocket',
      name: 'Startup Advisor',
      position: 'CEO',
    },
    avatar:
      'https://framerusercontent.com/images/RpYrDMXDlOWID5ThBOsHqfh8s.png',
    email: 'alex.dantart@startupadvisor.es',
    role: 'expert',
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    services: {
      [ServiceFormatKey.video]: [
        {
          id: '1',
          name: 'Consulta en Startup',
          expertId: '1',
          speciality: 'startupAdvisor',
          description: 'Asesoramiento para startups',
          price: 50,
          duration: 60,
          format: ServiceFormatKey.video,
        },
      ],
    },
    availability: [
      {day: 'Lu', hours: '16-18h'},
      {day: 'Mi', hours: '10-12h'},
    ],
    reviews: [],
    history: [],
    speciality: 'startupAdvisor',
  },
  {
    id: '2',
    username: 'JorgeDobon',
    name: 'Jorge Dobón',
    phone: '+34 123 456 789',
    creation_date: new Date(),
    birthday: new Date('1987-04-10'),
    sex: 'male',
    languages: ['es', 'en'],
    score: 4.9,
    headline:
      'Fundó Demium y lo llevó a 8 países. Levantó un fondo de +50M€ e invirtió en +350 emprendedores.',
    education: 'Universidad de Cataluña',
    job: {
      id: '2',
      specialityKey: 'fundraising',
      web: 'https://demium.com',
      color: '#FFD700',
      icon: 'money',
      name: 'Demium',
      position: 'Fundador',
    },
    avatar:
      'https://framerusercontent.com/images/sNf0uLDo63JithCyCb90rdetxaU.png',
    email: 'jorge.dobon@demium.com',
    role: 'expert',
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    services: {
      [ServiceFormatKey.call]: [
        {
          id: '2',
          name: 'Asesoramiento en Fundraising',
          expertId: '2',
          speciality: 'fundraising',
          description: 'Consejos sobre levantamiento de fondos',
          price: 40,
          duration: 30,
          format: ServiceFormatKey.call,
        },
      ],
    },
    availability: [
      {day: 'Ma', hours: '10-12h'},
      {day: 'Ju', hours: '14-16h'},
    ],
    reviews: [],
    history: [],
    speciality: 'fundraising',
  },
  {
    id: '3',
    username: 'JavierMegias',
    name: 'Javier Megías',
    phone: '+34 123 456 789',
    creation_date: new Date(),
    birthday: new Date('1986-08-20'),
    sex: 'male',
    languages: ['es', 'en'],
    score: 4.7,
    headline:
      'Ayudó a +500 startups a levantar +400M€. Lideró el fondo de inversión de la Generalitat Valenciana.',
    education: 'Universidad Politécnica de Cataluña',
    job: {
      id: '3',
      specialityKey: 'financialAdvisor',
      web: 'https://startupsinvestment.com',
      color: '#32CD32',
      icon: 'currency-eur',
      name: 'Startups Investment',
      position: 'Director de Inversiones',
    },
    avatar:
      'https://framerusercontent.com/images/QzaIB967MM0vnkYRUI989TToHG4.png',
    email: 'javier.megias@startupsinvestment.com',
    role: 'expert',
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    services: {
      [ServiceFormatKey.call]: [
        {
          id: '3',
          name: 'Asesoramiento Financiero para Startups',
          expertId: '3',
          speciality: 'financialAdvisor',
          description: 'Consejos sobre financiación y levantamiento de capital',
          price: 60,
          duration: 45,
          format: ServiceFormatKey.call,
        },
      ],
    },
    availability: [
      {day: 'Mi', hours: '14-16h'},
      {day: 'Vi', hours: '10-12h'},
    ],
    reviews: [],
    history: [],
    speciality: 'financialAdvisor',
  },
  {
    id: '4',
    username: 'RicardGarriga',
    name: 'Ricard Garriga',
    phone: '+34 123 456 789',
    creation_date: new Date(),
    birthday: new Date('1984-02-15'),
    sex: 'male',
    languages: ['es', 'en', 'ca'],
    score: 4.8,
    headline:
      'Ayudó a +100 startups a internacionalizarse. Lideró el programa de aceleración de Wayra en 10 países.',
    education: 'Universidad de Girona',
    job: {
      id: '4',
      specialityKey: 'startupAdvisor',
      web: 'https://wayraacceleration.com',
      color: '#FF4500',
      icon: 'globe',
      name: 'Wayra Acceleration',
      position: 'Director Global',
    },
    avatar:
      'https://framerusercontent.com/images/a3pIM1h1CS3Sdean4ufgg5EX9lw.png',
    email: 'ricard.garriga@wayraacceleration.com',
    role: 'expert',
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    services: {
      [ServiceFormatKey.video]: [
        {
          id: '4',
          name: 'Asesoramiento en Internacionalización',
          expertId: '4',
          speciality: 'startupAdvisor',
          description:
            'Consejos sobre cómo llevar tu startup al mercado internacional',
          price: 70,
          duration: 60,
          format: ServiceFormatKey.video,
        },
      ],
    },
    availability: [
      {day: 'Lu', hours: '10-12h'},
      {day: 'Ju', hours: '16-18h'},
    ],
    reviews: [],
    history: [],
    speciality: 'startupAdvisor',
  },

  // ... (y así sucesivamente para cada experto)
];

export const novolabsExpertsUnformated = [
  {
    categoria: 'Technology',
    nombre: 'Alex Dantart',
    descripcion:
      'Fundó e invirtió +50 empresas. Vendió 4 por alrededor de 150M en total.',
    imagen:
      'https://framerusercontent.com/images/RpYrDMXDlOWID5ThBOsHqfh8s.png',
  },
  {
    categoria: 'Fundraising',
    nombre: 'Jorge Dobón',
    descripcion:
      'Fundó Demium y lo llevó a 8 países. Levantó un fondo de +50M€ e invirtió en +350 emprendedores.',
    imagen:
      'https://framerusercontent.com/images/sNf0uLDo63JithCyCb90rdetxaU.png',
  },
  {
    categoria: 'Marketing',
    nombre: 'David Tomas',
    descripcion:
      '+25 años CEO de Cyberclick. Autor del bestseller “La empresa más feliz del mundo”.',
    imagen:
      'https://framerusercontent.com/images/QzaIB967MM0vnkYRUI989TToHG4.png',
  },
  {
    categoria: 'Product',
    nombre: 'Stefano Scandia',
    descripcion:
      'Cofundó 4 empresas. Llevó a Renthia a operar en 30 países. Actualmente escalando Colibid.',
    imagen:
      'https://framerusercontent.com/images/a3pIM1h1CS3Sdean4ufgg5EX9lw.png',
  },
  {
    categoria: 'Sales',
    nombre: 'Agustin Linenberg',
    descripcion:
      'Cofundó Aerolab, con +$20M de venta acumulada. Mentor en Google, Endeavor, 500 Startups.',
    imagen:
      'https://framerusercontent.com/images/ww7CVq9eQdzx0SX84qdFQxd1Ls.png',
  },
];
