import {Helmet} from 'react-helmet';
import React, {ReactNode} from 'react';
import {gSpecialities, UserType} from 'oralify-common';

export const MetaShareWrap = ({
  user,
  children,
}: {
  user?: UserType;
  children?: ReactNode;
}) => {
  const currSpeciality = user?.speciality
    ? gSpecialities[user.speciality]?.name || gSpecialities.marketing
    : '';
  return (
    <>
      {user && (
        <Helmet>
          <title>{`${user.name} - ${
            currSpeciality ? ', especialista en ' + currSpeciality : ''
          } en Oralify`}</title>
          <meta
            name="description"
            content={`Conecta con ${
              user?.nameShort || user.name
            }, experto en ${currSpeciality} en Oralify. Habla con ${
              user.name
            } y resuelve tus problemas cotidianos.`}
          />
          <meta property="og:title" content={`${user.name} - Oralify`} />
          <meta
            property="og:description"
            content={`Conoce a ${user.name}, experto en ${user.speciality} en Oralify. Habla con ${user.name} y resuelve tus problemas cotidianos.`}
          />
          <meta property="og:image" content={user.avatar} />
          <meta
            property="og:url"
            content={`https://www.oralify.com/${user.username}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${user.name} - Perfil en Oralify`}
          />
          <meta
            name="twitter:description"
            content={`Conoce a ${user.name}, experto en ${currSpeciality} en Oralify. Habla con ${user.name} y resuelve tus problemas cotidianos.`}
          />
          <meta name="twitter:image" content={user.avatar} />
        </Helmet>
      )}
      {children}
    </>
  );
};
