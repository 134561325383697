import {eDivider, eLayout} from '../../ui';
import React, {ReactNode, useState} from 'react';
import {FlatList, Modal, View} from 'react-native';

import {SelectFieldItem} from './select-field-item';
import {ButtonSize, GButton} from '../GButton';
import {EeLayout} from '../../organisms';
import C from 'consistencss';

export type SelectFieldModalProps<Item> = {
  keyExtractor?: (item: Item) => string;
  labelExtractor: (item: Item) => string;
  onChange?: (value: string) => void;
  onGoBack: () => void;
  options: Item[];
  renderOptionPrefix?: (item: Item) => ReactNode;
  title?: string;
  subtitle?: string;
  value?: string;
  valueExtractor: (item: Item) => string;
  iconExtractor?: (item: Item) => string;
  isVisible?: boolean;
  shouldConfirm?: boolean;
};

export function SelectFieldModal<Item>({
  keyExtractor,
  labelExtractor,
  iconExtractor,
  onChange,
  onGoBack,
  options = [],
  renderOptionPrefix = () => <View />,
  title,
  value,
  valueExtractor,
  isVisible,
  shouldConfirm,
}: SelectFieldModalProps<Item>) {
  const selectedOption = options.find(item => valueExtractor(item) === value);
  const [currentOption, setOption] = useState(selectedOption);
  const handleSetOption = (item: Item) => () => {
    if (options.length === 1 || (currentOption && valueExtractor(item) === valueExtractor(currentOption))) {
      onChange && onChange(valueExtractor(item));
      onGoBack();
    } else {
      setOption(item);
      if (!shouldConfirm) {
        onChange && onChange(valueExtractor(item));
        onGoBack();
      }
    }
  };

  const onSelect = () => {
    if (currentOption) {
      onChange && onChange(valueExtractor(currentOption));
      onGoBack();
    }
  };

  return (
    <Modal visible={isVisible} onDismiss={onGoBack} animationType={'slide'} presentationStyle={'pageSheet'}>
      <EeLayout
        FooterComponent={
          shouldConfirm &&
          !!currentOption && (
            <GButton
              onPress={onSelect}
              size={ButtonSize.large}
              text={(iconExtractor ? iconExtractor(currentOption) : '') + ' ' + labelExtractor(currentOption)}
              disabled={!currentOption}
            />
          )
        }
        title={title}
        iconRight={'check'}
        hasSafeArea
        onPressRight={onSelect}
        onPressLeft={onGoBack}
        isModal>
        <View style={[eLayout.grow1]}>
          <FlatList
            keyExtractor={keyExtractor || valueExtractor}
            data={options}
            contentContainerStyle={[C.my4]}
            ItemSeparatorComponent={() => <View style={eDivider} />}
            renderItem={({item}) => (
              <SelectFieldItem
                testID={valueExtractor(item)}
                accessibilityLabel={valueExtractor(item)}
                key={valueExtractor(item)}
                icon={iconExtractor && iconExtractor(item)}
                onPress={handleSetOption(item)}
                label={labelExtractor(item)}
                renderPrefix={!!renderOptionPrefix && renderOptionPrefix(item)}
                isSelected={currentOption ? valueExtractor(item) === valueExtractor(currentOption) : false}
              />
            )}
          />
        </View>
      </EeLayout>
    </Modal>
  );
}
