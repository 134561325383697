import React, {useCallback, useMemo, useState} from 'react';
import {FullIconType} from '../../types/icon-types';
import 'moment/locale/en-gb'; /*
moment.locale("en-gb");*/
import {Alert, Modal, View} from 'react-native';
import {CalendarList} from 'react-native-calendars';
import {EeSecondaryHeader} from '../../organisms';
import dayjs from 'dayjs';
import chroma from 'chroma-js';
import {GButton} from '../GButton';
import C from 'consistencss';
import {DATE_CALENDAR} from '../../types/utils/dates';
import {palette} from 'oralify-common';

export type DateRangePickerProps = {
  children?: React.ReactNode;
  title?: string;
  startValue?: string;
  hasDateRange?: boolean;
  isVisible?: boolean;
  onGoBack: () => void;
  icon?: FullIconType;
  onSet: (start: string, end: string) => void;
};
const markedToday = {
  marked: true,
  dotColor: palette.blueSec,
};

const formatedToday = dayjs().format(DATE_CALENDAR);

export function GDateRangePicker({
  icon,
  title,
  startValue,
  isVisible,
  hasDateRange,
  onGoBack,
  onSet,
}: DateRangePickerProps) {
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    startValue || null,
  );

  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null);
  const [markedDates, setMarkedDates] = useState<{[p: string]: any}>({
    [formatedToday]: {markedToday},
  });

  const daysElapsed = useMemo(
    () => dayjs(selectedEndDate).diff(dayjs(selectedStartDate), 'days') + 1,
    [selectedEndDate, selectedStartDate],
  );

  const generateMarkedDates = useCallback(
    (start: string, end: string) => {
      let currentDate = start;
      const colorScale = chroma
        .scale([palette.redSec, palette.orangeSec])
        .mode('lch');
      let colorIndex = 0;
      while (currentDate !== end) {
        if (markedDates) {
          markedDates[currentDate] = {
            color: colorScale(colorIndex).hex(),
            textColor: palette.white,
          };
        }
        currentDate = dayjs(currentDate).add(1, 'day').format(DATE_CALENDAR);
        colorIndex += 1 / (dayjs(end).diff(dayjs(start), 'day') + 1);
      }
      if (markedDates) {
        markedDates[start] = {
          startingDay: true,
          color: palette.redSec,
          textColor: palette.white,
        };
      }
      if (markedDates) {
        markedDates[end] = {
          endingDay: true,
          color: palette.orangeSec,
          textColor: palette.white,
        };
      }
      return markedDates;
    },
    [markedDates],
  );

  const handleSelectDate = useCallback(
    (date: string) => {
      if (!hasDateRange) {
        setSelectedStartDate(date);
        setSelectedEndDate(date);
        setMarkedDates({
          [date]: {
            startingDay: true,
            endingDay: true,
            color: palette.orangeSec,
            textColor: palette.white,
          },
        });
      } else if (!selectedStartDate) {
        setSelectedStartDate(date);
        setMarkedDates({
          [date]: {
            startingDay: true,
            color: palette.orangeSec,
            textColor: palette.white,
          },
        });
      } else if (!selectedEndDate) {
        let start = selectedStartDate;
        let end = date;
        if (dayjs(date).isBefore(selectedStartDate)) {
          start = date;
          end = selectedStartDate;
        }
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        if (start === end) {
          // Handle single day selection
          setMarkedDates({
            [start]: {
              startingDay: true,
              endingDay: true,
              color: palette.orangeSec,
              textColor: palette.white,
            },
          });
        } else {
          setMarkedDates(generateMarkedDates(start, end));
        }
      } else {
        setSelectedStartDate(date);
        setSelectedEndDate(null);
        setMarkedDates({
          [date]: {
            startingDay: true,
            color: palette.orangeSec,
            textColor: palette.white,
          },
        });
      }
    },
    [generateMarkedDates, hasDateRange, selectedEndDate, selectedStartDate],
  );

  const confirmSelection = () => {
    if (selectedStartDate && selectedEndDate) {
      onSet(selectedStartDate, selectedEndDate);
      onGoBack();
    } else {
      Alert.alert('You need to select a date range', 'At least two values');
    }
  };

  return (
    <Modal visible={isVisible} presentationStyle={'formSheet'}>
      <EeSecondaryHeader
        iconLeft={icon}
        title={title}
        onPressLeft={onGoBack}
        iconRight={'check'}
        onPressRight={confirmSelection}
      />
      <CalendarList
        markingType={'period'}
        firstDay={1}
        pastScrollRange={4}
        futureScrollRange={4}
        markedDates={{
          ...markedDates,
          [formatedToday]: {...markedDates[formatedToday], ...markedToday},
        }}
        onDayPress={day => {
          handleSelectDate(day.dateString);
        }}
      />
      <View style={[C.absolute, C.wFull, C.bottom8]}>
        {selectedEndDate && selectedStartDate && (
          <GButton
            icon={'check'}
            disabled={!(selectedStartDate && selectedEndDate)}
            text={'Create ' + daysElapsed + ' days travel'}
            onPress={confirmSelection}
          />
        )}
      </View>
    </Modal>
  );
}

export default GDateRangePicker;
