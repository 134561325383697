import {Image, ImageSourcePropType} from 'react-native';
import C from 'consistencss';

export const responsiveImageStyle = (image: ImageSourcePropType) => {
  return [
    C.wFull,
    {
      height: undefined,
      aspectRatio:
        Image.resolveAssetSource(image).width /
        Image.resolveAssetSource(image).height,
    },
  ];
};
