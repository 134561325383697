import {Alignment, EeSize, TextType} from '../../ui';
import C from 'consistencss';
import React, {ReactNode} from 'react';
import {View} from 'react-native';

import {GIcon, GText} from '../../atoms';
import {FullIconType} from '../../types/icon-types';

export type HeaderIconTypes = {
  iconRight?: FullIconType;
  iconLeft?: FullIconType;
  onPressRight?: () => void;
  onPressLeft?: () => void;
};

export type HeaderProps = {
  children?: ReactNode;
  title?: string;
  isModal?: boolean;
} & HeaderIconTypes;

/** TODO discuss if not needed (use @navigation/navigate one) */
export function EeSecondaryHeader({
  isModal,
  title,
  iconRight = isModal ? 'close' : 'chevron-left',
  iconLeft /*= isModal ? 'close' : 'chevron-left'*/,
  onPressLeft,
  onPressRight,
}: HeaderProps) {
  /*const {goBack} = useNavigation();
                                  //const leftShouldClose = isModal && !!iconRight;
                                  const handleBack = isModal ? onPressLeft : onPressRight || goBack;
                                  //const handleRight = onPressRight || (isModal && (() => goBack()));
                                  const closeLeftIcon =
                                    iconLeft || (isModal && iconRight) ? 'close' : 'chevron-left';
                                  const actionRightIcon = iconRight || (isModal && 'close');*/
  return (
    <View style={[C.mt6, C.mb3, C.justifyCenter, C.itemsCenter, C.row]}>
      {iconLeft && (
        <GIcon
          icon={iconLeft}
          size={EeSize.icon}
          wrapStyle={[C.left4, C.absolute]}
          onPress={onPressLeft}
        />
      )}
      <View style={[C.wHalf, C.selfCenter, C.itemsCenter]}>
        <GText
          style={C.uppercase}
          align={Alignment.Center}
          color={palette.orangeSec}
          type={TextType.h3Orange}
          numberOfLines={2}
        >
          {title}
        </GText>
      </View>
      {onPressRight && (
        <GIcon
          icon={iconRight}
          size={EeSize.iconWrapper}
          color={palette.orangeSec}
          circular
          wrapStyle={[C.right4, C.absolute]}
          onPress={onPressRight}
        />
      )}
    </View>
  );
}

export default EeSecondaryHeader;
