import React, {useEffect, useState} from 'react';
import {FlatList, View} from 'react-native';
import {GPill} from '../molecules/gPill';
import {onTop} from '../ui';
import C from 'consistencss';
import {useSpeciality} from '../types/SpecialityProvider';
import useResponsive from '../ui/helpers/useResponsive';
import {centerRow} from '../molecules/gInfoRow';
import {
  FilterType,
  Gender,
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ServiceType,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import {useGDropdown} from '../organisms/UseGDropdown';
import {GIcon} from '../atoms';
import {ItemType} from 'react-native-dropdown-picker';

type ServiceProps = {
  list?: ItemType<any>[];
  initialFormat?: ServiceFormatKey;
  users?: UserType[] | undefined;
  showSummary?: boolean;
  transp?: boolean;
};

type ServiceFilterReturnType = [
  ServiceFilterComp: () => JSX.Element,
  currService: ServiceFormatKey | undefined,
  /*matchingServiceUsers?: UserType[],*/
  resetServices?: () => void,
];

export const useFormatFilter = ({
  initialFormat /*= ServiceFormatKey.audio,*/,
  transp = false,
  showSummary = false,
  users = [],
}: ServiceProps): ServiceFilterReturnType => {
  const [currFormat, setCurrFormat] = useState<ServiceFormatKey | undefined>(
    initialFormat,
  );
  const [currFormatMap, setCurrFormatMap] = useState<ItemType<any>[]>([]);

  useEffect(() => {
    setCurrFormatMap(
      Object.entries(serviceFormatMap).map(([key, value]) => ({
        value: key,
        label: value.name,
        icon: () => (
          <GIcon
            color={key === currFormat ? palette.purpleSec : palette.greyish}
            icon={value.icon}
          />
        ),
      })),
    );
  }, [currFormat]);
  const {GDropdown, setValue} = useGDropdown({
    initialValue: currFormat,
    key: 'formatDropdown',
  });
  const resetFormat = () => setCurrFormat(undefined);
  const FormatSelector = () => (
    <View>
      {showSummary ? (
        <GDropdown
          items={currFormatMap}
          value={currFormat}
          placeholder={'Formato'}
          onSelectItem={item => {
            setCurrFormat(currFormat === item.value ? undefined : item.value);
            setValue(currFormat === item.value ? {} : item.value);
          }}
          searchable={false}
        />
      ) : (
        <FlatList
          data={
            Object.entries(serviceFormatMap) as [
              ServiceFormatKey,
              ServiceType,
            ][]
          }
          keyExtractor={([key]) => key}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[C.selfStart, onTop()]}
          horizontal
          renderItem={({item}) => {
            const amount = users
              ? users.filter(
                  ({services}) => services && services.hasOwnProperty(item[0]),
                ).length
              : 0;
            const handlePress = () => {
              setCurrFormat(
                currFormat && currFormat === item[0]
                  ? undefined
                  : (item[0] as ServiceFormatKey),
              );
            };
            return (
              <GPill
                {...item[1]}
                long
                transp={transp}
                color={palette.purpleSec}
                text={item[1].name}
                quantity={amount}
                onPress={handlePress}
                selected={currFormat ? currFormat === item[0] : false}
              />
            );
          }}
        />
      )}
    </View>
  );

  return [FormatSelector, currFormat, /*matchingFormatUsers,*/ resetFormat];
};

/** Speciality: */
type SpecialityProps = {
  list?: [SpecialityKey | string, FilterType][];
  initialSpeciality?: SpecialityKey;
  users?: UserType[] | undefined;
  transp?: boolean;
  showSummary?: boolean;
  withRandom?: boolean;
  collapseLimit?: number;
};

export type SpecialityFilterCompReturnType = {
  onPress?: () => void;
  currSpecialityKey?: SpecialityKey;
};
type SpecialityFilterReturnType = {
  SpecialityFilterComp: (arg0: SpecialityFilterCompReturnType) => JSX.Element;
  /*currSpeciality: SpecialityType | undefined;
    currSpecialityKey?: SpecialityKey | undefined;
    onSetSpeciality?: (spec: SpecialityKey) => void;
    matchingProf?: UserType[];*/
  resetSpecialities?: () => void;
};
/*
export const useSpecialityCategoryFilter = ({
  list = Object.entries(specialityCategories) as [SpecialityKey, SpecialityType][],
  initialSpeciality,
  users = [],
  transp = false,
  showSummary = false,
  withRandom,
  collapseLimit,
}: SpecialityProps): SpecialityFilterReturnType => {
  const getRandomSpeciality = () => {
    const specialities = Object.values(SpecialityKey);
    const randomIndex = Math.floor(Math.random() * specialities.length);
    return specialities[randomIndex];
  };
  const [currSpecialityKey, setCurrSpecialityKey] = useState<SpecialityKey | undefined>(
    initialSpeciality || getRandomSpeciality(),
  );
  const [withRandomCategory, setWithRandomCategory] = useState(withRandom);
  const {TooltipWrapper: SpecialityTooltip, setShowTooltip} = useModalTooltip({});

  const [expandedCategory, setExpandedCategory] = useState<string | null>(matchingCategory(currSpecialityKey));
  const currSpeciality = currSpecialityKey ? gSpecialities[currSpecialityKey] : undefined;
  const [showMore, setShowMore] = useState(!expandedCategory);

  const onSetCategory = (category: SpecialityKey) => {
    setExpandedCategory(category);
    setWithRandomCategory(false);
  };

  const onSetSpeciality = (category: SpecialityKey) => {
    setCurrSpecialityKey(category);
    setExpandedCategory(matchingCategory(category));
  };

  /!** Generate random Speciality Key every 2 seconds (unless user sets something)*!/

  useEffect(() => {
    if (withRandomCategory) {
      const interval = setInterval(() => {
        const newSpeciality = getRandomSpeciality();
        onSetSpeciality(newSpeciality);
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [withRandomCategory]);

  const displayedSpecialities = showMore ? list : list?.slice(0, collapseLimit);
  const {resp} = useResponsive();
  const matchingProf = currSpecialityKey
    ? users?.filter(
        ({speciality}) =>
          currSpecialityKey &&
          gSpecialities[speciality as SpecialityKey] &&
          gSpecialities[speciality as SpecialityKey].name === currSpecialityKey,
      )
    : users;
  const resetSpecialities = () => setCurrSpecialityKey(undefined);
  const SpecialityFilterComp = () => (
    <View /!*style={[showTooltip && C.pb20]}*!/>
      {showSummary ? (
        <GPill
          quantity={list?.length}
          icon={showMore ? 'chevron-right' : 'chevron-down'}
          text={'Especialidades'}
          transp={transp}
          onPress={() => setShowMore(!showMore)}
          color={palette.orangeSec}
          long
          selected
        />
      ) : (
        <FlatList
          horizontal
          extraData={[currSpecialityKey, expandedCategory]}
          keyExtractor={([key]) => key}
          style={[C.pr4, resp().width]}
          showsHorizontalScrollIndicator={false}
          data={displayedSpecialities}
          renderItem={({item}) => {
            if (!item || !item[1]) {
              return null;
            }
            const {name, emoji} = item[1];
            const amount = users ? users.filter(({speciality}) => item && speciality === item[0]).length : 0;

            const isCategory = specialityCategories.hasOwnProperty(item[0]);
            const isSelected = expandedCategory === item[0];

            if (isCategory) {
              return (
                <SpecialityTooltip
                  onPress={() => onSetCategory(item[0])}
                  TooltipTrigger={
                    <GPill
                      key={name}
                      symbol={emoji as string}
                      text={name}
                      transp={transp}
                      /!*onHoverOut={() => setShowTooltip(false)}
                      onHover={() => {
                        //setWithRandomCategory(false);
                        setShowTooltip(true);
                        onSetCategory(item[0]);
                      }}*!/
                      quantity={amount}
                      color={palette.orangeSec}
                      long
                      selected={false}
                    />
                  }
                  /!*wrapStyle={[C.bgTransp, C.p0, C.m0, C.radius4]}*!/
                  /!*disabled={!isSelected /!*|| !showTooltip*!/}*!/
                  TooltipContent={
                    <FlatList
                      numColumns={4}
                      extraData={[currSpecialityKey, expandedCategory]}
                      keyExtractor={([key]) => key}
                      showsHorizontalScrollIndicator={false}
                      data={Object.entries(gSpecialities).filter(
                        ([key]) =>
                          expandedCategory &&
                          specialityCategories[expandedCategory]?.services.includes(key as SpecialityKey),
                      )}
                      renderItem={({item}) => {
                        if (!item || !item[1]) {
                          return null;
                        }
                        const {name: specName, emoji: specEmoji} = item[1];
                        const specAmount = users
                          ? users.filter(({speciality}) => item && speciality === item[0]).length
                          : 0;
                        const selected = currSpecialityKey ? currSpecialityKey === item[0] : false;
                        const handlePress = () => {
                          onSetSpeciality(item[0] as SpecialityKey);
                          setShowTooltip(false);
                          setWithRandomCategory(false);
                        };
                        return (
                          <GPill
                            key={specName}
                            symbol={specEmoji as string}
                            text={specName}
                            /!*onHover={() => setShowTooltip(true)}
                            onHoverOut={() => setShowTooltip(false)}*!/
                            onPress={handlePress}
                            quantity={specAmount}
                            color={palette.orangeSec}
                            long
                            selected={selected}
                          />
                        );
                      }}
                    />
                  }
                />
              );
            }
            return null;
          }}
        />
      )}
    </View>
  );

  return {SpecialityFilterComp, currSpeciality, currSpecialityKey, onSetSpeciality, matchingProf, resetSpecialities};
};
*/

export const useSpecialityFilter = ({
  list = Object.entries(gSpecialities),
  collapseLimit = 12,
  transp = true,
  users = [],
}: SpecialityProps): SpecialityFilterReturnType => {
  const {setSpecialityKey} = useSpeciality();
  const [showMore, setShowMore] = useState(false);

  const resetSpecialities = () => setSpecialityKey(undefined);
  const {isSmall, isBig} = useResponsive();
  const shownValues = showMore ? list : list?.slice(0, collapseLimit);

  const numColumns = isSmall ? undefined : isBig ? 6 : 3;
  const SpecialityFilterComp = ({
    onPress,
    currSpecialityKey,
  }: SpecialityFilterCompReturnType) => (
    <FlatList
      data={shownValues}
      key={numColumns}
      horizontal={isSmall}
      contentContainerStyle={C.selfCenter}
      numColumns={numColumns}
      keyExtractor={item => item[0] + numColumns}
      ListFooterComponent={
        showMore ? undefined : (
          <GPill
            wrapStyle={[C.selfCenter]}
            icon={showMore ? 'chevron-up' : 'chevron-down'}
            onPress={() => setShowMore(curr => !curr)}
            transp={transp}
            long
            text={`${list?.length - collapseLimit} más`}
          />
        )
      }
      renderItem={({item}) => {
        const [key, val] = item;
        const {name, emoji} = val;
        /*const amount = users
                  ? users.filter(({speciality}) => speciality === key).length
                  : 0;*/
        const selected = key === currSpecialityKey;

        return (
          <GPill
            key={name}
            symbol={emoji as string}
            text={name}
            wrapStyle={onTop(false)}
            onPress={() => {
              setSpecialityKey(key as SpecialityKey);
              onPress && onPress();
            }}
            /* quantity={amount}*/
            color={palette.orangeSec}
            long
            transp={transp}
            selected={selected}
          />
        );
      }}
    />
  );

  return {SpecialityFilterComp, resetSpecialities};
};

type GenderSelectorReturnType = [
  GenderSelectorComp: () => JSX.Element,
  selectedGender: Gender | undefined,
  resetGender: () => void,
];

export const useGenderSelector = (
  initGender: Gender = Gender.Female,
): GenderSelectorReturnType => {
  const [selectedGender, setSelectedGender] = useState<Gender | undefined>(
    initGender,
  );

  const resetGender = () => setSelectedGender(initGender);

  const isMen = selectedGender === Gender.Male;
  const GenderSelectorComp = () => (
    <View style={[centerRow, C.m2, C.pb2]}>
      <GPill
        icon="female"
        text={'Mujer'}
        selected={!isMen}
        color={!isMen ? palette.purpleSec : palette.secondaryGrey}
        onPress={() => setSelectedGender(Gender.Female)}
      />
      <GPill
        icon="male"
        selected={isMen}
        text={'Hombre'}
        color={isMen ? palette.bluePrim : palette.secondaryGrey}
        onPress={() => setSelectedGender(Gender.Male)}
      />
    </View>
  );

  return [GenderSelectorComp, selectedGender, resetGender];
};
