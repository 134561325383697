import React, {useEffect, useState} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import {useNavigate, useParams} from 'react-router-dom';

import useResponsive from '../ui/helpers/useResponsive';
import {useGoogleDBLogout} from '../api/userAuthApi';
import GSpinner from '../atoms/GSpinner';
import {MetaShareWrap} from '../components/MetaShareWrap';
import {EeSize, gImages, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {Api_Routes, palette, RoleKey} from 'oralify-common';
import {SETTINGS_STEPS, settingsSteps} from '../types/stepTypes';
import {PublicProfile} from './PublicProfile';
import {useRecoilState} from 'recoil';
import {currentUserAtom} from '../store/useUser';
import {trans} from '../store/language';
import {apiCaller} from '../api/api';

export const Profile = () => {
  const {step} = useParams();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const {resp, isSmall} = useResponsive();
  const [currentUser, setUser] = useRecoilState(currentUserAtom);
  /*const [currentUser, setUser] = useAuthState();*/
  useEffect(() => {
    if (!currentUser) {
      /**Public profile*/
      navigate('/' /*+ (username || '')*/);
    } else if (currentUser.id) {
      apiCaller({
        url: Api_Routes.user.privateProfile,
        /*token: currentUser,*/
      }).then(({data: newUser}) => {
        setUser(newUser);
      });
    }
  }, []);

  const {GoogleLogoutComp} = useGoogleDBLogout();
  const [currStepKey, setCurrStepKey] = useState<SETTINGS_STEPS>(
    (step as SETTINGS_STEPS) || SETTINGS_STEPS.PROFILE,
  );
  useEffect(() => {
    setCurrStepKey(step);
  }, [step]);
  const currStep = settingsSteps[currStepKey];
  const settingsMap = isSmall /*&& !showMore*/
    ? [
        ...Object.entries(settingsSteps),
        [
          'expand',
          {
            icon: showMore ? 'chevron-left' : 'chevron-right',
            onPress: () => {
              setShowMore(!showMore);
            },
            title: showMore ? 'Mostrar menos' : 'Mostrar más',
          },
        ],
      ]
    : Object.entries(settingsSteps);
  const shouldShowMore = !isSmall || showMore;
  return (
    <MetaShareWrap user={currentUser}>
      {currentUser ? (
        <View style={[C.row, C.itemsStart]}>
          <View
            style={
              [
                ,/*isSmall && [C.maxw10, C.bgBlue]*/
                /*C.shadowMd,
                           C.radius2*/
              ]
            }
          >
            {settingsMap.map(([key, {icon, title, isExpert, onPress}]) => {
              const isSel = key === currStepKey;
              return (
                ((isExpert && currentUser?.role === RoleKey.Expert) ||
                  !isExpert) && (
                  <TouchableOpacity
                    style={[
                      C.shadowMd,
                      C.radius2,
                      isSmall ? C.p2 : C.p4,
                      C.mb3,
                      C.mr2,
                      C.row,
                      C.justifyStart,
                      C.itemsCenter,
                    ]}
                    onPress={() => {
                      if (onPress) onPress();
                      else {
                        navigate('/profile/' + key);
                        setCurrStepKey(key as SETTINGS_STEPS);
                      }
                    }}
                    icon={icon}
                  >
                    <GIcon
                      color={isSel ? palette.orangeSec : undefined}
                      size={
                        isSmall
                          ? EeSize.lg
                          : isSel
                          ? EeSize.iconWrapper
                          : EeSize.icon
                      }
                      icon={icon}
                    />
                    {shouldShowMore && (
                      <GText
                        style={[C.ml2, C.capitalize]}
                        color={isSel ? palette.orangeSec : undefined}
                        type={isSel ? TextType.bodyBold : TextType.body}
                      >
                        {title}
                      </GText>
                    )}
                  </TouchableOpacity>
                )
              );
            })}
          </View>
          {!currentUser ? (
            <GSpinner />
          ) : (
            <ScrollView
              contentContainerStyle={[
                /*resp().width,*/
                C.justifyStart,
                C.selfStart,
                resp().height,
                isSmall && [/*resp(0.8).maxWidth,*/ C.itemsStart],
                isSmall ? C.px2 : C.px4,
                C.bgWhite,
              ]}
            >
              {currStep?.Component ? (
                <currStep.Component user={currentUser} />
              ) : (
                /*: (
                                                                                                                                                                                  <>
                                                                                                                                                                                    <View
                                                                                                                                                                                      style={[
                                                                                                                                                                                        C.justifyCenter,
                                                                                                                                                                                        C.selfCenter,
                                                                                                                                                                                        !isSmall && [C.itemsStart, C.row],
                                                                                                                                                                                        eLayout.gap4,
                                                                                                                                                                                        C.mb4,
                                                                                                                                                                                      ]}
                                                                                                                                                                                    >
                                                                                                                                                                                      <View style={[C.mb4]}>
                                                                                                                                                                                        <GText style={C.mb12} type={TextType.h3Orange}>
                                                                                                                                                                                          {trans(
                                                                                                                                                                                            currentUser
                                                                                                                                                                                              ? 'Así te veran los usuarios'
                                                                                                                                                                                              : 'Usuario no encontrado',
                                                                                                                                                                                          )}
                                                                                                                                                                                        </GText>
                                                                                                                                                                                        <UserCard showRibbon user={currentUser} showInitialTab />
                                                                                                                                                                                      </View>
                                                                                                                                                                                      <View>
                                                                                                                                                                                        <GText style={C.mb8} type={TextType.h3Orange}>
                                                                                                                                                                                          {trans('Comparte tu perfil y hazte viral')}
                                                                                                                                                                                        </GText>
                                                                                                                                                                                        {currentUser && <ShareQR user={currentUser} />}
                                                                                                                                                                                      </View>
                                                                                                                                                                                    </View>
                                                                                                                                                                                  </>
                                                                                                                                                                                )*/

                /** Logout case*/
                <View
                  style={[
                    C.justifyCenter,
                    resp(0.8).width,
                    C.selfCenter,
                    C.itemsCenter,
                  ]}
                >
                  <GText type={TextType.h3Orange}>
                    {trans('Estás seguro de cerrar sesión?')}
                  </GText>
                  <GIcon
                    resizeMode={'contain'}
                    wrapStyle={[C.radius8]}
                    icon={gImages.oralify404}
                    size={isSmall ? EeSize.image : EeSize.imageBig}
                  />

                  <GoogleLogoutComp />
                </View>
              )}
            </ScrollView>
          )}
        </View>
      ) : (
        <PublicProfile user={currentUser} />
      )}
      {/*</SideContainer>*/}
    </MetaShareWrap>
  );
};
