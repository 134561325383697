import {GIcon, GText} from '../atoms';
import C from 'consistencss';
import {Pressable, View} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Alignment, eColor, EeSize, eSizes, StylesArray, TextType} from '../ui';
import {FullIconType} from '../types/icon-types';

import {Gender, palette} from 'oralify-common';
import GSpinner from '../atoms/GSpinner';

export interface IAvatarProps {
  name?: string;
  subtitle?: string;
  color?: string;
  image?: FullIconType;
  badge?: FullIconType;
  onPress?: () => void;
  onHover?: () => void;
  onAddImg?: () => void;
  wrapStyle?: StylesArray;
  isBig?: boolean;
  showName?: boolean;
  horiz?: boolean;
  size?: EeSize;
  online?: boolean;
  isLoading?: boolean;
  gender?: Gender;
}

export const GAvatar = ({
  image,
  badge,
  isLoading,
  isBig,
  size = isBig ? EeSize.avatarBig : EeSize.avatar,
  online,
  name,
  onHover,
  onAddImg,
  color = palette.secondaryDarkGrey,
  horiz,
  subtitle,
  showName,
  wrapStyle,
  gender = Gender.Female,
  onPress,
}: IAvatarProps) => {
  const [isHover, setIsHover] = useState(false);
  //const [imageError, setImageError] = useState(false);
  useEffect(() => {
    if (isHover && onHover) onHover();
  }, [isHover, onHover]);
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onPress={onAddImg || onPress}
      onHoverOut={() => setIsHover(false)}
      style={[horiz && [C.row, C.itemsCenter], wrapStyle]}
      disabled={!onPress}
    >
      <View
        style={[
          C.itemsCenter,
          C.selfCenter,
          C.radius25,
          /*C.bgWhite,*/
          //C.bgOrange,
          //isBig && [C.mr2, C.my4] /*: C.w15*/,
          image && eColor(online ? palette.greenPrim : palette.white, 3).border,
          /*wrapStyle,*/
        ]}
      >
        {isLoading ? (
          <GSpinner
            full={false}
            wrapStyle={[
              C.flex,
              eSizes[EeSize.avatarBig],
              C.radius40,
              C.bgWhite,
            ]}
          /> /*: imageError && name ? (
          <LinearGradient
            colors={orangeGrad}
            angle={120}
            useAngle
            style={[eSizes[size], C.itemsCenter, C.justifyCenter, C.radius25]}
          >
            <GText type={TextType.h1Logo} color={palette.white}>
              {nameInitials(name)}
            </GText>
          </LinearGradient>
        )*/
        ) : (
          <GIcon
            circular
            /*wrapStyle={[!image /!*|| imageError*!/ && C.opacity70]}*/
            icon={
              image || 'account-circle'
              /*: gender === Gender.Male
                                                                                                                                        ? gImages.expertMale
                                                                                                                                        : gImages.expertFemale*/
            }
            color={color}
            /*wrapStyle={[!image && C.opacity80]}*/
            resizeMode={/*isHover ?*/ 'cover' /*: 'cover'*/}
            size={image ? size : isBig ? size + 12 : size + 4}
            /*onError={() => {
                                                                                                                      setImageError(true);
                                                                                                                    }}*/
          />
        )}
        {badge && (
          <View
            style={[
              C.absolute,
              C.left_2,
              C.bottom_2,
              C.itemsCenter,
              C.justifyCenter,
              C.radius8,
              C.bgWhite,
              C.shadowSm,
              eSizes[isBig ? 4 : 3],
              eColor(palette.white, 1).border,
              C.px1,
            ]}
          >
            <GIcon size={EeSize.md} icon={badge} />
          </View>
        )}
        {online && (
          <View
            style={[
              C.absolute,
              C.shadowSm,
              isBig ? [C.right2, C.bottom2] : [C.right0, C.bottom0],
              C.radius5,
              eColor(palette.greenPrim).bg,
              eColor(palette.white, 2).border,
              // @ts-ignore
              eSizes[isBig ? 2 : 1],
            ]}
          />
        )}
        {onAddImg && (
          <View
            style={[
              C.absolute,
              isBig
                ? [C.p2, C.right_1, C.top_1]
                : [C.p1, C.right_1, C.bottom_1],
              C.radius12,
              C.itemsCenter,
              eColor(palette.white).bg,
              eColor(palette.orangeSec).border,
            ]}
          >
            <GIcon icon={'camera-outline'} color={palette.orangeSec} />
          </View>
        )}
      </View>

      {showName && name && (
        <GText
          numberOfLines={1}
          align={Alignment.Center}
          style={[C.p2, C.capitalize]}
          color={color}
          type={isBig ? TextType.h1 : TextType.bodyBold}
        >
          {name}
        </GText>
      )}
      {subtitle && (
        <GText numberOfLines={1} align={Alignment.Center} type={TextType.label}>
          {subtitle}
        </GText>
      )}
    </Pressable>
  );
};

/*export const useAddAvatar = ({img, }) => {
  const fileTypes = ['JPG', 'PNG', 'GIF'];
  const [img, setImg] = useState();
  const handleChange = file => {
    setImg(file);
  };
  return (
      <GAvatar
          onPress={onImageUpload}
          online={!!editUser.avatar}
          image={editUser.avatar}
          isBig
          gender={selectedGender}
          //color={currSpeciality?.color || palette.purpleSec}
      />
  );
}*/
