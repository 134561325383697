import {FlatList, Linking, Pressable, SectionList, View} from 'react-native';
import C from 'consistencss';

import React, {ReactElement, useState} from 'react';

import {eColor, EeSize, eText, gImages, reviewScale, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {cardWrapper} from '../molecules';
import dayjs from 'dayjs';
import {DATE_HOUR, formatDuration, round, userDate} from '../types/utils/dates';
import {GIconRow} from '../molecules/GIconRow';
import {GBadge} from '../molecules/gBadge';
import useResponsive from '../ui/helpers/useResponsive';
import {GAvatar} from '../molecules/GAvatar';
import {minutePacks} from '../api/services';
import {useTabs} from '../filters/useTabs';
import {centerRow} from '../molecules/gInfoRow';
import {
  gLanguages,
  gSpecialities,
  LanguageKey,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ServiceType,
  SpecialityKey,
  TimeslotStatus,
  TimeSlotType,
  UserType,
} from 'oralify-common';
import _, {groupBy, isArray, isEmpty} from 'lodash';
import {currentUserAtom} from '../store/useUser';
import {oralifyBaseURL} from '../api/api';
import {useRecoilState} from 'recoil';
import {useModal} from '../modals/useModal';
import {
  headerScreens,
  modalScreens,
  routeQueryParam,
} from '../routes/useGNav.web';
import TimeslotSelector from '../FormSelector/TimeslotSelector';

type UserCardProps = {
  user?: UserType;
  onPress?: () => void;
  initialSpeciality?: SpecialityKey;
  showInitialTab?: boolean;
  showRibbon?: boolean;
  children?: ReactElement;
};

export default ({
  user,
  onPress,
  initialSpeciality,
  showInitialTab,
  showRibbon,
  children,
}: UserCardProps) => {
  const {showModal: showLoginModal} = useModal(modalScreens.login);
  const color = palette.orangeSec;
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  const [isHover, setIsHover] = useState(false);
  const [showAllTimeslots, setShowAllTimeslots] = useState(false);

  /**Service tabs*/
  const availableTabs = user?.services
    ? groupBy(user.services, 'speciality')
    : {};

  const {TabHeaders, selectedTab} = useTabs<ServiceType>(
    availableTabs,
    initialSpeciality ||
      user?.services?.[0]?.speciality ||
      SpecialityKey.psycho,
    showInitialTab,
  );

  const [showServices, setShowServices] = useState(false);
  const currServices = availableTabs[selectedTab as SpecialityKey];
  const shownServices = showServices ? currServices : currServices?.slice(0, 2);
  const groupTimeslotsByDay = (timeslots: TimeSlotType[]) => {
    return (
      timeslots?.reduce((acc, timeSlot) => {
        // Usamos el formato 'YYYY-MM-DD' para asegurarnos de que sólo estamos agrupando por día y no incluyendo la hora.
        const day = dayjs(timeSlot.startTime).format('YYYY-MM-DD');
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(timeSlot);
        return acc;
      }, {}) || []
    );
  };

  // Función para transformar los timeslots agrupados en secciones
  const transformToSections = (groupedTimeslots: {
    [x: string]: TimeSlotType[];
  }) => {
    // Convertimos las claves del objeto en un array y las ordenamos.
    const sortedDays = Object.keys(groupedTimeslots).sort();
    return sortedDays.map(day => ({
      title: dayjs(day).format('ddd DD') + ': ',
      data: groupedTimeslots[day],
    }));
  };
  const sortedTimeslots = currServices?.[0]?.time_slots; /*|| legacyTimeslots*/

  const groupedTimeslots = groupTimeslotsByDay(sortedTimeslots);
  const sectionData = transformToSections(groupedTimeslots);

  const {resp, isSmall} = useResponsive();
  const allKeywords =
    user?.services && _.isArray(user?.services)
      ? [
          ...new Set(
            user?.services?.reduce((acc, service: ServiceType) => {
              const {keywords = []} = service; // Asigna un valor predeterminado de [] si keywords es undefined
              return Array.isArray(keywords) ? [...acc, ...keywords] : acc; // Comprueba si keywords es un array antes de desplegarlo
            }, []),
          ),
        ]
      : [];

  return user ? (
    <Pressable
      key={user.id}
      style={[
        cardWrapper,
        isHover && eColor(color, 2).border,
        C.shadowSm,
        C.selfStart,
        isSmall ? resp(0.85).width : [C.w120, C.mr6],
        C.p4,
      ]}
    >
      <Pressable
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        /*disabled={!onPress}*/
        onPress={() => {
          /*if (user)*/
          Linking.openURL(`${oralifyBaseURL}/${user.username || user.id}`);
          if (onPress) onPress();
        }}
        style={({pressed}) => [pressed && C.opacity40]}
      >
        <View
          style={[
            C.absolute,
            C.alignEnd,
            C.bgWhite,
            C.p1,
            C.radius4,
            C.row,
            C.right0,
            C.top15,
          ]}
        >
          {user.languages?.map(code => {
            const isCatalan = code === LanguageKey.CA;
            //const currCountry = getCountryByAlpha2(code.toUpperCase());
            //
            /*const isEng = code === 'EN';*/
            /*if (currCountry) {*/
            return (
              <GIcon
                icon={gLanguages[code as LanguageKey]?.emoji}
                /* size={*/ /*isCatalan ? EeSize.lg : undefined*/
                /*wrapStyle={[isCatalan && C.selfStart]}*/
                resizeMode={'contain'}
                size={isCatalan ? EeSize.md : EeSize.lg}
                key={code}
                wrapStyle={isCatalan && C.top_1}
                /*title={currCountry.name}*/ //currCountry.languages[0] /*getLocaleByName(code)*/}
                color={palette.orangeSec}
              />
            );
            /*}*/
          })}
        </View>
        <View style={[C.row, C.itemsCenter]}>
          <GAvatar
            online={user.is_connected}
            name={user.nameShort}
            showName={false}
            badge={user?.highlights?.icon}
            image={user.avatar}
            color={user.is_connected ? color : undefined}
          />
          <View style={[C.ml4, C.flex, C.selfStart, C.maxw80]}>
            <View style={[C.row, C.itemsCenter, C.justifyBetween]}>
              <View style={[centerRow]}>
                {user.phone_verified && user.email_verified ? (
                  <GIcon
                    color={palette.bluePrim}
                    icon={'verified'}
                    wrapStyle={C.pr2}
                  />
                ) : user.email_verified ? (
                  <GIcon
                    color={palette.bluePrim}
                    icon={'email-check'}
                    wrapStyle={C.pr2}
                  />
                ) : (
                  user.phone_verified && (
                    <GIcon
                      color={palette.bluePrim}
                      icon={'phone-check'}
                      wrapStyle={C.pr2}
                    />
                  )
                )}
                <GText
                  style={[C.capitalize]}
                  numberOfLines={1}
                  type={TextType.h4}
                >
                  {user?.nameShort}
                </GText>

                {/*{user.phone_verified && <GIcon color={palette.bluePrim} icon={'phone-check'} wrapStyle={C.pr2} />}*/}
              </View>
            </View>
            <GText
              numberOfLines={2}
              type={TextType.label}
              style={[C.my2, C.ml1]}
            >
              {user?.headline}
            </GText>
            {/** Second Row (Review + Headline)*/}
            {/* <View style={centerRow}>*/}
            {user.education && (
              <GIconRow icon={'graduation-cap'} text={user.education} />
            )}
            {/**
                         job: {
                         id: '3',
                         specialityKey: 'financialAdvisor',
                         web: 'https://startupsinvestment.com',
                         color: '#32CD32',
                         icon: 'currency-eur',
                         name: 'Startups Investment',
                         position: 'Director de Inversiones',
                         },*/}
            {user.job /*CompanyType*/ && (
              <GIconRow
                style={C.py1}
                icon={'briefcase'}
                name={user.job}
                text={user.job}
              />
            )}
            {user.highlights && !isArray(user.highlights) && (
              <GIconRow
                style={C.py1}
                color={palette.purpleSec}
                name={user.highlights?.specialityKey || ''}
                descr={user.highlights?.position}
                keywords={user.highlights?.keywords}
                icon={user.highlights?.icon}
                text={user.highlights?.name}
              />
            )}
            <View style={[C.row, C.itemsCenter, C.justifyBetween]} />
          </View>
        </View>
        {showRibbon && (
          <View style={[C.absolute, C.right_6, C.top_5]}>
            <GIcon
              icon={gImages.ribbon}
              size={EeSize.avatar}
              /*wrapStyle={[C.top_4]}*/
            />
            {/*<GPill
            wrapStyle={C.py0}
            long
            selected={isHover}
            color={color}
            icon={emoji}
            text={name}
          />*/}
          </View>
        )}
        {user?.avgReview && (
          <View
            style={[
              C.absolute,
              C.left_2,
              C.top_8,
              C.px1,
              C.bgWhite,
              C.radius20,
              eColor(palette.primaryMercuryGrey).border,
            ]}
          >
            <GIconRow
              icon={'star-rate'}
              style={[C.py0]}
              title={round(user.avgReview).toString()}
              color={reviewScale(user.avgReview).hex()}
            />
          </View>
        )}
      </Pressable>

      {!isEmpty(allKeywords) && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal /*={!transparent && !isSmall}*/
          data={allKeywords}
          style={[C.mt2, C.py1]}
          renderItem={({item}) => {
            return (
              <GBadge
                selected /*={selected}*/
                long
                wrapStyle={[C.mr2]}
                key={item}
                /*transp={transparent}*/
                text={item}
                color={palette.purpleSec}
                fontColor={palette.purpleSec}
                icon={'hashtag'}
              />
            );
          }}
        />
      )}

      {/** Tab Selector:*/}
      {user?.services && (
        <TabHeaders
          valExtractor={key => {
            //const servicesForFormat = user?.services?.[key as
            // ServiceFormatKey];
            return gSpecialities[key as SpecialityKey]; /*servicesForFormat &&
             servicesForFormat.length > 0
              ? servicesForFormat[0]
              : {};*/
          }}
        />
      )}

      {/* <Text>{JSON.stringify(shownServices)}</Text>*/}
      {user?.services && (isHover || selectedTab) && shownServices && (
        <View style={[C.py2, C.maxwFull]}>
          <View>
            {shownServices.map(
              ({
                name: currName,
                price,
                duration,
                format,
                keywords,
              }: ServiceType) => (
                <GIconRow
                  key={currName + (keywords ? JSON.stringify(keywords) : '')}
                  style={[C.justifyBetween, C.wFull, C.py1]}
                  name={currName || ''}
                  icon={serviceFormatMap[format as ServiceFormatKey]?.icon}
                  color={palette.purpleSec}
                  descr={formatDuration(duration)}
                  title={
                    (price || round(minutePacks[0].price * (duration || 1))) +
                    '€'
                  }
                  text={currName}
                />
              ),
            )}
            {currServices?.length &&
            !showServices &&
            currServices?.length > 2 ? (
              <GIconRow
                onPress={() => setShowServices(prev => !prev)}
                style={[C.p1, C.mb1, C.selfCenter]}
                color={palette.purpleSec}
                descr={`Mostrar ${
                  currServices.length - shownServices.length
                } servicios más`}
                icon={showServices ? 'chevron-up' : 'chevron-down'}
              />
            ) : null}
          </View>

          {sortedTimeslots && showAllTimeslots ? (
            <TimeslotSelector groupedTimeslots={sortedTimeslots} />
          ) : (
            <View
              style={[
                C.flex,
                C.justifyEnd,
                C.my2,
                eColor(palette.lightGrey).borderTop,
              ]}
            >
              <View style={[C.justifyEnd, C.mt3]}>
                {/*<GText>{JSON.stringify(sortedTimeslots)}</GText>*/}
                {sortedTimeslots && sortedTimeslots?.length > 0 && (
                  <GIconRow
                    name={'Disp: '}
                    descr={dayjs(sectionData[0].data[0]?.startTime).fromNow()} // Adjusted for new format
                    color={palette.purpleSec}
                    style={C.py2}
                    icon={'calendar-clock'}
                    text={userDate(sectionData[0].data[0]?.startTime)} // Adjusted for new format
                  />
                )}
                <SectionList
                  sections={sectionData}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={[C.itemsCenter, C.ml1]}
                  /*numColumns={1}*/
                  renderItem={({item: timeSlot}) =>
                    timeSlot && (
                      <GBadge
                        key={timeSlot.id || timeSlot.toString()} // Adjusted for new format
                        selected={timeSlot.status !== TimeslotStatus.booked}
                        /*symbol={dayjs(timeSlot.startTime).format(
                          'dd DD M YYYY, hh:mm',
                        )}*/
                        disabled={timeSlot.status === TimeslotStatus.booked}
                        onPress={async () => {
                          /*if (timeSlot.status === TimeslotStatus.booked) {
                            Toast.show({
                              type: 'error',
                              text1: 'Time slot already booked',
                            });
                          } else if (currUser) {*/
                          /** Route path:
                           *  '/booking/select/:expertUsername?/:serviceId?/:timeslotId?',*/
                          Linking.openURL(
                            oralifyBaseURL +
                              routeQueryParam(headerScreens.booking.path, {
                                timeslotId: timeSlot.id,
                              }),
                          );
                          /*} else
                            Linking.openURL(
                              oralifyBaseURL + headerScreens.accountLogin.path,
                            );
                        }*/
                        }}
                        wrapStyle={[C.px2, C.py1, C.mr2]}
                        textStyle={
                          timeSlot.status === TimeslotStatus.booked &&
                          eText.strike
                        }
                        /*icon={serviceMap[currentTab].icon}*/
                        color={palette.purpleSec}
                        fontColor={palette.purpleSec}
                        text={dayjs(timeSlot.startTime).format(DATE_HOUR)} // Adjusted for new format
                      />
                    )
                  }
                  renderSectionHeader={({section: {title}}) => (
                    <GText style={[C.capitalize]} type={TextType.tabSelected}>
                      {title}
                    </GText>
                  )}
                />
              </View>
            </View>
          )}
          {/*{!showAllTimeslots && (
            <GIconRow
              color={palette.purpleSec}
              onPress={() => setShowAllTimeslots(prev => !prev)}
              text={'Mostrar todos los horarios '}
              icon={'chevron-down'}
            />
          )}*/}
        </View>
      )}
      {children}
    </Pressable>
  ) : (
    <View />
  );
};
