/**This hook is used for getting active breakpoint (the same ConsistentCSS uses)*/
import {layout} from '../../ui';
import {useWindowDimensions} from 'react-native';

const useScreenSizeBreakpoint = () => {
  const {width} = useWindowDimensions();

  return Object.keys(layout).reduce((currentBreakpoint, breakpoint) =>
    layout[breakpoint] <= width ? breakpoint : currentBreakpoint,
  );
};

export default useScreenSizeBreakpoint;
