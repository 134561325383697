import {Alignment, IS_WEB, orangeGrad, textStyles, TextType} from '../ui';
import React, {ReactNode} from 'react';
import {Text, TextProps} from 'react-native';
import Animated from 'react-native-reanimated';
import {palette} from 'oralify-common';

export interface GTextProps extends TextProps {
  /**
   * font type.
   */
  type?: TextType;
  webGrad?: boolean;
  blur?: boolean;
  /**
   * font color.
   */
  color?: string; //#35353 or "blue"
  /**
   * text alignment.
   */
  align?: Alignment;
  /**
   * animated text
   */
  animated?: boolean;
  /**
   * children
   */
  children?: ReactNode;
}

export const GText = ({
  type = TextType.body,
  align = Alignment.Left,
  color,
  animated,
  webGrad,
  blur,
  children,
  style,
  ...rest
}: GTextProps) => {
  const {defaultStyle, color: defaultColor, family} = textStyles[type];
  const textProps = {
    ...rest,
    style: [
      defaultStyle,
      {
        color: color || defaultColor || palette.greyDark,
        fontFamily: family,
        textAlign: align,
      },
      blur && {
        textShadowColor: palette.white,
        textShadowRadius: 3,
        textShadowOffset: {width: 1, height: 1},
      },
      style,
      IS_WEB &&
        webGrad && {
          backgroundImage: `linear-gradient(120deg, ${orangeGrad[0]}, ${orangeGrad[1]}, ${orangeGrad[2]})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          //WebkitTextStroke: '1px white',
        },
    ],
  };

  return animated ? (
    <Animated.Text {...textProps}>{children}</Animated.Text>
  ) : (
    <Text {...textProps}>{children}</Text>
  );
};
