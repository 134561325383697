import React, {Component} from 'react';
import {GIcon, GText} from '../atoms';
import {Alignment, eColor, TextType} from '../ui';
import C from 'consistencss';
import {FlatList, TouchableOpacity, View} from 'react-native';
import {useRecoilState} from 'recoil';
import UseResponsive from '../ui/helpers/useResponsive';
import {
  gSpecialities,
  palette,
  RecurrenceKey,
  serviceFormatMap,
} from 'oralify-common';
import {
  currentStepAtom,
  newServiceAtom,
  SERVICE_STEPS,
  serviceSteps,
} from '../types/stepTypes';
import {useSpecialityCategorySelector} from '../types/SpecialityProvider';
import {GPill} from '../molecules/gPill';
import {formatDuration} from '../types/utils/dates';
import {useModal} from './useModal';
import {modalScreens} from '../routes/useGNav.web';

export const AddServiceWrapper = ({isModal = true}) => {
  const {isSmall} = UseResponsive();
  const showHoriz = isModal || isSmall;
  const {closeModal} = useModal(modalScreens.addService);

  const [newService, setNewService] = useRecoilState(newServiceAtom);
  const {
    selectedSpecialityKey,
    selectedKeywords,
    setCurrentCategoryKey,
    setCurrentSpecialityKey,
    CategoriesGroupSelector,
  } = useSpecialityCategorySelector({
    storeChange: true,
    fontColor: palette.orangeSec,
    showCTA: true,
  });
  const [currStepKey, setCurrStepKey] = useRecoilState(currentStepAtom);
  const currStep = serviceSteps[currStepKey];
  const shownSteps = Object.values(serviceSteps); /*!currUser?.speciality
    ? currSteps
    : currSteps.filter(({isExpert}) => !isExpert);*/

  const currIndex = shownSteps.findIndex(({key}) => key === currStepKey);

  /*useEffect(() => {
            if (!!currUser && currIndex < 1)
              setCurrStepKey(serviceSteps.roleCuriousExpert.key);
          }, [currUser, currIndex, setCurrStepKey]);*/
  /*const navigateToStep = (newStepKey: string) => {
            setCurrStepKey(newStepKey);
          };*/
  /**When selecting a speciality, jump to next one*/
  /*useEffect(() => {
            if (selectedSpecialityKey && currIndex < 1) {
              setCurrStepKey(SERVICE_STEPS.SERVICE_FORMAT);
              setNewService({...newService, speciality: selectedSpecialityKey});
            }
          }, [selectedSpecialityKey]);*/
  const handleNav = (newStepKey: SERVICE_STEPS) => {
    /**if going back to first one, reset both category and speciality*/
    if (currIndex > 0 && newStepKey === SERVICE_STEPS.SPECIALITY) {
      setCurrentSpecialityKey(undefined);
      setCurrentCategoryKey(undefined);
      setNewService({});
    }
    setCurrStepKey(newStepKey);
  };

  const currSpeciality = newService?.speciality || selectedSpecialityKey;
  const CurrStepContent = ({contentStep}: {contentStep: SERVICE_STEPS}) => {
    const {title, key, subtitle, icon, Component} = serviceSteps[contentStep];
    return (
      <View
        style={
          isModal ? [C.itemsCenter] : [C.itemsStart, C.flex, C.selfStart, C.my4]
        }
      >
        <View style={[C.mb6]}>
          {title && (
            <GText
              align={!isModal ? Alignment.Left : Alignment.Center}
              type={TextType.h1Logo}
            >
              {title}
            </GText>
          )}
          {subtitle && (
            <GText
              align={!isModal ? Alignment.Left : Alignment.Center}
              type={TextType.h3Orange}
            >
              {subtitle}
            </GText>
          )}
        </View>
        {contentStep === SERVICE_STEPS.SPECIALITY && (
          <GText style={[C.mb4]}>
            {'Puedes' + ' seleccionar otras especialidades más adelante'}
          </GText>
        )}
        {contentStep === SERVICE_STEPS.SPECIALITY && CategoriesGroupSelector}

        {currStep && Component && (
          <Component
            initialRecurrency={!isModal ? RecurrenceKey.Weekly : undefined}
            key={key}
            title={title}
            subtitle={subtitle}
            icon={icon}
          />
        )}
        {/*<ServiceDurationSelector currentFormat={ServiceFormatKey.audio} />
        {defaultService && <ServiceDetailsSelector service={defaultService} />}*/}
      </View>
    );
  };
  return (
    <View
      style={[
        C.justifyCenter,
        C.my4,
        /*C.wFull,*/
        isModal ? C.itemsCenter : C.itemsStart,
        /* C.justifyStart,*/
        !showHoriz && C.row,
      ]}
    >
      {/** Current TopxBar step navigator*/}
      <FlatList
        data={shownSteps}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        style={[C.px4]}
        horizontal={showHoriz}
        renderItem={({item, index}) => {
          const {title, key, icon} = item;
          const isSelected = isModal ? index <= currIndex : true;
          const selColor = isSelected ? palette.orangeSec : palette.greyish;

          return (
            <View
              key={key}
              style={[C.row /*, C.itemsStart, C.justifyBetween*/]}
            >
              <View
                key={key}
                style={[
                  C.w36,
                  !isModal && [C.mr12],
                  /*showHoriz && C.w20,*/ C.itemsCenter,
                ]}
              >
                <TouchableOpacity
                  disabled={!isSelected}
                  onPress={() => handleNav(key)}
                  style={[
                    C.p2,
                    eColor(selColor).border,
                    eColor(palette.white).bg,
                    C.radius10,
                    C.shadowMd,
                    C.selfCenter,
                  ]}
                >
                  {icon && <GIcon icon={icon} color={selColor} />}
                  {/*<GText type={TextType.body}>{subtitle || ''}</GText>*/}
                </TouchableOpacity>
                <GText
                  align={Alignment.Center}
                  style={[C.my2]}
                  color={selColor}
                  type={isSelected ? TextType.h6BodySmall : TextType.label}
                >
                  {title || ''}
                </GText>

                {/*<GText>{JSON.stringify(selectedSpecialityKey)}</GText>
              <GText>{JSON.stringify(newService?.format)}</GText>*/}
                {index === 0 && currSpeciality && (
                  <GPill
                    color={palette.orangeSec}
                    selected
                    /*long*/
                    text={gSpecialities[currSpeciality]?.name}
                    icon={gSpecialities[currSpeciality]?.emoji}
                  />
                )}
                {index === 1 && newService?.format && (
                  <GPill
                    color={palette.orangeSec}
                    selected
                    text={newService.format}
                    icon={serviceFormatMap[newService.format].emoji}
                  />
                )}
                {index === 2 && newService?.duration && (
                  <GPill
                    color={palette.orangeSec}
                    selected
                    text={formatDuration(newService.duration)}
                    icon={'⌛️'}
                  />
                )}
                {index === 3 && newService?.price && (
                  <GPill
                    color={palette.orangeSec}
                    selected
                    text={newService.price + '€'}
                    icon={'💰'}
                  />
                )}
                {/**Connector Line between steps*/}
                {index < shownSteps.length - 1 && (
                  <View
                    style={[
                      showHoriz && [C.absolute, C.right_14, C.top5],
                      eColor(
                        !isModal
                          ? palette.orangeSec
                          : index < currIndex
                          ? palette.orangeSec
                          : palette.greyish,
                      ).bg, // Aquí está la lógica condicional
                      C.shadowMd,
                      C.radius4,
                      showHoriz ? [C.w27, C.h1] : [/*C.h90,*/ C.flex, C.w1],
                    ]}
                  />
                )}
              </View>
              {!isModal && (
                <View style={[C.justifyStart]}>
                  <CurrStepContent contentStep={item.key} />
                </View>
              )}
            </View>
          );
        }}
      />
      {isModal && <CurrStepContent contentStep={currStepKey} />}
    </View>
  );
};
