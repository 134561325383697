import C from 'consistencss';
import {Animated, Pressable} from 'react-native';
import {
  Alignment,
  BASE_PIXEL,
  EeSize,
  gImages,
  IS_WEB,
  StylesArray,
} from '../ui';
import React from 'react';
import {GIcon} from './GIcon';
import useResponsive from '../ui/helpers/useResponsive';
import {GText} from './GText';
import {palette} from 'oralify-common';
import {Oralify} from '../../public/assets/svgMap';
import {ButtonSize, ButtonType, GButton} from '../molecules';
import {trans} from '../store/language';
import {useModal} from '../modals/useModal';
import {modalScreens} from '../routes/useGNav.web';

export const EmptyResults = ({
  text = 'Por desgracia no ha habido resultados',
  onPress,
}: {
  text?: string;
  onPress?: () => void;
}) => {
  const {resp, isSmall} = useResponsive();
  const {showModal} = useModal(modalScreens.addService);
  return (
    <Pressable
      disabled={!onPress}
      style={[
        C.itemsCenter,
        C.selfCenter,
        !isSmall && C.mlHalf,
        C.p4,
        C.shadowMd,
        C.justifyCenter,
        C.radius4,
      ]}
      onPress={onPress}
    >
      {text && (
        <GText
          align={Alignment.Center}
          /*style={C.maxwHalf}*/
          color={palette.orangeSec}
        >
          {text}
        </GText>
      )}
      {onPress && (
        <GButton
          color={palette.orangeSec}
          icon={'restart'}
          text={'Restaurar filtros'}
          size={ButtonSize.small}
          onPress={onPress}
          style={C.my3}
          type={ButtonType.secondary}
        />
      )}
      <GIcon
        icon={
          gImages.oralify404 /*{uri: 'https://i.imgur.com/NGfvD0T.jpg'
         /!*gImages.empty*!/}*/
        }
        resizeMode={'contain'}
        size={EeSize.image}
        wrapStyle={[C.radius4, C.my2]}
      />
      {text && (
        <GText
          align={Alignment.Center}
          /*style={C.maxwHalf}*/
          color={palette.orangeSec}
        >
          {trans('Aunque siempre puedes ser el primero...')}
        </GText>
      )}
      {onPress && (
        <GButton
          icon={'account-star'}
          size={ButtonSize.small}
          text={'¡Ofrecer mis servicios!'}
          style={C.my3}
          onPress={() => {
            showModal();
          }}
          type={ButtonType.primary}
        />
      )}
    </Pressable>
  );
};
export const GSpinner = ({
  size = EeSize.md,
  onPress,
  wrapStyle,
  full = true,
}: {
  size?: EeSize;
  wrapStyle?: StylesArray;
  onPress?: () => void;
  full?: boolean;
}) => {
  const accordion = new Animated.Value(0);

  Animated.loop(
    Animated.sequence([
      Animated.timing(accordion, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: !IS_WEB,
      }),
      Animated.timing(accordion, {
        toValue: 0,
        duration: 2000,
        useNativeDriver: !IS_WEB,
      }),
    ]),
  ).start();

  const accordionInterpolate = accordion.interpolate({
    inputRange: [0.25, 1],
    outputRange: [0.75, 0.25],
  });

  const currSize = BASE_PIXEL * (6 + BASE_PIXEL) * size;

  const animatedStyle = {
    transform: [{scaleY: accordionInterpolate}],
  };
  const {resp} = useResponsive();
  return (
    <Pressable
      style={[
        wrapStyle,
        full
          ? [
              C.itemsCenter,
              C.selfCenter,
              C.minh40,
              C.justifyCenter,
              resp(0.8).width,
            ]
          : [C.bgWhite, C.selfCenter, C.radius10],
      ]}
      onPress={onPress}
    >
      <Animated.View style={animatedStyle}>
        {IS_WEB ? (
          <GIcon icon={gImages.oralify} size={size + 3} />
        ) : (
          <Oralify height={currSize} width={currSize} />
        )}
      </Animated.View>
    </Pressable>
  );
};

export default GSpinner;
