import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {
  AnalyticsCallOptions,
  getAnalytics,
  Item,
  logEvent,
} from 'firebase/analytics';
import {getStorage} from 'firebase/storage';
import 'firebase/compat/auth';
import compatApp from 'firebase/compat/app';

/*import {FirebaseAnalyticsTypes} from '@react-native-firebase/analytics';
import AnalyticsCallOptions = FirebaseAnalyticsTypes.AnalyticsCallOptions;*/

export const firebaseConfig = {
  //apiKey: env.FIREBASE_API_KEY,
  apiKey: 'AIzaSyCFlRV621JeI2IvOoGJ0CURaIBMcIdPK2E',
  authDomain: 'oralify7.firebaseapp.com',
  projectId: 'oralify7',
  storageBucket: 'oralify7.appspot.com',
  messagingSenderId: '414603217613',
  appId: '1:414603217613:web:2703d0ff2d41c1ccd93784',
  measurementId: 'G-5DRZK9XZVM',
};
compatApp.initializeApp(firebaseConfig);

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const gAnalytics = getAnalytics(firebaseApp);
export const gStorage = getStorage(firebaseApp);
export const gLogEvent = (
  eventName: string,
  eventParams?:
    | {
        [key: string]: any;
        coupon?: string | undefined;
        currency?: string | undefined;
        items?: Item[] | undefined;
        payment_type?: string | undefined;
        value?: number | undefined;
      }
    | undefined,
  options?: AnalyticsCallOptions | undefined,
) => logEvent(gAnalytics, eventName, eventParams, options);
