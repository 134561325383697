import { breakPoints } from "../constants";
import useScreenSizeBreakpoint from "./use-screen-size-breakpoint";

/**This hook is used for getting getting number of columns in a layout*/
export const useNumberOfColumns = (): number => {
  const breakpoint = useScreenSizeBreakpoint();

  const {columns} = breakPoints[breakpoint];

  return columns;
};

export default useNumberOfColumns;
