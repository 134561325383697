import React from 'react';
import {FlatList, SafeAreaView, View} from 'react-native';
import C from 'consistencss';
import {
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ServiceType,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import {GCard} from '../molecules';
import {GPill} from '../molecules/gPill';
import {TextType} from '../ui';
import {trans} from '../store/language';
import {GText} from '../atoms';
import UserCard from '../organisms/UserCard';
import {formatDuration} from '../types/utils/dates';
import {useModal} from '../modals/useModal';
import {modalScreens} from '../routes/useGNav.web';

export const ServiceList = ({user}: {user: UserType}) => {
  const services = user.services;
  const {showModal} = useModal(modalScreens.addService);

  return (
    <SafeAreaView
      style={[C.bgWhite, C.m5]}
      /*collapsibleHeader
            contentColumnSpan={10}
            backgroundColor={speciality ? palette.white : palette.background}
            iconRight={'plus-circle'}
            title={(!IS_WEB && 'Expertos') || undefined}*/
    >
      <GText style={C.mb12} type={TextType.h3Orange}>
        {trans('Añade o duplica los servicios')}
      </GText>
      {/*{!user.services ? (
        <GSpinner full={false} />
      ) : (*/}
      <FlatList ///*<FlashList*/
        data={services as ServiceType[]}
        /* numColumns={5}*/
        horizontal
        //estimatedItemSize={matchingUsers?.length || 13000}
        /*extraData={[currService, currSpeciality, isConnected]}*/
        keyExtractor={({id}: ServiceType) => id}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={C.selfStart}
        ListFooterComponent={
          <GCard
            style={[C.my7, C.flex]}
            icon={'plus'}
            image={'✨'}
            onPress={() => {
              showModal();
            }}
            title={'Añadir servicio'}
            subtitle={'Ó añadir un \nformato diferente...'}
            text={'También puedes copiar o duplicar otros servicios'}
          />
        }
        /*ListEmptyComponent={<EmptyResults text={`Aún no tienes Servicios`} />}*/
        renderItem={({item: currUser}) => {
          const {
            name,
            id,
            speciality,
            format,
            price,
            duration,
            keywords,
            address,
            description,
          } = currUser;
          const {emoji: specialityIcon, name: specialityName} =
            gSpecialities[speciality as SpecialityKey];
          const {icon: formatIcon, name: formatName} =
            serviceFormatMap[format as ServiceFormatKey];
          return (
            <View style={[C.m2]}>
              <GCard
                style={[C.mx4, C.flex]}
                key={id}
                image={specialityIcon}
                text={name}
                title={price + '€'}
                subtitle={formatDuration(duration)}
                icon={'💰'}
              >
                <GPill
                  color={palette.orangeSec}
                  selected
                  long
                  wrapStyle={[C.mt4]}
                  text={formatName}
                  icon={formatIcon}
                />
                {/*<GPill
                    color={palette.orangeSec}
                    selected
                    text={formatDuration(duration)}
                    icon={'⌛️'}
                  />*/}

                {/*<GPill
                    color={palette.orangeSec}
                    selected
                    text={price + '€'}
                    icon={'💰'}
                  />*/}
              </GCard>

              <GPill
                color={palette.orangeSec}
                selected
                long
                wrapStyle={[C.absolute, C.top_2, C.right_2]}
                text={specialityName}
                icon={specialityIcon}
              />
            </View>
          );
        }}
      />
      {/*)}*/}

      <GText style={C.mb12} type={TextType.h3Orange}>
        {trans('Así te veran los usuarios')}
      </GText>
      <UserCard user={user} showInitialTab />
    </SafeAreaView>
  );
};
