import React, {createContext, useContext, useState} from 'react';
import {
  gSpecialities,
  palette,
  specialityCategories,
  SpecialityKey,
  SpecialityMapType,
} from 'oralify-common';
import {FlatList, View} from 'react-native';
import C from 'consistencss';
import {GIcon, GText} from '../atoms';
import {customText, eColor, eText, TextType} from '../ui';
import useResponsive from '../ui/helpers/useResponsive';
import {currentStepAtom, newServiceAtom, SERVICE_STEPS} from './stepTypes';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {GButton} from '../molecules';
import _, {isEmpty} from 'lodash';
import {GPill} from '../molecules/gPill';
import {trans} from '../store/language';
import {useGDropdown} from '../organisms/UseGDropdown';
import {camelCaseToWords} from './utils/dates';
import {ItemType} from 'react-native-dropdown-picker';
import {GBadge} from '../molecules/gBadge';
import {SpecialityDropdown} from '../components/SpecialityDropdown';

export const matchingCategory = (specKey?: SpecialityKey) =>
  Object.entries(specialityCategories).find(
    ([key, {services}]) =>
      specKey && services && services.includes(specKey) && key,
  )?.[0] as SpecialityKey;

interface SpecialityContextType {
  currSpecialityKey?: SpecialityKey;
  setSpecialityKey: React.Dispatch<
    React.SetStateAction<SpecialityKey | undefined>
  >;
  currSpeciality?: SpecialityMapType;
  randomSpecialityKey: SpecialityKey;
  randomSpeciality: SpecialityMapType;
  getRandomSpeciality: () => SpecialityKey;
}

const SpecialityContext = createContext<SpecialityContextType | undefined>(
  undefined,
);

export const getRandomSpecialityKey = /* useCallback(*/ (): SpecialityKey => {
  const specialities = Object.values(SpecialityKey);
  const randomIndex = Math.floor(Math.random() * specialities.length);
  return specialities[randomIndex];
}; /*, [])*/

/** TODO @deprecate*/
export const SpecialityProvider = ({children}: {children: JSX.Element}) => {
  const [currSpecialityKey, setCurrSpecialityKey] = useState<
    SpecialityKey | undefined
  >(); /** User Set*/
  /*const [randomSpeciality, setRandomSpec] = useState<SpecialityKey>(
                                                                                                                getRandomSpeciality(),
                                                                                                              );*/

  const currSpeciality = currSpecialityKey
    ? gSpecialities[currSpecialityKey]
    : undefined;
  /*const shownSpeciality: SpecialityKey = currSpecialityKey || randomSpeciality;*/

  /*useEffect(() => {
                                                                                                                /!*if (!currSpecialityKey) {*!/
                                                                                                                const timeoutId = setTimeout(() => {
                                                                                                                  setRandomSpec(getRandomSpeciality());
                                                                                                                }, 2000);

                                                                                                                return () => {
                                                                                                                  clearTimeout(timeoutId);
                                                                                                                };
                                                                                                                /!*}*!/
                                                                                                              }, [currSpecialityKey, getRandomSpeciality, randomSpeciality]);
                                                                                                            */
  return (
    <SpecialityContext.Provider
      value={{
        setSpecialityKey: setCurrSpecialityKey,
        currSpecialityKey,
        currSpeciality,
        /*randomSpecialityKey: shownSpeciality,
                                                                                                                                                                                                                                                                                                                                                                                                                                                        randomSpeciality: gSpecialities[shownSpeciality],*/
        /*getRandomSpeciality,*/
      }}
    >
      {children}
    </SpecialityContext.Provider>
  );
};

export const useSpeciality = (): SpecialityContextType => {
  const context = useContext(SpecialityContext);
  if (!context) {
    throw new Error('useSpeciality must be used within a SpecialityProvider');
  }
  return context;
};

export const useSpecialityCategorySelector = ({
  initialCategory,
  transparent = false,
  /*list = [],*/
  storeChange = false,
  fontColor = palette.purpleSec,
  showCategoryPills = false,
  showCTA = false,
  initialSpeciality,
  initialKeywords = [],
}: {
  initialCategory?: keyof typeof specialityCategories;
  initialSpeciality?: SpecialityKey;
  initialKeywords?: string[];
  fontColor?: string /*list?: UserType[];*/;
  storeChange?: boolean;
  showCategoryPills?: boolean;
  showCTA?: boolean;
  transparent?: boolean;
}) => {
  const specCategoryEntries = Object.entries(specialityCategories);
  const [currentCategoryKey, setCurrentCategoryKey] = useState<
    string | undefined
  >(
    initialCategory || initialSpeciality
      ? specCategoryEntries.find(
          ([key, {services}]) =>
            _.find(services, s => s === initialSpeciality) && key,
        )?.[0]
      : undefined,
  );
  const mappedTreeValues = Object.keys(specialityCategories).reduce(
    (accumulator, categoryKey) => {
      const category = specialityCategories[categoryKey];

      // Añadir la categoría principal
      accumulator.push({
        label: category.name,
        icon: () => <GIcon icon={category.emoji} />,
        value: categoryKey,
      });

      // Añadir subcategorías (especialidades)
      const children = category.services.map(serviceKey => {
        const service = gSpecialities[serviceKey];
        return {
          label: service.name,
          value: serviceKey,
          icon: () => <GIcon icon={service.emoji} />,
          parent: categoryKey,
        };
      });

      return accumulator.concat(children);
    },
    [],
  );

  const [selectedKeywords, setSelectedKeywords] = useState<ItemType<string>[]>(
    initialKeywords.map(keywd => ({
      value: keywd,
      label: keywd,
    })),
  );
  const {isSmall, resp} = useResponsive();
  const [newService, setNewService] = useRecoilState(newServiceAtom);
  const setCurrStepKey = useSetRecoilState(currentStepAtom);
  const [currentSpecialityKey, setCurrentSpecialityKey] =
    useState(initialSpeciality);
  const currCategory =
    (currentCategoryKey && specialityCategories[currentCategoryKey]) ||
    undefined;
  const currSpeciality =
    currentCategoryKey && currentSpecialityKey
      ? gSpecialities[currentSpecialityKey as SpecialityKey]
      : undefined;

  const numColumnsCategories =
    isSmall && transparent ? 2 : isSmall || transparent ? 3 : undefined;

  const onReset = () => {
    setCurrentCategoryKey(undefined);
    setCurrentSpecialityKey(undefined);
    setSelectedKeywords([]);
  };
  const currFontColor = transparent ? palette.white : fontColor;
  const showKeywords =
    currentSpecialityKey &&
    currentCategoryKey &&
    gSpecialities[currentSpecialityKey]?.keywords;
  const {
    isOpen: isSpecialityCategoryOpen,
    setOpenPicker: setOpenSpecialityCategory,
    GDropdown: SpecialityCategoryDropdown,
  } = useGDropdown({
    initialValue: currentSpecialityKey,
    key: 'SpecialityCategoryDropdown',
  });
  const {
    isOpen: isSpecialityOpen,
    setOpenPicker: setOpenSpeciality,
    GDropdown: SpecialityDropdown,
  } = useGDropdown({
    initialValue: currentSpecialityKey,
    key: 'SpecialityDropdown',
  });
  const {
    GDropdown: KeywordDropdown,
    isOpen: isKeywordsOpen,
    setOpenPicker: setOpenKeywords,
  } = useGDropdown({
    multiple: true,
    initialValue: selectedKeywords
      ? selectedKeywords.map(({value}) => value || '')
      : [],
    key: 'keywordDropdown',
  });
  const toggleLang = (item: ItemType<any>) => {
    setSelectedKeywords(prev => {
      // Check if the item is already in the array
      const index = _.findIndex(prev, {value: item.value});
      return index === -1
        ? [...prev, item]
        : _.filter(prev, currItem => currItem.value !== item.value);
    });
  };
  const CategoriesGroupSelector = (
    <View>
      <View style={[isSpecialityOpen && C.minh60]}>
        {showCategoryPills && (
          <GText
            color={currFontColor}
            type={/*currCategory ? TextType.tab : */ TextType.tabSelected}
            style={C.mb4}
          >
            {trans('Categoría del servicio')}
          </GText>
        )}
        <View style={[C.row, C.itemsStart]}>
          {/**Alternative more visual, starting display*/}
          {showCategoryPills && !currentCategoryKey ? (
            <View>
              <FlatList
                numColumns={numColumnsCategories}
                key={numColumnsCategories}
                horizontal={!transparent && !isSmall}
                data={specCategoryEntries}
                keyExtractor={item => item[0] + numColumnsCategories}
                renderItem={({item: [currkey, currVal]}) => {
                  const selected = currentCategoryKey === currkey;
                  const onSetSpeciality = () => {
                    setCurrentCategoryKey(selected ? undefined : currkey);
                    setCurrentSpecialityKey(undefined);
                    setSelectedKeywords([]);
                    setOpenSpeciality(true);
                  };
                  return (
                    <GPill
                      onPress={onSetSpeciality}
                      selected={selected}
                      transp
                      icon={currVal.emoji}
                      text={currVal.name}
                      long
                    />
                  );
                }}
              />
            </View>
          ) : currentCategoryKey /** Already has a category */ ? (
            <SpecialityDropdown
              /*mode={'BADGE'}*/
              selectColor={fontColor}
              placeholder={'Especialidad...'}
              searchPlaceholder={'Buscar entre las especialidades...'}
              setValue={setCurrentCategoryKey}
              value={
                currentCategoryKey /* {
                  value: currentSpecialityKey,
                  label: currSpeciality?.name,
                }*/
              }
              items={
                currCategory?.services.map((serviceKey: SpecialityKey) => {
                  const service = gSpecialities[serviceKey as SpecialityKey];
                  return {
                    label: service.name,
                    value: serviceKey,
                    icon: () => <GIcon icon={service.emoji || '👤'} />,
                  };
                }) || []
              }
              setOpen={setOpenSpeciality}
              onSelectItem={(
                item /*: {key: currkey, label, parent, value, icon},*/,
              ) => {
                setCurrentSpecialityKey(
                  item.value === currentSpecialityKey
                    ? undefined
                    : (item.value as SpecialityKey),
                );
                if (storeChange) setNewService(currSpeciality);
                setSelectedKeywords([]);
              }}
              open={isSpecialityOpen}
            />
          ) : (
            /** No category */
            <SpecialityCategoryDropdown
              mode={'BADGE'}
              selectColor={fontColor}
              /*selectedItemLabelStyle={[eColor(fontColor).text]}*/
              placeholder={'Categoría y especialidad...'}
              searchPlaceholder={
                'Buscar entre las categorías y especialidades...'
              }
              /*searchTextInputProps={{autoFocus: true}}*/
              /*labelStyle={[eColor(fontColor).text]}*/
              listParentContainerStyle={[
                C.shadowMd,
                eColor(palette.shadow).borderBottom,
                C.radius2,
                C.mt4,
              ]}
              listChildContainerStyle={C.ml4}
              listChildLabelStyle={[
                customText(TextType.body),
                eColor(palette.secondaryDarkGrey).text,
              ]}
              listParentLabelStyle={[
                customText(TextType.body),
                eColor(palette.greyDark).text,
              ]}
              open={isSpecialityCategoryOpen}
              setValue={setCurrentSpecialityKey}
              value={
                /*currentCategoryKey ||
                                                                                                                                                                                                                                                                                                                                                                                                                  currentSpecialityKey*/
                {
                  value: currentSpecialityKey || currentCategoryKey,
                  label: currSpeciality?.name || currCategory?.name,
                }
              }
              items={mappedTreeValues}
              onSelectItem={(
                item /*: {key: currkey, label, parent, value, icon},*/,
              ) => {
                /**Is a children speciality*/
                if (item.parent) {
                  // Set Speciality
                  setCurrentCategoryKey(
                    currentCategoryKey === item.parent
                      ? undefined
                      : item.parent.toString(),
                  );
                  setCurrentSpecialityKey(
                    item.value === currentSpecialityKey
                      ? undefined
                      : (item.value as SpecialityKey),
                  );
                  if (storeChange) setNewService(currSpeciality);
                  setSelectedKeywords([]);
                } else {
                  //Set Category
                  setCurrentCategoryKey(item.value);
                  setCurrentSpecialityKey(undefined);
                  setSelectedKeywords([]);
                }
              }}
              setOpen={setOpenSpecialityCategory}
            />
          )}
          {/** Keywords */}
          {showKeywords && currSpeciality?.keywords && (
            <KeywordDropdown
              placeholder={trans('Categorías...')}
              selectColor={fontColor}
              searchPlaceholder={trans('Buscar Categorías...')}
              value={selectedKeywords.map(({value}) => value)}
              items={currSpeciality.keywords.map(keyword => ({
                label: camelCaseToWords(keyword),
                value: keyword,
              }))}
              mode={'BADGE'}
              renderBadgeItem={item => {
                return (
                  _.findIndex(selectedKeywords, {value: item.value}) !== -1 && (
                    <View style={[C.p1]}>
                      <GBadge
                        text={item.label}
                        fontColor={fontColor}
                        selected
                        wrapStyle={[C.radius2]}
                        actionIcon={'close'}
                        onPress={() => {
                          toggleLang(item);
                          setOpenKeywords(false);
                        }}
                      />
                    </View>
                  )
                );
              }}
              onSelectItem={items => {
                setSelectedKeywords(items);
                setOpenKeywords(false);
              }}
              closeAfterSelecting
              showBadgeDot
              setItems={setSelectedKeywords}
              multiple={true}
              open={isKeywordsOpen}
              setOpen={setOpenKeywords}
              setValue={setSelectedKeywords}
            />
          )}
        </View>
      </View>
      {/** Breadcrumbs */}
      {currCategory?.name && (
        <View style={[C.row, C.itemsCenter, C.my2]}>
          <GText
            onPress={() => {
              setCurrentCategoryKey(undefined);
              setCurrentSpecialityKey(undefined);
              setSelectedKeywords([]);
            }}
            type={TextType.tabSelected}
            style={[eText.underline]}
            color={currFontColor}
          >
            {currCategory.name}
          </GText>
          {currSpeciality?.name && (
            <GIcon color={palette.greyish} icon={'chevron-right'} />
          )}
          <GText
            onPress={() => {
              setCurrentSpecialityKey(undefined);
              setSelectedKeywords([]);
            }}
            style={[eText.underline]}
            type={TextType.tabSelected}
            color={currFontColor}
          >
            {currSpeciality?.name}
          </GText>
          {!isEmpty(selectedKeywords) && (
            <>
              <GIcon color={palette.greyish} icon={'chevron-right'} />
              <GText
                type={TextType.tabSelected}
                onPress={() => {
                  setSelectedKeywords([]);
                }}
                style={[eText.underline, C.capitalize]}
                color={currFontColor}
              >
                {selectedKeywords
                  .map(({label}) => camelCaseToWords(label))
                  .join(', ')}
              </GText>
            </>
          )}
        </View>
      )}
      {showCTA &&
        storeChange &&
        currentSpecialityKey &&
        (selectedKeywords.length > 0 ||
          !gSpecialities[currentSpecialityKey]?.keywords) && (
          <GButton
            icon={'account-star'}
            /*loading={loading }*/
            text={`Soy experto en 
            ${gSpecialities[currentSpecialityKey]?.name}`}
            onPress={() => {
              setNewService(prev => ({
                ...prev,
                speciality: currentSpecialityKey,
                keywords: selectedKeywords?.map(({value}) => value),
              }));
              setCurrStepKey(SERVICE_STEPS.SERVICE_FORMAT);
            }}
          />
        )}
    </View>
  );

  return {
    currentCategoryKey,
    selectedSpeciality: currSpeciality,
    setCurrentCategoryKey,
    onResetSpecialities: onReset,
    setSelectedKeywords,
    selectedKeywords,
    selectedSpecialityKey: currentSpecialityKey,
    setCurrentSpecialityKey,
    CategoriesGroupSelector,
  };
};
