import C from 'consistencss';
import React from 'react';
import {Trans} from 'react-i18next';

import {GText, GTextProps} from '../atoms';

export interface EeTransText extends GTextProps {
  i18nKey: string | (string & string[]);
  values?: Record<string, unknown>;
  count?: number;
  components?: readonly React.ReactElement[] | {readonly [tagName: string]: React.ReactElement};
}

export function EeTransText({type, i18nKey, values, count, components, ...props}: EeTransText) {
  return (
    <GText type={type} {...props}>
      <Trans
        i18nKey={i18nKey}
        values={values}
        count={count}
        components={{
          bold: <GText type={type} style={C.bold} />,
          // TODO: Add emphasized styles
          em: <GText type={type} />,
          br: <GText type={type}>\n</GText>,
          p: <GText type={type} />,
          ...components,
        }}
      />
    </GText>
  );
}
