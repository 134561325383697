import React, {useState} from 'react';
import {FlatList, View} from 'react-native';
import {GIconRow} from '../molecules/GIconRow';
import {
  getRandomSpecialityKey,
  matchingCategory,
  useSpeciality,
} from '../types/SpecialityProvider';
import C from 'consistencss';
import {eDividerVert, TextType} from '../ui';
import {GText} from '../atoms';
import useResponsive from '../ui/helpers/useResponsive';
import {useModal} from '../modals/useModal';
import {
  gSpecialities,
  specialityCategories,
  SpecialityKey,
} from 'oralify-common';
import {modalScreens} from '../routes/useGNav.web';

export const SpecialityDropdown = () => {
  const {setSpecialityKey} = useSpeciality();
  const randomSpecialityKey = getRandomSpecialityKey();
  const [expandedCategory, setExpandedCategory] = useState<string | undefined>(
    matchingCategory(randomSpecialityKey),
  );
  const {isSmall} = useResponsive();
  const {closeModal: closeBurgerModal} = useModal(modalScreens.tooltip);
  return (
    <View style={[C.row, !isSmall && C.minw100]}>
      <FlatList
        ListHeaderComponent={
          <GText style={C.mb4} type={TextType.bodyBold}>
            Categorías
          </GText>
        }
        keyExtractor={([key]) => key + expandedCategory}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[C.p2, !isSmall && C.minw50]}
        data={Object.entries(specialityCategories)}
        renderItem={({item}) => {
          const [key, {name, emoji}] = item;
          const selected = expandedCategory === key;
          return (
            <GIconRow
              icon={emoji}
              actionIcon={'chevron-right'}
              text={name}
              //onHoverOut={() => setExpandedCategory(undefined)}
              onHover={() => {
                setExpandedCategory(key);
              }}
              onPress={() => {
                setExpandedCategory(key);
              }}
              selected={selected}
            />
          );
        }}
      />
      <View style={[eDividerVert]} />
      <FlatList
        ListHeaderComponent={
          <GText style={C.mb4} type={TextType.bodyBold}>
            Especialidades
          </GText>
        }
        keyExtractor={([key]) => key + randomSpecialityKey}
        contentContainerStyle={[C.p2]}
        showsHorizontalScrollIndicator={false}
        data={Object.entries(gSpecialities).filter(
          ([key]) =>
            expandedCategory &&
            specialityCategories[expandedCategory]?.services.includes(
              key as SpecialityKey,
            ),
        )}
        renderItem={({item}) => {
          const [key, {name: specName, emoji: specEmoji}] = item;
          const selected = randomSpecialityKey === key;
          const handlePress = (remove = false) => {
            setSpecialityKey(remove ? undefined : (key as SpecialityKey));
            if (isSmall) closeBurgerModal();
          };
          return (
            <GIconRow
              icon={specEmoji as string}
              text={specName}
              onHover={handlePress}
              //onHoverOut={() => handlePress(true)}
              onPress={() => handlePress()}
              selected={selected}
            />
          );
        }}
      />
    </View>
  );
};
