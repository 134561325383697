import {t} from 'i18next';
import {CurrencyCode, CurrencyType} from './currency';
import {currentLocale} from '../store/language/countryMap';

export enum Area {
  squareFeet = 'ft2',
  squareMeters = 'm2',
  none = 'none',
}

export enum Distance {
  kilometers = 'km',
  miles = 'mi',
}

export enum Weight {
  kg = 'kg',
  lb = 'lb',
  t = 't',
  tUS = 't',
}

export const round2 = (num?: number) => (num ? Math.round(num * 100) / 100 : 0);
export const currFormat = (
  num: number,
  curr?: typeof CurrencyCode | CurrencyType | undefined,
) =>
  new Intl.NumberFormat(currentLocale, {
    /*signDisplay: 'always',*/
    minimumFractionDigits: 0,
    useGrouping: true,
    style: 'currency', // @ts-ignore
    currency: curr?.code || 'EUR', //curr && (curr?.code || countryCurrencyMap[curr] || 'EUR'),
  }).format(round2(num));

export enum UnitSystem {
  metric = 'metric',
  customary = 'customary',
}

export const unitsMap = [
  {
    id: UnitSystem.metric,
    name: t('profile.preferences.units_metric'),
  },
  {
    id: UnitSystem.customary,
    name: t('profile.preferences.units_customary'),
  },
];

/** TODO keep adding Tons.... */
export type Units = Area | Distance;
