import {GIcon, GText} from '../atoms';
import C from 'consistencss';
import {Pressable} from 'react-native';
import React, {useState} from 'react';
import {Alignment, eColor, EeSize, StylesArray, TextType} from '../ui';
import {FullIconType} from '../types/icon-types';
import {centerRow} from './gInfoRow';
import {palette} from 'oralify-common';

export interface PillProps {
  text?: string;
  symbol?: string;
  quantity?: number | string;
  actionIcon?: FullIconType;
  icon?: FullIconType;
  color?: string;
  fontColor?: string;
  transp?: boolean;
  disabled?: boolean;
  selected?: boolean;
  long?: boolean;
  stretch?: boolean;
  onPress?: () => void;
  onHover?: () => void;
  onHoverOut?: () => void;
  key?: React.Key | null | undefined;
  wrapStyle?: StylesArray;
}

//export const pillWrapper = [centerRow, C.mr3, C.mb3, C.py1, C.px3, C.overflowHidden];
export const GPill = ({
  icon,
  actionIcon,
  symbol,
  text,
  transp,
  onHover,
  stretch,
  onHoverOut,
  color = transp ? palette.white : undefined,
  selected,
  disabled,
  long = false,
  quantity,
  onPress,
  wrapStyle,
}: PillProps) => {
  const [isHover, setIsHover] = useState(false);
  const currSelected = selected !== undefined ? selected : isHover;
  const bordColor = transp
    ? currSelected
      ? palette.white
      : 'transparent'
    : currSelected
    ? color
    : !disabled
    ? palette.primaryMercuryGrey
    : palette.greyDark;

  const currColor = transp
    ? palette.white
    : currSelected
    ? color
    : !disabled
    ? palette.primaryMercuryGrey
    : palette.greyDark;

  /*useEffect(() => {
    if (isHover && onHover) onHover();
  }, [isHover, onHover]);*/

  return (
    <Pressable
      style={[
        centerRow,
        C.mb3,
        stretch ? C.p0 : C.px3,
        C.overflowHidden,
        selected ? C.shadowLg : C.shadowSm,
        eColor(bordColor, 1).border,
        transp ? eColor(palette.transp).bg : C.bgWhite,
        C.mr2,
        transp ? C.h10 : C.h8,
        C.py1,
        C.radius6,
        wrapStyle,
      ]}
      onHoverIn={() => {
        if (onHover) {
          onHover();
          setIsHover(true);
        }
      }}
      onHoverOut={() => {
        if (onHoverOut) {
          onHoverOut();
          setIsHover(false);
        }
      }}
      disabled={!onPress}
      onPress={onPress}
    >
      {icon && (
        <GIcon
          color={currColor}
          wrapStyle={C.p1}
          icon={icon}
          size={EeSize.md}
        />
      )}
      {symbol && (
        <GText align={Alignment.Center} style={C.mx1} type={TextType.tab}>
          {symbol}
        </GText>
      )}
      {text && (
        <GText
          style={[C.capitalize, C.mx1, !isHover && !long && C.maxw20]}
          numberOfLines={1}
          color={transp ? palette.white : palette.greyDark}
          align={Alignment.Center}
          type={currSelected ? TextType.tabSelected : TextType.tab}
        >
          {text}
        </GText>
      )}
      {!!quantity && (
        /*&& quantity > 1 &&*/ <GText
          style={[C.ml1]}
          type={TextType.tab}
          numberOfLines={1}
          align={Alignment.Center}
          color={palette.secondaryDarkGrey}
        >
          {quantity}
        </GText>
      )}
      {actionIcon && (
        <GIcon
          wrapStyle={[C.ml1, C.mt1]}
          color={currColor}
          icon={actionIcon}
          size={EeSize.md}
        />
      )}
    </Pressable>
  );
};
