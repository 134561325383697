import React from 'react';
import {FlatList, View} from 'react-native';
import C from 'consistencss';
import {GFormatCard} from '../molecules';
import {useSetRecoilState} from 'recoil';
import useResponsive from '../ui/helpers/useResponsive';
import {
  currentStepAtom,
  newServiceAtom,
  SERVICE_STEPS,
} from '../types/stepTypes';
import {ServiceFormatKey, serviceFormatMap} from 'oralify-common';

export const ServiceFormatSelector = ({big = false}) => {
  const {isSmall} = useResponsive();
  const setNewService = useSetRecoilState(newServiceAtom);
  const setCurrStepKey = useSetRecoilState(currentStepAtom);
  const handleSetTab = (key: ServiceFormatKey) => {
    setNewService(prevService =>
      prevService ? {...prevService, format: key} : {format: key},
    );
    setCurrStepKey(SERVICE_STEPS.SERVICE_DURATION);
  };
  return (
    <View style={C.itemsCenter}>
      <FlatList
        horizontal={!isSmall}
        showsVerticalScrollIndicator={false}
        keyExtractor={([key]) => key}
        contentContainerStyle={C.p2}
        data={Object.entries(serviceFormatMap)}
        renderItem={({item}) => {
          const [key, values] = item;
          return (
            <GFormatCard
              big={big}
              keyItem={key}
              {...values}
              onSetTab={() => handleSetTab(key as ServiceFormatKey)}
            />
          );
        }}
      />
    </View>
  );
};
