import C from 'consistencss';
import {FlatList, View} from 'react-native';
import {GFormatCard} from '../molecules';
import React from 'react';
import useResponsive from '../ui/helpers/useResponsive';
import {
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  ServiceType,
} from 'oralify-common';
import {
  currentStepAtom,
  newServiceAtom,
  SERVICE_STEPS,
} from '../types/stepTypes';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {GText} from '../atoms';
import {Alignment, TextType} from '../ui';
import {formatDuration} from '../types/utils/dates';
import {round2} from '../types/units';

export const ServiceDurationSelector = ({
  currentFormat = ServiceFormatKey.person,
  onAddService,
}: {
  currentFormat?: ServiceFormatKey;
  onAddService?: (format: ServiceFormatKey, newService: ServiceType) => void;
}) => {
  const {isSmall} = useResponsive();
  const [newService, setNewService] = useRecoilState(newServiceAtom);
  const currrFormat = newService?.format || currentFormat;

  const setCurrStepKey = useSetRecoilState(currentStepAtom);
  const handleSetTab = (currDuration: Partial<ServiceType>) => {
    setNewService(prevService =>
      prevService ? {...prevService, ...currDuration} : currDuration,
    );
    setCurrStepKey(SERVICE_STEPS.SERVICE_DETAILS);
    if (onAddService && newService) onAddService(currrFormat, newService);
  };
  return (
    <View style={[C.itemsCenter]}>
      <FlatList
        horizontal={!isSmall}
        keyExtractor={({name}) => name}
        data={serviceFormatMap[currrFormat].defaults}
        contentContainerStyle={C.p4}
        renderItem={({item}) => {
          const {duration = 1, price = 1, name, highlight} = item;
          /*const {selected = false} = currUser?.services?.[currentFormat] || {};*/
          const priceMin = (price / duration) * 0.85;
          const priceHour = priceMin * 60;
          return (
            <GFormatCard
              keyItem={name || '' + duration}
              emoji={highlight ? '✨' : undefined}
              format={price + '€'}
              /*duration={duration}*/
              defaults={[]}
              descr={name}
              isSelected={false}
              big={!isSmall}
              onSetTab={() => handleSetTab(item)}
            >
              <GText
                align={Alignment.Center}
                color={palette.orangeSec}
                type={TextType.h2}
              >
                {formatDuration(duration)}
              </GText>
              <GText
                align={Alignment.Center}
                color={palette.greyish}
                type={TextType.label}
                style={[C.mt2]}
              >
                Ganarías {round2(priceMin)}€/min, {round2(priceHour)}€/h
              </GText>
            </GFormatCard>
          );
        }}
      />
    </View>
  );
};
