import {ReactNode} from 'react';

import GModalCenterMobile from '../modals/GModalCenterMobile';
import GCard from './GCard';
import {FullIconType} from '../types/icon-types';

export interface BannerProps {
  isPrimaryActive: boolean;
  isSecondaryActive: boolean;
  title: string;
  primaryText: string;
  secondaryText: string;
  subtitle: string;
  image: FullIconType;
  onClose: () => void;
  primaryOnPress: () => void;
  secondaryOnPress: () => void;
  children?: ReactNode;
}

export const EeBanner = ({
  onClose,
  children,
  image,
  primaryText,
  secondaryText,
  title,
  subtitle,
  primaryOnPress,
  secondaryOnPress,
}: BannerProps) => {
  return (
    <GModalCenterMobile onClose={onClose}>
      <GCard
        image={image}
        textCTA={primaryText}
        textSecondary={secondaryText}
        onPressSecondary={secondaryOnPress}
        onPressCTA={primaryOnPress}
        title={title}
        subtitle={subtitle}
      >
        {children}
      </GCard>
    </GModalCenterMobile>
  );
};

export default EeBanner;
