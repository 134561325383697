import C from 'consistencss';
import React, {useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {Alignment, gImages, TextType} from '../ui';
import {mision, propuestaDeValor} from '../types/mockLanding';
import {useModal} from '../modals/useModal';
import {GText} from '../atoms';
import {ButtonSize, ButtonType, GButton} from '../molecules';
import {SideContainer} from '../components/SideContainer';
import useResponsive from '../ui/helpers/useResponsive';
import {modalScreens} from '../routes/useGNav.web';
import {AddServiceWrapper} from '../modals/AddServiceWrapper';
import {trans} from '../store/language';
import UserCard from '../organisms/UserCard';
import {getUserInfo} from '../api/userApi';
import {centerRow} from '../molecules/gInfoRow';
import {ShareQR} from '../organisms/ShareQR';

export const ExpertLanding = () => {
  const {showModal: showLoginModal, ModalComponent: LoginModalComponent} =
    useModal(modalScreens.login);
  const {ModalComponent: AddServicesModal, showModal: showAddServicesModal} =
    useModal(modalScreens.addService);
  const {isSmall, wrapStyle, resp, isHuge} = useResponsive();
  /*  const [currStepKey, setCurrStepKey] = useRecoilState(currentStepAtom);
  const currStep = serviceSteps[currStepKey];
  const shownSteps = Object.values(serviceSteps);
  const {
    selectedSpecialityKey,
    selectedKeywords,
    setCurrentCategoryKey,
    setCurrentSpecialityKey,
    CategoriesGroupSelector,
  } = useSpecialityCategorySelector({storeChange: true, showCTA: true});*/
  //const mockUser = mockUsers[8];
  const [shownUser, setShownUser] = useState();
  useEffect(() => {
    getUserInfo('guillermoS').then(resp => setShownUser(resp));
  }, []);
  return (
    <SafeAreaView>
      {/* User Card*/}
      <SideContainer img={gImages.calendarVisits}>
        <View style={[C.itemsCenter, C.my4, C.wFull, C.selfCenter]}>
          <GText
            align={Alignment.Center}
            /* color={palette.white}*/
            type={TextType.h2}
          >
            {trans('Empieza a monetizar')}
          </GText>
          <GText align={Alignment.Center} style={[C.my5]}>
            {propuestaDeValor.descripcion}
          </GText>
          <GButton
            type={ButtonType.secondary}
            text={propuestaDeValor.botonTexto}
            size={ButtonSize.large}
            onPress={() => showLoginModal()}
            style={[C.mb12, C.selfCenter]}
          />
        </View>
      </SideContainer>
      {/** Controla tu disponibilidad*/}
      <SideContainer>
        <View style={[centerRow]}>
          <UserCard showRibbon  showInitialTab user={shownUser} />
          <View style={[C.itemsCenter, C.my4, C.maxwHalf, C.selfCenter]}>
            <GText
              align={Alignment.Center}
              /* color={palette.white}*/
              type={TextType.h2}
            >
              {trans('Empieza a monetizar')}
            </GText>
            <GText align={Alignment.Center} style={[C.my5]}>
              {propuestaDeValor.descripcion}
            </GText>
            <GButton
              type={ButtonType.secondary}
              text={propuestaDeValor.botonTexto}
              size={ButtonSize.large}
              onPress={() => showLoginModal()}
              style={[C.mb12, C.selfCenter]}
            />
          </View>
        </View>
      </SideContainer>

      <SideContainer
        imgLeft
        img={isHuge /*!randomUser*/ && gImages.shareMockup}
      >
        <View style={[centerRow]}>
          <View style={[C.itemsCenter, C.my4, C.maxwHalf, C.selfCenter]}>
            <GText
              align={Alignment.Center}
              /* color={palette.white}*/
              type={TextType.h2}
            >
              {propuestaDeValor.titulo}
            </GText>
            <GText align={Alignment.Center} style={[C.my5]}>
              {propuestaDeValor.descripcion}
            </GText>
            <GButton
              type={ButtonType.secondary}
              text={propuestaDeValor.botonTexto}
              size={ButtonSize.large}
              onPress={() => showLoginModal()}
              style={[C.mb12, C.selfCenter]}
            />
          </View>
          <ShareQR user={shownUser} />
        </View>
      </SideContainer>

      {/** How To*/}
      <SideContainer>
        <GText type={TextType.h1} style={[C.mb4]}>
          {trans('✨ Crea tu perfil en minutos')}
        </GText>
        <AddServiceWrapper isModal={false} />
      </SideContainer>

      {/** Misión */}
      <SideContainer>
        {/*<FlatList
          data={novolabsExperts}
          renderItem={({item}) => <UserCard user={{...item}} />}
        />*/}
        <View style={[C.itemsCenter, C.mt_12]}>
          <GText align={Alignment.Center} type={TextType.h2}>
            {mision.titulo}
          </GText>
          <GText align={Alignment.Center} style={[C.my5]}>
            {mision.descripcion}
          </GText>
        </View>
      </SideContainer>
    </SafeAreaView>
  );
};
