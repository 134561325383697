import C from 'consistencss';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';

import {palette} from 'oralify-common';

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const eText = StyleSheet.create({
  bold: {fontWeight: 'bold'},
  italic: {fontStyle: 'italic'},
  underline: {textDecorationLine: 'underline'},
  strike: {textDecorationLine: 'line-through', textDecorationStyle: 'solid'},
});

export const loremPic = 'https://picsum.photos/200';
export const loremIpsumFonts = 'The quick brown fox jumps over the lazy dog';
export const loremIpsum =
  'Lorem ipsum home text placeholder. Dolor, sit amet, consectetur adipisicing elit.';

export const LETTER_SPACING = 0.3;

export const defLetterSpacing = {letterSpacing: LETTER_SPACING};

const fontWeights = {
  monserrat: {
    regular: 'Montserrat-Regular', // 400
    bold: 'Montserrat-Bold', // 700
  },
  museo: {
    light: 'MuseoSansRounded-300', // 300
    semiBold: 'MuseoSansRounded-500', // 500
    bold: 'MuseoSansRounded-700', // 700
  },
  sans: {
    regular: 'SourceSansPro-Regular',
    semiBold: 'SourceSansPro-SemiBold',
  },
};

export const fontSizes = {
  sm: {fontSize: 10}, // 2.5*BASE_PIXEL
  md: {fontSize: 14}, // 3.5*BASE_PIXEL
  lg: {fontSize: 30}, // 7.5*BASE_PIXEL
};

/**
 * @todo Use enum instead
 */
export const fontsMap = {
  title: fontWeights.monserrat.regular,
  titleBold: fontWeights.monserrat.bold,
  body: fontWeights.museo.light,
  bodyBold: fontWeights.museo.bold, // TODO remove? unused now
  link: fontWeights.sans.regular,
};

export enum TextType {
  h1Logo = 'h1Logo',
  h1 = 'h1',
  h2 = 'h2',
  h3Orange = 'h3Orange',
  h4 = 'h4',
  bodyBold = 'bodyBold',
  h6BodySmall = 'h6BodySmall',
  body = 'body',
  bodyBig = 'bodyBig',
  tab = 'tab',
  tabSelected = 'tabSelected',
  inputField = 'inputField',
  inputFieldActive = 'inputFieldActive',
  link = 'link',
  label = 'label',
}

export type GTextStyles = {
  family: string;
  defaultStyle: StyleProp<TextStyle>;
  color?: string; // Overrides default style for specific variants
  variants?: Record<string, TextStyle>;
};
export const customText = (textType: TextType) => {
  return [
    {fontFamily: textStyles[textType].family},
    textStyles[textType].defaultStyle,
  ];
};

export const textStyles: Record<TextType, GTextStyles> = {
  [TextType.h1Logo]: {
    family: fontWeights.museo.bold,
    defaultStyle: C.font8,
  },
  [TextType.h1]: {
    family: fontWeights.monserrat.bold,
    defaultStyle: C.font8,
  },
  [TextType.h2]: {
    family: fontWeights.monserrat.bold,
    defaultStyle: C.font7,
  },
  [TextType.h3Orange]: {
    family: fontWeights.museo.semiBold,
    color: palette.orangeSec,
    defaultStyle: [C.font5, C.mb4, C.mt6],
  },
  [TextType.h4]: {
    family: fontWeights.monserrat.bold,
    defaultStyle: C.font5,
  },
  /** Body ( Museo) */
  [TextType.h6BodySmall]: {
    family: fontWeights.museo.bold,
    defaultStyle: [C.font3],
  },
  [TextType.bodyBig]: {
    family: fontWeights.museo.light,
    defaultStyle: [C.font5],
  },
  [TextType.body]: {
    family: fontWeights.museo.light,
    defaultStyle: C.font4,
  },
  [TextType.bodyBold]: {
    family: fontWeights.museo.bold,
    defaultStyle: C.font4,
  },
  [TextType.tabSelected]: {
    family: fontWeights.museo.bold,
    defaultStyle: fontSizes.md,
  },
  [TextType.tab]: {
    family: fontWeights.museo.light,
    defaultStyle: fontSizes.md,
  },
  [TextType.label]: {
    family: fontWeights.museo.light,
    defaultStyle: [C.font3],
  },
  [TextType.inputField]: {
    family: fontWeights.monserrat.bold,
    defaultStyle: [C.font3, {letterSpacing: 0.04}],
  },
  [TextType.inputFieldActive]: {
    family: fontWeights.monserrat.bold,
    defaultStyle: [fontSizes.sm, {letterSpacing: 0.03}],
  },
  [TextType.link]: {
    family: fontWeights.sans.semiBold,
    defaultStyle: [C.font3, eText.underline],
  },
};
