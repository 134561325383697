import {createClient} from '@supabase/supabase-js';

import {
  QueryFunction,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, {AxiosInstance} from 'axios';
import {Api_Routes, SpecialityKey} from 'oralify-common';
import env from 'react-dotenv';
import Toast from 'react-native-toast-message';
import {getToken} from '../store/useUser';

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

/*
export const supabaseWeb = createClient(env.SUPABASE_URL, env.SUPABASE_SECRET);
*/
export const supabaseWeb = createClient(
  'https://yvvlxrliprbltnhnwhqs.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl2dmx4cmxpcHJibHRuaG53aHFzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzMDA0NzcsImV4cCI6MTk5Njg3NjQ3N30.mw6R1qzAuxFEOquX-ssZQ6aA-aZhyKIXKk3NafBacjw',
);
export const railwayToken = 'a587f9b9-464c-4fd6-b7f7-951edeeb2e8c';
export const baseURLRailwayLegacy =
  'https://nodejs-production-bd36.up.railway.app';
export const oralifyBaseURL = 'https://oralify.com';
export const oralifyAPI = 'https://api.oralify.com';
/*
export const bearerToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl2dmx4cmxpcHJibHRuaG53aHFzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzMDA0NzcsImV4cCI6MTk5Njg3NjQ3N30.mw6R1qzAuxFEOquX-ssZQ6aA-aZhyKIXKk3NafBacjw';
*/

export enum TYPES_API {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const DB_TABLE = {
  users: 'users',
  services: 'services',
  experts: 'experts',
};

export const expertFilterURL = ({
  speciality,
  availability,
  category,
  services = true,
  format,
  topRated,
  availableSoon,
  isOnline,
  keywords,
  showReal,
  extraParams = {},
}: {
  speciality?: SpecialityKey;
  availability?: string;
  category?: string;
  services?: boolean;
  showReal?: boolean;
  keywords?: string[];
  format?: string;
  topRated?: boolean;
  availableSoon?: boolean;
  isOnline?: boolean;
  extraParams?: {};
}) => {
  const params = {
    ...(speciality && {speciality}),
    ...(category && {category: category}),
    ...(availability !== undefined && {availability}),
    services: services.toString(),
    ...(format && {format}),
    ...(topRated !== undefined && {topRated}),
    ...(availableSoon !== undefined ? {availableSoon} : ''),
    ...(isOnline !== undefined ? {isConnected: isOnline} : ''),
    ...(keywords && keywords.length > 0 && {keywords}),
    ...(showReal !== undefined && {showReal}),
    ...extraParams,
  };

  const baseRoute = Api_Routes.user.experts;

  const queryString = new URLSearchParams(params).toString();
  return queryString ? `${baseRoute}?${queryString}` : baseRoute;
};

export const cleanObjDB = (obj: Object) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key as string] = value === undefined ? null : value;
    return acc;
  }, {});
};

/**@deprecated*/
export function useApiQuery<T>(
  key: Array<string>,
  queryFn: QueryFunction<T>,
  options?: UseQueryOptions<T, Error>,
): UseQueryResult<T, Error> {
  return useQuery<T, Error>(key, queryFn, {
    onError: error =>
      console.error(`Error en la consulta ${key.join(', ')}:`, error.message),
    onSuccess: data =>
      console.log(`Consulta ${key.join(', ')} completada con éxito:`, data),
    ...options,
  });
}

type LoggerParams = {
  baseURL: string;
  url: string;
  op?: {};
  error?: Error;
  token?: string;
  type?: TYPES_API;
  params?: {};
  response?: {};
};
export const gLog = ({
  baseURL,
  url,
  op,
  token,
  error,
  type,
  params,
  response,
}: LoggerParams) => {
  if (error) {
    console.log(
      '❌ERROR: ',
      type === 'GET' ? ' { GET } ' : ' { POST } ',
      `URL: ${baseURL}${url} | Operation: ${op} | Error: `,
      error,
      token,
      params,
      error?.response?.data,
    );
    /*if (error?.response?.code === 403 || error?.response.code === 400) {
                                                  console.log('error', error.response?.data)
                                                }*/
  } else {
    console.log(
      '✅Fetch: ',
      type === 'GET' ? ' { GET } ' : ' { POST } ',
      `URL: ${baseURL}${url} | Operation: ${op} | params: ${JSON.stringify(
        params,
      )} | \n✨ Response: `,
      response,
      token,
    );
  }
};

const createAxiosInstance = ({
  baseURL = env.SUPABASE_EDGE_URL,
  extraHeaders = {},
  token,
}: {
  baseURL: string;
  extraHeaders: Record<string, string>;
  token?: string;
}) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const headers = {
    'Content-Type': 'application/json',
    timeZone: userTimeZone,
    ...extraHeaders,
  };
  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }
  console.log(' About to call: ', baseURL, headers);
  return axios.create({
    baseURL,
    headers,
  });
};

/*const apiFetcher = async (
  axiosInstance: AxiosInstance,
  method: TYPES_API,
  url: string,
  data?: Record<string, any>,
) => {
  if (method === TYPES_API.GET) {
    return await axiosInstance.get(url, {params: data});
  } else if (method === TYPES_API.POST) {
    return await axiosInstance.post(url, data);
  }
  // Añade otros métodos si es necesario
};*/

type BaseApiCallOptions = {
  url: string;
  op?: string;
  params?: Record<string, any>;
  camelCase?: boolean;
  token?: string;
  type?: TYPES_API;
  extraHeaders?: Record<string, string>;
  baseURL?: string;
};

type ApiCallOptions = BaseApiCallOptions & {
  showToast?: boolean;
  onSuccess?: (data: any) => any;
};

export const apiCaller = async ({
  url = Api_Routes.user.experts,
  op,
  params,
  type = TYPES_API.GET,
  extraHeaders = {},
  baseURL = oralifyAPI,
  token,
  showToast = true,
}: ApiCallOptions) => {
  const currToken = token || (await getToken()) || '';
  const axiosInstance = createAxiosInstance({
    baseURL,
    extraHeaders,
    token: currToken,
  });
  return createQueryFn(axiosInstance, {
    url,
    op,
    params,
    token: currToken,
    type,
  })()
    .then(response => {
      gLog({
        baseURL: baseURL,
        url: url,
        op: op,
        type: type,
        params: JSON.stringify(params),
        response: response,
      });
      return {data: response, error: null};
    })
    .catch(error => {
      gLog({
        baseURL: baseURL,
        url: url,
        op: op,
        type: type,
        params: JSON.stringify(params),
        error: error,
      });
      if (showToast) {
        Toast.show({
          type: 'error',
          text1: `Error al hacer ${url} con ${JSON.stringify(
            error?.notices || error?.response || error,
            null,
            2,
          )}`,
        });
      }
      return {data: null, error: error};
    });
};

const createQueryFn = (
  axiosInstance: AxiosInstance,
  {url, op, params, token, baseURL = oralifyAPI, type}: ApiCallOptions,
) => {
  return async () => {
    let callParams = {...params};

    /*if (withUserToken) {
      callParams.user_token = await getToken();
    }*/ /*else {
      callParams.client_token = SECRETS.TOKENS.clientToken[ENV];
    }*/
    if (op) {
      callParams.op = op;
    }
    const response =
      type === TYPES_API.POST
        ? await axiosInstance.post(url, callParams)
        : await axiosInstance.get(url, {params: callParams});

    gLog({
      baseURL,
      url: url,
      op,
      params,
      type,
      response: response.data,
    });
    return response.data;
  };
};

export const useGetAPI = (options: ApiCallOptions) =>
  useQuery([options.url], async () => {
    const response = await apiCaller(options);
    if (response.error) {
      throw response.error;
    }
    return response.data;
  });

type UseSetApiCallOptions = ApiCallOptions & {
  onSuccess?: (data: any) => any;
};

export const useSetAPI = (options: UseSetApiCallOptions) =>
  useMutation(
    async () => {
      const response = await apiCaller(options);
      if (response.error) {
        throw response.error;
      }
      return response.data;
    },
    {
      onSuccess: options.onSuccess,
    },
  );
