import ApiCalendar from 'react-google-calendar-api';
import {GIconRow} from '../molecules/GIconRow';
import React from 'react';
import {Linking, View} from 'react-native';
import {ButtonSize, ButtonType, GButton} from '../molecules';
import {gImages} from '../ui';
import {Api_Routes} from 'oralify-common';
import {apiCaller} from '../api/api';
import {currentUserAtom} from '../store/useUser';
import {useRecoilState} from 'recoil';
import C from 'consistencss';

const config = {
  clientId:
    '414603217613-1k574fddh5lm7c332cia57emsujihpp2.apps.googleusercontent.com', //'113005249599307856963',
  //'321480107487-ep5oos2qcb591a7fq07a45f2ikao8l6q.apps.googleusercontent.com',
  apiKey: 'AIzaSyCFlRV621JeI2IvOoGJ0CURaIBMcIdPK2E', // 'AIzaSyAMFcX3YmjwIQe_rDW3QQasO_JHRHeNw50',
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
};

/** 1. Añades disp
 * 2. añades Service
 * 3. Worker every 24h / 1 week (domingo a la noche) nodeCron*/
/* TODO cuaresma
export const generateTimeslots = async ( )=>{
    const personalEvents = Await apiCalendarWeb.listUpcomingEvents(10);
    availabilty.forEach(
        if(personalEvents[0].startTime > availabilty[0].startTime)
        availabilty[0].startTime = personalEvents[0].startTime
    )

}
*/

export const apiCalendarWeb = new ApiCalendar(config);

export const GoogleCalendarSelector = () => {
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  /* const {data, error} = useGetAPI({url: '/auth/google'});*/
  return (
    <View style={[C.row, C.itemsCenter]}>
      <GIconRow
        icon={gImages.googleCalendar}
        text={'Añade tu cuenta de Google Calendar'}
      />
      <GButton
        text={'Integrar calendario'}
        icon={gImages.googleCalendar}
        type={ButtonType.secondary}
        size={ButtonSize.small}
        onPress={async () => {
          if (currUser) {
            const {data} = await apiCaller({
              url: Api_Routes.auth.authGoogleCalendar,
            });
            console.log(' carlos ', data);
            if (data.url) Linking.openURL(data.url);
          }
          //oralifyAPI +
          /*Linking.openURL(Api_Routes.auth.authGoogleCalendar, )*/ // '/auth/googleCalendar');
          /*const result = await apiCalendarWeb.handleAuthClick();
          const resultUpcoming = await apiCalendarWeb.listUpcomingEvents(10);
          const result2 = await apiCalendarWeb.createEventWithVideoConference(
            {
              summary: 'Videoconferencia Oralify con Google Meet',
              location: 'Oralify.com',
              description: 'Descripción de la reunión.',
              start: {
                dateTime: '2023-12-21T17:15:00+01:00',
                timeZone: 'Europe/Madrid',
              },
              end: {
                dateTime: '2023-12-21T17:30:00+01:00',
                timeZone: 'Europe/Madrid',
              },
              attendees: [
                {
                  email: 'gsierra@guillesierra.com',
                  displayName: 'Guille S',
                  organizer: true,
                },
                {
                  email: 'jorgecuesta.90@gmail.com',
                  displayName: 'Jorge C',
                  organizer: false,
                  responseStatus: 'accepted',
                },
              ],
              conferenceData: {
                createRequest: {
                  requestId: 'randomString', // Este debe ser un identificador único para la solicitud de conferencia.
                  conferenceSolutionKey: {type: 'hangoutsMeet'},
                },
              },
              reminders: {
                useDefault: false,
                overrides: [
                  {method: 'email', minutes: 24 * 60},
                  {method: 'popup', minutes: 10},
                ],
              },
            },
            undefined,
            'all',
          );*/
          console.log(
            'Calendario de Guille: ',
            /*data,
            error,*/
            /* result,
            result2,
            resultUpcoming,*/
          );
        }}
      />
    </View>
  );
};
