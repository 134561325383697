import {Alignment, eDivider, TextType} from '../ui';
import C from 'consistencss';
import {Pressable, ViewProps} from 'react-native';
import React from 'react';
import {GIcon, GText} from '../atoms';
import {Units} from '../types/units';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

export interface CollapseRowProps extends ViewProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  icon?: FullIconType;
  placeholder?: string;
  value: string;
  unit?: Units;
  label?: string;
  isLast?: boolean;
  onPress: (a: any) => void;
}

/** TODO discuss fi could be merged to EeRow */
export const EeCollapseRow = ({
  children,
  isOpen,
  icon,
  value,
  unit,
  label,
  onPress,
  placeholder,
  isLast = false,
}: CollapseRowProps) => {
  /*const [currValue, setValue] = useState(value);*/
  return (
    <Pressable
      onPress={item => {
        /*setValue(item);*/
        onPress(item);
      }}
      style={[
        C.row,
        !isLast && !isOpen && eDivider,
        C.itemsCenter,
        C.m2,
        C.py2,
        C.flex,
        C.justifyBetween,
      ]}
    >
      {icon && <GIcon icon={icon} />}
      <GText
        align={Alignment.Left}
        numberOfLines={1}
        type={value ? TextType.tabSelected : TextType.tab}
        color={value ? palette.orangeSec : palette.greyDark}
        style={[C.ml2, C.flex]}
      >
        {value || label}
      </GText>
      {/*</View>*/}
      {/*<GText
        numberOfLines={1}
        color={value ? palette.primaryBlue : palette.primaryDarkGrey}
        style={[C.ml2, C.flex]}>
        {typeof value === 'number' ? value + ' ' + unit : placeholder}
      </GText>*/}
      {/*{value && (
        <GText
          type={TextType.tabSelected}
          color={palette.primaryBlue}
          style={C.ml2}>
          {value}
        </GText>
      )}*/}
      <GIcon
        color={palette.orangeSec}
        icon={isOpen ? 'chevron-left' : 'chevron-right'}
        wrapStyle={C.ml2}
      />
      {children}
    </Pressable>
  );
};
