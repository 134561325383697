import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  KeyboardTypeOptions,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';
import C from 'consistencss';
import {eColor, EeSize, StylesArray, textStyles, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {isMailValid} from '../types/utils/dates';
import {Units} from '../types/units';
import {FullIconType} from '../types/icon-types';
import GSpinner from '../atoms/GSpinner';
import {isEmpty} from 'lodash';
import {palette} from 'oralify-common';
import {FormField} from '../store/useGForm';

export interface InputProps extends TextInputProps {
  initialValue?: string | number | undefined;
  hint?: string;
  active?: boolean;
  keyboardType?: KeyboardTypeOptions;
  onSetText?: (text: string) => void;
  unit?: Units;
  max?: number;
  disabled?: boolean;
  icon?: FullIconType;
  formType?: FormTypes;
  isValid?: boolean;
  isReq?: boolean;
  loading?: boolean;
  isPassword?: boolean;
  errorMessage?: string;
  prefix?: string;
  color?: string;
  suffix?: string;
  size?: 'large' | 'medium' | 'small';
  background?: string;
}

export enum FormTypes {
  default = 'default',
  password = 'password',
  mail = 'mail',
  name = 'name',
  title = 'title',
  descr = 'descr',
  price = 'price',
  calendar = 'calendar',
  number = 'number',
  phone = 'phone',
}

export const formTypes: Partial<
  Record<
    FormTypes,
    {
      onCheck?: (text: string) => boolean;
      maxLength?: number;
      keyboardType?: KeyboardTypeOptions | undefined;
      autocomplete?: EeAutocomplete;
      textType?: EeTextContentType;
      errorMessage?: string;
    }
  >
> = {
  [FormTypes.default]: {
    //onCheck: (text: string) => text.length > 3,
    /*maxLength: 32,*/
    keyboardType: 'default',
    autocomplete: 'off',
    //errorMessage: 'El texto debe tener al menos 4 caracteres.',
  },
  [FormTypes.name]: {
    //onCheck: (text: string) => text.length > 3,
    /*maxLength: 32,*/
    keyboardType: 'default',
    autocomplete: 'name',
    //errorMessage: 'El texto debe tener al menos 4 caracteres.',
  },
  [FormTypes.mail]: {
    onCheck: (text: string) => isMailValid(text),
    maxLength: 64,
    keyboardType: 'email-address',
    autocomplete: 'email',
    textType: 'emailAddress',
    errorMessage: 'Por favor, introduce un correo electrónico válido.',
  },
  [FormTypes.password]: {
    onCheck: (text: string) => text.length > 5,
    /*maxLength: 15,*/
    keyboardType: 'default',
    isPassword: true,
    autocomplete: 'password',
    textType: 'password',
    errorMessage: 'La contraseña debe tener al menos 4 caracteres.',
  },
  [FormTypes.price]: {
    onCheck: (text: string) => Number(text) <= 999,
    maxLength: 3,
    keyboardType: 'numeric',
    errorMessage: 'No puedes cobrar más de 999€',
  },
  [FormTypes.number]: {
    onCheck: (text: string) => Number(text) < 180,
    maxLength: 4,
    keyboardType: 'numeric',
    errorMessage: 'No puede durar más de 180 min ( 3h ) ',
  },
  [FormTypes.phone]: {
    onCheck: (text: string) => text.length > 8,
    maxLength: 10,
    keyboardType: 'phone-pad',
    autocomplete: 'email',
    errorMessage: 'El teléfono debe tener al menos 8 dígitos.',
  },
};

export const FormWrapper = ({
  placeholder,
  children,
  maxWidth = true,
  color = palette.greenPrim,
  extraStyles,
}: {
  placeholder?: string;
  children?: ReactElement | ReactElement[];
  color?: string;
  maxWidth?: boolean;
  extraStyles?: StylesArray;
}) => (
  <View
    style={[
      C.radius2,
      maxWidth && C.w80,
      C.mt4,
      C.mr4,
      C.py1,
      C.px2,
      eColor(color).border,
      extraStyles,
    ]}
  >
    {placeholder && (
      <GText
        type={TextType.label}
        color={palette.greyish}
        style={[C.absolute, C.top_2, C.bgWhite, C.px1, C.radius2]}
      >
        {placeholder}
      </GText>
    )}
    {children}
  </View>
);

/** To use GFormInput,
 * create a map of the existing forms
 * and use properly the @formTypes above */
export const GFormInput = forwardRef<TextInput, InputProps>(
  (
    {
      formType = FormTypes.default,
      onSetText,
      icon = 'label',
      value,
      max,
      color = palette.orangeSec,
      initialValue,
      prefix,
      suffix,
      placeholder,
      hint,
      isPassword,
      errorMessage,
      disabled = false,
      loading = false,
      isValid = true,
      isReq = false,
      style,
      ...rest
    },
    ref,
  ) => {
    const textInputRef = useRef<TextInput>(null);
    const inputHandler = textInputRef.current;
    const inputFocused = inputHandler?.isFocused() || false;
    const [isFocused, setIsFocused] = useState(inputFocused);
    const [showPassword, setShowPassword] = useState(false);
    const [numValue, setNumValue] = useState<number>(
      Number(value) || Number(initialValue) || max || 0,
    );
    const [currentValue, setCurrentValue] = useState(
      value ?? initialValue ?? '',
    );
    const [touched, setTouched] = useState(false);

    const [wasBlurred, setWasBlurred] = useState(false);
    const [showError, setShowError] = useState(false);

    const setFocus = (wasFocused = false) => {
      if (!disabled) {
        if (inputHandler) {
          wasFocused ? inputHandler.blur() : inputHandler.focus();
        }
        setIsFocused(prev => !prev);
        if (wasFocused) {
          setWasBlurred(true);
          setTouched(true); // Agregar un temporizador para mostrar el mensaje de error después de 3 segundos
          setTimeout(() => {
            setShowError(true);
          }, 2000);
        } else {
          // Reiniciar el temporizador cuando el input está en foco nuevamente
          setShowError(false);
        }
      }
    };

    const onHandleText = (text: string) => {
      if (numValue) {
        let num = Number(text);

        if (max && num > max) {
          num = max;
          text = max.toString();
        }
        setNumValue(num);
      }
      setCurrentValue(text);
      if (onSetText) {
        onSetText(text);
      }
    };
    /*, [onHandleText])*/

    /*useEffect(() => {
                                                                                      if (value) onHandleText(value);
                                                                                    }, [onHandleText, value]);*/

    const inputTypes = formTypes[formType];
    const currError = errorMessage || inputTypes?.errorMessage;

    useEffect(() => {
      if (max !== undefined) setCurrentValue(numValue.toString());
    }, [max, numValue]);

    const isCheckValid =
      inputTypes?.onCheck && inputTypes.onCheck(currentValue.toString());
    const isAllValid = isValid && isCheckValid;
    const currColor = disabled
      ? palette.secondaryLightGrey
      : isAllValid && currentValue
      ? palette.greenSec
      : isFocused
      ? color
      : !currentValue || isCheckValid
      ? palette.primaryMercuryGrey
      : wasBlurred && showError
      ? palette.redSec
      : palette.primaryMercuryGrey;

    const errorShown =
      !!currentValue &&
      !isAllValid &&
      !!currError &&
      wasBlurred &&
      showError &&
      touched;

    return (
      <View>
        <FormWrapper
          placeholder={!isEmpty(value) ? placeholder : undefined}
          color={currColor}
          extraStyles={[style, errorShown && C.mb1]}
        >
          {/*<View>
        <View style={[C.m2, C.row, rowHeight, C.itemsCenter, C.px2, eColor(color).border, C.radius2, style]}>
          <View style={[!max && C.flex, C.justifyEnd]}>*/
          /*{!isEmpty(value) && (
                                                                                                                                                                                                                                                                                                      <GText
                                                                                                                                                                                                                                                                                                        type={TextType.label}
                                                                                                                                                                                                                                                                                                        color={palette.greyish}
                                                                                                                                                                                                                                                                                                        style={[C.absolute, C.top_3, C.bgWhite, C.px1, C.radius2]}>
                                                                                                                                                                                                                                                                                                        {placeholder}
                                                                                                                                                                                                                                                                                                      </GText>
                                                                                                                                                                                                                                                                                                    )}*/}
          {/*<>*/}
          <View style={[C.row, C.justifyStart, C.itemsCenter, !max && C.flex]}>
            {/** Placeholder */}
            {icon && <GIcon icon={icon} color={color} wrapStyle={C.mr2} />}
            {prefix && <GText color={color}>{prefix}</GText>}
            {max && (
              <GIcon
                icon={'chevron-down'}
                color={numValue > 1 ? color : palette.greyish}
                onPress={() =>
                  numValue > 1 && setNumValue(prevState => prevState - 1)
                }
                /*checked={numValue > 1}*/
                wrapStyle={C.mx2}
              />
            )}
            <TextInput
              ref={ref}
              {...inputTypes}
              placeholderTextColor={palette.greyish}
              value={currentValue?.toString()}
              editable={!disabled}
              placeholder={placeholder + (hint ? ': ' + hint : '')}
              style={[
                // @ts-ignore
                {outlineStyle: 'none'},
                textStyles[TextType.inputField].defaultStyle,
                max ? C.maxw6 : C.flex,
                C.py2,
              ]}
              selectionColor={color}
              secureTextEntry={isPassword && !showPassword}
              onChangeText={onHandleText}
              onFocus={() => setFocus(false)}
              onBlur={() => setFocus(true)}
              onSubmitEditing={() => setFocus(true)}
              {...rest}
            />
            {suffix && <GText color={color}>{suffix}</GText>}

            {max && (
              <GIcon
                icon={'chevron-up'}
                color={numValue < max ? color : palette.greyish}
                onPress={() =>
                  numValue < max && setNumValue(prevState => prevState + 1)
                }
                /*checked={numValue < max}*/
                wrapStyle={C.mx2}
              />
            )}

            {/*</View>*/}
            {loading && (
              <View>
                <GSpinner size={EeSize.xsm} full={false} />
              </View>
            )}
            {isAllValid && currentValue && (
              <GIcon
                icon={'check'}
                wrapStyle={[C.bottom1, C.right1, C.absolute]}
                size={EeSize.sm}
                color={color}
              />
            )}
            {isReq && (
              <GIcon
                icon={'asterisk'}
                wrapStyle={[C.absolute, C.top1, C.right1]}
                size={EeSize.xsm}
                color={color}
              />
            )}
            {isPassword && !disabled && (
              <GIcon
                icon={showPassword ? 'eye-off' : 'eye'}
                onPress={() => setShowPassword(prev => !prev)}
                size={EeSize.sm}
                wrapStyle={[C.left_6]}
                color={color}
              />
            )}
          </View>
        </FormWrapper>
        {errorShown && (
          <GText
            numberOfLines={1}
            color={palette.redSec}
            style={[C.ml2 /*C.absolute, C.bottom_4*/]}
            type={TextType.inputFieldActive}
          >
            {currError}
          </GText>
        )}
      </View>
    );
  },
);

/** We can choose here the used ones (from the complete list) */
export type EeAutocomplete =
  | 'birthdate-day'
  | 'birthdate-full'
  | 'birthdate-month'
  | 'birthdate-year'
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-day'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-number'
  | 'email'
  | 'gender'
  | 'name'
  | 'name-family'
  | 'name-given'
  | 'name-middle'
  | 'name-middle-initial'
  | 'name-prefix'
  | 'name-suffix'
  | 'password'
  | 'password-new'
  | 'postal-address'
  | 'postal-address-country'
  | 'postal-address-extended'
  | 'postal-address-extended-postal-code'
  | 'postal-address-locality'
  | 'postal-address-region'
  | 'postal-code'
  | 'street-address'
  | 'sms-otp'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-device'
  | 'username'
  | 'username-new'
  | 'off'
  | undefined;

type EeTextContentType =
  | 'none'
  | 'URL'
  | 'addressCity'
  | 'addressCityAndState'
  | 'addressState'
  | 'countryName'
  | 'creditCardNumber'
  | 'emailAddress'
  | 'familyName'
  | 'fullStreetAddress'
  | 'givenName'
  | 'jobTitle'
  | 'location'
  | 'middleName'
  | 'name'
  | 'namePrefix'
  | 'nameSuffix'
  | 'nickname'
  | 'organizationName'
  | 'postalCode'
  | 'streetAddressLine1'
  | 'streetAddressLine2'
  | 'sublocality'
  | 'telephoneNumber'
  | 'username'
  | 'password'
  | 'newPassword'
  | 'oneTimeCode'
  | undefined;
export const mailPswdFields = [
  {
    key: 'email',
    formType: FormTypes.mail,
    icon: 'email',
    placeholder: 'Mail',
    isRequired: true,
  },
  {
    key: 'password',
    formType: FormTypes.password,
    icon: 'lock1',
    placeholder: 'Contraseña',
    isRequired: true,
  },
];
export const userFormFields = [
  {
    key: 'name',
    autoFocus: true,
    formType: FormTypes.name,
    icon: 'person',
    placeholder: 'Nombre',
    isRequired: true,
    hint: 'Juan Perez',
  },
  {
    key: 'email',
    formType: FormTypes.mail,
    icon: 'email',
    placeholder: 'Mail',
    hint: 'juan1992@gmail.com',
    isRequired: true,
  },
  {
    key: 'phone',
    prefix: '+34 🇪🇸 ',
    autoCapitalize: 'words',
    formType: FormTypes.phone,
    icon: 'phone',
    hint: '603435843',
    placeholder: 'Phone',
    isRequired: true,
  },
  {
    key: 'education',
    icon: 'graduation-cap',
    hint: 'Universidad de Barcelona',
    forSpecialist: true,
    placeholder: 'Educación',
    isRequired: false,
  },
  /* {
                                            key: 'username',
                                            prefix: 'oralify.com/',
                                            icon: 'globe',
                                            placeholder: 'Nombre de usuario',
                                            isRequired: true,
                                          },*/

  {
    key: 'job',
    forSpecialist: true,
    icon: 'work',
    hint: 'Consultor SAP, Telefónica',
    placeholder: 'Profesión',
    isRequired: false,
  },
  {
    key: 'headline',
    forSpecialist: true,
    hint:
      'Fundador y profesor del Instituto Baikal. Inversor y mentor de' +
      ' startups.',
    icon: 'timeline-check',
    placeholder: 'Descripción',
    isRequired: false,
  },
] as FormField[];
