import C from 'consistencss';
import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import {GIcon, GText} from '../atoms';
import {cardWrapper} from './GCard';
import {Renderable} from './ee-row';
import {
  BASE_PIXEL,
  eColor,
  EeSize,
  eSizes,
  Gradients,
  StylesArray,
  TextType,
} from '../ui';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

export interface SideCardProps /*extends ViewProps*/ {
  image?: FullIconType;
  title?: string;
  color?: string;
  imageFullWidth?: boolean;
  onPress?: () => void;
  subtitle?: string;
  text?: string;
}

export enum SideCardTypes {
  default = 'default',
  gradient = 'gradient',
}

export type CardSideType = Record<
  SideCardTypes,
  {
    title: TextType;
    imageSize: EeSize;
    subtitle: TextType;
    color?: string;
    padding: StylesArray;
    borderRadius: StylesArray;
  }
>;
export const cardSideType: CardSideType = {
  [SideCardTypes.default]: {
    title: TextType.h3Orange,
    imageSize: EeSize.sideCard,
    subtitle: TextType.tabSelected,
    padding: [C.py3],
    borderRadius: C.radius4,
  },
  [SideCardTypes.gradient]: {
    title: TextType.h3Orange,
    imageSize: EeSize.sideCard,
    subtitle: TextType.tabSelected,
    color: palette.white,
    padding: [C.py3, C.pl0],
    borderRadius: C.radius4,
  },
};

export function EeSideCard({
  image,
  title,
  subtitle,
  color,
  text,
  onPress,
  greenGradient,
  type = SideCardTypes.default,
  style,
  children,
}: SideCardProps & {type?: SideCardTypes; greenGradient?: boolean}) {
  const CurrentSVG: Renderable<any> = () =>
    greenGradient ? Gradients.green : Gradients.redGrad;
  const fontColor = cardSideType[type]?.color;
  return (
    <View
      style={[cardSideType[type].borderRadius, C.m1, C.overflowHidden, style]}
    >
      <Pressable
        android_ripple={{
          color: palette.secondaryGrey,
          radius: BASE_PIXEL * 50,
        }}
        disabled={!onPress}
        onPress={onPress}
        style={({pressed}) => [
          cardWrapper,
          C.row,
          cardSideType[type].padding,
          cardSideType[type].borderRadius,
          pressed && C.opacity40,
        ]}
      >
        {type === SideCardTypes.gradient && (
          <View style={StyleSheet.absoluteFill}>
            <CurrentSVG preserveAspectRatio="none" />
          </View>
        )}
        {image && (
          <GIcon
            icon={image}
            rounded
            wrapStyle={[cardSideType[type].padding]}
            size={cardSideType[type].imageSize}
          />
        )}
        <View style={[C.flex, C.ml4]}>
          <View style={[C.flex, C.row, C.itemsCenter, C.justifyBetween]}>
            {color && (
              <View
                style={[C.radius6, eSizes[EeSize.xsm], eColor(color).bg, C.mr1]}
              />
            )}
            {title && (
              <GText
                /*color={color}*/
                numberOfLines={2}
                style={[C.uppercase, C.flex]}
                type={cardSideType[type].title}
              >
                {title}
              </GText>
            )}
            {subtitle && (
              <GText
                color={palette.bluePrim || fontColor}
                style={C.my2}
                type={cardSideType[type].subtitle}
              >
                {subtitle}
              </GText>
            )}
          </View>
          {text && (
            <GText numberOfLines={1} color={fontColor}>
              {text}
            </GText>
          )}
          {children}
        </View>
      </Pressable>
    </View>
  );
}

export default EeSideCard;
