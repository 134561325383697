import React, {useState} from 'react';
import {View} from 'react-native';
import C from 'consistencss';
import {eColor} from '../ui';
import {GIconRow} from '../molecules/GIconRow';
import {
  palette,
  RoleKey,
  ServiceFormatKey,
  serviceFormatMap,
  ServiceType,
  SpecialityKey,
  SpecialityMapType,
} from 'oralify-common';

type UseTabsReturnType<T> = {
  selectedTab: string | SpecialityKey | undefined;
  TabHeaders: React.FC<{valExtractor?: (key: string) => T}>;
  onSetTab: (tab: string) => void;
};

export const useTabs = <
  T extends ServiceType | ServiceFormatKey | SpecialityMapType | RoleKey,
>(
  tabs: Record<string, T> | [string, T][],
  defaultTab?: string | SpecialityKey | RoleKey | ServiceFormatKey | T,
  showTabs = false,
  color?: string,
): UseTabsReturnType<T> => {
  const currColor = color || palette.purpleSec;
  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    defaultTab || (showTabs ? Object.keys(tabs)[0] : undefined),
  );

  const onSetTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const currMap = /*tabs && isArray(tabs) ? tabs : */ tabs
    ? Object.entries(tabs)
    : [];

  const TabHeaders: React.FC<{
    icon?: string;
    name?: string;
    descr?: string;
    valExtractor?: (key: string) => T;
  }> = ({valExtractor, icon, name, descr}) => (
    <View style={[C.row, C.wFull, C.mt4, C.justifyStart]}>
      {currMap &&
        currMap.map(([key, item]) => {
          /*const  {format}  = item*/
          const currItem = valExtractor ? valExtractor(key) : undefined;
          const keyAccesor = key || item?.format || currItem?.name;
          const nameAccesor = item?.format || currItem?.name || key;
          const isSelected = selectedTab === keyAccesor;

          const shownName =
            name || currItem?.price ? `${currItem.price}€` : nameAccesor;
          /*const shownTitle = isSelected ? nameAccesor : valExtractor ? `${price}€` : '';
        const shownSubtitle = (!isSelected && valExtractor && `${duration} min`) || '';*/
          const currIcon =
            icon ||
            item?.icon ||
            item?.emoji ||
            currItem?.emoji ||
            currItem?.icon ||
            serviceFormatMap[key as ServiceFormatKey]?.icon;
          return (
            keyAccesor && (
              <GIconRow
                icon={currIcon}
                key={key}
                style={[
                  isSelected && C.px2,
                  C.pt2,
                  //C.radiusstart4,
                  /*C.flex,*/
                  eColor(
                    isSelected ? currColor : palette.greyish,
                    isSelected ? 2 : 1,
                  ).borderBottom,
                ]}
                color={isSelected ? currColor : color}
                secColor={color ? palette.whiteFour : undefined}
                text={isSelected ? descr : shownName}
                title={!isSelected ? descr : shownName}
                onPress={() => onSetTab(key)}
              />
            )
          );
        })}
    </View>
  );

  return {
    selectedTab,
    TabHeaders,
    onSetTab,
  };
};

export const putoCuaresma = [
  {
    id: '6',
    phone: '555-888-9999',
    creation_date: '2023-07-20T13:40:42.789+00:00',
    birthday: '1986-09-28',
    name: 'Michael Johnson',
    sex: 'Male',
    isMock: true,
    score: null,
    headline: 'Experienced Real Estate Agent',
    education: 'B.A. in Real Estate Management',
    company: null,
    username: 'MichaelJRealState',
    avatar: 'https://randomuser.me/api/portraits/men/77.jpg',
    email: 'michaeljohnson@example.com',
    oral_coins: 130,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
        name: 'Property Consultation',
        price: 50,
        duration: 60,
        speciality: 'realState',
        format: 'call',
        description: 'Consultation on real estate properties.',
        expertId: '6',
        keywords: null,
        time_slots: [
          {
            id: '6833728a-808e-4a80-8ad3-a62c5ffb15ff',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2023-12-18T08:00:00+00:00',
            endTime: '2023-12-18T09:00:00+00:00',
            expertId: '6',
            creationDate: null,
          },
          {
            id: '5061d201-c821-4150-8606-a66059cf501d',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2023-12-18T09:00:00+00:00',
            endTime: '2023-12-18T10:00:00+00:00',
            expertId: '6',
            creationDate: null,
          },
          {
            id: '11d1a84d-78eb-41a6-86e5-c2154919a43d',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2023-12-18T10:00:00+00:00',
            endTime: '2023-12-18T11:00:00+00:00',
            expertId: '6',
            creationDate: null,
          },
          {
            id: 'e88931f4-8952-4a0c-973d-72286a655fca',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2023-12-27T09:00:00+00:00',
            endTime: '2023-12-27T10:00:00+00:00',
            expertId: '6',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '20cde5e0-1585-43d9-8e89-f23e75d12cad',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2023-12-27T10:15:00+00:00',
            endTime: '2023-12-27T11:15:00+00:00',
            expertId: '6',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '3ec78c77-8dac-4bb4-b149-0f8572552488',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2024-01-01T09:00:00+00:00',
            endTime: '2024-01-01T10:00:00+00:00',
            expertId: '6',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '76f77bba-3769-43f7-83cd-1d1599a1b12e',
            availabilityId: '541bd52b-75b6-44ef-bd8a-7007f456cff5',
            status: 'available',
            price: 50,
            serviceId: '98fcde56-9e30-4fbe-80de-e1acb48d44c5',
            startTime: '2024-01-01T10:15:00+00:00',
            endTime: '2024-01-01T11:15:00+00:00',
            expertId: '6',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '10',
    phone: '555-222-3333',
    creation_date: '2023-11-10T12:30:15.765+00:00',
    birthday: '1979-06-07',
    name: 'Matthew Wilson',
    sex: 'Male',
    isMock: true,
    score: null,
    headline: 'Real Estate Advisor',
    education: 'B.A. in Real Estate Management',
    company: null,
    username: 'MatthewWRealState',
    avatar: 'https://randomuser.me/api/portraits/men/80.jpg',
    email: 'matthewwilson@example.com',
    oral_coins: 140,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
        name: 'Property Consultation',
        price: 50,
        duration: 60,
        speciality: 'realState',
        format: 'video',
        description: 'Consultation on real estate properties.',
        expertId: '10',
        keywords: null,
        time_slots: [
          {
            id: 'db315a53-8df3-460a-8078-426a799c9048',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2023-12-18T08:00:00+00:00',
            endTime: '2023-12-18T09:00:00+00:00',
            expertId: '10',
            creationDate: null,
          },
          {
            id: 'e46bd76a-a69c-4c83-b40a-00a34a741ff6',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2023-12-18T09:00:00+00:00',
            endTime: '2023-12-18T10:00:00+00:00',
            expertId: '10',
            creationDate: null,
          },
          {
            id: '9bf4b3ca-797d-4d03-afff-7dcef2aba52d',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2023-12-18T10:00:00+00:00',
            endTime: '2023-12-18T11:00:00+00:00',
            expertId: '10',
            creationDate: null,
          },
          {
            id: 'd810b5d0-1bbf-443d-a4d8-a77f1b4e3d27',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2023-12-27T09:00:00+00:00',
            endTime: '2023-12-27T10:00:00+00:00',
            expertId: '10',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'f3f06ddb-dd66-4a1d-b7f2-570a4f6e2e48',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2023-12-27T10:15:00+00:00',
            endTime: '2023-12-27T11:15:00+00:00',
            expertId: '10',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '7712b135-f098-433b-a1c4-50e85eb7fd85',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2024-01-01T09:00:00+00:00',
            endTime: '2024-01-01T10:00:00+00:00',
            expertId: '10',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '285bd813-25e3-494c-a382-78e5de0dba4a',
            availabilityId: '87adbb66-3b07-40a4-85cb-0345f18449ef',
            status: 'available',
            price: 50,
            serviceId: 'f743ffc2-b484-41ad-9b08-bbac77b8a0eb',
            startTime: '2024-01-01T10:15:00+00:00',
            endTime: '2024-01-01T11:15:00+00:00',
            expertId: '10',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '3',
    phone: '+34 123 456 789',
    creation_date: '2023-10-25T12:54:39.901+00:00',
    birthday: '1986-08-20',
    name: 'Javier Megías',
    sex: 'Female',
    isMock: true,
    score: null,
    headline:
      'Ayudó a +500 startups a levantar +400M€. Lideró el fondo de inversión de la Generalitat Valenciana.',
    education: 'Universidad Politécnica de Cataluña',
    company: null,
    username: 'JavierMegias',
    avatar:
      'https://framerusercontent.com/images/QzaIB967MM0vnkYRUI989TToHG4.png',
    email: 'javier.megias@startupsinvestment.com',
    oral_coins: 120,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['psycho', 'realState'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
        name: 'Therapy Session',
        price: 40,
        duration: 60,
        speciality: 'psycho',
        format: 'call',
        description: 'Individual therapy session.',
        expertId: '3',
        keywords: null,
        time_slots: [
          {
            id: 'cd900a82-4390-4253-8ab8-f270f65e24de',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2023-12-18T09:00:00+00:00',
            endTime: '2023-12-18T10:00:00+00:00',
            expertId: '3',
            creationDate: null,
          },
          {
            id: '0a83bf90-42cc-48cd-ba35-a66c974edcb9',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2023-12-18T10:00:00+00:00',
            endTime: '2023-12-18T11:00:00+00:00',
            expertId: '3',
            creationDate: null,
          },
          {
            id: 'b52f04a5-db86-4c25-a0b7-51c338859039',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2023-12-18T11:00:00+00:00',
            endTime: '2023-12-18T12:00:00+00:00',
            expertId: '3',
            creationDate: null,
          },
          {
            id: 'cdc2d688-7f54-4cbb-a210-338d7b9fc240',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2023-12-27T10:00:00+00:00',
            endTime: '2023-12-27T11:00:00+00:00',
            expertId: '3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '98de9007-291c-4c2f-9f8f-9bd160c72700',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2023-12-27T11:10:00+00:00',
            endTime: '2023-12-27T12:10:00+00:00',
            expertId: '3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'beed7b0c-6a0e-4dca-bd38-b37088aa4a51',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2024-01-01T10:00:00+00:00',
            endTime: '2024-01-01T11:00:00+00:00',
            expertId: '3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'ea744d27-e1cd-4a7b-8c17-841276fd52ab',
            availabilityId: '22531777-28e4-4f75-8572-8436573e4752',
            status: 'available',
            price: 40,
            serviceId: '66d1923d-fcbd-4fee-9f04-8cf1a8734d3b',
            startTime: '2024-01-01T11:10:00+00:00',
            endTime: '2024-01-01T12:10:00+00:00',
            expertId: '3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '4',
    phone: '+34 123 456 789',
    creation_date: '2023-10-25T12:54:39.901+00:00',
    birthday: '1984-02-15',
    name: 'Ricard Garriga',
    sex: 'Male',
    isMock: true,
    score: null,
    headline:
      'Ayudó a +100 startups a internacionalizarse. Lideró el programa de aceleración de Wayra en 10 países.',
    education: 'Universidad de Girona',
    company: null,
    username: 'RicardGarriga',
    avatar:
      'https://framerusercontent.com/images/a3pIM1h1CS3Sdean4ufgg5EX9lw.png',
    email: 'ricard.garriga@wayraacceleration.com',
    oral_coins: 135,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
        name: 'Real Estate Consultation',
        price: 55,
        duration: 60,
        speciality: 'realState',
        format: 'call',
        description: 'Consultation on real estate properties.',
        expertId: '4',
        keywords: null,
        time_slots: [
          {
            id: '370eaaa4-776a-473c-ac45-3858c1b3f4f0',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-19T09:30:00+00:00',
            endTime: '2023-12-19T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '7bdd94da-2d36-4abb-ad4d-b45a49300b33',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-19T10:45:00+00:00',
            endTime: '2023-12-19T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8f3760f1-298c-499c-8f03-b2a18e74c6e2',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-21T09:30:00+00:00',
            endTime: '2023-12-21T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '41437377-1dc3-46b2-ac46-cc4dbb3275b5',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-21T10:45:00+00:00',
            endTime: '2023-12-21T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '19c04f68-04ce-464c-9312-573417821963',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-26T09:30:00+00:00',
            endTime: '2023-12-26T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '50328c7c-ee3a-427d-b49d-12a03b4a0166',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-26T10:45:00+00:00',
            endTime: '2023-12-26T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '2608071a-428b-4764-b0ef-dd123f8a1c53',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-28T09:30:00+00:00',
            endTime: '2023-12-28T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '1bdaa106-3566-4026-9f09-d04818a60e91',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2023-12-28T10:45:00+00:00',
            endTime: '2023-12-28T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '333e6bac-69d7-4466-8e8e-c541b0c89481',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2024-01-02T09:30:00+00:00',
            endTime: '2024-01-02T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '4b8eeeb7-5f90-4425-b037-d255bed28cc6',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2024-01-02T10:45:00+00:00',
            endTime: '2024-01-02T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '9fd33ad7-78f8-4f73-ac6c-62e13823c995',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2024-01-04T09:30:00+00:00',
            endTime: '2024-01-04T10:30:00+00:00',
            expertId: '4',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '4b36ce2b-98ed-4097-91c5-a6eb1bcd3e03',
            availabilityId: '9e1d3167-2a1b-44ae-808b-07f820eea8a7',
            status: 'available',
            price: 55,
            serviceId: '6fb552b6-4037-4b1b-a0ea-85ca16fef6b3',
            startTime: '2024-01-04T10:45:00+00:00',
            endTime: '2024-01-04T11:45:00+00:00',
            expertId: '4',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '11',
    phone: '555-999-8888',
    creation_date: '2023-07-03T09:45:55.654+00:00',
    birthday: '1984-03-24',
    name: 'Sophia Clark',
    sex: 'Female',
    isMock: true,
    score: null,
    headline: 'Licensed Therapist',
    education: 'M.A. in Clinical Psychology',
    company: null,
    username: 'SophiaCListener',
    avatar: 'https://randomuser.me/api/portraits/women/83.jpg',
    email: 'sophiaclark@example.com',
    oral_coins: 130,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['psycho', 'realState', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
        name: 'Therapy Session',
        price: 45,
        duration: 50,
        speciality: 'psycho',
        format: 'person',
        description: 'Individual therapy session.',
        expertId: '11',
        keywords: null,
        time_slots: [
          {
            id: '31a8d743-f4c0-4c76-8485-c57a75f15cdc',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-19T08:30:00+00:00',
            endTime: '2023-12-19T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'a47bc71a-82cc-4a52-be65-103412ab98cb',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-19T09:30:00+00:00',
            endTime: '2023-12-19T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '7e486916-6a89-4a13-8548-d759732a9e9a',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-21T08:30:00+00:00',
            endTime: '2023-12-21T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'ebabd0d4-e625-4b9c-8f96-8e3b103b268f',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-21T09:30:00+00:00',
            endTime: '2023-12-21T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '46a613dc-bd25-46c3-a8e1-aec602c3eb0b',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-26T08:30:00+00:00',
            endTime: '2023-12-26T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'fbaf5247-4357-42cd-98e6-ef2f0db48f62',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-26T09:30:00+00:00',
            endTime: '2023-12-26T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'c73c28e8-680f-431a-99e6-ea33971124c6',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-28T08:30:00+00:00',
            endTime: '2023-12-28T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b9620749-5e08-4892-a220-2a26821ff2fd',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2023-12-28T09:30:00+00:00',
            endTime: '2023-12-28T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '7f85b5f5-e5b4-4704-979a-124e5e81e0a6',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2024-01-02T08:30:00+00:00',
            endTime: '2024-01-02T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'b4746aba-9831-480b-a72a-0c82276c0deb',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2024-01-02T09:30:00+00:00',
            endTime: '2024-01-02T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '71ca468d-cbbf-4a8b-a208-50955ec4439e',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2024-01-04T08:30:00+00:00',
            endTime: '2024-01-04T09:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'f1a57aaf-748f-422f-a1ae-94fd0565db93',
            availabilityId: 'd750e8bd-6993-4b32-bf15-d67e106b9e0b',
            status: 'available',
            price: 45,
            serviceId: '00cabdb3-8e08-4285-bdd9-e798a6428e23',
            startTime: '2024-01-04T09:30:00+00:00',
            endTime: '2024-01-04T10:20:00+00:00',
            expertId: '11',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '12',
    phone: '555-777-1111',
    creation_date: '2023-04-19T13:20:30.987+00:00',
    birthday: '1981-08-10',
    name: 'Liam Anderson',
    sex: 'Male',
    isMock: true,
    score: null,
    headline: 'Real Estate Specialist',
    education: 'B.S. in Real Estate Finance',
    company: null,
    username: 'LiamARealState',
    avatar: 'https://randomuser.me/api/portraits/men/79.jpg',
    email: 'liamanderson@example.com',
    oral_coins: 140,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
        name: 'Real Estate Consultation',
        price: 55,
        duration: 60,
        speciality: 'realState',
        format: 'video',
        description: 'Consultation on real estate properties.',
        expertId: '12',
        keywords: null,
        time_slots: [
          {
            id: '6688673f-9039-4963-ac8d-87b2ff078ce3',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2023-12-18T08:30:00+00:00',
            endTime: '2023-12-18T09:30:00+00:00',
            expertId: '12',
            creationDate: null,
          },
          {
            id: 'a4341b54-33ca-488f-9a53-95620bf9dfcd',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2023-12-18T09:30:00+00:00',
            endTime: '2023-12-18T10:30:00+00:00',
            expertId: '12',
            creationDate: null,
          },
          {
            id: '483ef3de-7b5a-4fa4-8a77-1b84bfff0b30',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2023-12-18T10:30:00+00:00',
            endTime: '2023-12-18T11:30:00+00:00',
            expertId: '12',
            creationDate: null,
          },
          {
            id: '4233c240-3a5a-4b5e-aad9-1f740f8842c7',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2023-12-27T09:30:00+00:00',
            endTime: '2023-12-27T10:30:00+00:00',
            expertId: '12',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'feb53059-afd0-4973-9212-52189e6c9533',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2023-12-27T10:45:00+00:00',
            endTime: '2023-12-27T11:45:00+00:00',
            expertId: '12',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '9c4e1ed7-e84d-47d0-9e12-8140326dc93f',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2024-01-01T09:30:00+00:00',
            endTime: '2024-01-01T10:30:00+00:00',
            expertId: '12',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b522c9e4-3186-4e91-adb5-a3283bb86e40',
            availabilityId: '3dc99567-e9c1-4ce5-a402-a33a2807adac',
            status: 'available',
            price: 55,
            serviceId: '0d47cdad-e21f-4c03-a0d7-7672426524c2',
            startTime: '2024-01-01T10:45:00+00:00',
            endTime: '2024-01-01T11:45:00+00:00',
            expertId: '12',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '7',
    phone: '555-333-2222',
    creation_date: '2023-10-03T17:12:58.456+00:00',
    birthday: '1978-07-15',
    name: 'Elizabeth Martinez',
    sex: 'Female',
    isMock: true,
    score: null,
    headline: 'Experienced Therapist',
    education: 'Ph.D. in Clinical Psychology',
    company: null,
    username: 'ElizabethMListener',
    avatar: 'https://randomuser.me/api/portraits/women/69.jpg',
    email: 'elizabethmartinez@example.com',
    oral_coins: 140,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['psycho', 'realState', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '7a58172d-e4bd-41a7-b636-72151742d58a',
        name: 'Therapy Session',
        price: 45,
        duration: 50,
        speciality: 'psycho',
        format: 'person',
        description: 'Individual therapy session.',
        expertId: '7',
        keywords: null,
        time_slots: [
          {
            id: '7394dcda-99ed-4625-b07a-a8a3a68e0858',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-19T08:30:00+00:00',
            endTime: '2023-12-19T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'bd57e4a4-94c6-4f37-85d0-ba7ef15d1bda',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-19T09:30:00+00:00',
            endTime: '2023-12-19T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'e3b692b8-d7cd-4331-ba73-cd4a0954da21',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-21T08:30:00+00:00',
            endTime: '2023-12-21T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'a0cd5986-c63b-496a-90a7-bf70912cdb25',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-21T09:30:00+00:00',
            endTime: '2023-12-21T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'b25fb518-552f-4e64-a67d-a84448937863',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-26T08:30:00+00:00',
            endTime: '2023-12-26T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '1c464959-1e51-4d61-9837-2becd3c40632',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-26T09:30:00+00:00',
            endTime: '2023-12-26T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '9235f731-f278-4eca-a463-5f0a1a42f815',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-28T08:30:00+00:00',
            endTime: '2023-12-28T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '2854ce8b-4368-4989-9289-4c14cffc5bb5',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2023-12-28T09:30:00+00:00',
            endTime: '2023-12-28T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '77f0521e-08c7-4592-b960-92bdca47cdac',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2024-01-02T08:30:00+00:00',
            endTime: '2024-01-02T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'e2a3a406-feea-40e4-a1aa-4e6b0e755507',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2024-01-02T09:30:00+00:00',
            endTime: '2024-01-02T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'd4ebad2a-59ef-41d5-a347-bd4a55416296',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2024-01-04T08:30:00+00:00',
            endTime: '2024-01-04T09:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'a58ce1a4-ceec-4333-b50a-ddb0d7483d49',
            availabilityId: 'e727e0a5-62ab-4705-b95b-b70436ac6885',
            status: 'available',
            price: 45,
            serviceId: '7a58172d-e4bd-41a7-b636-72151742d58a',
            startTime: '2024-01-04T09:30:00+00:00',
            endTime: '2024-01-04T10:20:00+00:00',
            expertId: '7',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
    phone: '+34128923123',
    creation_date: '2023-10-23T15:37:58+00:00',
    birthday: '1987-06-11',
    name: 'Antonio Velazquez',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'FullStack Developer',
    education: 'Univerdad Autonoma de Madrid',
    company: null,
    username: 'antonioV',
    avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
    email: 'antonio.velazquez1987@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['computer'],
    languages: ['ES', 'EN', 'FR'],
    job: ' Web Developer',
    provider: 'password',
    last_sign_in: '2023-10-23T15:37:58+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'f975bddf-bf6c-4016-896f-710acff6ff33',
        name: 'Enseñando React',
        price: 60,
        duration: 45,
        speciality: 'computer',
        format: 'call',
        description: '',
        expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
        keywords: ['software'],
        time_slots: [
          {
            id: 'db3a1869-6fae-4d4f-9125-5bd02ca0fc6e',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T14:45:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: 'ae3f7354-1b16-45f2-8ecb-a69db7f2b7a1',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-18T14:45:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '23925df0-7bf0-4185-b7d0-c4e7f65c9431',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T16:15:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '1aecede0-b83e-4c23-9b83-f0ae6d2e5566',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-18T16:15:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '7a40818c-d100-42f5-a5d7-a17f7d5176aa',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T15:45:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'd94c6724-e54d-4b40-9624-06d1a4d87596',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-27T15:55:00+00:00',
            endTime: '2023-12-27T16:40:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '0f8de9fb-5d48-402f-933b-638632d1506e',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-27T16:50:00+00:00',
            endTime: '2023-12-27T17:35:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '7638ce22-2540-4160-8077-3aa49d74096c',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T15:45:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'fb544e09-50c6-4971-8d79-7334814a0b78',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-29T15:55:00+00:00',
            endTime: '2023-12-29T16:40:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '77e3ae7c-cc4b-4f59-a7ea-e1d13199bd56',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-29T16:50:00+00:00',
            endTime: '2023-12-29T17:35:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '38455722-f7bb-447b-b9a9-826dfd684fcd',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-30T08:00:00+00:00',
            endTime: '2023-12-30T08:45:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b67c13ef-f307-42d7-8d1d-769203554670',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-30T08:55:00+00:00',
            endTime: '2023-12-30T09:40:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '4b67df19-a6c5-49ea-8552-398f3f9cd2ae',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2023-12-30T09:50:00+00:00',
            endTime: '2023-12-30T10:35:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '744f8187-4fd7-4327-87d5-305f80597dcf',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2024-01-01T15:00:00+00:00',
            endTime: '2024-01-01T15:45:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '3ad42068-b7c6-4a8a-b144-b292af096ba6',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2024-01-01T15:55:00+00:00',
            endTime: '2024-01-01T16:40:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '2ce33fea-5d67-48e3-a857-8aa5f0630538',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 60,
            serviceId: 'f975bddf-bf6c-4016-896f-710acff6ff33',
            startTime: '2024-01-01T16:50:00+00:00',
            endTime: '2024-01-01T17:35:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
      {
        id: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
        name: 'Enseñando CSS',
        price: 40,
        duration: 30,
        speciality: 'computer',
        format: 'video',
        description: '',
        expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
        keywords: ['software'],
        time_slots: [
          {
            id: '491d13d4-1dbd-4b65-bfed-01c73351b473',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T14:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '113f7aa1-c076-49d7-8cb3-a8ce2b78cb13',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T14:30:00+00:00',
            endTime: '2023-12-18T15:00:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '400111bb-1ab6-4377-87b7-622de9bdbb98',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '31db3447-aefb-4815-ab4b-1a8a519b8f1c',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '52e30f5e-e7e9-4abd-9003-74aba7de8f8b',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '3aa98249-3a48-4ec2-a37d-6488cde1b7fc',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-18T16:30:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: null,
          },
          {
            id: '494dcad0-8e22-40a3-9cc6-86a8464ba400',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T15:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '50a89c25-5ff2-4154-9cf8-e4c617c66af1',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-27T15:40:00+00:00',
            endTime: '2023-12-27T16:10:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '27d2cca5-8b65-42b4-86cf-187c19047cc7',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-27T16:20:00+00:00',
            endTime: '2023-12-27T16:50:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '3941489b-ace3-43d9-ab6f-d051714057f2',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'e79430bb-f6f1-4d06-b6f1-9650fb4132ed',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T15:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '3f1d6a62-5e79-4daa-8cba-51eec96810e5',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-29T15:40:00+00:00',
            endTime: '2023-12-29T16:10:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'c2ff88ff-76ac-4c05-9e14-039b7be1ff12',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-29T16:20:00+00:00',
            endTime: '2023-12-29T16:50:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '370fa91f-03aa-4502-8f5a-1181c63d3024',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'e7395352-02ed-4fd4-995b-8856bfeb26af',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-30T08:00:00+00:00',
            endTime: '2023-12-30T08:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'd294a45b-cd8f-4407-a546-fdfc9dd01a70',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-30T08:40:00+00:00',
            endTime: '2023-12-30T09:10:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b2b25125-aa01-4ce0-8f72-42749c325ba9',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-30T09:20:00+00:00',
            endTime: '2023-12-30T09:50:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'eb98fb05-e9f2-48ed-8832-504482e3cac8',
            availabilityId: '6c30126a-cd6a-4a94-bd25-05e2315c09ac',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2023-12-30T10:00:00+00:00',
            endTime: '2023-12-30T10:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '7ad8d808-2cca-4077-99cb-96a14dcefa15',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2024-01-01T15:00:00+00:00',
            endTime: '2024-01-01T15:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '70dd97e3-4550-45b4-9477-4a8772979dd8',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2024-01-01T15:40:00+00:00',
            endTime: '2024-01-01T16:10:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '73f65d59-40f0-491b-84c3-14ef344970dc',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2024-01-01T16:20:00+00:00',
            endTime: '2024-01-01T16:50:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '1f671ffa-7d4f-411d-963b-ad4c674b46c4',
            availabilityId: '2af7d0bc-792f-448c-965b-546b950b93e5',
            status: 'available',
            price: 40,
            serviceId: 'b6dc9e36-6d14-4fd9-a467-92f9fe12b797',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:30:00+00:00',
            expertId: 'xeEFHJLDoUZkJiFVx7JQw6JlNL03',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
    phone: '3543434343',
    creation_date: '2023-10-23T17:12:44+00:00',
    birthday: '1999-09-12',
    name: 'Paco Chocolatero',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Fundador del grupo Planeta & La Caixa',
    education: 'UB',
    company: null,
    username: 'pacoC',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2FpacoC?alt=media&token=5fbe36dd-6fd0-4fd1-87d8-acb14fc3085b',
    email: 'paco.chocolatero43@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['personalTrainer'],
    languages: ['PT', 'ES'],
    job: 'Consultor SAP',
    provider: 'password',
    last_sign_in: '2023-10-23T17:12:44+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '4ee84729-8e60-423b-a839-a7a246606eff',
        name: 'Encuentro Personalizado',
        price: 100,
        duration: 60,
        speciality: 'personalTrainer',
        format: 'person',
        description: null,
        expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
        keywords: ['fitness', 'musculación'],
        time_slots: [
          {
            id: '34728fe2-2023-4100-97c5-bf0a2f81d0d0',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T15:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: null,
          },
          {
            id: '08768b14-6ebd-4bcc-8151-89d141c5e27b',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: null,
          },
          {
            id: 'd2cf86b3-b834-436a-adda-6234a8703474',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: null,
          },
          {
            id: 'c4e31489-1bdf-4859-bebb-3afdd7405b29',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-26T15:00:00+00:00',
            endTime: '2023-12-26T16:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '47bbfcd0-b4c8-479d-86e4-c79a7688ca63',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-26T16:15:00+00:00',
            endTime: '2023-12-26T17:15:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '6c2a9cd2-fe5d-4a8d-8034-93ab8a321b32',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T16:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '691d876d-6bfc-448e-8d77-df3c6449348a',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2023-12-27T16:15:00+00:00',
            endTime: '2023-12-27T17:15:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '39398ab3-35d3-4aad-90cb-dc52c3c2bf86',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2024-01-01T15:00:00+00:00',
            endTime: '2024-01-01T16:00:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'ae511fbd-d6be-4b7b-8c72-df41782d1d91',
            availabilityId: 'e4ce8195-a98d-450f-908c-8157fab09ca0',
            status: 'available',
            price: 100,
            serviceId: '4ee84729-8e60-423b-a839-a7a246606eff',
            startTime: '2024-01-01T16:15:00+00:00',
            endTime: '2024-01-01T17:15:00+00:00',
            expertId: 'WxCbZOwddrRHvbfB7j8nWKWhlRV2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '9',
    phone: '555-111-2222',
    creation_date: '2023-09-28T14:55:10.987+00:00',
    birthday: '1987-11-12',
    name: 'Olivia Davis',
    sex: 'Female',
    isMock: true,
    score: null,
    headline: 'Experienced Psychologist',
    education: 'M.A. in Clinical Psychology',
    company: null,
    username: 'OliviaDListener',
    avatar: 'https://randomuser.me/api/portraits/women/79.jpg',
    email: 'oliviadavis@example.com',
    oral_coins: 120,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['psycho', 'realState'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '605b8b50-6426-40c3-9f81-f31d93b80126',
        name: 'Therapy Session',
        price: 40,
        duration: 60,
        speciality: 'psycho',
        format: 'person',
        description: 'Individual therapy session.',
        expertId: '9',
        keywords: null,
        time_slots: [
          {
            id: '817e0c2a-b21c-4fc1-8771-1e4e5264d18f',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-19T10:00:00+00:00',
            endTime: '2023-12-19T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'bf3438cb-edb9-44a3-8e7f-172fa1bb7a0d',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-19T11:10:00+00:00',
            endTime: '2023-12-19T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '81a1de82-6205-42ef-b887-240ff01f1fa0',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-21T10:00:00+00:00',
            endTime: '2023-12-21T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '4fe5d86d-3244-4948-ad8e-831b683b3a35',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-21T11:10:00+00:00',
            endTime: '2023-12-21T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'aa87ed4a-8572-4ce6-939a-1f070b7c7699',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-26T10:00:00+00:00',
            endTime: '2023-12-26T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '22c76214-3a10-44c0-b573-bbd4604f7f4c',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-26T11:10:00+00:00',
            endTime: '2023-12-26T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'c31626aa-a453-4e65-b706-d342b97fb746',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-28T10:00:00+00:00',
            endTime: '2023-12-28T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '33895b00-be4f-44bb-9b4d-230d70b3fc8c',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2023-12-28T11:10:00+00:00',
            endTime: '2023-12-28T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'f194a190-6853-40f5-97ff-b61a28db26e6',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2024-01-02T10:00:00+00:00',
            endTime: '2024-01-02T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '922a5174-96d4-4db6-b57e-b629cf27ab47',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2024-01-02T11:10:00+00:00',
            endTime: '2024-01-02T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'b13df8a1-e3d8-4be5-b36d-f42179d9b913',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2024-01-04T10:00:00+00:00',
            endTime: '2024-01-04T11:00:00+00:00',
            expertId: '9',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '8d6f7dd4-8145-4882-8583-599c5e32ba74',
            availabilityId: 'ce16128d-729f-4f67-91c1-65ee4b082226',
            status: 'available',
            price: 40,
            serviceId: '605b8b50-6426-40c3-9f81-f31d93b80126',
            startTime: '2024-01-04T11:10:00+00:00',
            endTime: '2024-01-04T12:10:00+00:00',
            expertId: '9',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '5',
    phone: '555-444-3333',
    creation_date: '2023-09-12T16:58:10.654+00:00',
    birthday: '1992-12-05',
    name: 'Emily White',
    sex: 'Female',
    isMock: true,
    score: null,
    headline: 'Licensed Therapist',
    education: 'M.A. in Clinical Psychology',
    company: null,
    username: 'EmilyWPsycho',
    avatar: 'https://randomuser.me/api/portraits/women/80.jpg',
    email: 'emilywhite@example.com',
    oral_coins: 110,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['psycho', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
        name: 'Therapy Session',
        price: 45,
        duration: 50,
        speciality: 'psycho',
        format: 'video',
        description: 'Individual therapy session.',
        expertId: '5',
        keywords: null,
        time_slots: [
          {
            id: 'd0d7f845-f094-426e-a7be-314cb199e437',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-19T08:30:00+00:00',
            endTime: '2023-12-19T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'f8f240a9-620b-474b-a667-07abd68d680b',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-19T09:30:00+00:00',
            endTime: '2023-12-19T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '9f3ec446-8356-415f-b3ba-0de1efc582c6',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-21T08:30:00+00:00',
            endTime: '2023-12-21T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'f9ad4136-d3c0-4ba4-ac2d-ec670ec25d62',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-21T09:30:00+00:00',
            endTime: '2023-12-21T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '3fbcbc62-d9eb-48aa-af6f-fdeb74a4d897',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-26T08:30:00+00:00',
            endTime: '2023-12-26T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '5ccdfa59-f6d6-413f-b30a-b001fa03a99c',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-26T09:30:00+00:00',
            endTime: '2023-12-26T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'e04b2ca2-1c11-4b51-95e6-bcac6b1d0f26',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-28T08:30:00+00:00',
            endTime: '2023-12-28T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b356b385-3e15-4869-9cca-22a874582cac',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2023-12-28T09:30:00+00:00',
            endTime: '2023-12-28T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '3342aa49-f3a0-4157-96de-4a18a1e9b946',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2024-01-02T08:30:00+00:00',
            endTime: '2024-01-02T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'e21e141b-097e-4e21-b1eb-18eecdda4b34',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2024-01-02T09:30:00+00:00',
            endTime: '2024-01-02T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '9c15bf2c-69bf-4822-870d-93155c1482a1',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2024-01-04T08:30:00+00:00',
            endTime: '2024-01-04T09:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '0a573597-d700-47a6-a06f-e14f3fdb71aa',
            availabilityId: '586e2604-1ebd-41fe-9128-6b5abb9a1c33',
            status: 'available',
            price: 45,
            serviceId: 'f6033eaa-1ab2-4282-ab91-80694f6d189d',
            startTime: '2024-01-04T09:30:00+00:00',
            endTime: '2024-01-04T10:20:00+00:00',
            expertId: '5',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
    phone: '+34128252525',
    creation_date: '2023-10-19T16:58:56+00:00',
    birthday: '1980-04-12',
    name: 'Rodolfo Madariaga',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Profesor en Universidad con mas de 10 años de experiencia',
    education: 'U.A.B Barcelona',
    company: null,
    username: 'rodolfoM',
    avatar: 'https://randomuser.me/api/portraits/men/98.jpg',
    email: 'rodolfo.madariaga90@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['computer'],
    languages: ['ES', 'EN'],
    job: 'Teacher',
    provider: 'password',
    last_sign_in: '2023-10-19T16:58:56+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
        name: 'Consulta molona',
        price: 25,
        duration: 45,
        speciality: 'computer',
        format: 'person',
        description: '',
        expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
        keywords: ['backend', 'hardware', 'redes'],
        time_slots: [
          {
            id: '3f7129b5-20d0-4860-9bcd-1e9aaed1a09c',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-23T12:00:00+00:00',
            endTime: '2023-12-23T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '2082d34a-5493-42a3-9cac-e9159ca58909',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-23T12:55:00+00:00',
            endTime: '2023-12-23T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '2e85f904-90e0-4402-bb9a-ad5b52689d01',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-23T13:50:00+00:00',
            endTime: '2023-12-23T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'd8991132-2f1a-4067-8c64-7e5c9800601f',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-23T14:45:00+00:00',
            endTime: '2023-12-23T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '5bfb1c1e-8756-49d2-8fb9-591e1b2c1b5b',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-24T12:00:00+00:00',
            endTime: '2023-12-24T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '43519b34-ab14-4146-8a32-12ad6a4b60a2',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-24T12:55:00+00:00',
            endTime: '2023-12-24T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '5ddce123-48fa-482c-bf04-010050f960c0',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-24T13:50:00+00:00',
            endTime: '2023-12-24T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8dd1fe31-f3ef-4f43-8801-3b4fb785ac0f',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-24T14:45:00+00:00',
            endTime: '2023-12-24T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '842a720e-4594-491e-a804-31721fe64f02',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-30T12:00:00+00:00',
            endTime: '2023-12-30T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '0ba9d93c-b878-43b4-a111-f113ace7eb0f',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-30T12:55:00+00:00',
            endTime: '2023-12-30T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '66fd3c53-b379-48ef-9890-960d5a2365ae',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-30T13:50:00+00:00',
            endTime: '2023-12-30T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '941f1b3f-834b-4536-bb88-6483c3cb09cb',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-30T14:45:00+00:00',
            endTime: '2023-12-30T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '0aa8cf14-c97b-48c0-9cb2-9d79e8a1def4',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-31T12:00:00+00:00',
            endTime: '2023-12-31T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '7c93c643-1c43-4dbb-a5ac-cc43abb85201',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-31T12:55:00+00:00',
            endTime: '2023-12-31T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'b87a0093-3215-4c0c-8cf5-cd0617689217',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-31T13:50:00+00:00',
            endTime: '2023-12-31T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'd646669e-6557-44b2-af11-b46c88c62d13',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2023-12-31T14:45:00+00:00',
            endTime: '2023-12-31T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'c7f0549c-7337-4ab3-a1cf-a5aead2b4249',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-06T12:00:00+00:00',
            endTime: '2024-01-06T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'f6b6b3da-2d1d-4c62-a895-b5d862f8c697',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-06T12:55:00+00:00',
            endTime: '2024-01-06T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '8cdd13f4-08e6-41bb-87b0-634491928f25',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-06T13:50:00+00:00',
            endTime: '2024-01-06T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'eed84f15-28f1-414f-9b1c-c79c1c26a4ee',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-06T14:45:00+00:00',
            endTime: '2024-01-06T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '4320b03e-1427-4d49-8f93-bc12c1297735',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-07T12:00:00+00:00',
            endTime: '2024-01-07T12:45:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'd97ed2b4-e7b9-413b-9958-3b97da299069',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-07T12:55:00+00:00',
            endTime: '2024-01-07T13:40:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'f332ec60-7f64-42da-bed9-aca4e6dc1d6e',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-07T13:50:00+00:00',
            endTime: '2024-01-07T14:35:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'adf7184e-68f3-4a28-bf36-7e262aefbf6d',
            availabilityId: 'd2d39723-d065-4323-a185-907ee3b51bda',
            status: 'available',
            price: 25,
            serviceId: 'a18bc703-a576-46de-a2fe-03823c9eee2e',
            startTime: '2024-01-07T14:45:00+00:00',
            endTime: '2024-01-07T15:30:00+00:00',
            expertId: 'GGML3xOa7cU9ZlMaE8tXML1A4Lk2',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
    phone: '753947573',
    creation_date: '2023-10-23T19:39:52+00:00',
    birthday: '1992-09-12',
    name: 'Valerie Barreto Colon',
    sex: 'Female',
    isMock: false,
    score: null,
    headline: null,
    education: 'EAE',
    company: null,
    username: 'valerieB',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2FvalerieB?alt=media&token=1777c1fa-1757-4ca8-85ef-78b997975442',
    email: 'valerie.barreto08@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: false,
    role: 'Expert',
    speciality: ['marketing'],
    languages: ['ES', 'EN'],
    job: 'SEO Specialist',
    provider: 'google.com',
    last_sign_in: '2023-11-12T22:29:13+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
        name: 'Diálogo en Video',
        price: 18,
        duration: 30,
        speciality: 'marketing',
        format: 'video',
        description: null,
        expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
        keywords: ['SEO'],
        time_slots: [
          {
            id: 'd341b134-8b5a-4fe6-81f4-58071fc356bc',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-19T15:00:00+00:00',
            endTime: '2023-12-19T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '77fe001c-6ebe-49db-978e-952e9df4ab91',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-19T15:45:00+00:00',
            endTime: '2023-12-19T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '059a2e25-340a-4aa1-bb4b-36ded3ef1f45',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-19T16:30:00+00:00',
            endTime: '2023-12-19T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '79df6240-8fdc-40aa-b985-5f181f993fea',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-19T17:15:00+00:00',
            endTime: '2023-12-19T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8a1e5b94-af01-4ebd-872f-dd3489aee240',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-20T15:00:00+00:00',
            endTime: '2023-12-20T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'ebb94a00-7f6b-4950-b489-3875062bdd95',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-20T15:45:00+00:00',
            endTime: '2023-12-20T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '4fae0780-8bec-49f0-b2ac-7344510690b3',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-20T16:30:00+00:00',
            endTime: '2023-12-20T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '673f14d4-e83e-4803-8fcc-0b131c3ce8ee',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-20T17:15:00+00:00',
            endTime: '2023-12-20T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '0b8107d0-b7c8-452f-9902-d227994381f9',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T18:00:00+00:00',
            endTime: '2023-12-21T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '2cdca281-90c9-488a-83fc-e55a40fb3fa7',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T18:30:00+00:00',
            endTime: '2023-12-21T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8d27d671-a0c5-474c-b9a9-1c07e5c0fd2d',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T19:00:00+00:00',
            endTime: '2023-12-21T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '30bd1cf3-c587-4129-a7f8-139d354c73a0',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T19:30:00+00:00',
            endTime: '2023-12-21T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'a2c8ab21-6ab4-488e-afe6-f2de170ff9f0',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T20:00:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '08893fb3-e2d1-46af-87bb-62740fffb311',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T20:30:00+00:00',
            endTime: '2023-12-21T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '5262d4a7-2c67-420e-98b7-f7733ddca2b6',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T21:00:00+00:00',
            endTime: '2023-12-21T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '97d0249b-1149-4a6b-a28d-8bd38503de91',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T21:30:00+00:00',
            endTime: '2023-12-21T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '39d6ed18-dd83-4314-b683-8d3d8a69b051',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T22:00:00+00:00',
            endTime: '2023-12-21T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8e3e0313-3b7d-4f95-811e-a19856220a29',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-21T22:30:00+00:00',
            endTime: '2023-12-21T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '113f88d8-685c-439a-896d-99330b360f40',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T15:00:00+00:00',
            endTime: '2023-12-22T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '461dc731-c104-4e9e-b371-aa8d14e209ab',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T15:45:00+00:00',
            endTime: '2023-12-22T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'e854b9c2-794f-4e34-a030-68443d3ef79c',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T16:30:00+00:00',
            endTime: '2023-12-22T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '0414b5b7-eeff-4432-82ec-dd8bad8c9751',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T17:15:00+00:00',
            endTime: '2023-12-22T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'f4c2ba92-dd71-4f8a-b0c2-671ee69ab683',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T18:00:00+00:00',
            endTime: '2023-12-22T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'd51744d4-88b1-4092-9e60-98598398b7c1',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T18:30:00+00:00',
            endTime: '2023-12-22T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '8953f148-011b-4061-8558-edc2083856a2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T19:00:00+00:00',
            endTime: '2023-12-22T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '88cdd14e-44a7-494c-91c7-318e75401107',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T19:30:00+00:00',
            endTime: '2023-12-22T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'b9d6926a-cb93-49a6-ab0b-ee985dc89819',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T20:00:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '10182818-f68c-4e3f-80b3-39cf69ca9e19',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T20:30:00+00:00',
            endTime: '2023-12-22T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'bc2d94d7-031c-4d4f-9dac-768f4cb026b6',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T21:00:00+00:00',
            endTime: '2023-12-22T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'cf93363c-7564-4b5b-b12d-462a7e85a1d4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T21:30:00+00:00',
            endTime: '2023-12-22T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '79fd8dc2-d843-4614-abc8-3088771774b3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T22:00:00+00:00',
            endTime: '2023-12-22T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '5025fc29-9724-4881-8b6c-f48df60fbfcb',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-22T22:30:00+00:00',
            endTime: '2023-12-22T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'f701002b-501a-4431-90c2-6aaaf162acc2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-26T15:00:00+00:00',
            endTime: '2023-12-26T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '19337c8a-5c14-4ccd-a525-2990f87e8574',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-26T15:45:00+00:00',
            endTime: '2023-12-26T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'd89eed6f-397b-46d3-89b7-cce6d8173a01',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-26T16:30:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'a570a2a4-d559-4ebe-ae3a-67974111d961',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'feb9e3e2-ebcb-4683-8fda-f419f809dfc7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '05360fa2-10c1-48a9-87a8-9445f2713878',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-27T15:45:00+00:00',
            endTime: '2023-12-27T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '4d89c894-4de4-4df1-b296-214705710f30',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-27T16:30:00+00:00',
            endTime: '2023-12-27T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'dc1afccf-6948-41de-aaba-3a39efdd2d25',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-27T17:15:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '9ad5fec5-13f4-4bf9-a234-cd4870954312',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '12bc67ce-54df-4079-a5e4-e963e4bae007',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T18:30:00+00:00',
            endTime: '2023-12-28T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '4c536283-27c5-48e1-9819-f16ae77d5048',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8e9ebabf-5951-45e7-991b-e72de5ed08db',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T19:30:00+00:00',
            endTime: '2023-12-28T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '221b6ed8-a0dd-40b7-8cdb-83eb8962cd78',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '111e061b-4ed0-4b25-9059-5c30da35091a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T20:30:00+00:00',
            endTime: '2023-12-28T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dc02fa6a-c1d9-41a3-a38d-dc80cbff82bb',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5f5eedb1-b5b1-4aec-a4eb-711c8abe51ab',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T21:30:00+00:00',
            endTime: '2023-12-28T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4f357699-2c92-4544-8404-f34729e0aea7',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T22:00:00+00:00',
            endTime: '2023-12-28T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd4395a78-b416-41d0-89c0-5b651d4b63b2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-28T22:30:00+00:00',
            endTime: '2023-12-28T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ef2ed6f3-1a9f-42fc-85a8-28452bf985fe',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'a8518dc2-5bbd-4ea8-aa9b-416e850477e2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T15:45:00+00:00',
            endTime: '2023-12-29T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'a72921bf-5243-48f2-a75a-e7924c3f38a4',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T16:30:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: 'eb6264bf-bae6-4a3e-af63-1ecc462b7ec2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.476+00:00',
          },
          {
            id: '1e8013ed-c40a-45a8-93bb-7f8bdc45f806',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3bad70fc-be2e-4f54-8c18-7f2947770b2c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T18:30:00+00:00',
            endTime: '2023-12-29T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '797efbd0-d528-41de-aacb-8ba4b83dffeb',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '2cee6a36-f435-49b1-b1fd-8cd4670f194d',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T19:30:00+00:00',
            endTime: '2023-12-29T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd1861adc-d6e5-4e98-b115-0d2b062acf2a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cc3812f7-03cd-427f-b2b4-c6b5973a0a5c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T20:30:00+00:00',
            endTime: '2023-12-29T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fcb359e5-4cc6-4161-b369-f573f45d6546',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4da39e82-8a10-4c08-b369-911018fec5d2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T21:30:00+00:00',
            endTime: '2023-12-29T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1955c33d-4e74-456e-a7fb-bf24318b2256',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T22:00:00+00:00',
            endTime: '2023-12-29T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '78b18991-100c-495f-93d7-c97d93fc5c9a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2023-12-29T22:30:00+00:00',
            endTime: '2023-12-29T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dff62f11-775c-410b-a6f2-21ea0e9106bc',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-02T15:00:00+00:00',
            endTime: '2024-01-02T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: 'c33beae2-3251-40f7-8178-f4a3d07470c0',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-02T15:45:00+00:00',
            endTime: '2024-01-02T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '7ddf2c56-c64c-4ab3-9865-291bf02a6834',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-02T16:30:00+00:00',
            endTime: '2024-01-02T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '828209f8-eb85-4ea2-83c7-43e7797f37d2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-02T17:15:00+00:00',
            endTime: '2024-01-02T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '48f273a8-0ad4-427c-ae0f-2fd046665d13',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-03T15:00:00+00:00',
            endTime: '2024-01-03T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '676ad3ca-1ffe-4fc8-9681-90d7923b92f2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-03T15:45:00+00:00',
            endTime: '2024-01-03T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.186+00:00',
          },
          {
            id: '1c5ee060-dd64-425c-b95c-3a8885151052',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-03T16:30:00+00:00',
            endTime: '2024-01-03T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '8eb3e6aa-0749-482e-a8bc-d1ec07ced7b3',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-03T17:15:00+00:00',
            endTime: '2024-01-03T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '2259cfc7-d7da-471d-8afe-622019f1db3f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T18:00:00+00:00',
            endTime: '2024-01-04T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f6c15a67-276c-4454-b63f-88a7fa1b56fc',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T18:30:00+00:00',
            endTime: '2024-01-04T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd490ece7-9f8f-4dc4-96e9-8a52ff088548',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T19:00:00+00:00',
            endTime: '2024-01-04T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9d288ab6-046b-4634-9649-0000538cc616',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T19:30:00+00:00',
            endTime: '2024-01-04T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ed08400f-9e11-455d-84a6-09ce03887919',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T20:00:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9f8639d1-6ea5-41fb-99a8-0850a2160ef8',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T20:30:00+00:00',
            endTime: '2024-01-04T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '014e3a24-6b63-4209-b0dc-6d47cc0d22b0',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T21:00:00+00:00',
            endTime: '2024-01-04T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '52af7f5b-6d25-4a7b-973a-252589ea9ea3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T21:30:00+00:00',
            endTime: '2024-01-04T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '7fea6887-68bd-4cb4-996f-9ef1bfb60f7c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T22:00:00+00:00',
            endTime: '2024-01-04T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'bd3c0597-3198-4094-a0f2-f1e7c822c3a9',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-04T22:30:00+00:00',
            endTime: '2024-01-04T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '105d87bf-dcf1-4654-9800-3df0c1d27d26',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T15:00:00+00:00',
            endTime: '2024-01-05T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '86be5cde-93c2-470a-8871-10686593d391',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T15:45:00+00:00',
            endTime: '2024-01-05T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3e83f3af-6dc6-40a5-b35e-a8bfb07dfba9',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T16:30:00+00:00',
            endTime: '2024-01-05T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '04ffd761-5d5c-4ce5-8405-e6fb6a0888de',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T17:15:00+00:00',
            endTime: '2024-01-05T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9102742f-5d81-4bba-92e3-6878864ff73f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T18:00:00+00:00',
            endTime: '2024-01-05T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '24535cef-5df1-4cf2-a245-523697f0c0e4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T18:30:00+00:00',
            endTime: '2024-01-05T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4af2d64d-c722-4b6b-97d4-6b4de97625cc',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T19:00:00+00:00',
            endTime: '2024-01-05T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4026ecab-e860-46bc-a62c-e0b394fdeda3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T19:30:00+00:00',
            endTime: '2024-01-05T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '26fa00cd-baab-4c7e-b990-36998c6a0891',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T20:00:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3267d259-8981-4ef4-8e64-6a10bc4793f3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T20:30:00+00:00',
            endTime: '2024-01-05T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f6b133d8-711c-45eb-8c2c-e647d6937b98',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T21:00:00+00:00',
            endTime: '2024-01-05T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4f2e5c9c-514b-4efe-a193-de6daea20451',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T21:30:00+00:00',
            endTime: '2024-01-05T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6ed58e0d-d097-4b5f-893b-0bd15b6b0641',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T22:00:00+00:00',
            endTime: '2024-01-05T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd23c3fcc-48e3-40c6-ad9e-3fcad4b93b46',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 18,
            serviceId: 'b8f50bd0-680d-46c4-a933-daea75a4a5a6',
            startTime: '2024-01-05T22:30:00+00:00',
            endTime: '2024-01-05T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: '8d059b91-02af-43a2-8264-0d11ef0d0214',
        name: 'Conversación Extendida',
        price: 22,
        duration: 30,
        speciality: 'marketing',
        format: 'call',
        description: null,
        expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
        keywords: ['SEO'],
        time_slots: [
          {
            id: '510b3864-6c61-45b0-8fd7-2e704c502a08',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-19T15:00:00+00:00',
            endTime: '2023-12-19T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '55a67e41-96ab-47d3-9f01-3edf81cd1d05',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-19T15:45:00+00:00',
            endTime: '2023-12-19T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '0d4c94b5-2450-48b1-a2db-227106f4b858',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-19T16:30:00+00:00',
            endTime: '2023-12-19T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '7946986a-8ff8-4c51-9f80-b4592be92375',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-19T17:15:00+00:00',
            endTime: '2023-12-19T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'b9ff440d-9fcf-4964-b286-e80e043ba826',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-20T15:00:00+00:00',
            endTime: '2023-12-20T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'bff21138-9a00-4fe1-a86f-faf4be75f39e',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-20T15:45:00+00:00',
            endTime: '2023-12-20T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'e9849ee6-1010-41ea-ab8c-880be703b934',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-20T16:30:00+00:00',
            endTime: '2023-12-20T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'f158fd58-d1b3-49aa-b9b7-26c752798ebb',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-20T17:15:00+00:00',
            endTime: '2023-12-20T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: 'a65d694f-2bfa-4a75-8839-84c185257026',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T18:00:00+00:00',
            endTime: '2023-12-21T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '63b48a9f-c35b-4117-bfd3-9dce07b18405',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T18:30:00+00:00',
            endTime: '2023-12-21T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'aeb98050-43b4-4c21-95ac-eaaf6af570b3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T19:00:00+00:00',
            endTime: '2023-12-21T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6cf21657-2b7c-443b-9f75-7051879a4766',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T19:30:00+00:00',
            endTime: '2023-12-21T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '80e416f0-cd1b-4526-bd90-e34087660953',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T20:00:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'f5aa7271-13f2-47a5-90c8-673fa6672fa5',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T20:30:00+00:00',
            endTime: '2023-12-21T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6fba9aa6-c96f-4da9-9aea-8395509670f8',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T21:00:00+00:00',
            endTime: '2023-12-21T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '7f9f6b21-c281-4a52-9742-7cbb4ab16912',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T21:30:00+00:00',
            endTime: '2023-12-21T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '837f92b7-b85e-444d-8397-d23305ed14d1',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T22:00:00+00:00',
            endTime: '2023-12-21T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'd6187930-f698-4568-abce-fe0e1e113cfa',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-21T22:30:00+00:00',
            endTime: '2023-12-21T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0d7dd30d-f5fc-44e9-bb3b-644fa935a831',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T15:00:00+00:00',
            endTime: '2023-12-22T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '52f68ad4-9365-4f23-9418-14ef67aa3f8f',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T15:45:00+00:00',
            endTime: '2023-12-22T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '35fb4293-5e2d-42a7-8511-8e9741914bf6',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T16:30:00+00:00',
            endTime: '2023-12-22T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '5d5c85c7-0acb-4508-a43b-d49c369388d7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T17:15:00+00:00',
            endTime: '2023-12-22T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.472+00:00',
          },
          {
            id: '2d72caea-ecf9-4296-8dfd-acfb87291cff',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T18:00:00+00:00',
            endTime: '2023-12-22T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ce7463bb-3b7e-4b22-bcdd-c775b0d08dd0',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T18:30:00+00:00',
            endTime: '2023-12-22T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '1da9a393-1bb2-43dd-8113-00736460637a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T19:00:00+00:00',
            endTime: '2023-12-22T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '39d5fb55-6802-45e1-a74f-a2ed8ca9798b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T19:30:00+00:00',
            endTime: '2023-12-22T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e41af02c-027a-4679-a832-1c849f1adea3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T20:00:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '90649d9f-5938-4b74-90be-3e64cc02f0f2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T20:30:00+00:00',
            endTime: '2023-12-22T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4b7610a6-b0ff-41b8-b4e9-da2c9b1fcf0a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T21:00:00+00:00',
            endTime: '2023-12-22T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'cb682c82-6cd3-4f89-8e71-018ddcb398b7',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T21:30:00+00:00',
            endTime: '2023-12-22T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '3da36b31-de29-4508-8d27-4ca3837e126f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T22:00:00+00:00',
            endTime: '2023-12-22T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'd7f611b1-41ed-4449-a674-bc7f5788d0f2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-22T22:30:00+00:00',
            endTime: '2023-12-22T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '318bb0d7-21b8-489f-a802-3d911561cca7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-26T15:00:00+00:00',
            endTime: '2023-12-26T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '39b034a0-934d-4ddf-ba2d-98ef789769e2',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-26T15:45:00+00:00',
            endTime: '2023-12-26T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dfe552f8-88c5-4390-b375-fccd1869989b',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-26T16:30:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '222af264-3ac1-4db4-a5d4-e87d51205c19',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '53940450-0c9c-4d2d-82e5-86e6bc37c25f',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5d49331c-318c-4625-967e-195de26571b1',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-27T15:45:00+00:00',
            endTime: '2023-12-27T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c81625fb-c321-4f77-9ba8-7ae8ccca914c',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-27T16:30:00+00:00',
            endTime: '2023-12-27T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fddbf450-cc08-4176-8dc8-8e907a078091',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-27T17:15:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c75109fb-d871-4a6e-b080-a3ca21acbc05',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd52dbb4a-3f2f-4b56-9335-af802deb2a72',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T18:30:00+00:00',
            endTime: '2023-12-28T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f5441a03-01f9-496c-994c-9569b1eeef78',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '59ab561e-8135-4e9c-bf7b-1a962dca6bf4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T19:30:00+00:00',
            endTime: '2023-12-28T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '2f22853e-1ee1-4c94-8dc2-7b9aaa3c0518',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6956bb43-4d1b-4ab2-9d7e-9c7864a193c0',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T20:30:00+00:00',
            endTime: '2023-12-28T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '68eabfc4-5aaa-4864-b2e2-7efcdbcf3bda',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '318ed2f1-b998-477e-8584-a77b2f2b9bf4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T21:30:00+00:00',
            endTime: '2023-12-28T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd1ca3cd3-35bc-4941-b81b-eeb38957800b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T22:00:00+00:00',
            endTime: '2023-12-28T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '07b0bccc-2654-468e-988f-4a2094261af6',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-28T22:30:00+00:00',
            endTime: '2023-12-28T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e7352404-e715-432c-bbc8-e69ad1a29ae7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f6405288-98ee-4b72-b9d0-8ad54b989312',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T15:45:00+00:00',
            endTime: '2023-12-29T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '55f0e238-9b99-4edd-b572-2e525a1f691e',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T16:30:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8c19f93b-3cd8-4569-b5da-1b754bb418ce',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'faa3bc8a-3cba-4bfb-9e0a-81f4658a78a5',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cebe13f6-2b76-45fd-86b4-41425507c83f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T18:30:00+00:00',
            endTime: '2023-12-29T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f3536f6c-fcf8-4fcf-af7e-c689478290ae',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '225ffadb-ecae-492b-a8d3-0337642b90d9',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T19:30:00+00:00',
            endTime: '2023-12-29T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '51d7b014-91b7-419d-84e6-fbcfdb9fa523',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1f4c92cf-21c1-4879-8354-e97f5232a0fa',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T20:30:00+00:00',
            endTime: '2023-12-29T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0b789684-684e-4c7d-9c04-69b782361c4b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b400f61e-ca8f-4398-b8da-960422a8e4df',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T21:30:00+00:00',
            endTime: '2023-12-29T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '56b9a9e9-4757-4aed-936b-a99b21019714',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T22:00:00+00:00',
            endTime: '2023-12-29T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'da4913f8-cecd-40e5-ba1c-1e501bda6857',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2023-12-29T22:30:00+00:00',
            endTime: '2023-12-29T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '08aed0cd-e278-432a-bcec-5f2c1a28f269',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-02T15:00:00+00:00',
            endTime: '2024-01-02T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3ac40862-a224-4369-8a1d-05a1f1138697',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-02T15:45:00+00:00',
            endTime: '2024-01-02T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '458b5032-a9d6-4869-8572-3807cedf9e96',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-02T16:30:00+00:00',
            endTime: '2024-01-02T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd3119a53-2078-4e8c-b355-59d055a779af',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-02T17:15:00+00:00',
            endTime: '2024-01-02T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '70b98283-34ef-445f-bf15-f4895ffbebd5',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-03T15:00:00+00:00',
            endTime: '2024-01-03T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd8371132-fab9-4f1f-8a04-365a7252067b',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-03T15:45:00+00:00',
            endTime: '2024-01-03T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3bbeae4a-7c27-43ce-9a8c-ba5adf4a23d4',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-03T16:30:00+00:00',
            endTime: '2024-01-03T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e9ebaf2c-e232-4da0-b1e7-42277b1d6147',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-03T17:15:00+00:00',
            endTime: '2024-01-03T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1811ee42-bc28-424a-b1bc-ce26ae05e6b3',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T18:00:00+00:00',
            endTime: '2024-01-04T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '088634c0-965b-4477-bd1b-9d168a45f3c2',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T18:30:00+00:00',
            endTime: '2024-01-04T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4417c49c-5de5-4460-b0fe-8d23d90bf6c4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T19:00:00+00:00',
            endTime: '2024-01-04T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'cbf61d0d-15b2-4f0a-a1f2-0159233bc804',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T19:30:00+00:00',
            endTime: '2024-01-04T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '65ffe934-a5bc-4b7a-b97a-c224286e976b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T20:00:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '244f8d46-93d2-455f-a44a-a34cec9144c1',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T20:30:00+00:00',
            endTime: '2024-01-04T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0e14ea07-6a84-41a6-8a88-5e9e3c811ff4',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T21:00:00+00:00',
            endTime: '2024-01-04T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e0b1e756-d37e-41e1-adaf-cfce3c52256c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T21:30:00+00:00',
            endTime: '2024-01-04T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ff6abcdd-e32b-4a13-b6b7-0eaaf7eb9078',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T22:00:00+00:00',
            endTime: '2024-01-04T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6440dae5-f353-4c3b-b0db-8c57b796da9f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-04T22:30:00+00:00',
            endTime: '2024-01-04T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'cb4f5541-da5d-4e51-9d7f-fd8925df3e44',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T15:00:00+00:00',
            endTime: '2024-01-05T15:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1e60650c-39a5-446f-91f1-3a83b2205100',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T15:45:00+00:00',
            endTime: '2024-01-05T16:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'cdad28a4-307a-4c9e-8547-270a482fa58d',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T16:30:00+00:00',
            endTime: '2024-01-05T17:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f1521dee-ddfb-4177-be1a-c4830f65127c',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T17:15:00+00:00',
            endTime: '2024-01-05T17:45:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '828a16be-be6e-46d5-8fb4-bd6c9a62d21d',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T18:00:00+00:00',
            endTime: '2024-01-05T18:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '59d3888e-8141-4e57-863a-a91fc2d35f60',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T18:30:00+00:00',
            endTime: '2024-01-05T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '42ebb74b-72e8-459d-9a07-ec2638eb2e6c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T19:00:00+00:00',
            endTime: '2024-01-05T19:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '02ccd5bb-3fcd-41c9-bed3-88f5ea37571d',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T19:30:00+00:00',
            endTime: '2024-01-05T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6b5a6ddb-fb5f-44a4-b57c-7ae62ae197ba',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T20:00:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '8bba3444-f6b8-43ab-8c91-434fdaeb8459',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T20:30:00+00:00',
            endTime: '2024-01-05T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '60025092-b5ed-4479-8123-6a2ac3f06db5',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T21:00:00+00:00',
            endTime: '2024-01-05T21:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '2fddaacd-541e-43c5-be69-b6319344a190',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T21:30:00+00:00',
            endTime: '2024-01-05T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '89a7b4eb-95d8-4bb2-8bcc-8cf77b0e63fb',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T22:00:00+00:00',
            endTime: '2024-01-05T22:30:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c836fe6e-4c2f-403f-9b8a-6e315ebb3d8f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 22,
            serviceId: '8d059b91-02af-43a2-8264-0d11ef0d0214',
            startTime: '2024-01-05T22:30:00+00:00',
            endTime: '2024-01-05T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
        name: 'Encuentro Personalizado',
        price: 50,
        duration: 60,
        speciality: 'marketing',
        format: 'person',
        description: null,
        expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
        keywords: ['SEO'],
        time_slots: [
          {
            id: 'fbd3bc5f-f3d8-4b07-8865-aafd4296e310',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-19T15:00:00+00:00',
            endTime: '2023-12-19T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6b5b7521-a27c-4468-b472-7617ca6b4fda',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-19T16:15:00+00:00',
            endTime: '2023-12-19T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '5aa3d256-a77b-42e1-bf7d-a3138926e455',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-20T15:00:00+00:00',
            endTime: '2023-12-20T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '603bc19d-faa7-4b64-a98b-1f571f525d64',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-20T16:15:00+00:00',
            endTime: '2023-12-20T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '37f8b00d-d970-4d96-90ad-18ac8cc58059',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-21T18:00:00+00:00',
            endTime: '2023-12-21T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e54c0c76-d662-4435-a8ac-9ea3fa1d0d89',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-21T19:00:00+00:00',
            endTime: '2023-12-21T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '9a279dbc-0422-4ff8-8109-2d2166be4e57',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-21T20:00:00+00:00',
            endTime: '2023-12-21T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '803bb5bb-a486-4089-bd97-cc8d546f5747',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-21T21:00:00+00:00',
            endTime: '2023-12-21T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '5f070f72-c6fd-45a3-b5a0-88693d490405',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-21T22:00:00+00:00',
            endTime: '2023-12-21T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '8e490e5b-1694-4d7c-827e-65b17d55c497',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T15:00:00+00:00',
            endTime: '2023-12-22T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '58449910-a516-4ccc-8759-240a713dc8db',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T16:15:00+00:00',
            endTime: '2023-12-22T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4c35a083-99d1-4a75-a8f5-b8574d7d24b5',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T18:00:00+00:00',
            endTime: '2023-12-22T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '568cf24e-c27a-43cd-a448-6f0c3ff2aa3b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T19:00:00+00:00',
            endTime: '2023-12-22T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ea0d3ced-e0a4-404e-bdff-c8ce5a447807',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T20:00:00+00:00',
            endTime: '2023-12-22T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '1d449b93-9972-4858-a85b-41f20a1d563b',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T21:00:00+00:00',
            endTime: '2023-12-22T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '55ffa106-3794-4f54-a2c3-0c873cdabf72',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-22T22:00:00+00:00',
            endTime: '2023-12-22T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b261f648-39b7-4479-92ab-675503fbad0b',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-26T15:00:00+00:00',
            endTime: '2023-12-26T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '38ffda4d-f4c0-4113-857a-8aefc7468ca7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-26T16:15:00+00:00',
            endTime: '2023-12-26T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e563fa1b-98a1-419a-b7b5-05716c09430f',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd4505564-b9db-4d91-8dc4-792c4fd26562',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-27T16:15:00+00:00',
            endTime: '2023-12-27T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '199cc158-06e0-4c8f-8cfa-69b51da0fe76',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '42f7d76f-90e8-48ae-be6b-8578700de045',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fe3dc3dd-7404-4ff1-bbba-4c4fbfbe8570',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd2624850-041a-4e1f-a116-78c1b045649a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '124200c1-d0a3-4c4a-961d-2febccb163ca',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-28T22:00:00+00:00',
            endTime: '2023-12-28T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '77fa8167-6d79-4720-bb2b-36642947c564',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '2cb0d6a6-2567-41ea-b061-8b854e1e0095',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T16:15:00+00:00',
            endTime: '2023-12-29T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4be7fbcd-56bf-4aea-ac3c-45fef443941c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '28239854-6951-4637-b09e-f2e17342c24e',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c9d4dd08-721a-4147-be62-a3ab6646d09f',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b0ef3912-a4a2-4f2b-87ed-1c19d424113a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '09d1d0ec-e241-482b-8938-0e003b1f05ab',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2023-12-29T22:00:00+00:00',
            endTime: '2023-12-29T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b4e23051-3a28-4aeb-8c96-8a7471c46b2d',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-02T15:00:00+00:00',
            endTime: '2024-01-02T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd5e72848-7a60-42ab-871e-049465e97cc7',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-02T16:15:00+00:00',
            endTime: '2024-01-02T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '08957b76-25bb-4c5c-9bc1-7f923eee1b03',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-03T15:00:00+00:00',
            endTime: '2024-01-03T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'dafae3ad-9d28-456a-91a2-ce3cab64a426',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-03T16:15:00+00:00',
            endTime: '2024-01-03T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '95bceacf-379a-4557-a5a0-d1294de3164a',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-04T18:00:00+00:00',
            endTime: '2024-01-04T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd5170386-c2d8-4724-a69d-1c86dfc484c9',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-04T19:00:00+00:00',
            endTime: '2024-01-04T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '04615901-76fe-4d6f-8fd8-f6347a24f7de',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-04T20:00:00+00:00',
            endTime: '2024-01-04T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '67b605a4-2042-4f70-948a-f760753cc691',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-04T21:00:00+00:00',
            endTime: '2024-01-04T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a2256b88-1b71-4d35-aaa7-847b6e2a1630',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-04T22:00:00+00:00',
            endTime: '2024-01-04T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '164698fb-f299-4a2e-ae4b-e68f99eea1de',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T15:00:00+00:00',
            endTime: '2024-01-05T16:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0baa899b-fada-4583-8ec1-ebbc1c9e7b2f',
            availabilityId: '3e539ad0-43f7-4973-b5dc-3999d6e8e2e9',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T16:15:00+00:00',
            endTime: '2024-01-05T17:15:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f039393c-ddc1-49b5-8b10-b01dd14da3e5',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T18:00:00+00:00',
            endTime: '2024-01-05T19:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd7d3eed2-2d1c-4441-b51b-92222e4cb170',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T19:00:00+00:00',
            endTime: '2024-01-05T20:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c8e0bbba-d931-4a1e-b913-4448ac04cd65',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T20:00:00+00:00',
            endTime: '2024-01-05T21:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ec71119b-c57f-4945-99ca-1457e0558c7c',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T21:00:00+00:00',
            endTime: '2024-01-05T22:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '173a17de-d378-4401-89b7-dfeeb28e5179',
            availabilityId: '3b3acac8-811d-4847-8f3d-c03a7bff9dd7',
            status: 'available',
            price: 50,
            serviceId: '0baa7f2b-8feb-4909-87d7-2f5a3e70c8b8',
            startTime: '2024-01-05T22:00:00+00:00',
            endTime: '2024-01-05T23:00:00+00:00',
            expertId: 'yghwuOKk6GdbfrcXUe2lnEpbEcJ3',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
    phone: '+34123123123',
    creation_date: '2023-10-23T15:15:47+00:00',
    birthday: '1978-05-08',
    name: 'Ruperto Fernandez',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Maestro clases particulares',
    education: 'U.A.B Barcelona',
    company: null,
    username: 'rupertoF',
    avatar: 'https://randomuser.me/api/portraits/men/99.jpg',
    email: 'ruperto.fernandez1994@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['onlineTutor'],
    languages: ['ES', 'EN', 'CA'],
    job: 'Teacher',
    provider: 'password',
    last_sign_in: '2023-10-23T15:15:47+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
        name: 'Clase Particular',
        price: 40,
        duration: 45,
        speciality: 'onlineTutor',
        format: 'call',
        description: '',
        expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
        keywords: ['idiomas', 'español', 'ingles'],
        time_slots: [
          {
            id: 'b4cc0dee-824f-4dfc-b488-37a73be0d18b',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-23T09:00:00+00:00',
            endTime: '2023-12-23T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '71c9699a-0b68-47cc-8bc9-803854e1296e',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-23T09:55:00+00:00',
            endTime: '2023-12-23T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '122abadc-c0c8-4c80-970f-ebef30c2a48e',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-24T09:00:00+00:00',
            endTime: '2023-12-24T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '22ebceb5-de16-45d7-9714-40925c5e1050',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-24T09:55:00+00:00',
            endTime: '2023-12-24T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '94a57a50-9051-4d76-ab02-53d2c9d3ebb7',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-30T09:00:00+00:00',
            endTime: '2023-12-30T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '55ff9ef1-4d93-4e68-a478-7e40d4617353',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-30T09:55:00+00:00',
            endTime: '2023-12-30T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '84ed9bbb-86d9-46a8-ace1-9e52ddc911af',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-31T09:00:00+00:00',
            endTime: '2023-12-31T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '69bc4f88-c4eb-4f6c-b8a0-b62c31872337',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2023-12-31T09:55:00+00:00',
            endTime: '2023-12-31T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c015ac50-caf4-4395-8aea-8b2ff88a9f9f',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2024-01-06T09:00:00+00:00',
            endTime: '2024-01-06T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6fd8781e-dbf8-4051-af63-c756549dc09b',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2024-01-06T09:55:00+00:00',
            endTime: '2024-01-06T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd4d88634-5a18-4a94-9cd2-ebc9e3adbc20',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2024-01-07T09:00:00+00:00',
            endTime: '2024-01-07T09:45:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '81a1d9c1-c2ea-45ab-b38c-70349923d18c',
            availabilityId: '7087b642-6f93-4eba-b21e-32e815ebf3e9',
            status: 'available',
            price: 40,
            serviceId: '6f0bf90b-7b9a-47aa-98ef-861a0f692a29',
            startTime: '2024-01-07T09:55:00+00:00',
            endTime: '2024-01-07T10:40:00+00:00',
            expertId: 'TSwZBF0NaJfK0nADWa3m2zX1mu83',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    phone: '+34 123 456 789',
    creation_date: '2023-10-25T12:54:39.901+00:00',
    birthday: '1987-04-10',
    name: 'Jorge Dobón',
    sex: 'Female',
    isMock: true,
    score: null,
    headline:
      'Fundó Demium y lo llevó a 8 países. Levantó un fondo de +50M€ e invirtió en +350 emprendedores.',
    education: 'Universidad de Cataluña',
    company: null,
    username: 'JorgeDobon',
    avatar:
      'https://framerusercontent.com/images/sNf0uLDo63JithCyCb90rdetxaU.png',
    email: 'jorge.dobon@demium.com',
    oral_coins: 150,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '169db476-080d-4354-a355-3ec347f5f11c',
        name: 'Property Consultation',
        price: 50,
        duration: 60,
        speciality: 'realState',
        format: 'video',
        description: 'Consultation on real estate properties.',
        expertId: '2',
        keywords: ['money'],
        time_slots: [
          {
            id: 'cc943c15-c879-4ec3-9e54-f647f26717b8',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-19T09:00:00+00:00',
            endTime: '2023-12-19T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b7a6df51-40e5-4058-8511-a96a3d60e255',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-19T10:15:00+00:00',
            endTime: '2023-12-19T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '33650338-e8ee-41c8-aa53-77075b1366c6',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-21T09:00:00+00:00',
            endTime: '2023-12-21T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '26403fa7-69eb-4cea-92fb-ec05139ae6ac',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-21T10:15:00+00:00',
            endTime: '2023-12-21T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '169cbb94-6bba-4c60-bb5c-a29a38896ef4',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-26T09:00:00+00:00',
            endTime: '2023-12-26T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3cfe1971-0220-4f0a-92a0-8c12892c8d07',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-26T10:15:00+00:00',
            endTime: '2023-12-26T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5a0a6938-d7a8-461d-a1cd-77751077d37b',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-28T09:00:00+00:00',
            endTime: '2023-12-28T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3a8ac660-efa6-4112-bf05-b9fbb8b12a56',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2023-12-28T10:15:00+00:00',
            endTime: '2023-12-28T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cec11261-9575-436b-8940-41c0e1621a41',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2024-01-02T09:00:00+00:00',
            endTime: '2024-01-02T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6c8dba2e-8b75-4773-9c67-8cefa7f7e2dc',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2024-01-02T10:15:00+00:00',
            endTime: '2024-01-02T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c5001991-5013-41cd-8c67-4c9207aa8836',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2024-01-04T09:00:00+00:00',
            endTime: '2024-01-04T10:00:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '829cab7a-80f4-4492-a18b-6754fec2dc90',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 50,
            serviceId: '169db476-080d-4354-a355-3ec347f5f11c',
            startTime: '2024-01-04T10:15:00+00:00',
            endTime: '2024-01-04T11:15:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
        name: 'Consulta molona',
        price: 25,
        duration: 45,
        speciality: 'realState',
        format: 'person',
        description: '',
        expertId: '2',
        keywords: ['backend', 'nutricion'],
        time_slots: [
          {
            id: '3d1c1cf8-d7c8-4738-9304-47ae9647c574',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-19T09:00:00+00:00',
            endTime: '2023-12-19T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'cba56317-4401-4733-a28b-dc3b991f5fe1',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-19T10:00:00+00:00',
            endTime: '2023-12-19T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '616ec8a8-8b76-44d5-8b64-f5acaddf2c8f',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-19T11:00:00+00:00',
            endTime: '2023-12-19T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ad551158-aae6-4410-910c-d8a56519dc3f',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-21T09:00:00+00:00',
            endTime: '2023-12-21T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ee8b279e-ce9a-4e51-aa97-45c2c276ea2a',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-21T10:00:00+00:00',
            endTime: '2023-12-21T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'de903cbf-875b-45d7-a94f-b49ec47ba18c',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-21T11:00:00+00:00',
            endTime: '2023-12-21T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '30f3ddc7-12b1-468a-9a55-1922078b1bbc',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-26T09:00:00+00:00',
            endTime: '2023-12-26T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5ae0034f-716d-41a5-ad10-d9d508510f10',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-26T10:00:00+00:00',
            endTime: '2023-12-26T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'bc0f6809-8d7e-42c6-a86e-56f459afca1f',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-26T11:00:00+00:00',
            endTime: '2023-12-26T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '13b3a055-9890-4dcb-922b-89ee757411a7',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-28T09:00:00+00:00',
            endTime: '2023-12-28T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '12b03879-995b-472c-bfba-6946963a40bb',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-28T10:00:00+00:00',
            endTime: '2023-12-28T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '451c9837-a7dc-404f-9d53-d9da5e12ef3a',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2023-12-28T11:00:00+00:00',
            endTime: '2023-12-28T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0eca6633-8d7a-4d91-bf5d-979cf9421f15',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-02T09:00:00+00:00',
            endTime: '2024-01-02T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '5328ef69-d013-43e9-abbb-4f9f417bd450',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-02T10:00:00+00:00',
            endTime: '2024-01-02T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '53b579ac-1e3c-436d-a851-7f1464657fbe',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-02T11:00:00+00:00',
            endTime: '2024-01-02T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '11bcde7e-c7cc-4251-acbf-30d5a240e620',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-04T09:00:00+00:00',
            endTime: '2024-01-04T09:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4103b610-bdca-474b-be30-52ed7b91fdfc',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-04T10:00:00+00:00',
            endTime: '2024-01-04T10:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a37ca360-372c-4c8b-8d8b-bf69ca279b2c',
            availabilityId: '08b9ea22-2ad5-46fe-88d1-b4894e1eccf6',
            status: 'available',
            price: 25,
            serviceId: '1c81e607-75f2-4fcb-b94e-a9135c3809a5',
            startTime: '2024-01-04T11:00:00+00:00',
            endTime: '2024-01-04T11:45:00+00:00',
            expertId: '2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '8',
    phone: '555-666-7777',
    creation_date: '2023-08-15T10:30:20.654+00:00',
    birthday: '1982-04-25',
    name: 'William Taylor',
    sex: 'Male',
    isMock: true,
    score: null,
    headline: 'Real Estate Specialist',
    education: 'B.S. in Real Estate Finance',
    company: null,
    username: 'WilliamTRealState',
    avatar: 'https://randomuser.me/api/portraits/men/78.jpg',
    email: 'williamtaylor@example.com',
    oral_coins: 130,
    is_connected: false,
    email_verified: true,
    phone_verified: true,
    role: 'Expert',
    speciality: ['realState', 'listener'],
    languages: null,
    job: null,
    provider: null,
    last_sign_in: null,
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
        name: 'Real Estate Consultation',
        price: 55,
        duration: 60,
        speciality: 'realState',
        format: 'video',
        description: 'Consultation on real estate properties.',
        expertId: '8',
        keywords: null,
        time_slots: [
          {
            id: '59dea456-9dbf-44da-a4c6-2e29fddda0b7',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2023-12-18T08:30:00+00:00',
            endTime: '2023-12-18T09:30:00+00:00',
            expertId: '8',
            creationDate: null,
          },
          {
            id: '24443e88-c9da-4e52-9634-99dd27128eeb',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2023-12-18T09:30:00+00:00',
            endTime: '2023-12-18T10:30:00+00:00',
            expertId: '8',
            creationDate: null,
          },
          {
            id: 'e505e84c-7e15-4766-90aa-0f53ee454fc4',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2023-12-18T10:30:00+00:00',
            endTime: '2023-12-18T11:30:00+00:00',
            expertId: '8',
            creationDate: null,
          },
          {
            id: '5b609a58-36ef-4b94-85f3-585c2d0565f6',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2023-12-27T09:30:00+00:00',
            endTime: '2023-12-27T10:30:00+00:00',
            expertId: '8',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e81696be-27b9-426b-897f-1429a4a0f4ff',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2023-12-27T10:45:00+00:00',
            endTime: '2023-12-27T11:45:00+00:00',
            expertId: '8',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '227bc8c5-7eeb-4f6b-b7e2-7aae9f58034c',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2024-01-01T09:30:00+00:00',
            endTime: '2024-01-01T10:30:00+00:00',
            expertId: '8',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e40e6b49-5194-4914-b5ae-91072cb702d2',
            availabilityId: 'b185cce8-6a05-43fb-b7fd-82e8072cc1ea',
            status: 'available',
            price: 55,
            serviceId: '1f55a05f-cb8c-4685-a7c2-fc109e51cd12',
            startTime: '2024-01-01T10:45:00+00:00',
            endTime: '2024-01-01T11:45:00+00:00',
            expertId: '8',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
    phone: '',
    creation_date: '2023-10-24T14:06:33+00:00',
    birthday: '1998-04-09',
    name: 'Fernando Lopez',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Content Creator',
    education: 'Univerdad Autonoma de Madrid',
    company: null,
    username: 'fernandoLinfluencer',
    avatar: 'https://randomuser.me/api/portraits/men/52.jpg',
    email: 'fernando.lopez98@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['influencer', 'personalImage'],
    languages: ['ES', 'EN', 'FR'],
    job: 'Content Creator',
    provider: 'password',
    last_sign_in: '2023-10-24T14:22:24+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
        name: 'Masterclass Redes',
        price: 25,
        duration: 30,
        speciality: 'influencer',
        format: 'video',
        description: '',
        expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
        keywords: [],
        time_slots: [
          {
            id: 'e47dabff-b9c9-4197-94a7-4ac1f7b2b926',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T08:00:00+00:00',
            endTime: '2023-12-18T08:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '1e3972c3-bc05-45b4-a994-37e1e600cd0b',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T08:30:00+00:00',
            endTime: '2023-12-18T09:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '51e42712-fbd4-4b95-a521-4fa72b94deac',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T09:00:00+00:00',
            endTime: '2023-12-18T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'c083a047-08e9-4fb3-aae5-70f748ecab64',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T09:30:00+00:00',
            endTime: '2023-12-18T10:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'ce346e53-aeb5-49a8-afe1-ff3d66cbfa5e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T10:00:00+00:00',
            endTime: '2023-12-18T10:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'cb7eec22-e587-476e-88ce-7241fdfa8aa4',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T10:30:00+00:00',
            endTime: '2023-12-18T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'c9346908-1b1f-4e64-a972-57ff938ec788',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T11:00:00+00:00',
            endTime: '2023-12-18T11:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'f516043e-4095-4031-b4f5-5c6b3d9a2e00',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-18T11:30:00+00:00',
            endTime: '2023-12-18T12:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'b2155c75-b363-4dc2-9dea-00ec0c044589',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-26T09:00:00+00:00',
            endTime: '2023-12-26T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '9e291e38-038c-43e5-9222-14d20a39c3c2',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-26T09:45:00+00:00',
            endTime: '2023-12-26T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8c981e5c-c9a9-44f8-bd39-b7c2d61a525e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-26T10:30:00+00:00',
            endTime: '2023-12-26T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '9e7bd6df-5217-4772-89bf-40a5f78b9adb',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-26T11:15:00+00:00',
            endTime: '2023-12-26T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd41d1c06-5feb-4092-bdef-93e225651286',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-26T12:00:00+00:00',
            endTime: '2023-12-26T12:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7a625528-5479-489d-80d5-e839d7760cbd',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-28T09:00:00+00:00',
            endTime: '2023-12-28T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f82a9ef3-2641-412f-a600-cf71e5f364fe',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-28T09:45:00+00:00',
            endTime: '2023-12-28T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'bbc440c4-a427-4b3f-a33e-1bebcc0c01c4',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-28T10:30:00+00:00',
            endTime: '2023-12-28T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '457d7fa8-33e2-4c89-90ed-72bb24908e29',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-28T11:15:00+00:00',
            endTime: '2023-12-28T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd3a90613-b926-4478-9407-d984edd3903a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-28T12:00:00+00:00',
            endTime: '2023-12-28T12:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ccff38ae-7995-4d70-8414-b70c7013d075',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-29T09:00:00+00:00',
            endTime: '2023-12-29T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '19f78ecf-a140-4efd-9e42-476cf565bedb',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-29T09:45:00+00:00',
            endTime: '2023-12-29T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '50d4f195-7f54-4917-a7d0-b0af15e21b73',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-29T10:30:00+00:00',
            endTime: '2023-12-29T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5321525f-e11a-4b32-8381-587ec7b4d44d',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-29T11:15:00+00:00',
            endTime: '2023-12-29T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4d553ea8-3afb-45c8-b0f5-d8e21eca2751',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2023-12-29T12:00:00+00:00',
            endTime: '2023-12-29T12:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '36efa61c-663a-4633-88b1-18946669f121',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2024-01-01T09:00:00+00:00',
            endTime: '2024-01-01T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5aa47dfb-3d04-4a1e-8141-bdeb8fdf834f',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2024-01-01T09:45:00+00:00',
            endTime: '2024-01-01T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cd562168-df9f-4ef7-bd31-31572759fd25',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2024-01-01T10:30:00+00:00',
            endTime: '2024-01-01T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ec3d0184-1c6e-4800-aa31-cddf72b6d6e2',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2024-01-01T11:15:00+00:00',
            endTime: '2024-01-01T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5ae62248-0df2-490e-9e28-18f82f6ef7bf',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 25,
            serviceId: 'c6bf6025-3ae8-43cd-b349-5634441fa42e',
            startTime: '2024-01-01T12:00:00+00:00',
            endTime: '2024-01-01T12:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: '2c22d3b4-c611-4870-9db4-23339f65031f',
        name: 'Consulta puntual',
        price: 100,
        duration: 15,
        speciality: 'personalImage',
        format: 'call',
        description: null,
        expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
        keywords: [],
        time_slots: [
          {
            id: '1beff56c-1446-4476-898c-690e53124a55',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T08:00:00+00:00',
            endTime: '2023-12-18T08:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '4c27b04b-2b1f-4820-acf8-0783b1061de9',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T08:15:00+00:00',
            endTime: '2023-12-18T08:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '80784ccd-a4d5-4592-9583-2e6be74dc58a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T08:30:00+00:00',
            endTime: '2023-12-18T08:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'b7639c00-0c86-464c-a5b3-e1030e61c3c0',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T08:45:00+00:00',
            endTime: '2023-12-18T09:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '4b80faae-3471-44c1-97e8-2e5ee8ca3de6',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T09:00:00+00:00',
            endTime: '2023-12-18T09:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'a699033e-ab8b-4564-8917-afde0f50b4e7',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T09:15:00+00:00',
            endTime: '2023-12-18T09:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'b54fc49f-ff7d-46a4-bff6-25e456084c16',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T09:30:00+00:00',
            endTime: '2023-12-18T09:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '721c5562-2f23-4b7b-9a30-85e853781b11',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T09:45:00+00:00',
            endTime: '2023-12-18T10:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '35449a8d-7516-4037-a3d4-d3a9cfb3a1af',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T10:00:00+00:00',
            endTime: '2023-12-18T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '0d1f021f-5426-49d7-aed1-271672cb652a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T10:15:00+00:00',
            endTime: '2023-12-18T10:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '29a15ba6-0eb7-41e2-8605-346ecafd1f64',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T10:30:00+00:00',
            endTime: '2023-12-18T10:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '16e096f6-adec-4ebc-a9a1-7e38c4e58a63',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T10:45:00+00:00',
            endTime: '2023-12-18T11:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '4fa888f1-33f5-45ef-972d-e0c6b645287f',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T11:00:00+00:00',
            endTime: '2023-12-18T11:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '00986f66-0e57-476f-a50e-37211a95c152',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T11:15:00+00:00',
            endTime: '2023-12-18T11:30:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'a0501909-4c80-4d68-bbfd-2d0d8166ee20',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T11:30:00+00:00',
            endTime: '2023-12-18T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: '3a8f2a23-1307-4a70-b74f-389247049fcb',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-18T11:45:00+00:00',
            endTime: '2023-12-18T12:00:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: null,
          },
          {
            id: 'f756aa03-f387-41f2-adff-c01b1ad53ab5',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T09:00:00+00:00',
            endTime: '2023-12-26T09:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7fce5891-5193-4789-a0c7-e2d8dae47d2e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T09:30:00+00:00',
            endTime: '2023-12-26T09:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'eb5f5f67-7085-456b-84fb-2e6759a0af0a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T10:00:00+00:00',
            endTime: '2023-12-26T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '32b05412-7dac-4eed-abee-88246436deff',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T10:30:00+00:00',
            endTime: '2023-12-26T10:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dd8c85c9-e79b-4dee-8e75-d0366a14351e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T11:00:00+00:00',
            endTime: '2023-12-26T11:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0052b5f0-5f75-4caf-9fa9-a3cd138b5c9f',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T11:30:00+00:00',
            endTime: '2023-12-26T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fefb14b9-0fcf-4b70-8f9e-a9fb61f913c6',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T12:00:00+00:00',
            endTime: '2023-12-26T12:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c28d4899-33b3-46e0-9744-4bd99390dfab',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-26T12:30:00+00:00',
            endTime: '2023-12-26T12:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '69e4faee-2a96-4b40-a603-55bedc7c1c20',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T09:00:00+00:00',
            endTime: '2023-12-28T09:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '21e3c040-74f8-4051-b2b3-aa505d689615',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T09:30:00+00:00',
            endTime: '2023-12-28T09:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3b476eed-8839-48e7-b03c-ba51c1e60f67',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T10:00:00+00:00',
            endTime: '2023-12-28T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6096a946-4a1c-491b-b5e3-eb6d494d671c',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T10:30:00+00:00',
            endTime: '2023-12-28T10:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '92321a93-1994-4c72-8690-1f06fccbc66e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T11:00:00+00:00',
            endTime: '2023-12-28T11:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '754f9785-5a90-42c6-9ec7-9915efb2f3f6',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T11:30:00+00:00',
            endTime: '2023-12-28T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fcbbf28e-2e56-4949-97e0-bb1e65d1b49d',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T12:00:00+00:00',
            endTime: '2023-12-28T12:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '534a61eb-1d04-472c-b051-0d66ef03501e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-28T12:30:00+00:00',
            endTime: '2023-12-28T12:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ca2cd3a5-17d0-445c-8faf-de20288afc65',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T09:00:00+00:00',
            endTime: '2023-12-29T09:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '53b3e728-7da9-4f5f-9bde-8698b3d4601a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T09:30:00+00:00',
            endTime: '2023-12-29T09:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e802ddbf-7d51-4e35-911e-770c8ca9a065',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T10:00:00+00:00',
            endTime: '2023-12-29T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8a298c82-e77c-480b-8d6e-5a17cf6febcc',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T10:30:00+00:00',
            endTime: '2023-12-29T10:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '93434aa4-2bab-4b45-91ad-26758e54832e',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T11:00:00+00:00',
            endTime: '2023-12-29T11:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '283c49a2-905e-4cc7-847e-13d7a386027c',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T11:30:00+00:00',
            endTime: '2023-12-29T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '710cce4d-4c86-4a93-b52f-9cce29727069',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T12:00:00+00:00',
            endTime: '2023-12-29T12:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dc88a0e1-5027-4422-9d04-6edaba97ec24',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2023-12-29T12:30:00+00:00',
            endTime: '2023-12-29T12:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0eca7335-1490-4f2e-b02e-2f889ced0e9f',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T09:00:00+00:00',
            endTime: '2024-01-01T09:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '65599132-744e-4766-834b-566dc36aafbe',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T09:30:00+00:00',
            endTime: '2024-01-01T09:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '031656c0-8826-4567-ac67-1130446a56e9',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T10:00:00+00:00',
            endTime: '2024-01-01T10:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd9535e47-f4f0-4a1e-a65c-ccabef18324d',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T10:30:00+00:00',
            endTime: '2024-01-01T10:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4d2c3b3d-5704-4ef3-9fdb-8f707fef7b1a',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T11:00:00+00:00',
            endTime: '2024-01-01T11:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8fa79534-d7b3-4ede-8cd9-743b073edefd',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T11:30:00+00:00',
            endTime: '2024-01-01T11:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '740216dc-adf7-48b2-add6-3c779b8ec545',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T12:00:00+00:00',
            endTime: '2024-01-01T12:15:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '84becafe-8800-4880-9fdc-25956ddeb03d',
            availabilityId: 'ef90b591-f880-45d0-ad2e-26c8f2cded21',
            status: 'available',
            price: 100,
            serviceId: '2c22d3b4-c611-4870-9db4-23339f65031f',
            startTime: '2024-01-01T12:30:00+00:00',
            endTime: '2024-01-01T12:45:00+00:00',
            expertId: '5PEpC6vasWXPy31uVDdvpFkRbLU2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
    phone: '688916845',
    creation_date: '2023-10-24T15:49:43+00:00',
    birthday: '1992-09-12',
    name: 'Marc Molins',
    sex: 'Male',
    isMock: false,
    score: null,
    headline:
      'Te ayudo a crecer la rentabilidad de tu negocio con reporting y 8 palancas de crecimiento.',
    education: 'UPC',
    company: null,
    username: 'marcM',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2Fmxmolins?alt=media&token=225f9bcd-9d8b-4cb6-8776-5886ff1defcd',
    email: 'mxmolins@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['startupAdvisor'],
    languages: ['ES', 'CA', 'EN'],
    job: 'Growth Expert - Product Manager',
    provider: 'password',
    last_sign_in: '2023-10-24T15:49:43+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
        name: 'Análisis en Video',
        price: 90,
        duration: 45,
        speciality: 'startupAdvisor',
        format: 'video',
        description: null,
        expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
        keywords: ['tecnología'],
        time_slots: [
          {
            id: 'd095440b-7c48-4d2d-8df7-6e21b4075cd3',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '67c62d04-53f0-4a1d-822a-660b87a040cf',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'ca59f235-6cbc-4b59-8128-2de7f89ba57d',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T17:30:00+00:00',
            endTime: '2023-12-18T18:15:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '548227c7-df29-42d8-a027-a1c336a491ff',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T18:15:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '42c07c6a-08cd-4273-a387-1700cece651d',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T19:00:00+00:00',
            endTime: '2023-12-18T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '1f754bef-a4ec-4a67-8255-c410ca08787e',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-18T19:45:00+00:00',
            endTime: '2023-12-18T20:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '0a937756-b488-4baa-b6c0-91a621cf7802',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0b7abd73-fff4-4355-ac4e-cf32a1ed7a52',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-26T18:00:00+00:00',
            endTime: '2023-12-26T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b03457c8-bcc0-43ac-a471-43cb772705e8',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-26T19:00:00+00:00',
            endTime: '2023-12-26T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '73a300c8-3e38-46a4-a066-b8581d0f61da',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-26T20:00:00+00:00',
            endTime: '2023-12-26T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'a99fab17-093b-4bc7-8b7a-881f4bff49dd',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-26T21:00:00+00:00',
            endTime: '2023-12-26T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5db85876-bc84-42f3-a174-8ff4947dc0ec',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '53a04a7e-d3b4-46a3-a516-13cb616124bb',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-27T18:00:00+00:00',
            endTime: '2023-12-27T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cca41539-4bf5-40ee-a1b4-9beacfc0adbe',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-27T19:00:00+00:00',
            endTime: '2023-12-27T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '28a0333d-1a99-4215-b89a-0511bbb476d5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-27T20:00:00+00:00',
            endTime: '2023-12-27T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ffea67d4-8102-4a57-9c90-665899b0ee75',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-27T21:00:00+00:00',
            endTime: '2023-12-27T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7345e34f-503b-48e5-a23c-03d5f310ce4c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '84e75e91-4dea-41d1-be99-9cc1a78043a5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ba370f51-9b77-4431-bd96-c7300fa6258f',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'bb5eb4f1-c4c1-4073-b0a9-f5c3a20c3c7d',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '09cd29aa-6784-4164-8f48-eddd948fb760',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '45f96600-b9fb-4970-9bf2-320826dd0eb5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6f7cf5a6-4735-40c6-9fbf-64c523e9de91',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '458e472b-42db-4b62-8016-ba34c762765b',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '49fd0055-7cda-4b57-a408-20cebbdc532a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '09fc81c7-0e40-4a56-9a69-916ff067b2e4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '69a0a9ce-522d-438b-b649-671ce6269b86',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c7f6d8f2-0ed2-4af4-8233-bfa992c59149',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2024-01-01T18:00:00+00:00',
            endTime: '2024-01-01T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ed13e4d8-882d-4d6a-98b5-3b95d3b5903e',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2024-01-01T19:00:00+00:00',
            endTime: '2024-01-01T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ebc4167c-ca60-4d2e-b2a7-057d5fb468f2',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2024-01-01T20:00:00+00:00',
            endTime: '2024-01-01T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'be1c2164-67a7-482f-93ea-bd9ac53fe3b4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: '5d66a19f-5145-4fb9-abf5-c7801eba39be',
            startTime: '2024-01-01T21:00:00+00:00',
            endTime: '2024-01-01T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
        name: 'Análisis en Video',
        price: 90,
        duration: 45,
        speciality: 'startupAdvisor',
        format: 'video',
        description: null,
        expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
        keywords: ['tecnología'],
        time_slots: [
          {
            id: 'b7c78586-5cbf-424a-b280-02cc0d4c43b7',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '0818da4f-42b2-46af-8f6b-699767972a0f',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '73e3f184-b05f-42c2-8133-76bb156928db',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T17:30:00+00:00',
            endTime: '2023-12-18T18:15:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '735f383b-5e76-4751-af86-00ed10214487',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T18:15:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '88b516da-1fc5-411c-b3ba-b3eb22d41006',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T19:00:00+00:00',
            endTime: '2023-12-18T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '4bc9bc6a-f0d1-4c74-84ea-ddc18f2350f4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-18T19:45:00+00:00',
            endTime: '2023-12-18T20:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'ea5a9782-9267-44c5-896a-7d7d94c7e052',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1634b901-80ef-49a2-a49a-a22ab98ed44c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-26T18:00:00+00:00',
            endTime: '2023-12-26T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '66227806-fa67-41fa-8e4f-2c099faeb38b',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-26T19:00:00+00:00',
            endTime: '2023-12-26T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '50238465-a8b8-42d6-9e0e-18e369bd7855',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-26T20:00:00+00:00',
            endTime: '2023-12-26T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '12ccb2d5-9ed4-4b45-be1a-12a946cd6141',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-26T21:00:00+00:00',
            endTime: '2023-12-26T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '96002f75-ffc6-43e0-adb2-aec6bb1d3c6d',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '785fe472-e5c6-4939-a2c2-21d2198c49ba',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-27T18:00:00+00:00',
            endTime: '2023-12-27T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '9bbeb266-b4db-4313-8255-1baf82b28882',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-27T19:00:00+00:00',
            endTime: '2023-12-27T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5b7b603c-e6ac-4d5f-a0b0-34931d234eee',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-27T20:00:00+00:00',
            endTime: '2023-12-27T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '01162bd2-5d0e-45ff-9c40-023d6169a7ac',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-27T21:00:00+00:00',
            endTime: '2023-12-27T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '358f08a0-3a5d-406f-bdd9-e9c2cbf218c9',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '172c49ea-5c65-4d89-bae8-a9f668ff8174',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e2c0d188-5090-497a-a19e-e5368ed3f89f',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '916600b1-007c-4e54-ac9d-668876aed53c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ba582495-200e-419b-b77b-d3f6f17c3c9d',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4ef6d4a7-62b2-470b-a157-b8ed56b21d2a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c18c2d99-9299-4d04-a9cc-7ea2ad663da6',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'a39ca9b0-8742-4bc4-80b5-51f097aaf694',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'de87a978-9cef-4d66-baf5-21a75d130334',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd0d74eb0-ec82-47da-8117-0320c3f6db30',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cb91f538-fc47-421c-abd1-5d51b8c51691',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '77c85b9c-5d5c-41b5-a5a1-fec994077fb0',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2024-01-01T18:00:00+00:00',
            endTime: '2024-01-01T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c1e5ce6a-2f82-4641-abe9-2c0da87290b4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2024-01-01T19:00:00+00:00',
            endTime: '2024-01-01T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd4d02a00-ec9f-439b-a8a8-0bbfbe1929df',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2024-01-01T20:00:00+00:00',
            endTime: '2024-01-01T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ea735ff1-49bc-42f9-a46f-9b90d64572f4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 90,
            serviceId: 'ed872139-7c4c-44bf-bbcf-d607da852dba',
            startTime: '2024-01-01T21:00:00+00:00',
            endTime: '2024-01-01T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
        name: 'Análisis en Video',
        price: 120,
        duration: 45,
        speciality: 'startupAdvisor',
        format: 'video',
        description: null,
        expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
        keywords: ['tecnología'],
        time_slots: [
          {
            id: '160adaf3-bd8f-4ce1-b39f-a18aac5959d4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'd6d8d10c-7d10-42cc-b854-78358870badc',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'f9b8e4ea-2e6c-41e7-ba68-5d4ca4c81c52',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T17:30:00+00:00',
            endTime: '2023-12-18T18:15:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'fa1828d6-cc12-4cdf-abea-2d4f8e6475fc',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T18:15:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '0f139e87-5d65-417c-8fc8-f02c2d88cd93',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T19:00:00+00:00',
            endTime: '2023-12-18T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '6080149b-e522-421e-adf0-8dfe64d0aa67',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-18T19:45:00+00:00',
            endTime: '2023-12-18T20:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'f3dd3b75-d4ef-43d7-be7a-a30f266f392a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3445a29f-3246-4490-a060-e000d54e7488',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-26T18:00:00+00:00',
            endTime: '2023-12-26T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '23389d33-c092-4e12-84e3-ee6b06071637',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-26T19:00:00+00:00',
            endTime: '2023-12-26T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '54556706-481b-4df8-900c-ef734674f8db',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-26T20:00:00+00:00',
            endTime: '2023-12-26T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6f702833-7fa5-4871-aefe-122b2a5a8483',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-26T21:00:00+00:00',
            endTime: '2023-12-26T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e781b02c-008e-4c18-9f48-f86f42bfaa23',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b27d8b6f-3ef9-4736-9441-bce70b045c9a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-27T18:00:00+00:00',
            endTime: '2023-12-27T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6f011443-4581-4b7c-aca6-662db1b09d11',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-27T19:00:00+00:00',
            endTime: '2023-12-27T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4a731755-0ce5-45c6-b866-203be169ef0b',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-27T20:00:00+00:00',
            endTime: '2023-12-27T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'cd1d2d02-f127-48fd-8a3e-6701e1ec64b4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-27T21:00:00+00:00',
            endTime: '2023-12-27T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8435a340-c84b-4d61-9bd1-75be5e5b9c86',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1a969a2f-ea25-44a9-8f49-280198bdb077',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8fd1d628-dffd-499a-8c5d-d94c6b88e447',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6700ee43-7f88-4ee7-ac0c-68a383bd9a03',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c1ea6874-9a03-4a25-863d-920a8380268a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '40d5c46e-3b72-415d-8e11-0f8846b58450',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1988bdc6-ec2a-4641-8994-ad97aeafc0a4',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5f499cbe-a2dc-46df-8ed4-a07aa417bd36',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '9b2fa4f5-d91a-4a39-9147-244601f2b17a',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ee645d06-6e11-4b93-ae17-57ccf443349c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8ef7d465-5ad7-4951-b61b-09e798f8bc74',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7fd070bd-175a-4d4d-8dfa-b0a34a12d485',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2024-01-01T18:00:00+00:00',
            endTime: '2024-01-01T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8b86f85c-6e42-4852-aec7-90f50c169ce6',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2024-01-01T19:00:00+00:00',
            endTime: '2024-01-01T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '33b19bdb-1d27-48a2-9d47-02afb5fdf479',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2024-01-01T20:00:00+00:00',
            endTime: '2024-01-01T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3673a91f-1294-4767-baf2-d333cad93a97',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: '16bc01ca-c145-47ca-8ff3-e3f7b5c3e454',
            startTime: '2024-01-01T21:00:00+00:00',
            endTime: '2024-01-01T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
        name: 'Análisis en Video',
        price: 120,
        duration: 45,
        speciality: 'startupAdvisor',
        format: 'video',
        description: null,
        expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
        keywords: ['tecnología'],
        time_slots: [
          {
            id: '846dc06b-1ff0-40eb-b416-a2c15f6c1795',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '8c3653d0-f314-4228-9679-3352ebbef6bc',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'e3178677-dbdc-4001-b75f-4ceb25d455db',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T17:30:00+00:00',
            endTime: '2023-12-18T18:15:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: 'a3c88e57-f9c1-41a2-80dc-f61f5d25ddeb',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T18:15:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '49576d53-426c-4c57-a371-6172e5d58965',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T19:00:00+00:00',
            endTime: '2023-12-18T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '5f682439-050b-4848-a90d-997f841e51d5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-18T19:45:00+00:00',
            endTime: '2023-12-18T20:30:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: null,
          },
          {
            id: '550fccc9-236a-443d-982f-29436c30ccd6',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '465011dd-5e9e-4ce9-840e-8b26ed02773c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-26T18:00:00+00:00',
            endTime: '2023-12-26T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '29018b01-4e37-492b-b60c-e41d13a683be',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-26T19:00:00+00:00',
            endTime: '2023-12-26T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '97375b07-861c-4042-99ba-d9d9d975ac7f',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-26T20:00:00+00:00',
            endTime: '2023-12-26T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '148b6388-2f64-4751-b41b-b2dcea80a330',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-26T21:00:00+00:00',
            endTime: '2023-12-26T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'eed333af-65e9-4d06-9ed0-217dc3fad85c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b96c2b76-36c9-4f43-8bef-3b55cae5642c',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-27T18:00:00+00:00',
            endTime: '2023-12-27T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '06a516c5-be99-427c-ab53-d4adc00aa098',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-27T19:00:00+00:00',
            endTime: '2023-12-27T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'a120608a-77aa-40cd-9b73-856c0627d7de',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-27T20:00:00+00:00',
            endTime: '2023-12-27T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'c7770683-9132-4743-bdd2-79d93941a012',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-27T21:00:00+00:00',
            endTime: '2023-12-27T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '16812403-9d3f-4717-a955-f9ae9c0730b5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '23fe3539-8b57-4aa9-bc64-b8d38dff98e5',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4286cad1-d999-4177-8e29-e3e0111ecb08',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-28T19:00:00+00:00',
            endTime: '2023-12-28T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '4cf8b3cd-57d5-4928-b58d-9def9a1e2f88',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-28T20:00:00+00:00',
            endTime: '2023-12-28T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'de98a279-b4dd-480d-bcd6-1d20ae39ea32',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b358128b-7046-4d02-a511-6d214366b7e7',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6033b48b-7b2c-41a9-b381-17613c1b6f90',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-29T18:00:00+00:00',
            endTime: '2023-12-29T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '458076b3-941b-4523-aa73-b8af07337642',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-29T19:00:00+00:00',
            endTime: '2023-12-29T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '9b76a35b-cfcf-4036-b8ce-4dc8af4b4248',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-29T20:00:00+00:00',
            endTime: '2023-12-29T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '774cdb36-d4a4-49bc-aef9-24db1491ef09',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2023-12-29T21:00:00+00:00',
            endTime: '2023-12-29T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f527ab73-c38f-4e86-ae04-75b4a8418bd1',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b1d31898-c670-4cbe-98bc-0be2fd177dde',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2024-01-01T18:00:00+00:00',
            endTime: '2024-01-01T18:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0b76fa33-e56b-4b08-955f-c46c3d6203fe',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2024-01-01T19:00:00+00:00',
            endTime: '2024-01-01T19:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7577ec9b-dbae-43a4-9af6-8366091ed6db',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2024-01-01T20:00:00+00:00',
            endTime: '2024-01-01T20:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '276e3d22-9644-4c69-a7c0-ea379d2e9bd3',
            availabilityId: '5847aa32-2e07-4f02-8d2f-f5992646417a',
            status: 'available',
            price: 120,
            serviceId: 'affee380-8e09-4266-8cbb-ffbc756a8f7e',
            startTime: '2024-01-01T21:00:00+00:00',
            endTime: '2024-01-01T21:45:00+00:00',
            expertId: 'KLiKtimoRtcWcSoD2mtARqrOYN82',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
    phone: '+34914853123',
    creation_date: '2023-10-24T14:34:20+00:00',
    birthday: '1995-05-08',
    name: 'Pruebas JWT',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Traductor de Inglés',
    education: 'Univerdad Autonoma de Madrid',
    company: null,
    username: 'pruebasJlanguage',
    avatar: 'https://randomuser.me/api/portraits/men/42.jpg',
    email: 'pruebasjwt@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['language', 'influencer'],
    languages: ['ES', 'EN', 'FR'],
    job: 'English Teacher',
    provider: 'password',
    last_sign_in: '2023-10-25T11:01:31+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '641802f0-f547-47f0-b61c-daf976a0e04c',
        name: 'Clase Twitch',
        price: 35,
        duration: 60,
        speciality: 'influencer',
        format: 'video',
        description: '',
        expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
        keywords: [],
        time_slots: [
          {
            id: 'c884cb40-abac-435e-bb2e-7676cf95a4de',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: '9cf209f3-6968-4eb4-a891-2e23fa2e1e44',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: '10aac385-7b4f-4919-953c-312f29761e96',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-18T17:00:00+00:00',
            endTime: '2023-12-18T18:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: 'aa3c8e87-0f62-4cb6-9cbb-b1e6763d3ba1',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-18T18:00:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: 'd3d1d957-c207-4513-abd4-608fe75220f6',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-26T16:00:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '2731e9d5-20b4-4ee3-b830-9c2d7a924a8d',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '35f9eb8b-2ea9-448f-8622-afe70658245b',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-26T18:30:00+00:00',
            endTime: '2023-12-26T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '25e3bd1a-8238-4fc1-aa99-c7fec1094ab9',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-28T16:00:00+00:00',
            endTime: '2023-12-28T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '90fd9b60-3685-40d0-9808-ec32f3fdb91f',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-28T17:15:00+00:00',
            endTime: '2023-12-28T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'fd441274-946f-4bd9-89c8-bc19063faab9',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-28T18:30:00+00:00',
            endTime: '2023-12-28T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'f778c1c5-d9d6-40c4-9d46-b751f9cb859d',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd0edff8c-ffd8-4d42-ad0e-b21f553fe9e1',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '65d24d5f-a9eb-4a01-884b-907dab2ffa21',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2023-12-29T18:30:00+00:00',
            endTime: '2023-12-29T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '37748352-3624-4120-8f93-46bb1b1e396a',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5e07c910-9848-42f7-b338-c46fc603d2eb',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2024-01-01T17:15:00+00:00',
            endTime: '2024-01-01T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '40cb7f51-8451-47e1-9474-82b7f2b4d6e3',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 35,
            serviceId: '641802f0-f547-47f0-b61c-daf976a0e04c',
            startTime: '2024-01-01T18:30:00+00:00',
            endTime: '2024-01-01T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
        name: 'Sesión de video',
        price: 69,
        duration: 60,
        speciality: 'language',
        format: 'video',
        description: null,
        expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
        keywords: ['inglés', 'pronunciación', 'gramática'],
        time_slots: [
          {
            id: 'f2bc62a0-108e-4248-8ff1-28198deed2c9',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: 'fe3183b3-8a35-40bc-baeb-a3f170c94599',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: 'f632e446-fcda-4476-89b2-1d026b3b4583',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-18T17:00:00+00:00',
            endTime: '2023-12-18T18:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: 'b8809a26-d254-4cd0-a7c4-770037f2dc6b',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-18T18:00:00+00:00',
            endTime: '2023-12-18T19:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: null,
          },
          {
            id: '46713538-cb15-461e-b546-00fcd72ea8a8',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-26T16:00:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'a8279009-88ce-490e-a3ec-78c858593c55',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'db1617dd-ef8b-4745-8d27-7d2dc8e88a78',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-26T18:30:00+00:00',
            endTime: '2023-12-26T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b652ea7b-11d4-4f7e-8b5c-ea85400a6c64',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-28T16:00:00+00:00',
            endTime: '2023-12-28T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd75a8242-bd09-40f1-8ee2-d62071a518af',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-28T17:15:00+00:00',
            endTime: '2023-12-28T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '7463b849-e680-474c-9ec9-7b86af011aa0',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-28T18:30:00+00:00',
            endTime: '2023-12-28T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '0a8b74e4-04f1-4cc6-8686-78ee5ba2f46c',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dd133888-77a3-4d57-8a76-71b6bee22443',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6ec9effe-4685-4f24-a843-91392130c0e0',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2023-12-29T18:30:00+00:00',
            endTime: '2023-12-29T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'bf21df23-d185-405d-9b78-6640c6ba5e2e',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T17:00:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ee04c7fe-c38e-4288-850e-005173e02b66',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2024-01-01T17:15:00+00:00',
            endTime: '2024-01-01T18:15:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '344d7eb6-9f93-48a4-a24d-c6d80fdfad5d',
            availabilityId: '121595f3-7e23-40cc-b259-18599a5d4913',
            status: 'available',
            price: 69,
            serviceId: '592e9294-dc1b-4e44-9ac3-4e3c5d879219',
            startTime: '2024-01-01T18:30:00+00:00',
            endTime: '2024-01-01T19:30:00+00:00',
            expertId: 'cCIkwuIRjLcXWfhsBuKnyE9LRMk2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
    phone: '6549545445',
    creation_date: '2023-10-19T03:57:58+00:00',
    birthday: '1992-09-12',
    name: 'Guillermo Sierra',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: '🌍 Mochileando cuando puedo',
    education: 'UPC- Telecos',
    company: null,
    username: 'guillermoStravel',
    avatar:
      'https://lh3.googleusercontent.com/a/ACg8ocIIUljIFUCD-H3162ngQa1UtIuLK1UbwF5ahoVWa3-F4Q=s96-c',
    email: 'guillermo.sierra@bodeboca.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: false,
    role: 'Expert',
    speciality: ['travel'],
    languages: ['CA', 'FR', 'IT', 'PT', 'ES'],
    job: 'Mobile Developer',
    provider: 'google.com',
    last_sign_in: '2023-11-06T18:16:00+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
        name: 'Montemos la ruta por Sureste asiático',
        price: 40,
        duration: 30,
        speciality: 'travel',
        format: 'video',
        description: null,
        expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
        keywords: ['mochilero', 'cultura', 'aventura'],
        time_slots: [
          {
            id: '816d7a6b-dbe3-4155-9eba-2db98e75c9e7',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T18:00:00+00:00',
            endTime: '2023-12-19T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'd9fa06e6-e573-413e-9336-d0c62e2797f4',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T18:45:00+00:00',
            endTime: '2023-12-19T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c468db65-ea3a-49f1-b2af-a417d9a5d0cb',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T19:30:00+00:00',
            endTime: '2023-12-19T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'f535c37a-0152-45d5-8789-a9662e4c8481',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T20:15:00+00:00',
            endTime: '2023-12-19T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '84c93cee-eebd-4162-b7b6-9a254441d65f',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T21:00:00+00:00',
            endTime: '2023-12-19T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '2d11fb14-f87b-4599-bea2-24ad8806ae79',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T21:45:00+00:00',
            endTime: '2023-12-19T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4d60d02e-ec1d-4e3e-80ef-81d9aaf958e2',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-19T22:30:00+00:00',
            endTime: '2023-12-19T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '63299c7d-656c-4522-ba22-328568d8e1a6',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T18:00:00+00:00',
            endTime: '2023-12-21T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c85f1f11-019f-4232-a68d-7e6898aa7752',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T18:45:00+00:00',
            endTime: '2023-12-21T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '49a0a77d-0411-4b2e-86ee-fc7d61d13c5b',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T19:30:00+00:00',
            endTime: '2023-12-21T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'bf394883-dd4b-483b-bca6-2a0ff59bcd4b',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T20:15:00+00:00',
            endTime: '2023-12-21T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '40882aa2-fabd-4ec1-8159-ce344e2702bb',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T21:00:00+00:00',
            endTime: '2023-12-21T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c492ce6a-3f3f-40ee-aadb-dfbf2ff81984',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T21:45:00+00:00',
            endTime: '2023-12-21T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c26d3801-0784-4d68-aed8-8e5fc7450a06',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-21T22:30:00+00:00',
            endTime: '2023-12-21T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '1c6bfc8c-d40b-45b1-84d9-83bba7b0abaf',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T18:00:00+00:00',
            endTime: '2023-12-26T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5aba9783-6ce4-4382-b731-ecb72106f1e6',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T18:45:00+00:00',
            endTime: '2023-12-26T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dc2a3fdc-71ed-40c5-bea9-fb2bf7acc6e2',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T19:30:00+00:00',
            endTime: '2023-12-26T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'bb689356-f67b-482c-90dc-5e8a9f88a798',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T20:15:00+00:00',
            endTime: '2023-12-26T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ad676bd4-fa24-4363-a307-8ba146d0da4f',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T21:00:00+00:00',
            endTime: '2023-12-26T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'a1f9b4b3-37d0-48c9-a502-8b0011fc7784',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T21:45:00+00:00',
            endTime: '2023-12-26T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ae0e3955-00d7-4f0f-addd-98facc56af4a',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-26T22:30:00+00:00',
            endTime: '2023-12-26T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'd2f7689a-62f9-49cc-9610-0db23bd37090',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T18:00:00+00:00',
            endTime: '2023-12-28T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e9d61b5e-7526-4e66-bb46-84eb937c5afd',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T18:45:00+00:00',
            endTime: '2023-12-28T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5c82d124-9532-424a-b148-0fa1baf5c728',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T19:30:00+00:00',
            endTime: '2023-12-28T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '390cfbbf-1929-4e4e-b2f0-76d950a05bec',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T20:15:00+00:00',
            endTime: '2023-12-28T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6a7ca1fb-f739-4617-8a1b-0c0c0f0932b2',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T21:00:00+00:00',
            endTime: '2023-12-28T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1f04e5db-1873-4aa2-9cc3-6c35f95f8d8c',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T21:45:00+00:00',
            endTime: '2023-12-28T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '426b2f23-be4f-45aa-818b-80c2bae56473',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2023-12-28T22:30:00+00:00',
            endTime: '2023-12-28T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '50b382df-e989-4904-90b0-b12f08ac4bae',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T18:00:00+00:00',
            endTime: '2024-01-02T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'cddea487-f15b-4056-b483-5e0df9bf2c3d',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T18:45:00+00:00',
            endTime: '2024-01-02T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6f73a066-7a73-4194-a04f-22a99a27b92b',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T19:30:00+00:00',
            endTime: '2024-01-02T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9b0b6ba3-3ff6-4bf6-b084-913d7a8f213a',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T20:15:00+00:00',
            endTime: '2024-01-02T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '80324288-3c71-4e9d-a580-e6b98f687186',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T21:00:00+00:00',
            endTime: '2024-01-02T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '61aa9396-1263-413e-ac8c-f3e16bb3e810',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T21:45:00+00:00',
            endTime: '2024-01-02T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '36383392-e1cc-4938-8b33-00eb9359c403',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-02T22:30:00+00:00',
            endTime: '2024-01-02T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e9783e79-82e6-4834-b4c7-b3d9a97f4e36',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T18:00:00+00:00',
            endTime: '2024-01-04T18:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c9f5a473-0e66-4545-a93a-fc3bbccf1b34',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T18:45:00+00:00',
            endTime: '2024-01-04T19:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3e1826dc-4470-45e1-ad01-99893094476d',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T19:30:00+00:00',
            endTime: '2024-01-04T20:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e1e81631-225a-45dc-af25-e62a6896eeec',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T20:15:00+00:00',
            endTime: '2024-01-04T20:45:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ebffc31e-b578-43f0-aae5-a8f2ee5844a3',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T21:00:00+00:00',
            endTime: '2024-01-04T21:30:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f821ceab-45e3-44d8-a459-a84f7306472b',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T21:45:00+00:00',
            endTime: '2024-01-04T22:15:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f514fc6a-e276-4e78-9b74-12ddf1ac2f77',
            availabilityId: 'fe4b39ad-73ab-417c-b20e-8b1f70ba62b6',
            status: 'available',
            price: 40,
            serviceId: '95e77ec7-eeed-4cd9-82de-bacb2710bcfe',
            startTime: '2024-01-04T22:30:00+00:00',
            endTime: '2024-01-04T23:00:00+00:00',
            expertId: 'lGAHQoiAtvTQrOQt8xfdKuLPPDC2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
    phone: '656275494',
    creation_date: '2023-10-24T16:51:56+00:00',
    birthday: '1992-09-12',
    name: 'Jorge Cuesta',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Backend Developer at Oralify',
    education: 'Nuclio Digital School',
    company: null,
    username: 'jorgeCbackend',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2FjorgeC1?alt=media&token=82c4cae4-73f1-4540-8647-e9a2108aa0c2',
    email: 'jorgecuesta.90@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: false,
    role: 'Expert',
    speciality: ['backend'],
    languages: ['ES', 'CA', 'EN'],
    job: 'Full Stack Developer',
    provider: 'google.com',
    last_sign_in: '2023-11-29T10:12:07+00:00',
    avgReview: 3,
    nReviews: 2,
    services: [
      {
        id: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
        name: 'Conversación Extendida',
        price: 27,
        duration: 30,
        speciality: 'backend',
        format: 'call',
        description: null,
        expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
        keywords: ['node.js'],
        time_slots: [
          {
            id: 'a2ea691e-6ee5-45f5-8407-05b2442a13c1',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T14:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: '1c4c3a80-90f0-4402-9b8a-2a6a86c462ed',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T14:30:00+00:00',
            endTime: '2023-12-18T15:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: 'c073f470-44b3-4903-aa51-354df0998efd',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: '1d36b4e5-ce6a-4d68-becf-b433084abdf5',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: '9862fd5f-090d-4eac-b9d6-9ed3be110ab8',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: '7efdd10f-290a-47ca-92d4-4d206ffac16c',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-18T16:30:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: null,
          },
          {
            id: '4e50394f-7b44-484e-b25f-1a6350dc945a',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T15:00:00+00:00',
            endTime: '2023-12-27T15:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '66de03e9-4fff-4ece-9719-f2cedeb505ab',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T15:30:00+00:00',
            endTime: '2023-12-27T16:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '97c95369-4b8e-4b20-b4ed-83b582ae8588',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T16:00:00+00:00',
            endTime: '2023-12-27T16:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b0778a59-bdf7-45a3-aa8a-f5d49c6ec876',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T16:30:00+00:00',
            endTime: '2023-12-27T17:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dd00ed7b-30d8-400b-bd69-eb4187bb4696',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ae97ef26-471d-4e35-a299-1b7a2309893e',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-27T17:30:00+00:00',
            endTime: '2023-12-27T18:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '1110ebf6-eba6-4b01-97f7-c763db9d06a2',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T15:00:00+00:00',
            endTime: '2023-12-29T15:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '2e345c7e-f41a-4b27-868a-96f46c71df69',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T15:30:00+00:00',
            endTime: '2023-12-29T16:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5107ec14-b26f-4dff-82ee-f494ae070254',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T16:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '94392eb9-a216-470f-9177-f338f084ba58',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T16:30:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'be681e23-ead7-46fb-822a-59dabccb3c42',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '79580d95-43e7-49a2-bd74-2b61a4068043',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2023-12-29T17:30:00+00:00',
            endTime: '2023-12-29T18:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '6cf051e2-de8a-4506-90c1-3217657b6605',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T15:00:00+00:00',
            endTime: '2024-01-01T15:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '97c43ae5-6498-4312-970b-91c0e38448f1',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T15:30:00+00:00',
            endTime: '2024-01-01T16:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '00cd372c-d702-431a-b994-89721f4fde47',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T16:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'e2cdff82-b3a4-4e6f-9920-47fc59aa6d1d',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T16:30:00+00:00',
            endTime: '2024-01-01T17:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '44952bfb-83f2-42ff-ae99-719ba1ceb598',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:30:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '97fd8390-8e10-4299-ac60-79cf9fb62d76',
            availabilityId: '94ce9909-3e81-4612-b2c8-d9c3a3bdf43d',
            status: 'available',
            price: 27,
            serviceId: 'a236c514-5ec0-412a-8e9b-77aa6eb5e155',
            startTime: '2024-01-01T17:30:00+00:00',
            endTime: '2024-01-01T18:00:00+00:00',
            expertId: 'qrg8E3qj85a4SCV3SrTnxfHfg743',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
    phone: '123123179',
    creation_date: '2023-11-13T15:34:51+00:00',
    birthday: '1990-03-18',
    name: 'Holasoynuevo',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'CEO de la vida',
    education: 'UAB',
    company: null,
    username: 'holasoynuevonutrition',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2Fholasoynuevo?alt=media&token=660a0459-5a96-4964-a6a8-835cd0764d95',
    email: 'holasoynuevo@a.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['nutrition'],
    languages: ['ES', 'CA'],
    job: 'Tecnico del gas',
    provider: 'password',
    last_sign_in: '2023-11-13T16:13:20+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
        name: 'Consulta puntual',
        price: 10,
        duration: 15,
        speciality: 'nutrition',
        format: 'call',
        description: null,
        expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
        keywords: ['suplementación', 'nutriciónDeportiva'],
        time_slots: [
          {
            id: 'ef07b79d-6b6a-477c-ab2e-8158e4c50481',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T16:00:00+00:00',
            endTime: '2023-12-22T16:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'bf68d82c-311a-492c-baf9-7000dd3b0c74',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T16:15:00+00:00',
            endTime: '2023-12-22T16:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ef746f6e-7410-46dd-851f-11e583d0a853',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T16:30:00+00:00',
            endTime: '2023-12-22T16:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e869488e-f65b-4ae6-8f35-f1d09ce7484f',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T16:45:00+00:00',
            endTime: '2023-12-22T17:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '122e278f-2ab9-41d1-a756-857341d5a8b1',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T17:00:00+00:00',
            endTime: '2023-12-22T17:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '18c757c0-c02f-4a41-b831-25be9cfe1699',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T17:15:00+00:00',
            endTime: '2023-12-22T17:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e05c5753-bd19-4927-8a3c-bede8e36d2e6',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T17:30:00+00:00',
            endTime: '2023-12-22T17:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'a3717d3a-31cc-4889-ba5f-647c6699df6d',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-22T17:45:00+00:00',
            endTime: '2023-12-22T18:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6a0d3aa2-88f4-4d5b-a1ae-8f406983443e',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T16:00:00+00:00',
            endTime: '2023-12-25T16:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '761e4937-6835-4601-bf6f-4e3e59c9e6df',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T16:15:00+00:00',
            endTime: '2023-12-25T16:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c9cdf8b3-386f-4708-a998-aff315f99894',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T16:30:00+00:00',
            endTime: '2023-12-25T16:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e4fd5bfb-5df0-4eda-bef5-259505c20959',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T16:45:00+00:00',
            endTime: '2023-12-25T17:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e0707bab-0a0b-4cbc-93e3-7166c6c17233',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T17:00:00+00:00',
            endTime: '2023-12-25T17:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4c6266b9-afbc-4902-83f0-0a6f33e667f5',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T17:15:00+00:00',
            endTime: '2023-12-25T17:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b0a1ca0c-7ba2-4182-a8a0-77096c71b476',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T17:30:00+00:00',
            endTime: '2023-12-25T17:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '39fb6468-bb44-465b-9fc9-0e8f051d2d9b',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2023-12-25T17:45:00+00:00',
            endTime: '2023-12-25T18:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '3082bcf5-0e91-428d-b40c-115723cf48d9',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T16:00:00+00:00',
            endTime: '2024-01-05T16:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'fb984f70-8781-45c4-a02a-0984335f74e6',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T16:15:00+00:00',
            endTime: '2024-01-05T16:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '529ecc97-fc85-444f-9e74-53daf705e6be',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T16:30:00+00:00',
            endTime: '2024-01-05T16:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1eb0aba8-511d-4e7f-b5ae-73a9c3663cec',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T16:45:00+00:00',
            endTime: '2024-01-05T17:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '157469f6-2962-4ebe-8586-6bcd1e05193f',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T17:00:00+00:00',
            endTime: '2024-01-05T17:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ed5d20c6-e0c5-49bf-98d7-13380d2cb894',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T17:15:00+00:00',
            endTime: '2024-01-05T17:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1cbc6fe7-6c09-42ac-8226-80eac373b353',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T17:30:00+00:00',
            endTime: '2024-01-05T17:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a89fca0b-5386-4978-b754-dcbc419dc952',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-05T17:45:00+00:00',
            endTime: '2024-01-05T18:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ede73310-dd12-484d-ac5a-afa5143bf76c',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T16:00:00+00:00',
            endTime: '2024-01-08T16:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'cc8ed6bb-78e0-43f9-ad2f-74a4121d5202',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T16:15:00+00:00',
            endTime: '2024-01-08T16:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1358e2bd-f2ad-4e0f-949f-99d67a1edbb6',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T16:30:00+00:00',
            endTime: '2024-01-08T16:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0fd67e07-695a-4140-a4f9-4300790820c7',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T16:45:00+00:00',
            endTime: '2024-01-08T17:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1753e3a5-fba6-45c3-8398-a88601dafd6f',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T17:00:00+00:00',
            endTime: '2024-01-08T17:15:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ecb5981f-2995-4372-9026-bb2a8fdd2b3a',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T17:15:00+00:00',
            endTime: '2024-01-08T17:30:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '01703c8e-e06a-4950-90f3-cedef3409cfd',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T17:30:00+00:00',
            endTime: '2024-01-08T17:45:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f77b24a7-2082-46e9-ad90-0496f59dafe6',
            availabilityId: 'b9bb3cd0-3a28-4488-9f60-7ff7f163b0c0',
            status: 'available',
            price: 10,
            serviceId: 'abecbcf0-72b5-4fb8-8cdb-032134cfe692',
            startTime: '2024-01-08T17:45:00+00:00',
            endTime: '2024-01-08T18:00:00+00:00',
            expertId: 'AhWQoxe0LdebMpZeK8DRNQxbC092',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'CORYq52KacTChJshicoOofxiLri1',
    phone: '645363434',
    creation_date: '2023-11-06T18:05:56+00:00',
    birthday: '1987-07-12',
    name: 'Marcos Becerra',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: null,
    education: 'UPF',
    company: null,
    username: 'marcosBartAndCraft',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2Fmarcos?alt=media&token=ec687b18-1658-47b2-a00d-cd9ea861ee8a',
    email: 'marcos@itziar.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['artAndCraft'],
    languages: ['ES'],
    job: 'SAP telecom',
    provider: 'password',
    last_sign_in: '2023-11-06T18:05:56+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
        name: 'Videoconsulta Exprés',
        price: 5,
        duration: 15,
        speciality: 'artAndCraft',
        format: 'video',
        description: null,
        expertId: 'CORYq52KacTChJshicoOofxiLri1',
        keywords: ['cerámica'],
        time_slots: [
          {
            id: 'd4b95f31-4fea-4dd4-b2ab-4879c31582a8',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T15:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '881ec73e-2e65-470d-bdfc-023bb536639d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T15:15:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: 'c41034a6-f64c-45fe-820a-835b76b70072',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T15:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '799b4058-b420-4aeb-8329-adb963c61436',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T15:45:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '06258521-fb3c-4ad7-9d03-27303631872f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '3a7c5d75-40f8-4514-a452-638d4ceec53d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T16:15:00+00:00',
            endTime: '2023-12-18T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '3ba5a0bd-670f-4829-be29-bc775622c255',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T16:30:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '5c31a453-eda8-46dc-8a8b-f55c4216281a',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '080e3e5c-b125-454e-9c02-e1165c941973',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T16:00:00+00:00',
            endTime: '2023-12-26T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3076bec2-406c-419b-8e6a-54b0423f084f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T16:15:00+00:00',
            endTime: '2023-12-26T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '055d00f7-1f87-4664-af0e-4f98931e1607',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T16:30:00+00:00',
            endTime: '2023-12-26T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '65577860-7973-4a92-aa43-e2a0f54c447a',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T16:45:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'af8a7e04-5d71-4ce9-938d-7633b4cbad61',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '8defd2b0-11e4-44b8-b7e5-e57fb53231d7',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3079137f-65ad-4ed1-b807-d027377eb739',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T17:30:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '28001fc7-8ab7-4217-8cab-06e3aaf10ae7',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-26T17:45:00+00:00',
            endTime: '2023-12-26T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'dcd4d193-7383-448d-8dcd-742c25d202ec',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T16:00:00+00:00',
            endTime: '2023-12-27T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'ab25b9fe-c31e-49b0-add6-87906b8b1815',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T16:15:00+00:00',
            endTime: '2023-12-27T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '41f01344-7b25-4023-84f0-00d41c348052',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T16:30:00+00:00',
            endTime: '2023-12-27T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '86f3a757-d910-4c42-af57-a5f2ccba9172',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T16:45:00+00:00',
            endTime: '2023-12-27T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '5c3ce3fd-eab1-40c9-b097-d4bc90c5559f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '520dfdef-f45a-440c-9c27-ef685af296c6',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T17:15:00+00:00',
            endTime: '2023-12-27T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '29436cba-462c-491f-801b-db9b89442ac9',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T17:30:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '92ab0498-0975-4aa1-b5ff-6bfa1316ebae',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-27T17:45:00+00:00',
            endTime: '2023-12-27T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '3d532af5-5a97-4d50-9530-192b0da10874',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T16:00:00+00:00',
            endTime: '2023-12-28T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'cdef514d-7660-43ff-b471-0c202a51309a',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T16:15:00+00:00',
            endTime: '2023-12-28T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'fe2706e1-9df3-438d-9e25-c2b0a70058eb',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T16:30:00+00:00',
            endTime: '2023-12-28T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '36261ffd-b46c-48cf-b3b4-4e8d23f84931',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T16:45:00+00:00',
            endTime: '2023-12-28T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '08a3c89d-a604-4c25-8408-24def9d7c392',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '5ca999a2-1e8d-43cb-88a0-2e53dfa46f56',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T17:15:00+00:00',
            endTime: '2023-12-28T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '309c1a38-f3e8-41c3-afb3-97db0b15d792',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T17:30:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'd2853639-718f-4652-b389-c4fda43fe8ff',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-28T17:45:00+00:00',
            endTime: '2023-12-28T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'd187dd3e-8709-494f-8d99-6be2d04ad44b',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '2f460bc2-6eb4-4dba-a60f-3de9d42700ca',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T16:15:00+00:00',
            endTime: '2023-12-29T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b790088d-cf8e-455a-a321-ef257112f143',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T16:30:00+00:00',
            endTime: '2023-12-29T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '2013f824-821d-4f38-a65a-07eb9b7edc06',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T16:45:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b22b567d-777e-4ccf-bc31-5240be29ecff',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '19c563ab-00be-4fc5-a80f-e86ac8be0f86',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '6536a84e-0226-43a9-ad5a-8873962c70b8',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T17:30:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7599cd88-7dd0-4d79-af78-8aa147d3be98',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2023-12-29T17:45:00+00:00',
            endTime: '2023-12-29T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '82e3897c-788c-4cda-aed5-505f2f8ce405',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '57f94ef0-7f33-499c-b6bb-85ec3461fcf2',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T16:15:00+00:00',
            endTime: '2024-01-01T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '92452ad4-7cca-4857-9c5d-894116a6ea55',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T16:30:00+00:00',
            endTime: '2024-01-01T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '26628f4e-d26f-40d3-8a77-6c6ca06f4c9f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T16:45:00+00:00',
            endTime: '2024-01-01T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3472565b-901d-4da1-8b8d-7053562d73d5',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: 'b7220b8c-a15a-4bfc-806b-eb1dfe816566',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T17:15:00+00:00',
            endTime: '2024-01-01T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '3124c8ab-cf6b-4a69-a78e-b191e318d7c7',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T17:30:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
          {
            id: '79dd88fe-a71d-40de-8ef3-5288b4a07800',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: '1cc298ca-0811-4cb9-a126-303f2ea62ce9',
            startTime: '2024-01-01T17:45:00+00:00',
            endTime: '2024-01-01T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.477+00:00',
          },
        ],
      },
      {
        id: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
        name: 'Videoconsulta Exprés',
        price: 5,
        duration: 15,
        speciality: 'artAndCraft',
        format: 'video',
        description: null,
        expertId: 'CORYq52KacTChJshicoOofxiLri1',
        keywords: ['cerámica'],
        time_slots: [
          {
            id: '21279f25-7811-41e3-a780-673e554da802',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T15:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: 'e9ddd669-0689-4886-9f26-8d6256f4def5',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T15:15:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '2d0e630d-cda2-4821-94ea-ff9104d344c2',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T15:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: 'a9812c33-8959-4f67-b6fa-823dc5c379ce',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T15:45:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: 'afbc9c71-a310-40ad-9e56-c86c39656956',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T16:00:00+00:00',
            endTime: '2023-12-18T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: 'd7f09afc-9077-4f2b-ac36-c7717a15a0e9',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T16:15:00+00:00',
            endTime: '2023-12-18T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '032de259-1c64-498e-80f7-be405847d763',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T16:30:00+00:00',
            endTime: '2023-12-18T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '1b469fb5-9d97-41c1-a74f-d79a3364504d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-18T16:45:00+00:00',
            endTime: '2023-12-18T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: null,
          },
          {
            id: '721203bb-dc00-45cf-afbb-a3e48f562061',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T16:00:00+00:00',
            endTime: '2023-12-26T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '5952c301-2feb-40b2-a08d-d67eeb1667a5',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T16:15:00+00:00',
            endTime: '2023-12-26T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'dd87aa67-4605-43d4-a72d-602261915508',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T16:30:00+00:00',
            endTime: '2023-12-26T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'f0d2521d-1d5c-4158-a154-cb9ab55b05d2',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T16:45:00+00:00',
            endTime: '2023-12-26T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'ff093550-a139-489c-946c-4baba5bfc41c',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '69bd6e28-d90c-427a-8925-c200710a9bc2',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T17:15:00+00:00',
            endTime: '2023-12-26T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '2587e64c-0de3-4ba4-b6ca-f748967f725a',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T17:30:00+00:00',
            endTime: '2023-12-26T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '17299779-8f76-4f88-81c4-c4b9982c20af',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-26T17:45:00+00:00',
            endTime: '2023-12-26T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'e2cfe8cd-ba3c-4f41-b6aa-96fe82cf7538',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T16:00:00+00:00',
            endTime: '2023-12-27T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'd4330abe-08f4-435c-b2ed-aecf3cb99ec0',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T16:15:00+00:00',
            endTime: '2023-12-27T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'f43ba8b3-bc2b-4fd4-977a-0196ed0ac92f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T16:30:00+00:00',
            endTime: '2023-12-27T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7789799d-507b-4659-9751-981834aeb72a',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T16:45:00+00:00',
            endTime: '2023-12-27T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '6758c2a1-2b4c-4eb4-9558-72d9b03762c2',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T17:00:00+00:00',
            endTime: '2023-12-27T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '4a2b6d70-ca57-4c3b-b546-46d38a5801d6',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T17:15:00+00:00',
            endTime: '2023-12-27T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '157ffba0-fd65-4da9-81e1-2fc4d95e9aed',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T17:30:00+00:00',
            endTime: '2023-12-27T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'd96c1634-2e72-4ab6-90f1-328eed361e40',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-27T17:45:00+00:00',
            endTime: '2023-12-27T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '31224696-b17c-44e1-a2b1-cb2c5f6a03d1',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T16:00:00+00:00',
            endTime: '2023-12-28T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7ebcc11a-0046-40eb-b3ae-2775d1f0c96d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T16:15:00+00:00',
            endTime: '2023-12-28T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'c19cd520-9546-4b37-aaec-d00eb8883f3c',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T16:30:00+00:00',
            endTime: '2023-12-28T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '96c94383-bbbc-40bc-a1fe-33df95bc32ae',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T16:45:00+00:00',
            endTime: '2023-12-28T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'a38de9e2-e03f-45ff-92ef-bfdc4f8e5fb1',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '453a52b4-58ba-446e-bca0-8b9bb161c8ff',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T17:15:00+00:00',
            endTime: '2023-12-28T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'db20a515-0ddb-4ca1-8d0a-447a96f8e813',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T17:30:00+00:00',
            endTime: '2023-12-28T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '3fb65ab2-9f66-4406-bf11-9d223512fb06',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-28T17:45:00+00:00',
            endTime: '2023-12-28T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '74843eb3-6813-49ad-aabf-e8dddaf64935',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '4b73b327-0107-46bb-91eb-690bca7dafa8',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T16:15:00+00:00',
            endTime: '2023-12-29T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '6cd6ac25-9316-4eb0-91de-79d53190c5fa',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T16:30:00+00:00',
            endTime: '2023-12-29T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'af627442-152d-445a-9a6d-f37066cd8bf6',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T16:45:00+00:00',
            endTime: '2023-12-29T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '9581916b-dc2a-4cbe-a6ba-c53a8de9c254',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T17:00:00+00:00',
            endTime: '2023-12-29T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '256836f9-1630-4c06-b119-08cd4c7d7631',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T17:15:00+00:00',
            endTime: '2023-12-29T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '66155f2d-52eb-4846-89a6-528305a46a56',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T17:30:00+00:00',
            endTime: '2023-12-29T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '934df68c-ebc8-4a48-a179-c9a4daa36685',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2023-12-29T17:45:00+00:00',
            endTime: '2023-12-29T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '21ebc2d5-992a-4e91-869f-c13c338f25e6',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T16:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '51618f8a-42df-4968-8d88-ee74952f290d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T16:15:00+00:00',
            endTime: '2024-01-01T16:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '8269939a-f574-4885-a39a-3608bdcc1627',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T16:30:00+00:00',
            endTime: '2024-01-01T16:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b764ac64-ec56-489e-b855-56702069bf25',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T16:45:00+00:00',
            endTime: '2024-01-01T17:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '0ae6165b-2dd0-4e83-9adc-e9d02b2ab39f',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T17:00:00+00:00',
            endTime: '2024-01-01T17:15:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'e5cb9c17-28d8-41c9-80e9-b6c997ea6b31',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T17:15:00+00:00',
            endTime: '2024-01-01T17:30:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '2ed61444-cdf7-427b-9973-45868ef76e1d',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T17:30:00+00:00',
            endTime: '2024-01-01T17:45:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '9b7ca607-7e1c-4996-8017-f868aa3f0dc8',
            availabilityId: '6076a048-8fe2-4d83-9d0c-54447370623c',
            status: 'available',
            price: 5,
            serviceId: 'f5c9b43f-3627-4067-b11c-43f2020784cd',
            startTime: '2024-01-01T17:45:00+00:00',
            endTime: '2024-01-01T18:00:00+00:00',
            expertId: 'CORYq52KacTChJshicoOofxiLri1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
    phone: '+16661541234',
    creation_date: '2023-11-13T15:45:55+00:00',
    birthday: '1995-05-08',
    name: 'Mariano',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Pruebas de Inglés',
    education: 'Univerdad Autonoma de Madrid',
    company: null,
    username: 'marianoinfluencer',
    avatar: 'https://randomuser.me/api/portraits/men/19.jpg',
    email: 'holasoynuevo2@a.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['influencer'],
    languages: ['ES', 'EN', 'FR'],
    job: 'English Teacher',
    provider: 'password',
    last_sign_in: '2023-11-16T17:01:53+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: 'd3a77467-184a-4930-8092-117f0d06f654',
        name: 'Clase Twitch',
        price: 35,
        duration: 45,
        speciality: 'influencer',
        format: 'video',
        description: '',
        expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
        keywords: [],
        time_slots: [
          {
            id: '4c375d90-9c1c-4a16-bf05-29c04a8fdbe9',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2023-12-23T08:00:00+00:00',
            endTime: '2023-12-23T08:45:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '952609ab-16e8-4ef8-a9f6-f0767cca4855',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2023-12-23T08:55:00+00:00',
            endTime: '2023-12-23T09:40:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '02557d4d-850c-42ec-8b57-de86cdef4942',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2023-12-30T08:00:00+00:00',
            endTime: '2023-12-30T08:45:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '84f81325-6be9-40c1-923c-63d01b830a48',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2023-12-30T08:55:00+00:00',
            endTime: '2023-12-30T09:40:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '9512a470-7d12-4689-aa24-a637dfa2b6e6',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2024-01-06T08:00:00+00:00',
            endTime: '2024-01-06T08:45:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e0d258c0-e20f-406e-ad79-90324b1c44a4',
            availabilityId: '8f23fcf0-8dde-420a-9b03-d1ee7a4d0bb8',
            status: 'available',
            price: 35,
            serviceId: 'd3a77467-184a-4930-8092-117f0d06f654',
            startTime: '2024-01-06T08:55:00+00:00',
            endTime: '2024-01-06T09:40:00+00:00',
            expertId: 'mpXTLqqbGeXwmZ9c9qjcPxB6izL2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
    phone: '692842705',
    creation_date: '2023-10-18T14:02:47+00:00',
    birthday: '1992-09-12',
    name: 'Guillermo Sierra',
    sex: 'Male',
    isMock: false,
    score: null,
    headline:
      'Serial Enterpreneur with +7y experience in Mobile & Frontend development ',
    education: 'UPC Telecomunicaciones',
    company: null,
    username: 'guillermoS',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2FguillermoS?alt=media&token=e2eee200-c09f-4efe-9d5c-cfed7c0fb647',
    email: 'gsierra@guillesierra.com',
    oral_coins: null,
    is_connected: true,
    email_verified: true,
    phone_verified: false,
    role: 'Expert',
    speciality: ['frontend', 'realState', 'productivity', 'travel'],
    languages: ['ES', 'EN', 'FR', 'CA'],
    job: 'Mobile Developer @Fluz & @Bodeboca',
    provider: 'google.com',
    last_sign_in: '2023-11-12T14:17:32+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
        name: 'Sesión de video',
        price: 75,
        duration: 60,
        speciality: 'frontend',
        format: 'video',
        description: null,
        expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
        keywords: ['mobile', 'react-native', 'javascript'],
        time_slots: [
          {
            id: '7c79fe0f-6cac-40e4-93c6-5c2032377948',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-18T12:00:00+00:00',
            endTime: '2023-12-18T13:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'c8f1523f-3324-453b-8fdf-75cc181674b9',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-18T13:00:00+00:00',
            endTime: '2023-12-18T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'b4663f86-4724-4cb6-a0db-9996c47534cb',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '3cf74e00-4429-4cc9-8bc2-af6ffde44fa1',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '1cab4136-9555-4b86-a419-4cddc465f212',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T13:00:00+00:00',
            endTime: '2023-12-26T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '93de6a6b-1980-4b54-aeb3-147dc586006b',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T14:00:00+00:00',
            endTime: '2023-12-26T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7cf1adb8-5ae6-4812-9415-a90e27e276d7',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T14:15:00+00:00',
            endTime: '2023-12-26T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '3a155159-22fc-4869-baae-bdca2967b2e0',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T15:15:00+00:00',
            endTime: '2023-12-26T16:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '127136ef-96d7-4f9c-9fb2-a8226ee8f2b4',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T15:30:00+00:00',
            endTime: '2023-12-26T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '6da50867-f80a-4e81-a9c7-76d85ed04287',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-26T16:30:00+00:00',
            endTime: '2023-12-26T17:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b44dded3-37c9-4955-ac5c-7bb3a4842aad',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-27T13:00:00+00:00',
            endTime: '2023-12-27T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '87916e13-6272-478b-bb90-97c890b638b0',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-27T14:15:00+00:00',
            endTime: '2023-12-27T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'a7f33332-969a-4a2e-9a85-7b284060ae36',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-27T15:30:00+00:00',
            endTime: '2023-12-27T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '995335bc-bf10-4258-bdd9-fb4e062787ec',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T13:00:00+00:00',
            endTime: '2023-12-28T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '085def14-045c-4c8f-9475-86b228bd6497',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T14:00:00+00:00',
            endTime: '2023-12-28T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '037fb74b-a5cf-4ff9-8820-04eaa39e3b52',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T14:15:00+00:00',
            endTime: '2023-12-28T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'a468a30e-8721-411a-8a0b-4bb612645978',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T15:15:00+00:00',
            endTime: '2023-12-28T16:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '3f0a877a-85d6-4179-a1bd-a0aa54152044',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T15:30:00+00:00',
            endTime: '2023-12-28T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '502795d1-dd0a-45db-89e1-e6a556dbbd9a',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-28T16:30:00+00:00',
            endTime: '2023-12-28T17:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '15489b5a-6cdd-4977-a6c5-3de2c8f9b625',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-29T13:00:00+00:00',
            endTime: '2023-12-29T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '4f396bd6-997a-4767-81e3-8041d62df858',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-29T14:15:00+00:00',
            endTime: '2023-12-29T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'dc0d98cc-84c9-477b-8757-9a2606f485e5',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2023-12-29T15:30:00+00:00',
            endTime: '2023-12-29T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '9afa1b71-9c01-4edc-b48a-13f411461c21',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2024-01-01T13:00:00+00:00',
            endTime: '2024-01-01T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'ec18e183-d0d9-4a89-9f43-48385c577636',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2024-01-01T14:15:00+00:00',
            endTime: '2024-01-01T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '641141ca-b288-4542-b219-ea3ff137401a',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 75,
            serviceId: '83d37b6d-7b9e-4ebd-a20c-540734fcc111',
            startTime: '2024-01-01T15:30:00+00:00',
            endTime: '2024-01-01T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
        ],
      },
      {
        id: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
        name: 'Conversación Extendida',
        price: 20,
        duration: 30,
        speciality: 'productivity',
        format: 'call',
        description: null,
        expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
        keywords: ['gestiónDelTiempo'],
        time_slots: [
          {
            id: 'c08b3953-504f-46f8-9c9c-b12117b890f3',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T12:00:00+00:00',
            endTime: '2023-12-18T12:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'b63f11f3-74d6-4179-b724-45ef5b205529',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T12:30:00+00:00',
            endTime: '2023-12-18T13:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'c1257bbc-c42a-433f-86f2-dfc8a367d933',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T13:00:00+00:00',
            endTime: '2023-12-18T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '67be553e-3fbd-4ffe-a9ac-a0e5cd15abe6',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T13:30:00+00:00',
            endTime: '2023-12-18T14:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '293950ba-fd02-40e5-bf6d-948287ae0a28',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T14:00:00+00:00',
            endTime: '2023-12-18T14:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'd2640a54-bbd4-4c7c-a3f2-79fbb9727058',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T14:30:00+00:00',
            endTime: '2023-12-18T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '49185db2-c0c4-451d-adea-e44cdebdf9b0',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T15:00:00+00:00',
            endTime: '2023-12-18T15:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: '5a200171-69c9-4d32-98c1-137d9345dc50',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-18T15:30:00+00:00',
            endTime: '2023-12-18T16:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: null,
          },
          {
            id: 'fb511ee7-3072-4c01-8c86-5ec0869c1c01',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T13:00:00+00:00',
            endTime: '2023-12-26T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'caebb6eb-ea39-46a0-a8e4-52404bf66469',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T13:45:00+00:00',
            endTime: '2023-12-26T14:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '53e5a2dc-1aa4-47e6-ad0e-8080a4ffa7d8',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T14:00:00+00:00',
            endTime: '2023-12-26T14:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '052e9674-c3df-4915-8926-896857f134c8',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T14:30:00+00:00',
            endTime: '2023-12-26T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '15673d95-61f1-4aca-a0c4-215409f90d9f',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T14:45:00+00:00',
            endTime: '2023-12-26T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '784f6748-65d3-4705-85c2-3113323d6568',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T15:15:00+00:00',
            endTime: '2023-12-26T15:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'f0ed8aaa-dbbc-49db-981b-f260beaeefab',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T15:30:00+00:00',
            endTime: '2023-12-26T16:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'a759e77c-6a62-4a35-ac76-8de3118d5325',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T16:00:00+00:00',
            endTime: '2023-12-26T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '5e33c772-ab75-4dcc-a566-2ed8656e74b3',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T16:15:00+00:00',
            endTime: '2023-12-26T16:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'bb1a10b9-7be2-4a59-859e-5a0c63a271da',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-26T17:00:00+00:00',
            endTime: '2023-12-26T17:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '5764338f-e51c-4e75-ab70-57484f7773a6',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-27T13:00:00+00:00',
            endTime: '2023-12-27T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '011df69e-28a3-477c-b3d5-eb523a5e579f',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-27T13:45:00+00:00',
            endTime: '2023-12-27T14:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '60c4d582-102e-4eec-a746-7f2eadfd3ad8',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-27T14:30:00+00:00',
            endTime: '2023-12-27T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '93a6ebf2-0fb5-42f5-88ca-1a067d7af479',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-27T15:15:00+00:00',
            endTime: '2023-12-27T15:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b70c24af-e41a-4684-a2b4-c7c5e3457b2a',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-27T16:00:00+00:00',
            endTime: '2023-12-27T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '53cbb9d5-d735-4084-aee2-7372c417022d',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T13:00:00+00:00',
            endTime: '2023-12-28T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '6121a3cd-91bd-4738-a26a-9992445329c2',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T13:45:00+00:00',
            endTime: '2023-12-28T14:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '35101329-7d20-451f-94fd-96aafa40e5c8',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T14:00:00+00:00',
            endTime: '2023-12-28T14:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'e497bcaf-78b6-49db-9e25-a3f2aae11dfe',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T14:30:00+00:00',
            endTime: '2023-12-28T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '1ef74e29-4b40-4478-be28-6572bfb25f38',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T14:45:00+00:00',
            endTime: '2023-12-28T15:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'ad36cb7e-c2ec-403b-883f-21e1e34a16a9',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T15:15:00+00:00',
            endTime: '2023-12-28T15:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7f8817a7-d5c7-476d-af2f-2adbcfe4e1ba',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T15:30:00+00:00',
            endTime: '2023-12-28T16:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '05343076-7e9f-4daa-bb74-98267611efa6',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T16:00:00+00:00',
            endTime: '2023-12-28T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b706ee57-20ed-466d-9b59-13aa52f18e95',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T16:15:00+00:00',
            endTime: '2023-12-28T16:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'a2447949-21aa-4d79-9410-b61dc98b3aa9',
            availabilityId: '70b9b747-1081-4147-a719-20b45de543e4',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-28T17:00:00+00:00',
            endTime: '2023-12-28T17:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '30d61510-118a-474e-aa77-97941b508346',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-29T13:00:00+00:00',
            endTime: '2023-12-29T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'ee44c29b-1914-4543-b4ad-dcd3ef635768',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-29T13:45:00+00:00',
            endTime: '2023-12-29T14:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '77d4c8b9-70fe-4ea4-8437-c79eb6c98c79',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-29T14:30:00+00:00',
            endTime: '2023-12-29T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '076f2605-0a72-4b15-bc58-881915c82aa0',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-29T15:15:00+00:00',
            endTime: '2023-12-29T15:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '7220b17e-d3f3-4193-b837-7f997a5cb4b7',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2023-12-29T16:00:00+00:00',
            endTime: '2023-12-29T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '3dde754e-a887-405c-a548-06c22051a7f8',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2024-01-01T13:00:00+00:00',
            endTime: '2024-01-01T13:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '21dc9971-0f38-4936-a615-20ee0e12a4e0',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2024-01-01T13:45:00+00:00',
            endTime: '2024-01-01T14:15:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'b9cd1e87-68ef-450c-b22a-64c6896bd33e',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2024-01-01T14:30:00+00:00',
            endTime: '2024-01-01T15:00:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'c6b21130-c2bf-45c5-b1c0-06d2efce781d',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2024-01-01T15:15:00+00:00',
            endTime: '2024-01-01T15:45:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: 'fb335720-bd70-4d16-9369-b094e25ad2a3',
            availabilityId: 'b399fe95-f16e-4c76-8254-0b78293b65dc',
            status: 'available',
            price: 20,
            serviceId: '3eed1ba8-4a6c-4beb-9059-d01a6371e839',
            startTime: '2024-01-01T16:00:00+00:00',
            endTime: '2024-01-01T16:30:00+00:00',
            expertId: 'ucOUYTS7YNTOiX4xMs9QcZ5Wnpu1',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
        ],
      },
    ],
  },
  {
    id: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
    phone: '699334956',
    creation_date: '2023-11-19T18:37:47+00:00',
    birthday: '1985-02-09',
    name: 'René',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: null,
    education: null,
    company: null,
    username: 'renfinancialAdvisor',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/oralify7.appspot.com/o/avatars%2Ffiredark?alt=media&token=1f88c3dd-5c34-439d-a8df-17887ab60693',
    email: 'firedark1985@gmail.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['financialAdvisor'],
    languages: ['ES'],
    job: null,
    provider: 'password',
    last_sign_in: '2023-11-19T18:37:47+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
        name: 'Encuentro Personalizado',
        price: 20,
        duration: 60,
        speciality: 'financialAdvisor',
        format: 'person',
        description: null,
        expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
        keywords: ['criptomonedas'],
        time_slots: [
          {
            id: 'a85b1198-5704-4f0f-a648-25e8328c7ab3',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-19T17:00:00+00:00',
            endTime: '2023-12-19T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4f7c3d9b-28e0-4eda-9752-f36b017f3775',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-19T18:15:00+00:00',
            endTime: '2023-12-19T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '980e65ae-84ce-415f-8491-e946b4fe4d2d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-19T19:30:00+00:00',
            endTime: '2023-12-19T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'a7de0f82-a284-43ca-b8f9-52fbb1755064',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-19T20:45:00+00:00',
            endTime: '2023-12-19T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b6cabf2f-3b7a-420e-a688-8fc4941a5597',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-20T17:00:00+00:00',
            endTime: '2023-12-20T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0b880fb6-4e60-44db-9e80-df5ee16ef815',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-20T18:15:00+00:00',
            endTime: '2023-12-20T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '59a4427b-d5de-49b9-bf01-59caa590fe98',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-20T19:30:00+00:00',
            endTime: '2023-12-20T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0f6fe682-b347-4c47-9127-5da6f7d2ebcb',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-20T20:45:00+00:00',
            endTime: '2023-12-20T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '571d7661-530c-4069-bd78-e91402faf3fe',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-21T17:00:00+00:00',
            endTime: '2023-12-21T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'db073f3b-3b41-427e-aa62-765309c883a0',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-21T18:15:00+00:00',
            endTime: '2023-12-21T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '831fa70d-9a9d-43df-93a9-3e369306e94f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-21T19:30:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'd90835b8-5e24-4d7b-888f-a3e01d527dce',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-21T20:45:00+00:00',
            endTime: '2023-12-21T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4f477225-4eb7-4cf3-a8a2-fcf5dc3b7fc0',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-22T17:00:00+00:00',
            endTime: '2023-12-22T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ff509e73-af3d-43f9-9781-69a1cf4880c2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-22T18:15:00+00:00',
            endTime: '2023-12-22T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '8a268227-1d73-4901-8c8b-10f16c32775a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-22T19:30:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ff2e8337-5f5f-48af-ba6a-e027c1ab2f13',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-22T20:45:00+00:00',
            endTime: '2023-12-22T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '152c2272-621b-4379-ac88-b8c6c4062aee',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-25T17:00:00+00:00',
            endTime: '2023-12-25T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4eca62d9-2913-49c6-ab02-97a754b110b2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-25T18:15:00+00:00',
            endTime: '2023-12-25T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '308fb2fd-4456-4e57-b27e-a4f863b86add',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-25T19:30:00+00:00',
            endTime: '2023-12-25T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6d8a2a33-5064-4e3a-bdac-3f642b4b1f51',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2023-12-25T20:45:00+00:00',
            endTime: '2023-12-25T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '634ed183-ae8f-483b-ae55-021682a14577',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-02T17:00:00+00:00',
            endTime: '2024-01-02T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ad4a692d-0539-4c93-994c-d7eab3eb075c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-02T18:15:00+00:00',
            endTime: '2024-01-02T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9bf7e97b-f69c-4c11-90c8-90b3685fe239',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-02T19:30:00+00:00',
            endTime: '2024-01-02T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c0e377fe-7077-4a6e-a23b-b052bbc2c7cf',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-02T20:45:00+00:00',
            endTime: '2024-01-02T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '12b9be54-4db9-41fe-8132-f0640d45390f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-03T17:00:00+00:00',
            endTime: '2024-01-03T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '27169585-f676-48c5-94c5-0252fec0c852',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-03T18:15:00+00:00',
            endTime: '2024-01-03T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ef8a5951-a459-4733-affc-104c38520243',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-03T19:30:00+00:00',
            endTime: '2024-01-03T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1f907c7c-7f95-4fff-858f-0eb0dce12424',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-03T20:45:00+00:00',
            endTime: '2024-01-03T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a78bd68e-0562-4c2d-b877-54cf3d8fe687',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-04T17:00:00+00:00',
            endTime: '2024-01-04T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'bd80adc6-1bb2-41e2-966b-fd1a42b1d523',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-04T18:15:00+00:00',
            endTime: '2024-01-04T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '137b8965-8d14-405b-9f3c-14afdec05f1e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-04T19:30:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9a9b2b99-d209-4663-8b80-8fd69bf53892',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-04T20:45:00+00:00',
            endTime: '2024-01-04T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '08848246-4f74-448e-a508-51ca9e89ae07',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-05T17:00:00+00:00',
            endTime: '2024-01-05T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4bf42a1e-3ac8-48f4-8ad1-0fc3fb162a37',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-05T18:15:00+00:00',
            endTime: '2024-01-05T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0021c712-4b93-4cbe-9961-8920e64cb043',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-05T19:30:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '062c432b-b245-4dfa-ae93-d1aef3851881',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-05T20:45:00+00:00',
            endTime: '2024-01-05T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'b76778a4-8449-47fe-824f-0768273e479b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-08T17:00:00+00:00',
            endTime: '2024-01-08T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e9de8a8c-5935-4d0b-9e14-88d2eb306df3',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-08T18:15:00+00:00',
            endTime: '2024-01-08T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '67d23cab-dc12-4c67-a7f1-5298186f475d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-08T19:30:00+00:00',
            endTime: '2024-01-08T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '38afff34-8578-40a9-bd14-6aa67d08d15c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: '77ab3c6f-d48c-4811-a892-8e0708c59c53',
            startTime: '2024-01-08T20:45:00+00:00',
            endTime: '2024-01-08T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
        name: 'Encuentro Personalizado',
        price: 20,
        duration: 60,
        speciality: 'financialAdvisor',
        format: 'person',
        description: null,
        expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
        keywords: ['criptomonedas'],
        time_slots: [
          {
            id: '2f1ff58a-2609-41eb-8cdd-98c827622637',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-19T17:00:00+00:00',
            endTime: '2023-12-19T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e6810a25-4214-42e8-907f-aefcc5fd4a04',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-19T18:15:00+00:00',
            endTime: '2023-12-19T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '93eef3c0-3f33-4d5c-82e1-8e72c3122fab',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-19T19:30:00+00:00',
            endTime: '2023-12-19T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ad369ebb-4e6d-4228-8260-17ba56aa759b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-19T20:45:00+00:00',
            endTime: '2023-12-19T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ff279e88-66a0-41b4-9f3b-1ac03925f14d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-20T17:00:00+00:00',
            endTime: '2023-12-20T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '67bb4ebb-99a5-4056-ae3b-ed605a727512',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-20T18:15:00+00:00',
            endTime: '2023-12-20T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '697bb96d-37da-43c6-8972-c94c1472a37a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-20T19:30:00+00:00',
            endTime: '2023-12-20T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0ea56aa7-e213-4985-a951-4aaed8f8cd32',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-20T20:45:00+00:00',
            endTime: '2023-12-20T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '024d77e7-68c6-43a1-85e9-2f90ac8dceae',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-21T17:00:00+00:00',
            endTime: '2023-12-21T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'eed5db58-328b-48bc-a911-bb9ebdcea34e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-21T18:15:00+00:00',
            endTime: '2023-12-21T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ad0d8278-1cbf-4645-b92f-4e79e232ee1a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-21T19:30:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0062cd1b-a6e0-4adb-96a1-a520b92cf3d2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-21T20:45:00+00:00',
            endTime: '2023-12-21T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '92440cbc-f480-4c9c-bb0a-92dcc44a0ea9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-22T17:00:00+00:00',
            endTime: '2023-12-22T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '424d3169-d704-48b8-b4ef-502705341969',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-22T18:15:00+00:00',
            endTime: '2023-12-22T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6fecf4c6-60fb-4d49-a6d5-7ac99d8fbae7',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-22T19:30:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '9c667ffe-3bf6-456e-928e-c0bb467f6212',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-22T20:45:00+00:00',
            endTime: '2023-12-22T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '70cdf047-73a6-44ce-a98f-179f78cedab8',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-25T17:00:00+00:00',
            endTime: '2023-12-25T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '06909375-4c7d-4b5e-a16e-f6dcf1733187',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-25T18:15:00+00:00',
            endTime: '2023-12-25T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '44f0c075-f27c-4a12-a016-5ea817e5608e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-25T19:30:00+00:00',
            endTime: '2023-12-25T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'fa94e524-b683-49ae-b830-77465ebed52f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2023-12-25T20:45:00+00:00',
            endTime: '2023-12-25T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '28353537-730d-48dc-922d-da25b0155e4c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-02T17:00:00+00:00',
            endTime: '2024-01-02T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1359f9ad-b4a8-45b2-8670-904b63ba2efc',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-02T18:15:00+00:00',
            endTime: '2024-01-02T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '48286fef-e9fa-475e-bd84-deb1b44124f9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-02T19:30:00+00:00',
            endTime: '2024-01-02T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4b465403-7249-4b01-b93b-6c4376564d8a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-02T20:45:00+00:00',
            endTime: '2024-01-02T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c391a256-46eb-4f66-a729-14c51512ad8b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-03T17:00:00+00:00',
            endTime: '2024-01-03T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e2f9b185-dfe1-46b3-b98c-2265e68dfa78',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-03T18:15:00+00:00',
            endTime: '2024-01-03T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e334cc84-4ffc-4bc2-a706-914c86051985',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-03T19:30:00+00:00',
            endTime: '2024-01-03T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'eaa89518-b9bf-4b30-8d17-83d74f3ed9d5',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-03T20:45:00+00:00',
            endTime: '2024-01-03T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '837424cd-40f3-4add-8f70-e6ffd6f00e92',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-04T17:00:00+00:00',
            endTime: '2024-01-04T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '400bba92-2708-4f84-abaa-0b12c3410094',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-04T18:15:00+00:00',
            endTime: '2024-01-04T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '5b1a57cc-ac91-46a8-aafa-48455c9c280d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-04T19:30:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '66927b8a-4fb1-4881-b3b5-74c6522f7961',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-04T20:45:00+00:00',
            endTime: '2024-01-04T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '109ffc0a-d54e-4072-929c-a9e08322da06',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-05T17:00:00+00:00',
            endTime: '2024-01-05T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e4a902e0-aa15-4898-8168-e00c0e3e7a38',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-05T18:15:00+00:00',
            endTime: '2024-01-05T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'df19cc69-3111-48e4-a9c1-d290d1217368',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-05T19:30:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd98c2ed6-5df6-4d32-ba96-fe9ad3ddb5ba',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-05T20:45:00+00:00',
            endTime: '2024-01-05T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '7dc70448-0483-4516-bcf7-09213b93d0c2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-08T17:00:00+00:00',
            endTime: '2024-01-08T18:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '8159b903-8e4c-4baf-915a-be70720b2f4b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-08T18:15:00+00:00',
            endTime: '2024-01-08T19:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ac5e3ba8-5c7e-479e-ba31-0837c90a2589',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-08T19:30:00+00:00',
            endTime: '2024-01-08T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'dc008500-9b1d-4ce0-bf7b-e888d9a9bd9d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 20,
            serviceId: 'a511c393-6bdb-4a78-8006-dc76fa14b50f',
            startTime: '2024-01-08T20:45:00+00:00',
            endTime: '2024-01-08T21:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: '5707e168-3278-437f-983f-bc2e394d4931',
        name: 'Conversación Extendida',
        price: 21,
        duration: 30,
        speciality: 'financialAdvisor',
        format: 'call',
        description: null,
        expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
        keywords: ['criptomonedas'],
        time_slots: [
          {
            id: '022f41d7-4537-459f-b71a-86c3d47a4fe1',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T17:00:00+00:00',
            endTime: '2023-12-19T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b6e9c77d-c62e-408c-921d-d13a8d01e967',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T17:45:00+00:00',
            endTime: '2023-12-19T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'ece04c3a-232e-4de0-9e94-68622f5f773c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T18:30:00+00:00',
            endTime: '2023-12-19T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b0f7acc9-fec5-4a67-b8cb-ca99b5ca29b2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T19:15:00+00:00',
            endTime: '2023-12-19T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4a430776-dfe9-452f-9e2e-b330082260db',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T20:00:00+00:00',
            endTime: '2023-12-19T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0911ebb8-b128-4837-bc51-2b38b1e07584',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T20:45:00+00:00',
            endTime: '2023-12-19T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6acc5758-8178-4496-811e-aaacddf2715c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-19T21:30:00+00:00',
            endTime: '2023-12-19T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e17d9756-b284-40b4-bea6-fc589a800b75',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T17:00:00+00:00',
            endTime: '2023-12-20T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b2b22080-4889-4dad-98fa-45dfd6765918',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T17:45:00+00:00',
            endTime: '2023-12-20T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'c4044cd4-ed7d-448f-8b76-05a8d054de4a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T18:30:00+00:00',
            endTime: '2023-12-20T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4b3d4217-58df-445a-a075-f79822a84c1f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T19:15:00+00:00',
            endTime: '2023-12-20T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '553789e0-3cda-4945-9228-ff674d0e9d3b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T20:00:00+00:00',
            endTime: '2023-12-20T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '20cfcaa5-484c-4aa5-bb58-226b04a884b8',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T20:45:00+00:00',
            endTime: '2023-12-20T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'e8953668-be65-4660-b5a2-615235dddab0',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-20T21:30:00+00:00',
            endTime: '2023-12-20T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '349a803a-a1ca-4c0b-bf01-1a9c532c8fbd',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T17:00:00+00:00',
            endTime: '2023-12-21T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '5a293e00-c3a8-4b60-b49c-fd44491c0f65',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T17:45:00+00:00',
            endTime: '2023-12-21T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'da8a6df0-91dc-4521-82a6-b110e50ce768',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T18:30:00+00:00',
            endTime: '2023-12-21T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'a65fd7d7-8a67-4566-9aeb-d1391da177f9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T19:15:00+00:00',
            endTime: '2023-12-21T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b60bb950-0a5b-4c1e-83b9-7b7d90ae6bf9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T20:00:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '423c7a82-490c-463f-9c82-f65581ce7147',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T20:45:00+00:00',
            endTime: '2023-12-21T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '2f46b021-286b-4986-8f12-c377d0fd2c18',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-21T21:30:00+00:00',
            endTime: '2023-12-21T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '0697ec65-a5b8-4cf6-9f73-32e20534f751',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T17:00:00+00:00',
            endTime: '2023-12-22T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b6df942a-aec7-488c-8978-a34308d1b5e3',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T17:45:00+00:00',
            endTime: '2023-12-22T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '39f81a20-4dca-4264-b718-f3c772a6a279',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T18:30:00+00:00',
            endTime: '2023-12-22T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'f9546b4b-c727-4602-9570-2a6b9fad3052',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T19:15:00+00:00',
            endTime: '2023-12-22T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '2f7fd77c-8900-4d57-9403-700bddd6fad2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T20:00:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'a62f1f28-3455-4089-bdd5-551cdca69e9f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T20:45:00+00:00',
            endTime: '2023-12-22T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'df13a868-41e2-426f-8860-ece2feed6ac2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-22T21:30:00+00:00',
            endTime: '2023-12-22T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '805c9b56-f970-472d-ae2e-bf5b38494883',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T17:00:00+00:00',
            endTime: '2023-12-25T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '7d9e20c1-b5fe-4d05-9a39-35d0cd99bdae',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T17:45:00+00:00',
            endTime: '2023-12-25T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'd274fef3-a4b2-4a22-989b-670ab9a07bf5',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T18:30:00+00:00',
            endTime: '2023-12-25T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'f351ad69-5ad6-41aa-9654-0c33e159f8aa',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T19:15:00+00:00',
            endTime: '2023-12-25T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'a048aa4d-b2ee-4eda-8100-a60088295ffe',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T20:00:00+00:00',
            endTime: '2023-12-25T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '554eaa4b-b83e-4e26-ac7a-c567e5770b8d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T20:45:00+00:00',
            endTime: '2023-12-25T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6d26c2ee-aed6-4ebc-bff0-4aa855167f34',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2023-12-25T21:30:00+00:00',
            endTime: '2023-12-25T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '9ba3ca8c-423a-4922-9064-a982f5d628c3',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T17:00:00+00:00',
            endTime: '2024-01-02T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'fa9a5e6a-4dc7-4ae3-aff6-5142425a53b6',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T17:45:00+00:00',
            endTime: '2024-01-02T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '7b1278e2-0a5a-491e-8f80-2d20509bae88',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T18:30:00+00:00',
            endTime: '2024-01-02T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '94f06d33-57b8-449d-bc99-b23604f068b1',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T19:15:00+00:00',
            endTime: '2024-01-02T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '74fb6de9-677c-482b-af72-0e50d85403f7',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T20:00:00+00:00',
            endTime: '2024-01-02T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '2ec074dd-9df0-411e-a2b1-9560e7ddac6f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T20:45:00+00:00',
            endTime: '2024-01-02T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '6467f7fd-31af-4c51-b96f-b2659518e20c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-02T21:30:00+00:00',
            endTime: '2024-01-02T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a8e6b04d-24d0-40ea-85e6-99fcf99c973b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T17:00:00+00:00',
            endTime: '2024-01-03T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '408ce178-6cfa-4da7-b993-fc1636d30046',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T17:45:00+00:00',
            endTime: '2024-01-03T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '35dd7de4-2284-47d8-8fc5-d71f8bc05f3b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T18:30:00+00:00',
            endTime: '2024-01-03T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'fe7deba3-00ac-4a30-a327-135ef078c5db',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T19:15:00+00:00',
            endTime: '2024-01-03T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1c2f7548-7e65-46bc-b2cf-3cd85ecd514f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T20:00:00+00:00',
            endTime: '2024-01-03T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '36aee001-4691-4282-b808-1b6c2de4cb04',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T20:45:00+00:00',
            endTime: '2024-01-03T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '808109c8-445f-4376-a55f-c5ec9e576311',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-03T21:30:00+00:00',
            endTime: '2024-01-03T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '4ff3b0b6-0714-44dd-8990-b5428923e09d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T17:00:00+00:00',
            endTime: '2024-01-04T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'b82c12c8-95a4-4f37-8784-a50fdb8651cb',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T17:45:00+00:00',
            endTime: '2024-01-04T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '8619b5f0-d2ad-4de5-9f74-62bf12301341',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T18:30:00+00:00',
            endTime: '2024-01-04T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c83e7eaf-f61b-4dc9-bea7-7a936e395492',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T19:15:00+00:00',
            endTime: '2024-01-04T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '1b0e3446-9647-479c-83a0-1e4b74421422',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T20:00:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '58e51370-9d99-4f50-a3a0-fcc8fc5017be',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T20:45:00+00:00',
            endTime: '2024-01-04T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'de08f16a-e2a4-4147-a582-d40e120d353c',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-04T21:30:00+00:00',
            endTime: '2024-01-04T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '45505878-7066-4466-bdce-2d0d53e0a775',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T17:00:00+00:00',
            endTime: '2024-01-05T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a62bc54d-f3af-4de4-91be-1e0a947e35a7',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T17:45:00+00:00',
            endTime: '2024-01-05T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'de22f2a4-8cd0-415b-b337-ad6910738b2a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T18:30:00+00:00',
            endTime: '2024-01-05T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '97b361b8-dd0e-4138-8032-39cd8e2eb42b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T19:15:00+00:00',
            endTime: '2024-01-05T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '549555b2-01e8-4a8d-aeff-2b2dbeb60d93',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T20:00:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '2c2b9a2e-9921-4c04-9ff5-eb25a564f010',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T20:45:00+00:00',
            endTime: '2024-01-05T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '06e48d8c-356a-4d52-9b95-11e8dd93d3bf',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-05T21:30:00+00:00',
            endTime: '2024-01-05T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'bcfbc169-5881-4c80-8147-9db8494d9e6f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T17:00:00+00:00',
            endTime: '2024-01-08T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '7443e05e-4414-4bcc-9e92-528f08092e80',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T17:45:00+00:00',
            endTime: '2024-01-08T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'b0d5e658-0923-4a5e-ad88-c6f3d8acfab2',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T18:30:00+00:00',
            endTime: '2024-01-08T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'b4f7baf5-c930-4999-86a3-dfd767c9c2f5',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T19:15:00+00:00',
            endTime: '2024-01-08T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3b813abb-c009-433e-9fb4-d381a7bfd669',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T20:00:00+00:00',
            endTime: '2024-01-08T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ffa1d5ca-8216-403b-88ec-ed0ae963625d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T20:45:00+00:00',
            endTime: '2024-01-08T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f2ffd975-16c3-482e-8041-394d509f401d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: '5707e168-3278-437f-983f-bc2e394d4931',
            startTime: '2024-01-08T21:30:00+00:00',
            endTime: '2024-01-08T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
      {
        id: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
        name: 'Conversación Extendida',
        price: 21,
        duration: 30,
        speciality: 'financialAdvisor',
        format: 'call',
        description: null,
        expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
        keywords: ['criptomonedas'],
        time_slots: [
          {
            id: '9c0b8cef-8c1d-43d8-88d0-e15a4dc06ef3',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T17:00:00+00:00',
            endTime: '2023-12-19T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '6fc1561f-fb51-46cc-9389-4d5893711d19',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T17:45:00+00:00',
            endTime: '2023-12-19T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '4184f9df-f143-4d96-8a36-08d7ae77d941',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T18:30:00+00:00',
            endTime: '2023-12-19T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '4c4616c4-43a5-4fea-a464-349668396e9d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T19:15:00+00:00',
            endTime: '2023-12-19T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'a0c3ebbe-7244-4521-a9d5-e6be5626e902',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T20:00:00+00:00',
            endTime: '2023-12-19T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'b433ff79-59b2-4c75-ad80-2920c701cbbe',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T20:45:00+00:00',
            endTime: '2023-12-19T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'f9e926cd-b067-4af8-a336-4b9fc9d2ef69',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-19T21:30:00+00:00',
            endTime: '2023-12-19T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'cf4c809a-307e-43f7-9d5f-cd2240f8872b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T17:00:00+00:00',
            endTime: '2023-12-20T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '0ad7f1a4-f618-446a-8dcc-287aff8ae0d1',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T17:45:00+00:00',
            endTime: '2023-12-20T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '35b45eb2-5d7b-4ee9-b3b5-23745919feb7',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T18:30:00+00:00',
            endTime: '2023-12-20T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '4bd9ec73-e821-4e6d-a3d2-c0ced3b4a491',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T19:15:00+00:00',
            endTime: '2023-12-20T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '6dbb6fb2-3ea8-4c83-824c-5582228ce76e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T20:00:00+00:00',
            endTime: '2023-12-20T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '0db917b3-9765-47f9-ad2d-ddb9c5eeca3f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T20:45:00+00:00',
            endTime: '2023-12-20T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '8e1b754a-cb98-499c-a320-c950c5dd6b67',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-20T21:30:00+00:00',
            endTime: '2023-12-20T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'a8e0af38-e614-490e-8e9e-d1caac6fea90',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T17:00:00+00:00',
            endTime: '2023-12-21T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '52170bf3-c055-471b-917a-aa25cf0961fe',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T17:45:00+00:00',
            endTime: '2023-12-21T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'c0e0f665-6f33-45ec-9ef2-a50a8a33e222',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T18:30:00+00:00',
            endTime: '2023-12-21T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '4d72914d-eb2c-4bfe-9adb-a8a111f30d6d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T19:15:00+00:00',
            endTime: '2023-12-21T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '937943f2-d3e9-4866-ae01-d60821a5f073',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T20:00:00+00:00',
            endTime: '2023-12-21T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '3ca87a95-5e27-493b-ac8b-115a4bec96a8',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T20:45:00+00:00',
            endTime: '2023-12-21T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'a1a4892b-87f3-4a92-888e-cd842996b3c4',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-21T21:30:00+00:00',
            endTime: '2023-12-21T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '1476773f-3f3d-4366-a269-b5713af9ad63',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T17:00:00+00:00',
            endTime: '2023-12-22T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '704679b1-2829-4848-8657-a9a4cc03521b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T17:45:00+00:00',
            endTime: '2023-12-22T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '258ca34f-a57c-414a-bb9e-265d4fd38702',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T18:30:00+00:00',
            endTime: '2023-12-22T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'ac6fa36f-afc6-40e4-9fc8-733d4e185ea0',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T19:15:00+00:00',
            endTime: '2023-12-22T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'f1bb5478-0c64-48c3-9f52-55abcbf87ee6',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T20:00:00+00:00',
            endTime: '2023-12-22T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '88d0ba3c-a778-4ef8-b46c-05eecdb7e283',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T20:45:00+00:00',
            endTime: '2023-12-22T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '7a866503-6845-4c6e-b7c7-85b2aa85b3a7',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-22T21:30:00+00:00',
            endTime: '2023-12-22T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '1d4685a2-e127-4c68-88bb-b1a5f182a178',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T17:00:00+00:00',
            endTime: '2023-12-25T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'b73c12a4-7a8c-44e6-8d83-b78dcaa36ff6',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T17:45:00+00:00',
            endTime: '2023-12-25T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'de5d3389-70fd-41ed-96f5-ae0e5e3d23f9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T18:30:00+00:00',
            endTime: '2023-12-25T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '4c974dbd-d82c-4308-b774-ec63e1ae256a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T19:15:00+00:00',
            endTime: '2023-12-25T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'fcba6f85-ac10-42bc-ac75-bf5ba23eb71d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T20:00:00+00:00',
            endTime: '2023-12-25T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '09621415-bcf7-432c-bcae-1dbecb2621bc',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T20:45:00+00:00',
            endTime: '2023-12-25T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: 'da9cc85e-5810-4c83-a8f5-b6d9a6a76654',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2023-12-25T21:30:00+00:00',
            endTime: '2023-12-25T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-11-26T23:59:01.473+00:00',
          },
          {
            id: '54c4032e-5959-4826-a0ff-1598db551b45',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T17:00:00+00:00',
            endTime: '2024-01-02T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9aa9a8c2-6935-449d-bed5-7dd197515a23',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T17:45:00+00:00',
            endTime: '2024-01-02T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '3d00f9ad-79f1-47f8-beb8-8a1f1fc0902e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T18:30:00+00:00',
            endTime: '2024-01-02T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '79e393ef-e9dc-485b-88cf-3c24247d906d',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T19:15:00+00:00',
            endTime: '2024-01-02T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '9a21a968-5cda-4051-a18c-6afb15620511',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T20:00:00+00:00',
            endTime: '2024-01-02T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ac223451-a3d2-4e25-99a8-90938dc77a73',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T20:45:00+00:00',
            endTime: '2024-01-02T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0a7436b3-7b8f-4d90-a39f-9a8773216cac',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-02T21:30:00+00:00',
            endTime: '2024-01-02T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c9704d83-0432-4202-a033-4136e08251bd',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T17:00:00+00:00',
            endTime: '2024-01-03T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '5006a61d-1043-4224-90e2-607a8f942bb1',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T17:45:00+00:00',
            endTime: '2024-01-03T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '03bb9e48-33b1-422a-bfab-1af92fa9247f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T18:30:00+00:00',
            endTime: '2024-01-03T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '90989b21-0c47-4f72-80fb-877b34c42346',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T19:15:00+00:00',
            endTime: '2024-01-03T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e22fdd2b-03e1-4e11-afd2-7a01a2f95120',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T20:00:00+00:00',
            endTime: '2024-01-03T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '7b0e6ecc-85c1-4e5f-91f9-1d69ad0f9f78',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T20:45:00+00:00',
            endTime: '2024-01-03T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '0e2da3e4-cef9-4cf0-b8c6-0d5e96448e25',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-03T21:30:00+00:00',
            endTime: '2024-01-03T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '129a276f-8032-42bb-aa5e-4a8e7a3182dd',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T17:00:00+00:00',
            endTime: '2024-01-04T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ba69d12e-f4f2-42d6-9a70-941db8bd15ef',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T17:45:00+00:00',
            endTime: '2024-01-04T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'b1d3b119-af4f-41dc-adca-030f020e9190',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T18:30:00+00:00',
            endTime: '2024-01-04T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a137576a-a6e8-4736-a476-449ced06b522',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T19:15:00+00:00',
            endTime: '2024-01-04T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'f8af7f4d-8bdd-4a06-8d73-dec23394ac3e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T20:00:00+00:00',
            endTime: '2024-01-04T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '056e512f-309d-4f0c-9edb-4a206f41357f',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T20:45:00+00:00',
            endTime: '2024-01-04T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '8caf6ed6-23e0-43d5-b10f-8286860bef60',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-04T21:30:00+00:00',
            endTime: '2024-01-04T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '71b58159-eced-4e0b-a413-63ddc907128b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T17:00:00+00:00',
            endTime: '2024-01-05T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '12a6f239-3756-42aa-9c2b-cd1a0a0cb444',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T17:45:00+00:00',
            endTime: '2024-01-05T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'c6307e98-13f1-444a-8f5d-74bf48212bca',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T18:30:00+00:00',
            endTime: '2024-01-05T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '2925c8a8-89ed-4647-94af-44eb600a60cd',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T19:15:00+00:00',
            endTime: '2024-01-05T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '814527ef-07a9-4d62-a877-a67f492a9376',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T20:00:00+00:00',
            endTime: '2024-01-05T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '06fe7842-85f6-44bc-b3f1-adb6b00fc33e',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T20:45:00+00:00',
            endTime: '2024-01-05T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '5f259701-d117-45a4-bdb3-c019e9774d71',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-05T21:30:00+00:00',
            endTime: '2024-01-05T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd6647921-548d-41f9-9aa4-2855dfbc8fdc',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T17:00:00+00:00',
            endTime: '2024-01-08T17:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '47507033-2b63-4503-86d6-c7df6c59ad41',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T17:45:00+00:00',
            endTime: '2024-01-08T18:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'd427b29a-4ae2-42de-a1bf-325da7fde83b',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T18:30:00+00:00',
            endTime: '2024-01-08T19:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a22150c0-258a-4f08-8cdf-2f6937bcd537',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T19:15:00+00:00',
            endTime: '2024-01-08T19:45:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: '14de99e1-0399-4843-b2d6-9ffa4744abbe',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T20:00:00+00:00',
            endTime: '2024-01-08T20:30:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'a62bf455-c9dd-41bb-8915-c20a0352e2d9',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T20:45:00+00:00',
            endTime: '2024-01-08T21:15:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'e1f2b4d2-6717-4618-bfec-63dcad160a8a',
            availabilityId: 'e47626f0-0053-485b-a0fe-9be4688f610a',
            status: 'available',
            price: 21,
            serviceId: 'd7fca344-8a01-4cf9-ac94-25a266ddc4db',
            startTime: '2024-01-08T21:30:00+00:00',
            endTime: '2024-01-08T22:00:00+00:00',
            expertId: '3fmiEGtnoPaSp6nHta4LHWNSYQN2',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
  {
    id: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
    phone: '+16689541234',
    creation_date: '2023-11-21T11:51:28+00:00',
    birthday: '1995-05-08',
    name: 'Fernando Jimenez',
    sex: 'Male',
    isMock: false,
    score: null,
    headline: 'Pruebas de BreakTime',
    education: 'GeeksHub',
    company: null,
    username: 'fernandoJscience',
    avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
    email: 'probandobreaktime@a.com',
    oral_coins: null,
    is_connected: true,
    email_verified: false,
    phone_verified: false,
    role: 'Expert',
    speciality: ['science'],
    languages: ['ES', 'EN', 'FR'],
    job: 'Tester',
    provider: 'password',
    last_sign_in: '2023-11-29T10:15:14+00:00',
    avgReview: null,
    nReviews: null,
    services: [
      {
        id: '688686ad-76a0-4c02-8383-f5dd48753e9c',
        name: 'Testeo BreakTime',
        price: 35,
        duration: 40,
        speciality: 'science',
        format: 'video',
        description: 'Testeando BreakTime',
        expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
        keywords: ['física'],
        time_slots: [
          {
            id: '65c159a6-4551-42a6-93a6-3d504ad38c60',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-23T09:30:00+00:00',
            endTime: '2023-12-23T10:10:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: '61963eb7-8e82-4ef3-ac91-10be6555eef9',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-23T10:20:00+00:00',
            endTime: '2023-12-23T11:00:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'e49864d8-b882-4d2e-b583-4c66acb956e5',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-23T11:10:00+00:00',
            endTime: '2023-12-23T11:50:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-11-26T23:59:01.474+00:00',
          },
          {
            id: 'a6333643-9477-45af-ad25-590abf45b8a3',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-30T09:30:00+00:00',
            endTime: '2023-12-30T10:10:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '22a816f2-76d0-4bbc-9d26-2a17284a2657',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-30T10:20:00+00:00',
            endTime: '2023-12-30T11:00:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '72503004-ff13-4e73-93e3-244c1a6696ad',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2023-12-30T11:10:00+00:00',
            endTime: '2023-12-30T11:50:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-03T23:59:01.478+00:00',
          },
          {
            id: '36b67fad-b433-4722-b332-379dfede9d4a',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2024-01-06T09:30:00+00:00',
            endTime: '2024-01-06T10:10:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'ba9c7689-eab9-46ea-bdbf-ae85c63776c2',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2024-01-06T10:20:00+00:00',
            endTime: '2024-01-06T11:00:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
          {
            id: 'af3bc10b-9721-488d-b643-b7c3e62dc661',
            availabilityId: '6e47cdae-ff38-460b-9e4d-074e79de87d4',
            status: 'available',
            price: 35,
            serviceId: '688686ad-76a0-4c02-8383-f5dd48753e9c',
            startTime: '2024-01-06T11:10:00+00:00',
            endTime: '2024-01-06T11:50:00+00:00',
            expertId: 'EVaC1DRMexMQifCYK7wKwsD7xT62',
            creationDate: '2023-12-10T23:59:01.187+00:00',
          },
        ],
      },
    ],
  },
];
