import {
  Alignment,
  BASE_PIXEL,
  eDisplay,
  EeSize,
  IS_IOS,
  TextType,
} from '../../ui';
import C from 'consistencss';
import React, {ReactNode, useState} from 'react';
import {SafeAreaView, StatusBar} from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

import {GIcon, GText} from '../../atoms';
import {HeaderIconTypes} from './ee-secondary-header';
import {useGNav} from '../../routes/useGNav';

//Header default extended height
export const HEADER_MAX_HEIGHT = BASE_PIXEL * 14;

//Header minimum height after scrolling up
export const HEADER_MIN_HEIGHT = HEADER_MAX_HEIGHT * 0.5;

const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;

export type EeAnimatedHeaderProps = {
  title?: string;
  id?: string;
  StickyHeader?: ReactNode;
  scrollOffsetY: SharedValue<number>;
} & HeaderIconTypes;

export const EeCollapsibleHeader = ({
  title,
  scrollOffsetY,
  iconRight,
  iconLeft,
  StickyHeader,
  onPressRight,
  onPressLeft,
}: EeAnimatedHeaderProps) => {
  const [height, setHeight] = useState(0);
  const showLine = height <= HEADER_MAX_HEIGHT * 0.6;
  const navigation = useGNav();

  const borderBottomWidth = useAnimatedStyle(() => {
    return {
      borderBottomColor: palette.primaryMercuryGrey,
      borderBottomWidth: interpolate(
        scrollOffsetY.value,
        [0, HEADER_SCROLL_DISTANCE],
        [0, IS_IOS ? 1 : 0.2],
        Extrapolation.CLAMP,
      ),
    };
  });

  const headerOpacity = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollOffsetY.value,
        [HEADER_MIN_HEIGHT, HEADER_MAX_HEIGHT],
        [0, 1],
        Extrapolation.CLAMP,
      ),
    };
  });
  return (
    <SafeAreaView style={[eDisplay.onTop, C.h12]}>
      <Animated.View
        style={[
          {paddingTop: StatusBar.currentHeight},
          C.itemsCenter /*showLine ? eColor(palette.primaryMercuryGrey).borderBottom : C.px4,*/,
          C.row,
          C.bgBackground,
          C.justifyEnd,
          eDisplay.onTop,
          !StickyHeader && borderBottomWidth,

          // TODO: Check if spacing/height is correct as soon as images on Android are shown.
          {height: HEADER_MIN_HEIGHT + (StatusBar.currentHeight || 0) / 2 + 40},
        ]}
        onLayout={event => {
          const {height: currHeight} = event.nativeEvent.layout;
          setHeight(currHeight);
        }}
      >
        {iconLeft && (
          <GIcon
            icon={iconLeft}
            /*size={EeSize.avatar}*/
            color={palette.orangeSec}
            circular
            wrapStyle={[
              C.left2,
              C.top2,
              C.absolute,
              eDisplay.onTop,
              C.bgBackground,
            ]}
            onPress={onPressLeft}
          />
        )}
        <Animated.View style={[C.flex, C.justifyEnd, headerOpacity]}>
          <GText
            style={C.uppercase}
            numberOfLines={1}
            type={TextType.h3Orange}
            align={Alignment.Center}
          >
            {title}
          </GText>
        </Animated.View>
        {iconRight && (
          <GIcon
            icon={iconRight}
            size={EeSize.avatar}
            color={palette.orangeSec}
            circular
            wrapStyle={[C.right4, C.top4, C.absolute, C.bgBackground]}
            onPress={onPressRight}
          />
        )}
      </Animated.View>
      {StickyHeader && (
        <Animated.View style={[C.pl4, C.bgBackground, headerOpacity]}>
          {!showLine && StickyHeader}
        </Animated.View>
      )}
    </SafeAreaView>
  );
};
