import C from 'consistencss';
// @ts-ignore
import Modal from 'modal-enhanced-react-native-web';
import React from 'react';

import {ScrollView, View} from 'react-native';

import {eDisplay, EeSize, StylesArray} from '../ui';
import useResponsive from '../ui/helpers/useResponsive';
import {GIcon} from '../atoms';
import {FullIconType} from '../types/icon-types';

export const GModalWeb = ({
  visible = false,
  onClose = () => {},
  isFull = false,
  params,
  icon,
  children,
  contentStyle,
  title,
}: {
  visible?: boolean;
  params?: Object;
  onClose?: () => void;
  isFull?: boolean;
  icon?: FullIconType;
  children?: JSX.Element;
  contentStyle?: StylesArray;
  title?: string;
}) => {
  const {isSmall, resp} = useResponsive();

  const showHeader = !!title || !!icon;
  return (
    <Modal
      onDismiss={onClose}
      onBackButtonPress={onClose}
      isVisible={visible}
      visible={visible}
      transparent /*={!(IS_IOS && isFull)}*/
      onSwipeComplete={onClose}
      /*
                                                                                                                presentationStyle={/!*(IS_IOS || isSmall || isFull) && 'pageSheet'*!/}
                                                                                                          */
      onRequestClose={onClose}
      onBackdropPress={onClose}
    >
      <View
        style={[
          C.radius4,
          resp(0.9).maxWidth,
          isSmall && resp(0.9).maxHeight,
          C.selfCenter,
          C.bgWhite,
        ]}
      >
        <View style={[C.px2, C.radiustop2, showHeader && C.bgPrimaryBlue]}>
          {/**Header*/}
          {/*{showHeader && (
            <View style={[C.row, C.justifyBetween, C.p4, C.bgPrimaryBlue]}>
              <View style={[C.row, C.itemsCenter]}>
                * @ts-ignore
                {icon && <Image source={icon} style={[vSizes.icon]} />}
                {title && <GText style={[fonts.title1, icon && C.ml3]}>{title}</GText>}
              </View>
            </View>
          )}*/}
          <GIcon
            icon={'close'}
            onPress={onClose}
            size={EeSize.iconWrapper}
            wrapStyle={[C.absolute, C.p2, eDisplay.onTop, C.right4, C.top4]}
          />
          <ScrollView
            showsHorizontalScrollIndicator={false}
            style={[
              C.radiusbottom2,
              isSmall ? C.p6 : C.p12,
              /*C.m_2,*/ resp(0.9).maxHeight,
              contentStyle,
            ]}
          >
            {children}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};
