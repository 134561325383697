import {GText} from '../atoms';
import {
  Alignment,
  customText,
  eColor,
  EeSize,
  gImages,
  textStyles,
  TextType,
} from '../ui';
import {FlatList, View} from 'react-native';
import React, {useState} from 'react';
import {trans} from '../store/language';
import {
  AvailabilitySettingsType,
  DayKey,
  gSpecialities,
  palette,
  RecurrenceKey,
  serviceFormatMap,
  ServiceType,
  SpecialityKey,
  statusInfoMapping,
  TimeslotStatus,
  TimeSlotType,
  UserType,
} from 'oralify-common'; // Asumiendo que esta importación está correcta
/*import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';*/
import {groupBy, isArray} from 'lodash';
import C from 'consistencss';
import {useTabs} from '../filters/useTabs';
import {
  DATE_FORMAT_SHORT,
  groupTimeSlotsByDate,
  timeToHourUTC,
} from '../types/utils/dates';
import {GBadge} from '../molecules/gBadge';
import WeekView from 'react-native-week-view';
import TimeslotSelector from './TimeslotSelector';
import useResponsive from '../ui/helpers/useResponsive';
import {AvailabilityList} from '../screens/AvailabilityList';
import {apiCalendarWeb} from './GoogleCalendarSelector';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {googleCalendarEvents} from '../store/useUser';
import {GAvatar} from '../molecules/GAvatar';
import {SETTINGS_STEPS} from '../types/stepTypes';
/*
import {
  CalendarListItemProps
} from "react-native-calendars/src/calendar-list/item";
*/

/*addLocale('es', {
  dayNames: [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado',
  ],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  monthNames: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  monthNamesShort: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ],
});*/

export const dayCalendarViews = {
  dayView: {
    icon: 'view-day',
    title: 'Diario',
    nDays: 1,
  },
  threeDayView: {
    icon: 'calendar-range',
    title: '3 días',
    nDays: 3,
  },
  fiveDayView: {
    icon: 'calendar-week',
    title: '5 días',
    nDays: 5,
  },
  weekView: {
    icon: 'calendar-week',
    title: 'Semanal',
    nDays: 7,
  },
  calendarView: {
    icon: 'calendar-month',
    title: 'Mensual',
    nDays: 30,
  },
  agendaView: {
    icon: 'view-agenda',
    title: 'Agenda',
    nDays: 50,
  },
};
export const RecurrenceSpanish = {
  [RecurrenceKey.Daily]: 'Diario',
  [RecurrenceKey.Weekly]: 'Semanal',
  [RecurrenceKey.Monthly]: 'Mensual',
  [RecurrenceKey.Yearly]: 'Anual',
};
export const WeekCalendarTimeSlots = ({user}: {user: UserType}) => {
  const [selectedStatus, setSelectedStatus] = useState<TimeslotStatus[]>(
    Object.values(TimeslotStatus),
  );

  const navigate = useNavigate();

  /** Speciality Tabs*/
  const groupedServices = user.services
    ? groupBy(user.services, 'speciality')
    : {};
  const {TabHeaders, selectedTab: currentSpeciality} = useTabs<ServiceType>(
    groupedServices,
    user.services?.[0]?.speciality || '',
    true,
  );

  const currServices =
    groupedServices[currentSpeciality as SpecialityKey] ?? {};

  /** Service tabs*/
  const {TabHeaders: ServiceTabHeaders, selectedTab: currentService} =
    useTabs<ServiceType>(
      currentSpeciality ? Object.entries(currServices) : [],
      '0',
      true,
    );
  const currSpecialityService =
    currServices[currentService ? currentService : 0];
  const selectedServiceTimeSlots = currentSpeciality
    ? currSpecialityService?.time_slots || []
    : [];

  /** Status filter*/
  const matchingStatus = selectedServiceTimeSlots.filter(
    (slot: TimeSlotType) => slot.status && selectedStatus.includes(slot.status),
  );

  const formatedMatchingStatus = matchingStatus.map((slot: TimeSlotType) => {
    const {duration = 30, name = ''} = currServices ? currServices[0] : {};
    return {
      id: slot.id,
      description: duration > 60 ? ` ${name} (${slot.price}) €` : '',
      icon: 'calendar',
      resolveOverlap: 'ignore',
      startDate: new Date(slot.startTime),
      endDate: new Date(slot.endTime || slot.startTime),
      color:
        statusInfoMapping[slot.status || TimeslotStatus.pending].color +
        '60' /*${groupedServices[selectedTab as
     SpecialityKey][0].name*/ /*palette.purpleSec + '80'*/,
      /*color:
                                                      groupedServices[selectedTab as SpecialityKey]?.[currentService]?.color +
                                                      '80',*/
      textStyle: textStyles[TextType.inputField].defaultStyle,
    };
  });

  /**Service (Format) filter*/
  const [daysShown, setDaysShown] = useState(dayCalendarViews.weekView.nDays);
  const [googleEvents, setGoogleEvents] = useRecoilState(googleCalendarEvents);
  const [selectedService, setSelectedService] = useState<string[]>(
    (user?.services &&
      isArray(user?.services) &&
      user?.services?.map(({name, id}) => id || name || '')) ||
      [],
  );

  const serviceSlotsByDate = groupTimeSlotsByDate(matchingStatus);

  const renderStatusItem = ({item}: {item: TimeslotStatus}) => {
    const {color, icon, nameEs} = statusInfoMapping[item];
    return (
      <GBadge
        onPress={() => {
          const isAlreadySelected = selectedStatus.includes(item);
          setSelectedStatus(prev =>
            isAlreadySelected ? prev.filter(s => s !== item) : [...prev, item],
          );
        }}
        color={color}
        fontColor={color}
        icon={icon}
        /*wrapStyle={[eColor(color + '20').bg]}*/
        text={nameEs}
        long
        selected={selectedStatus.includes(item)}
      />
    );
  };

  // Renderizado de filtro de servicios
  const renderServiceItem = ({
    item,
    index,
  }: {
    item: ServiceType;
    index: number;
  }) => (
    <GBadge
      onPress={() => {
        const isAlreadySelected = selectedService.includes(item.id || '');
        setSelectedService(prev =>
          isAlreadySelected
            ? prev?.filter(s => s !== item.id)
            : [...prev, item.id],
        );
      }}
      symbol={item.price + '€'}
      /*actionIcon={item.speciality ? gSpecialities[item.speciality].emoji : '✨'}*/
      color={palette.purpleSec} //getServiceColors(user.services)[index]}
      fontColor={palette.purpleSec} //getServiceColors(user.services)[index]}
      /*wrapStyle={[eColor(getServiceColors(user.services)[index] + '20').bg]}*/
      icon={item.format ? serviceFormatMap[item.format].icon : '📅'}
      text={item.name + ` (${item.duration} min)`}
      long
      selected={selectedService.includes(item.id)}
    />
  );
  /** Display the availaiblity in the calendar */
  const createAvailabilityEvents = (availability: AvailabilitySettingsType) => {
    return availability.dayOfWeek.map(day => ({
      id: availability.id,
      description:
        timeToHourUTC(availability.startTime) +
        ' - ' +
        timeToHourUTC(availability.endTime),
      startDate: getNextOccurrenceOfWeekday(day, availability.startTime),
      endDate: getNextOccurrenceOfWeekday(day, availability.endTime),
      resolveOverlap: 'stack',
      textColor: palette.white,
      color: palette.orangeSec + '10', // or any light color with opacity
      borderColor: palette.orangeSec, // choose a color that signifies availability
      borderWidth: 2, // adjust the border width as needed
    }));
  };
  const getNextOccurrenceOfWeekday = (weekday: DayKey, time: string) => {
    /*const daysOfWeek = [
                                        'Sunday',
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday',
                                    ];*/
    const dayIndex = Object.keys(DayKey).indexOf(weekday);
    const now = new Date();
    const result = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + ((7 + dayIndex - now.getDay()) % 7),
    );
    const [hours, minutes] = time.split(':');
    result.setHours(parseInt(hours, 10));
    result.setMinutes(parseInt(minutes, 10));
    return result;
  };
  const availabilityEvents =
    user?.availabilityexpert
      ?.map(each => createAvailabilityEvents(each))
      ?.flat() || [];

  const {resp, isSmall} = useResponsive();
  return (
    <View style={[!isSmall && C.itemsCenter, C.mb6]}>
      <GText type={TextType.h3Orange}>
        {trans('Calendario de disponibilidad ')}
      </GText>
      <View style={[C.row]}>
        {Object.values(dayCalendarViews).map(({icon, nDays, title}) => (
          <GBadge
            onPress={() => {
              setDaysShown(nDays);
            }}
            color={palette.purpleSec}
            fontColor={palette.purpleSec}
            icon={icon}
            /*wrapStyle={[eColor(color + '20').bg]}*/
            text={title}
            long
            selected={nDays === daysShown}
          />
        ))}
      </View>

      <View>
        <TabHeaders
          valExtractor={item => gSpecialities[item as SpecialityKey]}
        />
        {/*<ServiceTabHeaders
          valExtractor={item => serviceFormatMap[item as ServiceFormatKey]}
          icon={serviceFormatMap[currentService as ServiceFormatKey].icon}
        />*/}

        {/*<GText numberOfLines={2}>{JSON.stringify(serviceSlotsByDate)}</GText>*/}
        {/** Disponibilidades*/}
        <FlatList
          data={Object.values(TimeslotStatus)}
          renderItem={renderStatusItem}
          horizontal
          ListHeaderComponent={
            <GBadge
              selected
              color={palette.bluePrim}
              fontColor={palette.bluePrim}
              actionIcon={'refresh'}
              icon={gImages.googleCalendar}
              text="Google Calendar"
              onPress={async () => {
                const resultLogin = await apiCalendarWeb.handleAuthClick();
                const {result} = await apiCalendarWeb.listUpcomingEvents(100);
                console.log('Response: ', resultLogin, result);
                setGoogleEvents(prevEvents => [
                  ...prevEvents,
                  result.items.map(
                    (event: {
                      id: any;
                      attendees: {photoLink: any}[];
                      summary: any;
                      description: any;
                      start: {dateTime: any};
                      end: {dateTime: any};
                    }) => ({
                      ...event,
                      id: event.id,
                      /*image: event.attendees?.[0]?.photoLink,*/
                      title: event.summary,
                      description: event.description,
                      startDate: event.start.dateTime,
                      endDate: event.end.dateTime,
                      resolveOverlap: 'stack',
                      textColor: palette.white,
                      color: palette.bluePrim + '60', // or any light color
                      // with opacity
                      borderColor: palette.bluePrim, // choose a color that signifies
                      // availability
                      borderWidth: 2, // adjust the border width as needed
                    }),
                  ),
                ]);
              }}
            />
          }
          showsHorizontalScrollIndicator={false}
          style={[C.m2]}
          keyExtractor={item => item}
        />
        {/** Disponibilidad*/}
        <GText type={TextType.h4}>Disponibilidad</GText>
        <AvailabilityList user={user} />
        {/** Servicios y formatos*/}
        <GText type={TextType.h4}>Servicios y formatos</GText>
        {/*{JSON.stringify(googleEvents[0])}
        {JSON.stringify(availabilityEvents[0])}*/}
        <FlatList
          data={currServices}
          renderItem={renderServiceItem}
          horizontal
          ListFooterComponent={
            <GBadge
              icon={'plus'}
              fontColor={palette.purpleSec}
              color={palette.purpleSec}
              onPress={() => {
                navigate('/profile/' + SETTINGS_STEPS.SERVICES);
              }}
              symbol={'Añadir'}
              text="servicio / formato"
              selected
            />
          }
          showsHorizontalScrollIndicator={false}
          style={[C.m4]}
          keyExtractor={item => item.id}
        />

        {/** Current Timeslots filtered*/}
        {/*<SafeAreaView
          style={[
            C.border1,
            {width: deviceWidth * 0.4, height: deviceHeight * 0.4},
            C.itemsCenter,
            C.justifyStart,
          ]}
        >*/}
        {daysShown < 30 ? (
          <View style={[resp(0.7).maxWidth]}>
            <WeekView
              hourTextStyle={[
                customText(TextType.label),
                C.absolute,
                C.left3,
                C.top1,
                C.minw4,
              ]}
              startHour={12} //user.availabilityexpert?.[0]?.startTime.split(':')[0]}
              formatDateHeader={DATE_FORMAT_SHORT}
              onDragEvent={() => {}}
              events={[
                ...googleEvents,
                ...formatedMatchingStatus,
                ...availabilityEvents,
              ]}
              headerTextStyle={[
                customText(TextType.label),
                eColor(palette.orangeSec).text,
              ]}
              headerStyle={[
                C.h4,
                C.mb_8,
                eColor(palette.orangeSec).borderBottom,
                /*C.radius1,*/
                {marginLeft: 2},
              ]}
              eventTextStyle={[
                customText(TextType.label),
                eColor(palette.white).text,
                {marginTop: -2},
              ]}
              EventComponent={({event}) => (
                <View
                  style={[
                    C.flex,
                    /*C.row,*/
                    /*event.borderColor &&
                                                                                                                                                                                      eColor(event.borderColor || event.color, 2).borderLeft,*/
                    C.maxw40,
                  ]}
                >
                  {(event.image || event.creator?.displayName) && (
                    <GAvatar
                      image={event.image}
                      name={event.creator?.displayName}
                      size={EeSize.sm}
                      isBig={false}
                    />
                  )}
                  <GText
                    numberOfLines={1}
                    color={event.textColor || palette.white}
                    type={TextType.bodyBold}
                    align={Alignment.Left}
                  >
                    {JSON.stringify(event.title)}
                  </GText>
                  <GText
                    numberOfLines={1}
                    align={Alignment.Left}
                    color={event.textColor || palette.white}
                    type={TextType.label}
                  >
                    {event.description}
                  </GText>
                </View>
              )}
              eventContainerStyle={[
                eColor(palette.orangeSec, 2).borderLeft,
                C.p_3,
                C.radius1,
              ]}
              resolveOverlap="stack"
              timesColumnWidth={0}
              enableVerticalPinch={true}
              /*timeStep={30}*/
              formatTimeLabel={'H:mm'}
              /*allowScrollByDay={false}*/
              /*TodayHeaderComponent={({date}) => (
                                                                                                                              <GText
                                                                                                                                style={[C.max4]}
                                                                                                                                color={palette.orangeSec}
                                                                                                                                type={TextType.label}
                                                                                                                              >
                                                                                                                                Hoy! {dayjs(new Date(date)).format(DATE_FORMAT_SHORT)}
                                                                                                                              </GText>
                                                                                                                            )}*/
              hoursInDisplay={
                /** TODO make it dependent on service*/ /*currServices?.[0]
                  ? currServices?.[0]?.duration
                  :*/ 48
              }
              /*windowSize={3}
                                                                                                                                    initialNumToRender={3}*/
              /*headerStyle={[C.w120]}
                                                                                                                                    /!*style={[C.borderBlue, C.border1]}*!/
                                                                                                                                    beginAgendaAt={9}
                                                                                                                                    windowSize={deviceWidth * 0.8}
                                                                                                                                    hoursInDisplay={24}
                                                                                                                                    /!*eventContainerStyle={[C.borderBlue, C.border1, C.maxwHalf]}*!/
                                                                                                                                    /!*gridRowStyle={[C.m1]}*!/
                                                                                                                                    startHour={new Date().getHours()}
                                                                                                                                    showTitle
                                                                                                                                    showNowLine*/
              /*isRefreshing={!user.services}*/
              showNowLine
              showTitle
              selectedDate={new Date()}
              numberOfDays={daysShown}
              nowLineColor={palette.orangeSec}
            />
          </View>
        ) : (
          <TimeslotSelector
            services={user.services}
            groupedTimeslots={serviceSlotsByDate}
          />
        )}
      </View>
    </View>
  );
};

/** TODO use legacy comp?
 *
 * */
/*const DnDCalendar = withDragAndDrop(Calendar);
const localizer = dayjsLocalizer(dayjs);*/

/*export type CalendarEvent = TimeSlotType & {
    title: string;
    start: Date;
    end: Date;
    status: TimeslotStatus;
};
/!** @deprecated legacy crappy UI calendar buggy as fuck *!/
const adaptEventsForService = (service: ServiceType): CalendarEvent[] => {
    return (
        service.time_slots?.map((event: TimeSlotType, index) => ({
            ...event,
            title: `${
                service.format ? serviceFormatMap[service.format].emoji : '📅'
            } ${service.name} (${service.price} €)`,
            start: dayjs(event.startTime).toDate(),
            end: dayjs(event.endTime).toDate(),
            status: event.status as TimeslotStatus,
            /!* Object.values(TimeslotStatus)[
                 index % Object.values(TimeslotStatus).length
               ],*!/
        })) || []
    );
};*/
/*{JSON.stringify(groupedTimeslots)}*/
/*<FlatList
        horizontal
        style={[C.maxwHalf, C.selfCenter]}
        data={groupedTimeslots}
        renderItem={renderDayItem}
        keyExtractor={(item, index) => index.toString()}
      />*/

/*<GText type={TextType.h3Orange} style={[C.my3, C.pt4]}>
        {trans('Calendario de' + ' disponibilidad')}
      </GText>*/

/** To be @deprecated*/
/*<DnDCalendar
        localizer={localizer}
        events={filteredEvents}
        locale="es"
        defaultDate={new Date()}
        eventPropGetter={eventStyleGetter}
        defaultView="agenda"
        scrollToTime={filteredEvents[0]?.startTime}
        enableAutoScroll
        width={deviceWidth * (isHuge ? 0.6 : isSmall ? 0.5 : 0.4)}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        timeslots={15} // Aumentar el espacio entre las citas. Ajusta este
        // número como prefieras.
        step={15} //user?.services[0]?.duration || 30} // Intervalo de tiempo
        // de 30
        // minutos.
        // Ajusta
        // este número
        // como prefieras.
        style={{height: '90vh'}}
        resizable
      />*/
