import {ImageSourcePropType} from 'react-native';
import chroma from 'chroma-js';
import {IS_WEB} from './constants';

import {palette} from 'oralify-common';

export type ColorType = keyof typeof palette;
export const complementColor = (color: string) =>
  chroma(color).set('hsl.h', 30).hex();

export const orangeGrad = [
  palette.purpleSec,
  palette.redSec,
  palette.orangeSec,
  palette.orangeLight,
];
export const colorScaleNum = (num: number) =>
  chroma.scale(orangeGrad).domain([0, num]);
export const colorScale = chroma.scale(orangeGrad).domain([0, 5]);
export const priceScale = chroma
  .scale([palette.redSec, palette.greenPrim])
  .domain([0, 250]);
export const reviewScale = chroma
  .scale([palette.redSec, palette.orangeSec, palette.greenPrim])
  .domain([1, 5]);

export type SVGTypes = keyof typeof eSVG;

export const eSVG = {
  oralCoin: require('../../public/assets/oralCoin.svg'),
  flow: require('../../public/assets/flow.svg'),
  redGrad: require('../../public/assets/gradients/redOrangeGradient.svg'),
  oralify: require('../../public/assets/oralify.svg'),
  oralifyBg: require('../../public/assets/oralify-white.svg'),
};

/** not yet deprecated, might use oralify-common instead**/
export const gImages = {
  ribbon: 'https://imgur.com/fVqksHg.png', //require('../../public/assets/RibbonOralify.png'),
  oralify404: 'https://imgur.com/HQUSSKk.png',
  oralify404b: 'https://imgur.com/FBsKyvq.png',
  iaSpecialist: 'https://imgur.com/d7nCRAj.png',
  googleMeet:
    'https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg',
  googleCalendar:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/1024px-Google_Calendar_icon_%282020%29.svg.png',
  iaSpecialistMessi: 'https://imgur.com/rbGJ39L.png',
  iaMobileStack: 'https://i.imgur.com/WMqJw2a.png',
  iaVideoCallAccept: 'https://i.imgur.com/YBYWEY7.png',
  shareMockup: 'https://imgur.com/XTAM8Eh.png',
  calendarVisits: 'https://imgur.com/spKcG5w.png',
  video:
    'https://img.freepik.com/free-vector/video-call-concept-illustration_114360-1635.jpg?w=340&t=st=1688338431~exp=1688339031~hmac=279f58959152d42a890923207323cc5f8dba436796447af7ada47dd3d42e736d',
  call: 'https://img.freepik.com/free-vector/calling-concept-illustration_114360-1823.jpg?w=340&t=st=1685275388~exp=1685275988~hmac=245f31b3ff81a9b4d463ff8a989a6b5902b158b84d128ca623ab9ed0e687ba92',

  audioMessage:
    'https://img.freepik.com/free-vector/voice-control-concept-illustration_114360-1250.jpg?w=340&t=st=1688338110~exp=1688338710~hmac=5908bdb29215cb5615e70259247a6c497d14ca4c276b95703f19cb90242e587c',
  //search: 'https://img.freepik.com/concepto-servicio-contratacion-ilustracion-vectorial_357257-941.jpg?w=1380',
  onlineQuestions:
    'https://img.freepik.com/vector-gratis/ilustracion-plana-soporte-al-cliente_23-2148889375.jpg',
  calendar:
    'https://img.freepik.com/vector-gratis/mujer-que-elige-fechas-reserva-cita-calendario_23-2148552956.jpg?w=340&t=st=1682963159~exp=1682963759~hmac=9d2ff34233aa97aaa46203d7c5ac69ef909f334a1fec07462afa59830d2a09fd',
  reveiws:
    'https://img.freepik.com/vector-gratis/concepto-retroalimentacion-plana-organica_52683-62653.jpg',
  audioMessageOld:
    'https://img.freepik.com/vector-gratis/ilustracion-concepto-chat-voz_114360-7629.jpg?w=380&t=st=1682962934~exp=1682963534~hmac=93d5e105458f5c004db422945d254b54adaf82dff2f139ad39e213921672e692',
  oralCoin: 'https://imgur.com/jq7wgP0.png', //require('../../public/assets/OralCoin.jpg'),
  // 'https://imgur.com/iSZPAV3.png',
  mockup: 'https://imgur.com/k6TxeCW.png',
  loginTalikng:
    'https://img.freepik.com/free-vector/conversation-concept-illustration_114360-1102.jpg?w=740',
  mockAudioFlower:
    'https://img.freepik.com/free-vector/users-translating-speech-with-smartwatch-digital-translator-portable-translator-electronic-language-translator-concept-pinkish-coral-bluevector-isolated-illustration_335657-2510.jpg',
  mockAudio:
    'https://img.freepik.com/free-vector/tiny-people-near-phone-with-voice-assistant-screen-man-woman-using-ai-speaking-into-speaker-recording-voice-messages-digital-devices-flat-vector-illustration-technology-software-concept_74855-25352.jpg?size=626&ext=jpg&ga=GA1.1.2066370242.1680077282&semt=ais',
  //gossipGirls: 'https://imgur.com/PDzsWBL.png',
  //happyGirl: 'https://imgur.com/WLNbKBq.png',
  //manCall: 'https://imgur.com/IpZQFtp.png',
  empty:
    'https://cdni.iconscout.com/illustration/premium/thumb/error-404-4344461-3613889.png',
  //listener_women: 'https://imgur.com/L6C1Mh5.png',
  women: 'https://imgur.com/xs7gJZC.png',
  men: 'https://cdn-icons-png.flaticon.com/512/4140/4140048.png' /* 'https://www.pngfind.com/pngs/m/543-5437796_sad-woman-meets-with-provider-listening-to-someone.png',*/, //https://imgur.com/xs7gJZC.png',
  profileGirl: 'https://cdn-icons-png.flaticon.com/512/4140/4140047.png',
  oralifyCirc: IS_WEB
    ? 'https://drive.google.com/file/d/1-PrQB4f7lzPhj_jL4vcz6teGew71uTZw/view'
    : (require('../../public/assets/oralify-circ.png') as ImageSourcePropType),
  oralifyWhite: 'https://i.imgur.com/wejZzro.png',
  oralifyBg: IS_WEB
    ? 'https://imgur.com/SDlBocO.png'
    : (require('../../public/assets/oralify-white.png') as ImageSourcePropType),
  oralify: IS_WEB
    ? 'https://imgur.com/0DLnafc.png'
    : (require('../../public/assets/oralify.png') as ImageSourcePropType),
  expertMale:
    'https://img.freepik.com/free-photo/close-up-shot-pleased-attractive-mature-guy-with-beard-blue-eyes-shirt-with-unbuttoned-collar-smiling-satisfied-front-coming-home-being-greeted-by-loving-dog-grey-wall_176420-35890.jpg?w=380&t=st=1697091827~exp=1697092427~hmac=24021601d2fce40aca15f85c030be8dd336b6531f55ae6be863fb0e09df704d9',
  expertFemale:
    'https://img.freepik.com/free-photo/beautiful-every-day-make-up-look_181624-21819.jpg?w=430',
  expert1:
    'https://img.freepik.com/free-photo/close-up-shot-pleased-attractive-mature-guy-with-beard-blue-eyes-shirt-with-unbuttoned-collar-smiling-satisfied-front-coming-home-being-greeted-by-loving-dog-grey-wall_176420-35890.jpg?w=340&t=st=1697087790~exp=1697088390~hmac=267d7af25a9b580e8ba38e15e15a4bc68fde77cdfcf78271087dc7ce28c62ad3',
  experts:
    'https://global-uploads.webflow.com/5eb5f7f78b63035f53364ccc/6165df93646d815c5c2aa9c6_Group%2014%20Copy-p-1600.jpeg',
};
