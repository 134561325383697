import LinearGradientNative from 'react-native-linear-gradient';
// @ts-ignore
import WebLinearGradient from 'react-native-web-linear-gradient';
import {IS_WEB, TextType} from '../ui';
import React from 'react';
import {View} from 'react-native';
import Recorder from 'recorder-js';
import C from 'consistencss';
import {GIcon, GText} from '../atoms';
import {IconSource} from '../types/icon-types';
import {round} from '../types/utils/dates';
import {palette} from 'oralify-common';

export const GMaskedView = ({maskElement, ...props}) =>
  React.createElement(View, props, maskElement);

export const GLinearGradient = IS_WEB
  ? WebLinearGradient
  : LinearGradientNative;

export const GAudio = Recorder;

export const GRating = ({defaultRating = 4, count = 5}) => {
  const stars = Array.from({length: count}, (_, index) => {
    const starScore = index + 1;
    const isFilled = starScore <= defaultRating;

    return (
      <GIcon
        key={index}
        icon={'md-star-sharp'}
        color={isFilled ? palette.yellowSec : palette.lightGrey}
        iconSource={IconSource.MaterialIcons}
      />
    );
  });

  return (
    <View style={[C.row, C.itemsCenter]}>
      {stars}
      <GText type={TextType.label}>{round(defaultRating, 1)}</GText>
    </View>
  );
};
