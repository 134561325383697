import {eColor, EeSize} from '../../ui';
import C from 'consistencss';
import React, {ReactNode} from 'react';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';

import {GIcon, GText} from '../../atoms';
import {palette} from 'oralify-common';

export type SelectFieldItemProps = TouchableOpacityProps & {
  isSelected: boolean;
  onPress: () => void;
  label: string;
  icon?: string;
  renderPrefix?: ReactNode;
};

export const SelectFieldItem = ({
  isSelected,
  label,
  onPress,
  icon,
  renderPrefix,
  ...rest
}: SelectFieldItemProps) => (
  <TouchableOpacity onPress={onPress} {...rest}>
    <View style={[isSelected && eColor(palette.background).bg]}>
      <View style={[C.row, C.itemsCenter, C.py2]}>
        {icon && (
          <GText
            numberOfLines={2}
            color={palette.orangeSec}
            style={[!!renderPrefix && C.mx2, C.w, isSelected && C.mr2]}
          >
            {icon}
          </GText>
        )}
        <GText
          numberOfLines={2}
          style={[C.flex, !!renderPrefix && C.ml2, isSelected && C.mr2]}
          color={isSelected ? palette.orangeSec : palette.greyDark}
        >
          {label}
        </GText>
        {isSelected && (
          <GIcon
            icon={'chevron-right'}
            size={EeSize.icon}
            color={palette.purpleSec}
          />
        )}
      </View>
    </View>
  </TouchableOpacity>
);
