import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import React, {useEffect} from 'react';
import {Landing} from '../screens/Landing';
import {UserList} from '../screens/UserList';
import {Profile} from '../screens/Profile';
import {AnalyticsProvider, AuthProvider, StorageProvider} from 'reactfire';
import {TooltipChildrenContext} from 'react-native-walkthrough-tooltip';
import {WebLayout} from '../components/LandingWrapper';
import EmbedUserCard from '../organisms/EmbedUserCard';
import {ExpertLanding} from '../screens/ExpertLanding';
import {AddUserWrapper} from '../modals/AddUserWrapper';
import {AddServiceWrapper} from '../modals/AddServiceWrapper';
import {useModal} from '../modals/useModal';
import {PublicProfile} from '../screens/PublicProfile';
import {
  firebaseApp,
  firebaseAuth,
  gAnalytics,
  gLogEvent,
  gStorage,
} from '../store/firebaseInit';
import {getAuth} from 'firebase/auth';
import {BookingSelect} from '../FormSelector/BookingModal';
import {DropdownProvider} from '../organisms/UseGDropdown';

export const useGNav: () => (path: string) => void = () => {
  const navigate = useNavigate();
  return (path: string) => navigate(path);
};

export const modalScreens = {
  login: 'Login',
  tooltip: 'Tooltip',
  burger: 'Burger',
  addService: 'Add Service',
  addServiceFormat: 'Add Service Format',
  availabilityCalendar: 'Add availability Calendar',
};

/** Generates */
export const routeQueryParam = (
  path: string,
  queryParams: Record<string, undefined | string | string[]>,
) => {
  const searchParams = new URLSearchParams();
  console.log(' before creating params: ', path,queryParams)
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined) {
      if (typeof value === 'string') {
        searchParams.append(key, value);
      }
    }
  });

  const search = `${path}?${searchParams.toString()}`;
  console.log('hoa about to go to ', search);
  return search;
};

/** almost @deprecated*/
export const routeParam = (
  screenKey: keyof typeof headerScreens,
  params?: Record<string, undefined | string | string[]>,
): string => {
  let path = headerScreens[screenKey]?.path || '';

  path = path.replace(/\/:(\w+)\??/g, (match, paramKey) => {
    const value = params?.[paramKey];
    if (value === undefined) {
      return 'all';
    }

    const paramValue = Array.isArray(value)
      ? value.map(encodeURIComponent).join(',')
      : encodeURIComponent(value);

    return `/${paramValue}`;
  });
  // Elimina segmentos duplicados resultantes de parámetros indefinidos
  path = path.replace(/\/{2,}/g, '/');

  console.log('About to go to ', path);
  return path;
};

export const headerScreens /*: Record<
  string,
  CommonType & {path: string; pathMain?: string; Comp: JSX.Element | React.FC}
>*/ = {
  landing: {
    icon: 'chatbubble-ellipses-outline',
    Comp: Landing,
    name: 'Servicios',
    path: '/',
  },
  accountLogin: {
    icon: 'chatbubble-ellipses-outline',
    Comp: Landing,
    name: 'Login',
    path: '/account/login',
  },
  expertInfo: {
    icon: 'account-star',
    Comp: ExpertLanding,
    name: '¿Eres experto?',
    path: '/expert/landing',
  },
  expertSearch: {
    icon: 'account-star',
    Comp: UserList,
    name: 'Expertos',
    path: '/experts/search' /*:category?/:specialityKey?/:serviceFormat?/:keywords?'*/,
  },
  booking: {
    Comp: BookingSelect,
    name: 'Expertos',
    path: '/booking/select' /*:expertUsername?/:serviceId?/:timeslotId?'*/,
  },
  profile: {
    icon: 'user',
    Comp: Profile,
    name: 'Perfil',
    path: '/profile/:step?',
    pathMain: '/profile',
  },
  /*services: {
    icon: 'megaphone',
    Comp: Profile,
    name: 'Servicios',
    path: '/services',
  },*/
  /*profile: {
    icon: 'calendar-check',
    Comp: PublicProfile,
    name: 'Calendario',
    path: '/profile',
  },*/
  /*publicProfile: {
    icon: 'calendar-check',
    Comp: PublicProfile,
    name: 'Calendario',
    path: '/profile/:id',
  },*/
  /*payments: {
    icon: 'bank',
    Comp: Profile,
    name: 'Pagos',
    path: '/payments',
  },*/
  /* embed: {
            icon: 'embed',
            Comp: EmbedUserCard,
            name: 'Embed',
            path: '/embed/:id',
          },*/
  faq: {
    icon: 'question',
    Comp: Landing,
    name: 'FAQs',
    path: '/',
  } /*notFound: {
    icon: 'question',
    Comp: GSpinner,
    name: 'NotFound',
    path: '*',
  },*/,
};
export const headerNav = [
  headerScreens.landing,
  headerScreens.expertInfo,
  headerScreens.faq,
];

export const WebNavigator = () => {
  const auth = getAuth(firebaseApp);
  //const navigationRef = useRef();
  /*const routeNameRef = useRef();*/

  /*TODO in case we need firestore
  const {status, data: firestoreInstance} = useInitFirestore(
    async firebaseApp => {
      //await enableIndexedDbPersistence(db, true);
      /!* configDotenv();*!/
      return initializeFirestore(firebaseApp, {});
    },
  );
  // firestore init isn't complete yet
  if (status === 'loading') {
    return <GSpinner />;
  }*/

  return (
    /*<FirestoreProvider sdk={firestoreInstance}>*/
    <AuthProvider sdk={firebaseAuth}>
      {/*<DatabaseProvider sdk={database}>*/}
      <StorageProvider sdk={gStorage}>
        <AnalyticsProvider sdk={gAnalytics}>
          <DropdownProvider>
            <TooltipChildrenContext.Provider value={{tooltipDuplicate: true}}>
              {/* <UserProvider>*/}
              <BrowserRouter>
                <WebRoutes />
              </BrowserRouter>
              {/*</UserProvider>*/}
              {/*<NavigationContainer
              initialState={initialState}
              linking={linking}
              ref={gNavRef}
              onReady={() =>
                                                                                                                                                                                                                                                                            (routeNameRef.current = gNavRef.getCurrentRoute().name)
                                                                                                                                                                                                                                                                          }
              onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = gNavRef.getCurrentRoute().name;

                if (previousRouteName !== currentRouteName) {
                  await logEvent(analytics, 'screen_web_' + currentRouteName, {
                    screen_name: currentRouteName,
                    screen_class: currentRouteName,
                  });
                }
                routeNameRef.current = currentRouteName;
              }}>
              <Stack.Navigator/>
                {Object.entries(headerScreens).map(([key, {name, Comp, title}]) => (
                  <Stack.Screen
                    key={key}
                    name={name}
                    options={{
                      title: title || name,
                      headerShown: false,
                      animationTypeForReplace: isLoggedIn ? 'pop' : 'push',
                    }}>
                    {props => (
                      <WebLayout screen={name}>
                        <Comp {...props} />
                      </WebLayout>
                    )}
                  </Stack.Screen>
                ))}
              </Stack.Navigator>
            </NavigationContainer>*/}
            </TooltipChildrenContext.Provider>
          </DropdownProvider>
        </AnalyticsProvider>
      </StorageProvider>
      {/*</DatabaseProvider>*/}
    </AuthProvider>
    /*</FirestoreProvider>*/
  );
};
export const WebRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    gLogEvent('page_view', {page_location: location.pathname});
  }, [location.pathname]);
  const {ModalComponent: BookingModalComp} = useModal(modalScreens.burger);
  const {ModalComponent: LoginModalComponent} = useModal(modalScreens.login);
  const {ModalComponent: AddServicesModal} = useModal(modalScreens.addService);
  return (
    <>
      <Routes>
        {Object.entries(headerScreens).map(([key, {name, path, Comp}]) => (
          <Route
            key={key}
            path={path}
            element={
              name && (
                <WebLayout screen={name}>
                  <LoginModalComponent>
                    <AddUserWrapper />
                  </LoginModalComponent>
                  {/*<BookingModalComp>
                    <BookingModal />
                  </BookingModalComp>*/}
                  <AddServicesModal>
                    <AddServiceWrapper />
                  </AddServicesModal>
                  <Comp />
                </WebLayout>
              )
            }
          />
        ))}
        {/*
      embed: {
    icon: 'embed',
    Comp: EmbedUserCard,
    name: 'Embed',
    path: '/embed/:id',
  },
      */}
        <Route
          path="/embed/:username?/:type?"
          element={
            <>
              <EmbedUserCard />
              <LoginModalComponent>
                <AddUserWrapper />
              </LoginModalComponent>
              <AddServicesModal>
                <AddServiceWrapper />
              </AddServicesModal>
            </>
          }
        />
        <Route
          path="/:username"
          element={
            <WebLayout screen="Perfil público">
              <PublicProfile />
            </WebLayout>
          }
        />
      </Routes>
    </>
  );
};
/*Old Version:
export const expertQuery = (
  category?: string,
  specialityKey?: SpecialityKey,
  serviceFormat?: ServiceFormatKey,
  keywords?: string[],
) => {
  const parts = [
    category ? `/${category}` : '',
    specialityKey ? `/${specialityKey}` : '',
    serviceFormat ? `/${serviceFormat}` : '',
    keywords ? `/${keywords.map(encodeURIComponent).join(',')}` : '',
  ];

  return `/experts${parts.join('')}`;
};*/
