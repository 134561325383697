import C, {apply} from 'consistencss';
import {View, ViewProps} from 'react-native';
import Animated, {SharedValue, useAnimatedStyle, withSpring} from 'react-native-reanimated';
import React from 'react';

/**
 * Simple animated progress bar.
 */
export interface ProgressBarProps extends ViewProps {
  /** |0, 1] */
  progress: number | SharedValue<number>;
}

export function EeProgressBar({progress, style, ...rest}: ProgressBarProps) {
  const actualValue = typeof progress === 'number' ? progress : progress.value;
  const progressStyle = useAnimatedStyle(() => ({
    flex: withSpring(
      Math.max(actualValue, 0.03),
      actualValue > 0 && actualValue < 1 ? {mass: 5, damping: 50} : undefined,
    ),
  }));

  return (
    <View style={apply({flexBasis: 150}, C.radius3, C.h1, C.bgShadow, C.row, style)} {...rest}>
      <Animated.View style={apply(C.radius3, C.bgPrimaryGreen, progressStyle)} />
    </View>
  );
}

export default EeProgressBar;
