import {
  Alignment,
  ColorType,
  eColor,
  IS_IOS,
  IS_WEB,
  StylesArray,
  TextType,
  useNumberOfColumns,
} from '../ui';
import C from 'consistencss';
import React, {ReactNode} from 'react';
import {
  KeyboardAvoidingView,
  SafeAreaView,
  StatusBar,
  View,
} from 'react-native';
import Animated, {
  useAnimatedScrollHandler,
  useSharedValue,
} from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {GText} from '../atoms';
import {Renderable} from '../molecules';
import {EeCollapsibleHeader} from './header/ee-collapsible-header';
import EeSecondaryHeader, {HeaderProps} from './header/ee-secondary-header';
import {FABButton} from '../molecules/GIconRow';
import useResponsive from '../ui/helpers/useResponsive';
import {palette} from 'oralify-common';

// Constants
enum CONSTANTS {
  fullGridWidthRatio = 1,
  columnSpanDefault = 6,
}

export type EeLayoutProps = {
  onFAB?: () => void;
  /**
   * Implements a ScrollView wrapper,
   * not recommended for FlatList as children
   */
  hasScroll?: boolean;
  /**
   * If having a TextInput,
   * scrolls to top to display
   * properly the TextInput area
   */
  centerContent?: boolean;
  /**
   * If having a TextInput,
   * scrolls to top to display
   * properly the TextInput area
   */
  hasKeyboard?: boolean;
  /**
   * Sets background color of a wrapper
   */
  backgroundColor?: ColorType | string;
  /**
   *  Sets safe area insets for wrapping view. If only renderHeader is set it will render insets at the top.
   *  If only renderFooter is set, it will render insets only at the bottom. If both are rendered insets for both top and bottom.
   */
  hasSafeArea?: boolean;
  /**
   * Children as content of a DpLayout
   */
  children?: ReactNode;
  /**
   * Children as content of a DpLayout
   */
  StickyHeader?: ReactNode;
  /**
   * Header component
   */
  HeaderComponent?: ReactNode;
  /**
   * Wrapper style
   */
  wrapStyle?: StylesArray;
  /**
   * Footer component
   */
  FooterComponent?: ReactNode;
  /**
   * Number of columns element should fill
   */
  contentColumnSpan?: 4 | 6 | 8 | 10 | 12;
  /**
   * Collapsible header component
   */
  collapsibleHeader?: boolean;
} & HeaderProps;

export const EeLayout = ({
  backgroundColor = palette.background,
  hasSafeArea = false,
  collapsibleHeader = false,
  onFAB,
  StickyHeader,
  hasScroll = !!collapsibleHeader,
  hasKeyboard = hasScroll,
  HeaderComponent = null,
  FooterComponent = null,
  centerContent = false,
  onPressLeft,
  wrapStyle,
  title,
  children,
  contentColumnSpan = CONSTANTS.columnSpanDefault,
  ...rest
}: EeLayoutProps) => {
  /*const {navigate} = useNavigation();*/
  // Setting proper components for wrappers
  const ContentWrapper: /*TODO fix me!*/ Renderable<any> = hasScroll
    ? Animated.ScrollView
    : hasSafeArea
    ? SafeAreaView
    : View;

  // Handling safe area properly, It fixes problem related to usage of SaveAreaView
  const {top, bottom} = useSafeAreaInsets();

  // Function for applying padding for either ScrollView or Wrapper
  const applyPadding = (
    shouldApplyAtTheTop: boolean,
    shouldApplyAtTheBottom: boolean,
  ) =>
    hasSafeArea && [
      shouldApplyAtTheTop && {paddingTop: top},
      shouldApplyAtTheBottom && {paddingBottom: bottom},
    ];

  // Hooks
  const numberOfColumnsInGrid = useNumberOfColumns();

  const {width, resp, isSmall} = useResponsive();

  // Calculating contentToGridWidthRatio
  const contentToGridWidthRatio = Math.min(
    contentColumnSpan / numberOfColumnsInGrid,
    CONSTANTS.fullGridWidthRatio,
  );

  // Getting proper side padding
  const hasFullWidthContent =
    contentToGridWidthRatio === CONSTANTS.fullGridWidthRatio;
  const horizontalPadding = !hasFullWidthContent && C.px1;

  const scrollOffsetY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler(event => {
    scrollOffsetY.value = event.contentOffset.y;
  });

  return (
    <SafeAreaView
      style={[
        eColor(backgroundColor).bg,
        C.flex,
        /*
        applyPadding(!!collapsibleHeader, !!FooterComponent),
*/
      ]}
      {...rest}
    >
      {/** Setting properties of a status bar */}
      <StatusBar
        barStyle="dark-content"
        backgroundColor="transparent"
        translucent
      />

      {/** Rendering top-header */}
      {!IS_WEB &&
        (HeaderComponent ||
          (title &&
            (collapsibleHeader ? (
              <EeCollapsibleHeader
                scrollOffsetY={scrollOffsetY}
                StickyHeader={StickyHeader}
                onPressLeft={
                  onPressLeft /*|| (() => navigate(BottomTabScreens.profile))*/
                }
                title={title}
                {...rest}
              />
            ) : (
              <EeSecondaryHeader title={title} {...rest} />
            ))))}

      <KeyboardAvoidingView
        behavior={IS_IOS ? 'padding' : 'height'}
        style={C.flex}
        enabled={hasKeyboard}
        /* keyboardVerticalOffset={BASE_PIXEL * 4} */
      >
        {/** Rendering scroll view for content */}
        <ContentWrapper
          showsVerticalScrollIndicator={false}
          onScroll={scrollHandler}
          // onScroll={Animated.event(
          //   [{ nativeEvent: { contentOffset: { y: scrollOffsetY.value } } }],
          //   { useNativeDriver: false }
          // )}
          scrollEventThrottle={10}
          contentContainerStyle={[
            C.itemsCenter,
            C.minhFull,
            /*
            applyPadding(!HeaderComponent, !FooterComponent),
*/
          ]}
          style={[/*horizontalPadding, */ !hasScroll && C.itemsCenter, C.flex]}
        >
          {/** Content wrapper */}
          <View
            style={[
              C.flex,
              isSmall ? C.pl4 : C.p4,
              centerContent && C.itemsCenter,
              IS_WEB && [/*!isSmall && resp(0.9).maxWidth,*/ C.selfStart],
              collapsibleHeader && [C.minhFull],
              /*horizontalPadding,*/
              C.bgBlue,
              wrapStyle,
              /*{width: width * contentToGridWidthRatio},*/
            ]}
          >
            {collapsibleHeader && (
              <GText
                style={C.uppercase}
                numberOfLines={1}
                type={TextType.h1}
                align={Alignment.Left}
              >
                {title}
              </GText>
            )}
            {collapsibleHeader && StickyHeader}
            {children}
          </View>
        </ContentWrapper>
      </KeyboardAvoidingView>
      {/** Rendering footer */}
      {FooterComponent}
      {onFAB && <FABButton onPress={onFAB} opacity />}
    </SafeAreaView>
  );
};

export default EeLayout;
