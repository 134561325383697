import React, {useState} from 'react';
import {gLanguages, LanguageKey, palette} from 'oralify-common';
import {useGDropdown} from '../organisms/UseGDropdown';
import {GIcon} from '../atoms';
import {EeSize} from '../ui';
import {urlRegex} from '../types/utils/dates';
import C from 'consistencss';
import {GBadge} from '../molecules/gBadge';
import {ItemType} from 'react-native-dropdown-picker';
import _ from 'lodash';

export const useLangSelector = ({
  initialLang = [],
}: {
  initialLang?: LanguageKey[];
}) => {
  const [currLang, setCurrLang] = useState<ItemType<LanguageKey>[]>(
    initialLang.map(lang => ({
      value: lang,
      label: gLanguages[lang]?.name,
    })),
  );
  const langMap = Object.entries(gLanguages).map(([key, values]) => ({
    value: key,
    label: values.name,
    icon: () => (
      <GIcon
        size={urlRegex.test(values.emoji) ? EeSize.sm : EeSize.md}
        icon={values.emoji}
      />
    ),
  }));
  const toggleLang = (item: ItemType<any>) => {
    setCurrLang(prev => {
      // Check if the item is already in the array
      const index = _.findIndex(prev, {value: item.value});
      return index === -1
        ? [...prev, item]
        : _.filter(prev, currItem => currItem.value !== item.value);
    });
  };
  const {GDropdown, isOpen, setOpenPicker} = useGDropdown({
    multiple: true,
    key: 'langDropdown',
  });
  const LanguageSelector = (
    <GDropdown
      mode={'BADGE'}
      placeholder={'Idiomas'}
      searchable={true}
      renderBadgeItem={item => {
        return (
          _.findIndex(currLang, {value: item.value}) !== -1 && (
            /*currLang.includes(item.value) &&*/ <GBadge
              text={currLang.length < 3 ? item.label : undefined}
              actionIcon={'close'}
              fontColor={palette.purpleSec}
              onPress={() => {
                setOpenPicker(false);
                toggleLang(item);
              }}
              icon={gLanguages[item.value as LanguageKey].emoji}
            />
          )
        );
      }}
      closeIconContainerStyle={[C.bgBlue]}
      value={currLang}
      multipleText={'Idiomas'}
      showArrowIcon={true}
      closeAfterSelecting={true}
      showBadgeDot={true}
      onSelectItem={(items: ItemType<any>[]) => {
        setCurrLang(items);
      }}
      items={langMap}
      open={isOpen}
    />
  );
  return {LanguageSelector, currLang, setCurrLang};
};
