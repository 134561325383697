import {eColor, eLayout, gImages, onTop, vSizes} from '../ui';
import useResponsive from '../ui/helpers/useResponsive';
import {Image, ImageStyle, View, ViewStyle} from 'react-native';
import C from 'consistencss';
import {GIcon} from '../atoms';
import LinearGradient from 'react-native-linear-gradient';
import React, {ReactNode} from 'react';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

interface SideContainerProps {
  cover?: boolean;
  children: ReactNode;
  colBg?: string;
  img?: any;
  bgGrad?: string[];
  imgLeft?: boolean;
  imgSize?: any;
  imgStyle?: ImageStyle;
  imgRatio?: number;
  full?: boolean;
  style?: ViewStyle;
}

/**Dynamic wrapper for */
export const SideContainer: React.FC<SideContainerProps> = ({
  cover = false,
  children,
  colBg = palette.white,
  img,
  bgGrad,
  imgLeft,
  imgSize = vSizes.cardHuge,
  imgStyle,
  imgRatio = 0.5,
  full = true,
  style,
}) => {
  const {isSmall, wrapStyle, resp, isHuge} = useResponsive();

  const imgResize = cover ? 'cover' : 'contain';

  const Content = (
    <View
      style={[
        wrapStyle,
        isSmall ? C.p4 : C.p8,
        full && resp(isHuge ? 0.8 : 1).width,
        C.py6,
        !isSmall && [C.row, imgLeft && eLayout.reverse],
      ]}
    >
      <View
        style={
          isSmall
            ? [C.p5, onTop()]
            : [C.flex, C.selfCenter, img ? C.itemsStart : C.itemsCenter, C.p6]
        }
      >
        {children}
      </View>
      {img && (
        <GIcon
          icon={img}
          resizeMode={'contain'}
          wrapStyle={[
            imgStyle,
            isSmall
              ? [C.wFull, resp(imgRatio).height]
              : [C.flex, imgSize, C.selfLeft, onTop(false)],
          ]}
        />
      )}
    </View>
  );
  const wrap = [
    eColor(colBg).bg,
    resp().width,
    !isSmall && C.my10,
    C.selfCenter,
    C.itemsCenter,
    style,
  ];
  return bgGrad ? (
    <LinearGradient colors={bgGrad} style={wrap} useAngle angle={120}>
      {Content}
    </LinearGradient>
  ) : (
    <View style={wrap}>{Content}</View>
  );
};

/**Dynamic wrapper for */
export const SideAbsoluteContainer = ({
  children,
  img = gImages.women,
}: {
  children?: ReactNode;
  img?: FullIconType;
}) => {
  const {isSmall, wrapStyle, isTablet, resp} = useResponsive();
  return (
    <View>
      {/* @ts-ignore*/}
      <Image
        source={img}
        resizeMode={'contain'}
        style={[
          resp().width,
          onTop(false),
          C.absolute,
          isTablet
            ? [C.h54, C.opacity10, C.leftQuarter, C.top10]
            : isSmall
            ? [C.h46, C.opacity10, C.leftQuarter, C.top1]
            : [vSizes.cardHero, C.right0, C.bottom0],
        ]}
      />
      <View
        style={[
          wrapStyle,
          isSmall ? C.py2 : [C.selfCenter, C.itemsStart, C.py4],
        ]}
      >
        {children}
      </View>
    </View>
  );
};
