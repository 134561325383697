import {AppRegistry} from 'react-native';
import {name as appName} from './app.json';
import {App} from './App.web';

import '../public/index.css';
import '../public/fonts.css';

/*if (module.hot) {
  module.hot.accept();
}*/

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

/** TODO Soon
 * const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
 * root.render(
 *   <React.StrictMode>
 *     <App />
 *   </React.StrictMode>
 * );
 * */

/*if (typeof __DEV__ === 'undefined') {
  global.__DEV__ = env.NODE_ENV !== 'production';
}*/
/** TODO Soon
 * const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
 * root.render(
 *   <React.StrictMode>
 *     <App />
 *   </React.StrictMode>
 * );
 * */
