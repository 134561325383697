import C from 'consistencss';
import React from 'react';
import {View} from 'react-native';

import {ButtonSize, ButtonType, GButton} from '../molecules';
import {eLayout} from '../ui';

export type ButtonRowProps = {
  textCTA?: string;
  textSecondary?: string;
  onPressCTA?: () => void;
  onPressSecondary?: () => void;
};

export function EeButtonRow({
  textCTA,
  textSecondary,
  onPressCTA = () => {},
  onPressSecondary = () => {},
}: ButtonRowProps) {
  return (
    <View style={[C.row, C.wrap, C.justifyCenter, C.my2]}>
      {textSecondary && (
        <GButton
          text={textSecondary}
          size={ButtonSize.medium}
          type={ButtonType.secondary}
          style={[eLayout.grow1, eLayout.minWidth43]}
          onPress={onPressSecondary}
        />
      )}
      {textCTA && (
        <GButton
          text={textCTA}
          size={ButtonSize.medium}
          style={textSecondary ? [eLayout.grow1, eLayout.minWidth43] : undefined}
          onPress={onPressCTA}
        />
      )}
    </View>
  );
}

export default EeButtonRow;
