import {
  BASE_PIXEL,
  eColor,
  EeSize,
  eSizes,
  eSVG,
  IS_WEB,
  SVGTypes,
} from '../ui';
import C from 'consistencss';
import React from 'react';
import {ImageSourcePropType, Pressable, Text} from 'react-native';
import {
  eMojiRegex,
  FullIconSet,
  getKeyByValue,
  IconImageProps,
  iconSetsMap,
  IconSource,
  uriRegex,
} from '../types/icon-types';
import LinearGradient from 'react-native-linear-gradient';
import * as SvgIcons from '../../public/assets/svgMap';
import {palette} from 'oralify-common';
import FastImage from 'react-native-fast-image';

export const matchIconSet = (name: FullIconSet, PrefIconSource: IconSource) =>
  name && iconSetsMap[PrefIconSource]?.Set.hasIcon(name)
    ? {Set: PrefIconSource}
    : Object.values(iconSetsMap).filter(({Set}) => Set && Set.hasIcon(name))[0];

export function GIcon({
  icon,
  size = EeSize.icon,
  gradBg,
  circular,
  rounded,
  iconSource = IconSource.MaterialCommunityIcons,
  wrapStyle,
  tint,
  color,
  onPress,
  onError,
  ...rest
}: IconImageProps & {
  gradBg?: string[];
}) {
  const SVGImg = IS_WEB ? SvgIcons[icon] : eSVG[icon as SVGTypes]?.default;
  const MatchIcons = matchIconSet(
    icon as FullIconSet,
    iconSetsMap[iconSource]?.Set,
  );

  const MatchIconSet = MatchIcons && MatchIcons.Set;
  const iconName =
    typeof icon === 'number'
      ? getKeyByValue(icon, iconSetsMap[iconSource])
      : icon;
  const iconURI = uriRegex.test(icon as string)
    ? typeof icon === 'object' && 'uri' in icon
      ? icon.uri
      : {uri: icon}
    : icon;
  const iconEmoji = eMojiRegex.test(icon as string) && icon;

  const fontSize = (size + 2) * BASE_PIXEL;
  /*const pixelRatio = PixelRatio.get();
              const realSize = size*pixelRatio*BASE_PIXEL,*/
  /*const image = getImage({
                  width: PixelRatio.getPixelSizeForLayoutSize(200),
                  height: PixelRatio.getPixelSizeForLayoutSize(100),
                });*/
  const Content = SVGImg ? (
    /*IS_WEB ? (
                                          <SvgUri uri={SVGImg} width={fontSize} height={fontSize} /> // Utiliza SvgUri para renderizar imágenes SVG
                                        ) : (*/
    <SVGImg />
  ) : iconEmoji ? (
    <Text style={{fontSize: fontSize}}>{iconEmoji as string}</Text>
  ) : MatchIconSet ? (
    <MatchIconSet
      name={iconName as string}
      onPress={onPress}
      size={fontSize}
      color={color || palette.greyDark}
    /> /*@ts-ignore*/
  ) : (
    <FastImage
      {...rest}
      onError={onError}
      tintColor={tint}
      source={iconURI as ImageSourcePropType}
      style={[
        circular && C.radius30,
        rounded && C.radius3,
        eSizes[size],
        wrapStyle,
        tint && eColor(tint).tint,
      ]}
    />
  );

  return (
    <Pressable
      style={({pressed}) => [
        C.row,
        C.justifyStart,
        pressed && C.opacity40,
        C.itemsEnd,
        iconEmoji && C.pb1,
        wrapStyle,
      ]}
      disabled={!onPress}
      onPress={onPress}
    >
      {gradBg ? (
        <LinearGradient useAngle angle={120} colors={gradBg}>
          {Content}
        </LinearGradient>
      ) : (
        Content
      )}
    </Pressable>
  );
}
