import C from 'consistencss';
import React, {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import {GIcon, GText} from '../atoms';
import {eColor, EeSize, eLayout, StylesArray, TextType} from '../ui';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

export type IconTextProps = /* CommonType &*/ {
  dark?: boolean;
  horizontal?: boolean;
  iconCircular?: boolean;
  color?: string;
  icon?: FullIconType;
  secColor?: string;
  actionIcon?: FullIconType;
  selected?: boolean;
  onPress?: () => void;
  onHover?: () => void;
  onHoverOut?: () => void;
  text?: string;
  title?: string;
  descr?: string;
};

export type FABProps = IconTextProps & {
  opacity?: boolean;
};

const wrapper = [
  //C.absolute,
  eColor(palette.orangeLight).bg,
  eColor(palette.orangeSec, 2).border,
  C.radius8 /*C.right4,*/,
  C.h12,
  C.w12,
  C.itemsCenter,
  C.justifyCenter,
];
export const FABButton = ({onPress, opacity, icons}: FABProps) => {
  const [expanded, setExpanded] = useState(false);
  //const navigation = useNavigation();
  const toggleExpanded = () => {
    setExpanded(prev => !prev);
    //onPress && onPress();
  };

  /*useEffect(() => {
                                                                      const removeListener = navigation.addListener('beforeRemove', e => {
                                                                        if (expanded) {
                                                                          setExpanded(false);
                                                                          e.preventDefault();
                                                                        }
                                                                      });

                                                                      return removeListener;
                                                                    }, [navigation, expanded]);*/
  /*useEffect(() => {
                                                                                const onBackPress = () => {
                                                                                  if (expanded) {
                                                                                    setExpanded(false);
                                                                                    return true;
                                                                                  }
                                                                                  return false;
                                                                                };

                                                                                BackHandler.addEventListener('hardwareBackPress', onBackPress);

                                                                                return () =>
                                                                                  BackHandler.removeEventListener('hardwareBackPress', onBackPress);
                                                                              }, [expanded]);*/

  /*useFocusEffect(() => {
                                                                      console.log('horee Back to here focused', expanded);
                                                                      if (expanded) {
                                                                        setExpanded(false);
                                                                      }

                                                                      return () => {};
                                                                    });*/
  return (
    <>
      {expanded && (
        <Pressable
          onPress={toggleExpanded}
          style={[C.justifyEnd, C.bgShadow, StyleSheet.absoluteFillObject]}
        />
      )}
      <View
        style={[C.absolute, eLayout.gap, C.itemsCenter, C.bottom5, C.right4]}
      >
        {expanded &&
          icons?.map(({icon, text, onPress}, index) => (
            <GFABBubble
              dark
              key={text}
              icon={icon}
              text={text}
              style={index === 0 ? [C.right18, C.absolute, C.bottom_2] : C.mb5}
              onPress={onPress}
            />
          ))}
        <Pressable onPress={toggleExpanded} style={[wrapper]}>
          <GIcon icon={'plus'} size={EeSize.avatar} color={palette.white} />
        </Pressable>
      </View>
    </>
  );
};

export const GIconRow = ({
  onPress,
  icon,
  text,
  selected,
  onHover,
  onHoverOut,
  actionIcon,
  descr,
  title,
  iconCircular,
  horizontal = true,
  color = palette.secondaryDarkGrey,
  style,
}: IconTextProps & {style?: StylesArray}) => {
  const xPad = horizontal ? C.mr2 : C.mb2;
  const [isHover, setIsHover] = useState(false);
  const currSelected = selected || isHover;

  return (
    <Pressable
      onHoverIn={() => {
        if (onHover) {
          setIsHover(true);
          onHover();
        }
      }}
      onHoverOut={() => {
        if (onHoverOut) {
          onHoverOut();
          setIsHover(false);
        }
      }}
      onPress={onPress}
      style={[
        eLayout.gap,
        C.p1,
        currSelected && [C.radius2, eColor(palette.primaryMercuryGrey).border],
        horizontal && C.row,
        C.itemsCenter,
        style,
      ]}
    >
      {icon && (
        <GIcon
          circular={iconCircular}
          icon={icon}
          wrapStyle={xPad}
          size={!!title && !text ? EeSize.icon : EeSize.md}
          color={color}
        />
      )}
      {text && (
        <GText
          numberOfLines={1}
          type={currSelected ? TextType.tabSelected : TextType.tab}
          style={[!descr && C.flex, C.capitalize, xPad]}
        >
          {text}
        </GText>
      )}
      {descr && (
        <GText
          numberOfLines={1}
          type={TextType.body}
          style={[xPad, C.flex]}
          color={color}
        >
          {descr}
        </GText>
      )}
      {title && (
        <GText
          numberOfLines={1}
          color={color}
          /*color={!text ? color : undefined}*/ style={[C.capitalize]}
          type={TextType.bodyBold}
        >
          {title}
        </GText>
      )}
      {actionIcon && (
        <GIcon
          circular={iconCircular}
          icon={actionIcon}
          //wrapStyle={xPad}
          size={!!title && !text ? EeSize.icon : EeSize.md}
          color={color}
        />
      )}
    </Pressable>
  );
};

/** @deprecated ?*/
export const GFABBubble = ({
  onPress,
  icon,
  text,
  horizontal,
  color,
  style,
  dark,
}: IconTextProps & {style?: StylesArray}) => {
  return (
    <Pressable
      onPress={onPress}
      style={[style, horizontal && C.row, C.itemsCenter, C.mx2]}
    >
      {icon && (
        <GIcon
          icon={icon}
          wrapStyle={[wrapper, C.mb2]}
          size={EeSize.iconWrapper}
          color={palette.white}
        />
      )}
      {text && (
        <GText type={TextType.tabSelected} color={dark ? palette.white : color}>
          {text}
        </GText>
      )}
    </Pressable>
  );
};
