import C from 'consistencss';

import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {
  Animated,
  Pressable,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native';

import {
  Alignment,
  BASE_PIXEL,
  colorScaleNum,
  eColor,
  EeSize,
  onTop,
  orangeGrad,
  StylesArray,
  TextType,
  vSizes,
} from '../ui';
import {GIcon, GText} from '../atoms';
import {Tag} from './Cards';
import useResponsive from '../ui/helpers/useResponsive';
import {LandingFooter, LandingHeader} from './LandingHeader';
import LinearGradient from 'react-native-linear-gradient';
import {useLocation} from 'react-router-dom';
import {FullIconType} from '../types/icon-types';

import {palette, SpecialityKey, SpecialityMapType} from 'oralify-common';
import {headerScreens} from '../routes/useGNav.web';

export const Highlight = ({
  onPress = () => {},
  title,
  col = palette.orangeSec,
  icon,
  tag,
  descr,
}) => {
  const {isSmall} = useResponsive();

  return (
    <View key={title} style={[C.flex, C.itemsCenter, isSmall ? C.my2 : C.mr8]}>
      <GIcon resizeMode="contain" icon={icon} size={EeSize.imageBig} />
      <View style={[C.row, C.itemsCenter]}>
        <h2>
          <GText
            type={TextType.h4}
            align={Alignment.Center}
            style={[!isSmall && C.my4]}
          >
            {title}
          </GText>
        </h2>
        {tag && <Tag text={tag} col={palette.orangeSec} />}
      </View>
      <GText align={Alignment.Center}>{descr}</GText>
    </View>
  );
};

const bubbleWrapper = [
  vSizes.lg,
  C.radius10,
  eColor(palette.lightGrey, 2).border,
  /* eColor(palette.white, 4).border,*/
];
export const GroupedBubbles = ({
  list = [],
  maxShown = 5,
  onPress,
  condensed = false,
}: {
  list?: Record<string, SpecialityMapType>;
  maxShown?: number;
  onPress?: (key: SpecialityKey) => void;
  condensed?: boolean;
}) => (
  <View style={[C.row, condensed && C.right_3, C.mb2]}>
    {list
      .slice(0, maxShown)
      .map(([key, {icon, emoji, name, color, specialityKey}], index) =>
        emoji ? (
          <View
            key={name}
            style={[condensed ? C.w10 : C.w12, C.p2, C.itemsCenter]}
          >
            {/*{name && !condensed && (
            <GText style={[C.my2]} align={Alignment.Left} color={colorScale(index).hex()} type={TextType.tabSelected}>
              {level}
            </GText>
          )}*/}
            <CircWrapper
              key={emoji || icon}
              onPress={() => {
                console.log('about ReturnType nav', key);
                if (onPress && key) onPress(key);
              }}
              bordCol={color}
              wrapStyle={bubbleWrapper}
            >
              {(emoji || icon) && (
                <GIcon
                  wrapStyle={C.p0}
                  icon={emoji || icon}
                  size={EeSize.iconWrapper}
                />
              )}
              {/*<GText type={TextType.h6BodySmall}>{name}</GText>*/}
            </CircWrapper>
            {name && !condensed && (
              <GText
                style={[
                  C.minw30,
                  C.selfStart,
                  C.bgWhite,
                  C.opacity80,
                  C.px1,
                  C.ml1,
                  C.mt2,
                  {zIndex: -50 * index},
                  C.selfStart,
                  C.shadowLg,
                  C.radius4,
                  {transform: 'rotate(20deg)'},
                ]}
                align={Alignment.Center}
                color={colorScaleNum(maxShown)(index).hex()}
                type={TextType.tabSelected}
              >
                {name}
              </GText>
            )}
          </View>
        ) : (
          icon && <GIcon key={name} icon={icon} wrapStyle={bubbleWrapper} />
        ),
      )}
    {list.length > maxShown && (
      <CircWrapper bordCol={palette.white} wrapStyle={[bubbleWrapper, C.mt2]}>
        <GText type={TextType.tabSelected}>+{list.length - maxShown}</GText>
      </CircWrapper>
    )}
  </View>
);
export const CircWrapper = ({
  children,
  onPress,
  wrapStyle,
  icon,
  iconStyle,
  size = vSizes.lg,
  bordCol = palette.orangeSec,
}: {
  children?: ReactNode;
  onPress?: () => void;
  wrapStyle?: StylesArray;
  icon?: FullIconType;
  iconStyle?: StylesArray;
  size?: EeSize;
  bordCol?: string;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={[
        size,
        C.itemsCenter,
        C.shadowMd,
        C.justifyCenter,
        C.radius8,
        eColor(palette.white).bg,
        eColor(bordCol).border,
        wrapStyle,
      ]}
    >
      {children}
      {icon && (
        <GIcon
          icon={icon}
          size={EeSize.iconWrapper}
          color={bordCol}
          wrapStyle={iconStyle}
        />
      )}
    </Pressable>
  );
};

/***LAYOUT RELATED*/
export const RespRow = ({
  children,
  style,
}: {
  children?: ReactNode;
  style?: StylesArray;
}) => {
  const {isSmall, wrapStyle} = useResponsive();
  return (
    <View style={[isSmall ? C.itemsCenter : C.row, wrapStyle, style]}>
      {children}
    </View>
  );
};

export const WebLayout = ({
  screen = headerScreens.landing.name,
  children,
}: {
  screen: string;
  children: ReactNode;
}) => {
  const [scrollY, setScrollY] = useState(0);
  const {resp} = useResponsive();
  const showGradBg = screen !== headerScreens.landing.name || scrollY > 50;
  const {pathname} = useLocation();
  const scrollViewRef = useRef(null);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: 0, y: 0, animated: true});
    }
  }, [pathname]);

  return (
    <SafeAreaView style={[resp().height, C.bgWhite]}>
      <ScrollView
        ref={scrollViewRef}
        scrollEventThrottle={BASE_PIXEL * BASE_PIXEL}
        style={[onTop()]}
        /*   stickyHeaderIndices={[0]}
                                                                                                   stickyHeaderHiddenOnScroll={false}*/
        onScroll={event => setScrollY(event.nativeEvent.contentOffset.y)}
      >
        <LandingHeader withBg={showGradBg} current={screen} />
        <View style={[screen !== headerScreens.landing.name && C.p4]}>
          {/*{screen && (
            <GText style={C.capitalize} type={TextType.h3Orange}>
              {screen}
            </GText>
          )}*/}
          {children}
        </View>
        <LandingFooter />
      </ScrollView>
    </SafeAreaView>
  );
};

export const LandingWrapper = ({
  children,
  HeaderChild,
  bgGradient = orangeGrad,
  colorBg = palette.white,
}: {
  children?: ReactNode;
  HeaderChild?: ReactNode;
  bgGradient?: string[];
  colorBg?: string;
}) => {
  /*const {resp} = useResponsive();
                        const [scrollY, setScrollY] = useRecoilState(wrapperScroll);*/

  return (
    <Animated.View
      style={[C.bgWhite, C.top_40, C.mb_40 /*, resp().height*/]}
      /*contentContainerStyle={apply(IS_IOS ? C.pt8 : C.pt4, C.px2, C.m4)}*/
    >
      {bgGradient ? (
        <LinearGradient
          colors={bgGradient}
          style={[C.pt24, C.radiusbottom10, C.wFull]}
          useAngle
          angle={120}
        >
          {HeaderChild}
        </LinearGradient>
      ) : (
        <View style={[C.p4]}> {HeaderChild}</View>
      )}
      {children}
    </Animated.View>
  );
};
