import C from 'consistencss';
import React, {ReactNode} from 'react';
import {Pressable, View} from 'react-native';

import {GIcon, GText} from '../atoms';
import {eColor, EeSize, priceScale, StylesArray, TextType} from '../ui';
import {currFormat} from '../types/units';
import {ExpenseType} from '../types/userTypes';
import {GBadge} from './gBadge';
import {palette, SpecialityKey} from 'oralify-common';

export const centerRow = [C.row, C.itemsCenter, C.justifyCenter];

export type InfoCardProps = {
  onPress?: () => void;
  style?: StylesArray;
  co2?: number;
  compact?: boolean;
  children?: ReactNode;
} & ExpenseType;

export function GInfoRow({
  image,
  category,
  subcategory,
  color,
  compact,
  location,
  endLocation,
  description,
  co2,
  onPress,
  cost,
  style,
  name,
  children,
  ...rest
}: InfoCardProps & {
  color?: string;
}) {
  /*const {icon} = categoryMap[category || CategoryKey.fun];*/
  const colCO2 = co2 && co2 > 0 ? palette.redSec : palette.greenPrim;
  const compactText = [compact && C.maxwFull];
  return (
    <Pressable
      disabled={!onPress}
      //@ts-ignore
      android_ripple={C.radius12}
      onPress={onPress}
      style={({pressed}) => [
        C.bgWhite,
        C.radius2, //compact && C.selfEnd,
        compact ? [eColor(color, 2).border] : C.flex,
        C.p2,
        style /*C.flex,*/,
        C.my2,
        pressed && C.opacity40,
      ]}
      {...rest}
    >
      <View>
        <View style={[C.row]}>
          {/** Title & Text*/}
          <View style={[C.flex, C.justifyBetween, C.selfStart]}>
            <View
              style={[
                C.row,
                C.itemsCenter,
                C.mb2, //compact && eDevice(0.4).maxWidth,
              ]}
            >
              {location && (
                <GIcon
                  icon={'location-pin'}
                  color={palette.bluePrim}
                  size={EeSize.sm}
                />
              )}
              <GText
                numberOfLines={1}
                type={TextType.label}
                style={compactText}
                color={palette.bluePrim}
              >
                {location || name} {endLocation && ' - '} {endLocation}
              </GText>
              {/* {endLocation && (
                <GICon
                  icon={'routes'}
                  color={palette.primaryBlue}
                  size={EeSize.sm}
                />
              )}*/}
              {/*{endLocation && (
                <GText
                  numberOfLines={1}
                  type={TextType.label}
                  style={compactText}
                  color={palette.primaryBlue}>
                  {' - '}
                  {endLocation}
                </GText>
              )}*/}
              {/**Co2*/}
              {!compact &&
                co2 &&
                category === SpecialityKey.interviewPractice && (
                  <View style={[centerRow, C.ml2]}>
                    <GIcon
                      icon={co2 < 0 ? 'leaf' : 'leaf-outline'}
                      color={colCO2}
                      size={EeSize.sm}
                    />
                    <GText type={TextType.label} color={colCO2}>
                      {co2?.toFixed(2)} T CO₂
                    </GText>
                  </View>
                )}
            </View>
            {/**Second line descr+ img*/}
            <View style={[centerRow, C.justifyStart]}>
              {!!image && (
                <GIcon
                  wrapStyle={[C.mr2, C.radius2]}
                  isResponsive
                  icon={image}
                  size={EeSize.icon}
                />
              )}
              {!!name && !!location && (
                <GText
                  type={TextType.tabSelected}
                  style={compactText}
                  numberOfLines={1}
                >
                  {name}
                </GText>
              )}
            </View>
            {description && (
              <GText
                type={TextType.label}
                style={compactText}
                numberOfLines={1}
              >
                {description}
              </GText>
            )}
          </View>
          {/** Price & Date */}
          <View style={[C.itemsEnd, C.ml2]}>
            {!!cost && (
              <GText
                color={cost < 0 ? priceScale(cost).hex() : palette.greenPrim} //priceScale(0.4)} //palette.secondaryRed /*priceScale(0.9)*/}
                style={C.my1}
                type={TextType.tabSelected}
              >
                {currFormat(cost)}
              </GText>
            )}
            {/* <View style={centerRow}>*/}
            {/*{category && (
                <GBadge
                  wrapStyle={null}
                  text={category}
                  color={color}
                  icon={icon}
                />
              )}*/}
            {subcategory && (
              <GBadge
                text={subcategory.name}
                /*wrapStyle={C.mr_1}*/
                color={color}
                icon={subcategory.icon}
              />
            )}
            {/* </View>*/}

            {/*{date && (
              <GText type={TextType.label}>
                {dayjs(date).format(DATE_SHORT)}
              </GText>
            )}*/}
          </View>
        </View>
      </View>
      {children}
    </Pressable>
  );
}

export default GInfoRow;
