import {Alignment, eColor, EeSize, StylesArray, TextType} from '../ui';
import C from 'consistencss';
import {Pressable, View} from 'react-native';

import React, {ReactElement} from 'react';
import {GIcon, GText} from '../atoms';
import {ButtonRowProps, EeButtonRow} from '../organisms';
import {SideCardProps} from './ee-side-card';
import {CheckboxGrad} from '../components/Cards';
import {GPill} from './gPill';
import {FullIconType} from '../types/icon-types';
import {formatDuration} from '../types/utils/dates';
import {
  palette,
  ServiceFormatKey,
  ServiceMapType,
  ServiceType,
} from 'oralify-common';

interface ServiceItemProps extends ServiceType {
  keyItem?: string;
  emoji?: FullIconType;
  currServices?: ServiceMapType;
  /*format?: string;
  duration?: number;
  price?: number;*/
  /*title?: string;*/
  image?: FullIconType;
  descr?: string;
  isSelected?: boolean;
  big?: boolean;
  children?: ReactElement;
  onSetTab?: (key: string | ServiceType) => void;
  setSelectedServiceFormats?: (prev: any) => void;
}

/*export const FormatCardSelector: React.FC<ServiceItemProps> = ({
  keyItem,
  emoji,
  format,
  duration,
  price,
  image,
  descr,
  isSelected,
  big,
  onSetTab,
}) => {
  const [currPrice, setPrice] = useState<number>(price || 5);
  const [currDuration, setDuration] = useState<number>(duration || 5);

  return (
    <GFormatCard
      title={currPrice}
      price={currPrice}
      duration={currDuration}
      isSelected={isSelected}
      big={big}
      onSetTab={onSetTab}
    />
  );
};*/
export const GFormatCard: React.FC<ServiceItemProps> = ({
  keyItem,
  emoji,
  format,
  currServices,
  highlight,
  duration,
  defaults,
  /* title,
  price,*/
  image,
  descr,
  isSelected,
  big,
  children,
  onSetTab,
}) => {
  const currDefaults = isSelected ? defaults : defaults?.slice(0, 2);
  return (
    <Pressable
      key={keyItem}
      style={[
        cardWrapper,
        C.selfCenter,
        C.shadowMd,
        isSelected && eColor(palette.orangeSec, 2).border,
        big ? C.mr8 : C.mr5,
      ]}
      onPress={() => onSetTab && onSetTab(keyItem as ServiceFormatKey)}
    >
      {image && (
        <GIcon
          wrapStyle={[C.mx_2, C.mt_2, C.radius4, C.selfCenter]}
          resizeMode={'cover' /*isSelected ? 'contain' : 'center'*/}
          size={big ? EeSize.imageBig : EeSize.image}
          icon={image}
        />
      )}

      {/*{highlight && <GPill wrapStyle={[C.absolute, C.left0, C.top0]} text={'Top'} icon={'star'} />}*/}

      <View style={[C.p2, C.itemsCenter, C.selfCenter]}>
        {format && (
          <GText
            style={C.capitalize}
            align={Alignment.Center}
            numberOfLines={1}
            type={TextType.bodyBold}
          >
            {isSelected && (
              <CheckboxGrad
                /*onPress={setServiceToggle}*/
                wrapStyle={C.mr2}
                circ
                checked={isSelected}
                icon={isSelected ? 'check' : 'circle'}
              />
            )}
            {emoji && (
              <GIcon icon={emoji} wrapStyle={C.mr2} size={EeSize.icon} />
            )}
            {format}
          </GText>
        )}
        {descr && (
          <GText
            style={[C.mt2, big ? C.maxw60 : C.maxw34, C.selfCenter]}
            align={Alignment.Center}
            type={big ? TextType.body : TextType.label}
          >
            {descr}
          </GText>
        )}
        {!defaults ? (
          <GPill
            wrapStyle={[
              C.absolute,
              C.p0,
              big ? C.right_14 : C.right_10,
              C.top_8,
            ]}
            color={palette.orangeSec}
            selected={isSelected}
            icon={'clock'}
            text={formatDuration(duration)}
          />
        ) : (
          <View
            style={[
              C.row,
              C.absolute,
              C.selfCenter,
              C.itemsStart,
              C.p0,
              /*big ? C.right_4 : C.right_10,*/ C.bottom_11,
            ]}
          >
            {currDefaults?.map(({highlight, duration: currentDuration}) => (
              <GPill
                key={currentDuration}
                /*wrapStyle={}*/
                actionIcon={highlight ? 'star' : undefined}
                color={palette.orangeSec}
                selected={
                  (currServices &&
                    format &&
                    currServices[
                      format as ServiceFormatKey
                    ]?.serviceDetails?.some(
                      ({duration: currDuration}) =>
                        currDuration === currentDuration,
                    )) ||
                  false
                }
                icon={'clock'}
                text={formatDuration(currentDuration)}
                /*quantity={"'"}*/
              />
            ))}
          </View>
        )}
      </View>

      {children}
    </Pressable>
  );
};

export type CardProps = {
  type?: CardSize;
  icon?: FullIconType;
  style?: StylesArray;
  children?: ReactElement;
  key?: React.Key | null | undefined;
} & ButtonRowProps &
  SideCardProps;

export const cardWrapper = [
  /*C.overflowHidden,*/ eColor(palette.border).border /*C.itemsCenter,*/,
  C.my3,
  C.p4,
  C.bgWhite,
  C.radius3,
  C.shadowMd,
];

/** Card Types */
export enum CardSize {
  lg = 'lg',
  sm = 'sm',
}

export const cardType = {
  [CardSize.lg]: {
    title: TextType.h2,
    imageSize: EeSize.image,
    subtitle: TextType.h3Orange,
    style: [C.py4, C.px13, C.radius7, C.flex],
  },
  [CardSize.sm]: {
    imageSize: EeSize.sideCard /** Settings Small Cards*/,
    title: TextType.h4,
    subtitle: TextType.h3Orange,
    style: [C.py2, C.px6, C.radius4],
  },
};

export function GCard({
  image,
  icon,
  title,
  subtitle,
  onPress,
  text,
  imageFullWidth,
  type = CardSize.sm,
  textCTA,
  textSecondary,
  onPressCTA,
  onPressSecondary,
  style,
  children,
  ...rest
}: CardProps) {
  return (
    <Pressable
      key={rest.key}
      disabled={!onPress}
      onPress={onPress}
      /** @ts-ignore TODO Check Android version, check if that works */
      android_ripple={C.radius12}
      style={({pressed}) => [
        cardWrapper,
        style,
        C.itemsCenter,
        pressed && C.opacity40,
      ]}
      {...rest}
    >
      {title && (
        <GText
          align={Alignment.Center}
          style={C.mb2}
          type={cardType[type].title}
        >
          {icon && (
            <GIcon
              isResponsive={imageFullWidth}
              icon={icon}
              wrapStyle={C.mr3}
              /*wrapStyle={imageFullWidth && [C.mt_8, C.mx_7, C.radius4]}
                size={!imageFullWidth ? cardType[type].imageSize : undefined}*/
            />
          )}
          {title}
        </GText>
      )}
      {image && (
        <GIcon
          isResponsive={imageFullWidth}
          icon={image}
          wrapStyle={imageFullWidth ? [C.mt_8, C.mx_7, C.radius4] : C.mb_5}
          size={!imageFullWidth ? cardType[type].imageSize : undefined}
        />
      )}
      {subtitle && (
        <GText
          align={Alignment.Center}
          style={C.my4}
          type={cardType[type].subtitle}
        >
          {subtitle}
        </GText>
      )}
      {text && (
        <GText style={[C.maxw40]} align={Alignment.Center}>
          {text}
        </GText>
      )}
      <View style={C.wFull}>{children}</View>

      {textCTA && (
        <View style={[C.row, C.itemsCenter, C.mt4]}>
          {textSecondary && (
            <EeButtonRow
              textSecondary={textSecondary}
              onPressCTA={onPressCTA}
              onPressSecondary={onPressSecondary}
              textCTA={textCTA}
            />
          )}
        </View>
      )}
    </Pressable>
  );
}

export default GCard;
