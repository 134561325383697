import C from 'consistencss';
import React, {useState} from 'react';
import {Pressable} from 'react-native';

import {GIcon, GText} from '../atoms';
import {Alignment, eColor, EeSize, eLayout, StylesArray, TextType} from '../ui';
import {centerRow} from './gInfoRow';
import {PillProps} from './gPill';
import useResponsive from '../ui/helpers/useResponsive';
import {lightCol} from '../ui/gPalette';
import {palette} from 'oralify-common';

export type BadgeProps = PillProps & {
  textStyle?: StylesArray;
  inverted?: boolean;
};

export function GBadge({
  textStyle,
  text,
  onPress,
  icon,
  symbol,
  color = palette.lightGrey,
  fontColor,
  selected,
  inverted,
  actionIcon,
  wrapStyle = [C.mr2, C.my2],
  ...rest
}: BadgeProps) {
  const fontCol = fontColor || (selected ? palette.white : color);
  const {resp} = useResponsive();

  // Establece el color de fondo más claro
  const bgColor = selected ? lightCol(color, 0.8, 1.5) : 'transparent';

  const [isHover, setIsHover] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      {...rest}
      onPress={onPress}
      disabled={!onPress}
      style={[
        centerRow,
        inverted && eLayout.reverse,
        C.shadowMd,
        (isHover || selected) && eColor(color).border,
        /*selected && eColor(bgColor).bg,*/
        C.px2 /* C.py1,*/,
        wrapStyle,
        C.radius4,
        C.radius1,
      ]}
    >
      {icon && (
        <GIcon
          icon={icon}
          size={/*selected ? EeSize.md :*/ EeSize.md}
          wrapStyle={C.p1}
          color={fontCol}
        />
      )}
      {symbol && (
        <GText
          style={[C.capitalize, C.mx1, textStyle]}
          color={fontCol}
          align={Alignment.Center}
          type={TextType.tabSelected}
        >
          {symbol}
        </GText>
      )}
      <GText
        type={/*selected ? TextType.tabSelected :*/ TextType.tab}
        color={selected ? fontCol : palette.secondaryDarkGrey}
        numberOfLines={1}
        style={[C.capitalize, textStyle, !selected && resp(0.14).maxWidth]}
      >
        {text}
      </GText>
      {actionIcon && (
        <GIcon
          wrapStyle={[C.ml1, C.mt1]}
          color={palette.greyish}
          icon={actionIcon}
          size={EeSize.md}
        />
      )}
    </Pressable>
  );
}
