import chroma from 'chroma-js';
import {palette} from 'oralify-common';

/**TODO replace step by step*/
export const paletteSort = {
  redSec: '#ec4d60',
  salmon: '#F28372',
  orangeSec: '#f89944',
  orangeLight: '#f8ad70',
  yellowPrimary: '#b29f25',
  yellowSec: '#bda834',
  greenPrim: '#6ebe47',
  greenSec: '#91d18b',
  turqPrim: '#04b581',
  turqSec: '#32c6a2',
  bluePrim: '#0290c9',
  blueSec: '#66cceb',
  blueLight: '#68A8D9',
  blueDark: '#006699',
  purpleSec: '#6c5fc7',
  softPink: '#faa6ae',

  // Grays and Whites
  black: '#000000',
  shadow: '#0000003F',
  darkGreyPrim: '#43525B',
  darkGreySec: '#768389',
  greyLight: '#b6b6b6',
  whiteDark: '#e3e3e3',
  background: '#f7f7f7',
  whiteFour: '#e5e5e5',
  white: '#fff',
};

export const eColor = (
  color = palette.greyDark,
  width = 1,
) => /*StyleSheet.create*/ ({
  border: {
    borderWidth: width,
    borderColor: color,
  },
  borderBottom: {
    borderBottomWidth: width,
    borderColor: color,
  },
  borderLeft: {
    borderLeftWidth: width,
    borderColor: color,
  },
  borderTop: {
    borderTopWidth: width,
    borderColor: color,
  },
  bg: {backgroundColor: color},
  tint: {tintColor: color},
  text: {color: color},
});

export const lightCol = (
  baseColor: string,
  targetLuminance: number,
  saturation: number,
) => {
  return chroma(baseColor)
    .luminance(targetLuminance)
    .saturate(saturation)
    .hex();
};
