import React, {useState} from 'react';
import {ButtonType, GButton, GFormInput, mailPswdFields} from '../molecules';
import {Alignment, buttonWidth, EeSize, gImages, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import C from 'consistencss';
import {View} from 'react-native';
import useResponsive from '../ui/helpers/useResponsive';
import {useGoogleDBLogin, usePhoneLogin} from '../api/userAuthApi';
import {apiCaller, TYPES_API} from '../api/api';
import {Api_Routes, palette, SpecialityKey} from 'oralify-common';
import {FormField, useUserForm} from '../store/useGForm';
import Toast from 'react-native-toast-message';
import {currentUserAtom, setToken} from '../store/useUser';
import {useModal} from './useModal';
import {headerScreens, modalScreens} from '../routes/useGNav.web';
import {useNavigate} from 'react-router-dom';
import {SETTINGS_STEPS} from '../types/stepTypes';
import {useRecoilState} from 'recoil';
import {gAnalytics, gLogEvent} from '../store/firebaseInit';
import {setUserProperties} from 'firebase/analytics';

const LoginModalContent = ({isSpecialist = false}) => {
  const {closeModal, modalParams} = useModal(modalScreens.login);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [showPhoneLogin, setShowPhoneLogin] = useState(false);
  const [currentUser, setUser] = useRecoilState(currentUserAtom);

  // correo electrónico y contraseña
  const {PhoneVerificationCodeInput} = usePhoneLogin(modalParams?.onSuccess);
  /*const {PhoneLoginComp, requestVerify, setPhoneNumber} =
    usePhoneLoginCaptcha();*/
  const {GoogleLoginComp} = useGoogleDBLogin(modalParams?.onSuccess);
  /*const {MailLoginComp} = useMailLogin();*/
  const {isSmall} = useResponsive();

  const handleContinueWithEmail = () => {
    setShowEmailLogin(true); // Mostrar campos de correo electrónico y contraseña al hacer clic en "Continuar con Mail"
  };

  const [formState, updateFormState, isFormValid] = useUserForm(
    {},
    mailPswdFields,
  );
  const onMailLogin = async () => {
    setLoading(true);
    const {
      data: {message, token, user},
    } = await apiCaller({
      url: Api_Routes.auth.signInWithEmail,
      params: {...formState},
      type: TYPES_API.POST,
    });
    if (user) setUser(user);
    if (token) await setToken(token);
    if (message) {
      if (message === 'User already exists') {
        navigate(
          headerScreens.profile.pathMain + '/' + SETTINGS_STEPS.VISIBILITY,
        );
        closeModal();
      }
      Toast.show({text1: 'Bienvenido ' + message, type: 'success'});
    }
    if (modalParams && modalParams.onSuccess) modalParams.onSuccess();
    /** Analytics*/
    setUserProperties(gAnalytics, user);
    gLogEvent('signIn', {provider: 'mail', ...user});
    setLoading(false);
  };
  return (
    <View style={[C.itemsCenter]}>
      {/*<GText>{JSON.stringify(currUser)}</GText>*/}
      {/*{currUser ? (
        <AddUserTabsModal isSpecialist={isSpecialist} />
      ) : (*/}
      <View style={[C.itemsCenter]}>
        <GText align={Alignment.Center} type={TextType.h1} style={C.my4}>
          {`¡Únete a Oralify!`}
        </GText>

        <GText align={Alignment.Center} type={TextType.body}>
          {'Resuelve dudas concretas\nó\ngana dinero como experto'}
        </GText>

        {!showPhoneLogin && (
          <GIcon
            size={isSmall ? EeSize.image : EeSize.imageBig}
            wrapStyle={C.selfCenter}
            icon={gImages.loginTalikng}
          />
        )}
        {showPhoneLogin ? (
          <PhoneVerificationCodeInput />
        ) : /*<PhoneLoginComp />*/
        /*<View>
              <GFormInput formType={FormTypes.phone} onSetText={(phone) => {setPhoneNumber(phone)}} />
              <GButton onPress={()=>requestVerify()} text={'Continuar'}/>
              <GFormInput formType={FormTypes.phone} onSetText={(phone) => {setPhoneNumber(phone)}} />
              <GButton onPress={()=>requestVerify()} text={'submit'}/>

            </View>*/
        /*<PhoneVerificationCodeInput />*/
        showEmailLogin ? (
          <form>
            {/*
            <GText>{JSON.stringify(formState)}</GText>
*/}
            <View style={[C.ml2, C.mb4]}>
              {mailPswdFields.map((field: FormField) => {
                const {key, formType, prefix, isRequired, icon, placeholder} =
                  field;
                return (
                  <GFormInput
                    key={SpecialityKey + key}
                    formType={formType}
                    autoFocus
                    isReq={isRequired}
                    prefix={prefix}
                    isPassword={key === 'password'}
                    /*loading={key === 'username' && isUsernameLoading}*/
                    icon={icon}
                    value={formState[key] as string}
                    placeholder={placeholder}
                    onSetText={(text: string) => updateFormState(key, text)}
                  />
                );
              })}
            </View>
            {/*<GFormInput
              formType={FormTypes.mail}
              placeholder="Correo electrónico"
              icon={'email'}
              value={email}
              onSetText={setEmail}
            />
            <GFormInput
              formType={FormTypes.password}
              placeholder="Contraseña"
              icon={'lock1'}
              value={password}
              isPassword
              onSetText={setPassword}
            />*/}
            <GButton
              loading={loading}
              onPress={() => onMailLogin()}
              disabled={!isFormValid}
              icon={'email'}
              text={'Continuar con Mail'}
            />
          </form>
        ) : (
          <>
            <GButton
              style={buttonWidth}
              onPress={handleContinueWithEmail}
              icon={'email'}
              text={'Continuar con Mail'}
            />
            <GoogleLoginComp />
            <GButton
              onPress={() => setShowPhoneLogin(true)}
              icon={'phone'}
              style={buttonWidth}
              color={palette.orangeSec}
              type={ButtonType.secondary}
              text={'Usar teléfono'}
            />
          </>
        )}
      </View>
      {/*)}*/}
    </View>
  );
};

export default LoginModalContent;
