// Tooltip.js
// useTooltip.js
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {BASE_PIXEL, eColor, IS_WEB} from '../ui';
import {GModalWeb} from './GModalWeb';
import {GModalCenterMobile} from '../organisms';

import {atom, useRecoilState} from 'recoil';
import C from 'consistencss';
import {
  GestureResponderEvent,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import useResponsive from '../ui/helpers/useResponsive';
import {palette} from 'oralify-common';
import {modalScreens} from '../routes/useGNav.web';
import {gLogEvent} from '../store/firebaseInit';

export const modalState = atom<{[modalName: string]: boolean}>({
  key: 'modalState',
  default: {},
});

export const useModal = (modalName: string) => {
  const [modals, setModals] = useRecoilState(modalState);
  const [modalParams, setModalParams] = useState({});

  const showModal = (params = {}) => {
    setModals({...modals, [modalName]: true});
    gLogEvent('openModal', modalParams);
    setModalParams(params);
  };

  const closeModal = () => {
    setModals({...modals, [modalName]: false});
  };
  const ModalComponent = ({children}: {children: JSX.Element}) =>
    IS_WEB ? (
      <GModalWeb
        visible={modals[modalName]}
        onClose={closeModal}
        params={modalParams}
      >
        {children}
      </GModalWeb>
    ) : (
      <GModalCenterMobile onClose={closeModal} params={modalParams}>
        {children}
      </GModalCenterMobile>
    );
  return {
    visible: modals[modalName] || false,
    showModal,
    modalParams,
    closeModal,
    ModalComponent,
  };
};
type TooltipProps = {
  TooltipTrigger: JSX.Element;
  onPress?: () => void;
  TooltipContent: JSX.Element;
  disabled?: boolean;
  wrapStyle?: StyleProp<ViewStyle>;
};

type UsePressTooltipProps = {
  visible?: boolean;
  overlay?: boolean;
};

interface TooltipContextType {
  setTooltip: React.Dispatch<React.SetStateAction<any>>;
}

export const TooltipContext = createContext<TooltipContextType | undefined>(
  undefined,
);

export const TooltipProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const [tooltip, setTooltip] = useState<any>(null);

  return (
    <TooltipContext.Provider value={{setTooltip}}>
      {children}
      {tooltip && <Tooltip {...tooltip} />}
    </TooltipContext.Provider>
  );
};

const Tooltip = ({
  content,
  position,
  wrapStyle,
}: {
  content: ReactNode;
  position: {x: number; y: number};
  wrapStyle?: ViewStyle;
}) => {
  const {x, y} = position;

  return (
    <View
      style={[
        C.absolute,
        C.radius4,
        C.bgWhite,
        eColor(palette.greyish + '20', 2).border,
        C.left_2,
        C.p4,
        {top: BASE_PIXEL * 12 + y, left: x, zIndex: 9999},
        wrapStyle,
      ]}
    >
      {content}
    </View>
  );
};

export default Tooltip;

// hooks/useTooltip.js
export const useTooltip = (
  initialVisible = false,
  onVisibilityChange: (visible: boolean) => void,
) => {
  const {setTooltip} = useContext(TooltipContext);
  const [visible, setVisible] = useState(initialVisible);

  const showTooltip = (
    content: ReactNode,
    position: {x: number; y: number},
    wrapStyle?: ViewStyle,
  ) => {
    setTooltip({content, position, wrapStyle});
    setVisible(true);
    onVisibilityChange && onVisibilityChange(true);
  };

  const hideTooltip = () => {
    setTooltip(null);
    setVisible(false);
    onVisibilityChange && onVisibilityChange(false);
  };

  return {showTooltip, hideTooltip, visible};
};

/** @deprecated */
export const usePressTooltip = ({
  visible = false,
}: /*overlay = false,
  absolute = true,*/
UsePressTooltipProps) => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  // Usa useState para manejar el estado de `showContent`.
  const [showContent, setShowContent] = useState(visible);
  const {isSmall} = useResponsive();
  const {showModal: showModalTooltip, ModalComponent} = useModal(
    modalScreens.tooltip,
  );
  const handleVisibilityChange = (visible: boolean) => {
    setShowContent(visible);
  };

  const {
    showTooltip: showTooltipContext,
    hideTooltip: hideTooltipContext,
    visible: tooltipVisible,
  } = useTooltip(visible, handleVisibilityChange);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideTooltipContext();
    }
  };

  useEffect(() => {
    // Add event listener when component is mounted
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener when component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const TooltipWrapper = ({
    TooltipTrigger,
    onPress,
    TooltipContent,
    disabled,
    wrapStyle,
  }: TooltipProps) => {
    const handlePress = (event: GestureResponderEvent) => {
      if (!disabled) {
        const position = {
          x: event.pageX,
          y: event.pageY,
        };
        onPress && onPress();
        if (isSmall) {
          showModalTooltip(TooltipContent);
        } else showTooltipContext(TooltipContent, position, wrapStyle);
      }
    };

    return (
      <View ref={wrapperRef}>
        <Pressable onPress={handlePress}>{TooltipTrigger}</Pressable>
        {/*{isSmall && <ModalComponent>{<TooltipWrapper />}</ModalComponent>}*/}
      </View>
    );
  };

  return {
    TooltipWrapper,
    showTooltip: showTooltipContext,
    hideTooltip: hideTooltipContext,
    tooltipVisible,
    setShowTooltip: setShowContent,
  };
};
