import {Area, Distance} from '../units';
import dayjs from 'dayjs';
import {Timestamp} from 'firebase/firestore';
import 'dayjs/locale/es';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import {
  palette,
  ServiceType,
  TimeslotStatus,
  TimeSlotType,
} from 'oralify-common';
import chroma from 'chroma-js';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
// Instalar los plugins
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(duration);

// Establecer el idioma español
dayjs.locale('es');
dayjs.extend(isSameOrAfter);

export const DATE_SHORT = 'D MMM';
export const DATE_USER = 'dd, DMMM YY';
export const DATE_HOUR = 'H:mm';

export const BACKEND_DATE_HOUR = 'HH:mm:ss'; // 09:20 !== 9:20
export const BACKEND_BIRTHDAY = 'YYYY-MM-DD';

export const DATE_FORMAT_ALL = "ddd, DD MMM 'YY";
export const DATE_FORMAT_SHORT = 'dd, DD MMM';
export const DATE_FORMAT_PR = 'MM/dd/YY';
export const DATE_CALENDAR = 'YYYY-MM-DD';

export function dates(value: number, unit: ConversionType, reverse = false) {
  return value * Math.pow(CONVERSIONS[unit], reverse ? -1 : 1);
}

/*export const utcToHour = (date: Date) => {
  const result = dayjs(date, BACKEND_DATE_HOUR)
    .utc()
    .tz('Europe/Madrid')
    .format(DATE_HOUR);
  //dayjs(date,
  // BACKEND_DATE_HOUR).utc().tz('Europe/Madrid').format(DATE_HOUR)
  console.log('hoer start: ', date, result);
  return result;
};*/
export const timeToHourUTC = (date: string) => {
  return (
    dayjs(date, BACKEND_DATE_HOUR)
      /* .utc()
         .tz('Europe/Madrid')*/
      .format(DATE_HOUR)
  );
};

export const formatDuration = (minutes?: number, long?: boolean) => {
  const durationObj = dayjs.duration(minutes || 1, 'minutes');
  const hours = durationObj.hours();
  const mins = durationObj.minutes();
  return `${hours > 0 ? `${hours}h` : ''}${
    mins > 0 ? `${mins}${long ? ' min' : 'm'}` : ' '
  }`;
};

/** From snakeCase to words:
 * nutriciónDeportiva => Nutrición Deportiva */
export function convertSnakeCaseToWords(str: string): string {
  // Añadir un espacio antes de las letras mayúsculas y luego dividir por espacios
  const words = str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ');

  // Capitalizar la primera letra de cada palabra
  return words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const urlRegex: RegExp = /^https?:\/\//;

export function camelCaseToWords(str: string): string {
  // Añade un espacio antes de las letras mayúsculas y luego capitaliza la primera letra de cada palabra
  return str
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .replace(/^./, currStr => currStr.toUpperCase());
}

// Filtrado por status y formato
export function filterTimeSlots(
  timeSlots: TimeSlotType[],
  statusFilter: TimeslotStatus[],
  formatFilter: string[], // array de formatos seleccionados
): TimeSlotType[] {
  return timeSlots.filter(slot =>
    slot.status
      ? statusFilter.includes(slot.status) &&
        formatFilter.length === 0 /*|| formatFilter.includes(slot.)*/
      : false,
  );
}

export const getServiceColors = (services?: ServiceType[]) =>
  services?.map(
    (_, index) =>
      chroma
        .scale([palette.turqPrim, palette.purpleSec])
        .mode('lch')
        .colors(services?.length)[index],
  ) || [];

export function groupTimeSlotsByDate(
  timeSlots: TimeSlotType[],
): Record<string, TimeSlotType[]> {
  return timeSlots.reduce((acc, timeSlot) => {
    // Extraer la fecha sin la hora usando dayjs
    const dateKey = dayjs(timeSlot.startTime).format(DATE_CALENDAR);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(timeSlot);
    return acc;
  }, {} as Record<string, TimeSlotType[]>);
}

export const capitalizeWords = (str: string): string => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

/*** @deprecated, moved to Common and backend:
 From Guillermo Sierra, returns: guilleS*/
export const nameInitial = (name?: string): string => {
  const parts = capitalizeWords(name || '').split(' ');
  return name
    ? parts.length > 1
      ? `${parts[0]} ${parts[1].charAt(0)}.`
      : parts[0]
    : '';
};

/*** @deprecated, moved to Common and backend:
 From Guillermo Sierra, returns: GS*/
export const nameInitials = (name?: string): string => {
  return name
    ? name
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
    : '';
};

export const dataURLtoBlob = (dataurl: string) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type: mime});
};

export type ComplexTime = Timestamp | string | Date | undefined;
export const isAvailableSoon = (timestamp: ComplexTime) =>
  dayjs(timeStampToDate(timestamp)).isBetween(
    dayjs(),
    dayjs().add(3, 'day'),
    'day',
    '[]',
  );

const pad = (num: number, extraZero?: boolean): string => {
  return ((extraZero ? '0' : '') + num).slice(-2);
};

export const msToTextOld = (milisecs: number): string => {
  const secs = Math.floor(milisecs / 1000);
  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  //const miliseconds = Math.floor((milisecs % 1000) / 10);

  return pad(minutes) + ':' + pad(seconds, true);
};
export const msToText = (milisecs: number): string => {
  const secs = Math.floor(milisecs / 1000);
  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;

  let result = '';
  if (minutes > 0) {
    result += pad(minutes) + ':';
  }
  result += pad(seconds);
  return result;
};

export const msToTextLong = (milisecs: number): string => {
  const secs = Math.floor(milisecs / 1000);
  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  const decs = Math.floor((milisecs % 100) / 10);

  let result = '';
  if (minutes > 0) {
    result += pad(minutes) + ':';
  }
  if (seconds > 0 || minutes > 0) {
    result += pad(seconds) + '.';
  }
  result += pad(decs);
  return result;
};

export const isUpcoming = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
) => dayjs(date).isSameOrAfter(dayjs(), 'day');

export const timeStampToDate = (
  timestamp: ComplexTime, // @ts-ignore
) =>
  timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
export const userDate = (timestamp: Timestamp | string | Date) => {
  const date = timeStampToDate(timestamp);
  const formattedDate = dayjs(date).format(DATE_FORMAT_ALL);
  /*const timeFromNow = dayjs(date).fromNow();*/
  return `Próx. fecha: ${formattedDate}` /*(${timeFromNow})*/;
};

export const removeArray = (arr = [], index: number = 0) =>
  arr?.slice(index, index);

export const matchMonth = (currMonth: number, date?: Date) =>
  date && currMonth === dayjs(date).month() + 1;

export const round = (num: number, dec = 1) =>
  Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);

export const isMailValid = (mail: string) =>
  !!mail
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export enum units {
  DAYS = 'days',
  HALF = 0.5,
  QUARTER = 0.25,
  HUNDRED = 100,
  DAYS_YEAR = 365,
}

export const CONVERSIONS = {
  [Area.squareMeters]: 1,
  [Area.squareFeet]: 10.7639,
  [Distance.kilometers]: 1,
  [Distance.miles]: 0.621371,
  perc: 1,
  usd: 1,
  none: 1,
};

export type ConversionType = keyof typeof CONVERSIONS;
