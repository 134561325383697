export {}; /*
import {displayFriendlyImpact} from '@eevie/mobile/util-footprint';
import {BASE_PIXEL, palette} from '../ui';

import {memo} from 'react';
import {Trans} from 'react-i18next';
import {
  Image as RNImage,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {Circle, G, Image, Text as SVGText} from 'react-native-svg';
import {PieChart} from 'react-native-svg-charts';

// TODO: Use correct image sizes
// TODO: Fix Android dev builds not showing images (some issue with metro not resolving path)

type Data = {
  key: number;
  icon?: ImageSourcePropType;
  value: number;
  svg: {fill: string};
};

export type ImpactScorePieChartProps = {
  forest: number;
  travel: number;
  food: number;
  consumption: number;
  byline?: string;
  large?: boolean;
  style?: StyleProp<ViewStyle>;
};

/!** TODO add ConnsitenCSS & add 'Ee-' suffix *!/
export const ImpactScorePieChart = memo(function ImpactScorePieChart({
  forest,
  travel,
  food,
  consumption,
  byline,
  large = false, // TODO: What should be the default?
  style,
}: ImpactScorePieChartProps) {
  const footprintRounded = displayFriendlyImpact(
    forest + travel + food + consumption,
  );

  const data: Data[] = [
    {
      key: 1,
      icon: eIcons.cutlery,
      value: Math.max(food),
      svg: {
        fill: legacyColors.foodCategoryColor,
      },
    },
    {
      key: 2,
      value: 0.2,
      svg: {
        fill: palette.primaryBlue,
      },
    },
    {
      key: 3,
      icon: eIcons.car,
      value: Math.max(travel, 0.05),
      svg: {
        fill: legacyColors.travelCategoryColor,
      },
    },
    {
      key: 4,
      value: 0.2,
      svg: {
        fill: palette.primaryBlue,
      },
    },
    {
      key: 5,
      icon: eIcons.forest,
      value: Math.max(forest),
      svg: {
        fill: legacyColors.forestCategoryColor,
      },
    },
    {
      key: 6,
      value: 0.2,
      svg: {
        fill: palette.primaryBlue,
      },
    },
    {
      key: 7,
      icon: eIcons.creditCard,
      value: Math.max(consumption),
      svg: {
        fill: legacyColors.shoppingCategoryColor,
      },
    },
    {
      key: 8,
      value: 0.2,
      svg: {
        fill: palette.primaryBlue,
      },
    },
  ];

  const Labels = ({
    slices,
  }: {
    slices: {
      labelCentroid: Array<string | number>;
      data: Data;
    }[];
  }) => {
    return slices.map((slice, index) => {
      const {labelCentroid, data: sliceData} = slice;

      return sliceData.icon ? (
        <G key={index} x={labelCentroid[0]} y={labelCentroid[1]}>
          <Circle
            r={20}
            fill={sliceData.svg.fill}
            stroke={sliceData.svg.fill}
            strokeWidth={2}
            fillOpacity={0.4}
          />
          <Image
            x={-12}
            y={-12}
            width={28}
            height={28}
            opacity="1"
            href={sliceData.icon}
          />
          {/!** Added Badge, check with design *!/}
          <Circle
            r={BASE_PIXEL * 2}
            fill={sliceData.svg.fill}
            fillOpacity={0.8}
            stroke={sliceData.svg.fill}
            strokeWidth={2}
            x={BASE_PIXEL * 4}
            y={BASE_PIXEL * 4}
          />
          <SVGText
            fill={palette.white}
            fontWeight="bold"
            fontSize={8}
            x={BASE_PIXEL * 4}
            y={BASE_PIXEL * 4 + 3}
            textAnchor="middle">
            {round(sliceData.value)}
          </SVGText>
        </G>
      ) : null;
    });
  };

  return (
    <View
      style={[
        styles.container,
        large ? styles.containerLarge : undefined,
        style,
      ]}>
      <PieChart
        style={[styles.chart, large ? styles.chartLarge : undefined]}
        data={data}
        innerRadius={90}
        outerRadius={100}
        labelRadius={large ? 140 : 130}
        padAngle={0}
        sort={(a, b) => a.key - b.key}>
        {/!* @ts-ignore *!/}
        <Labels slices={[{labelCentroid: ['blup'], data: data[0]}]} />
      </PieChart>

      <View
        style={[
          styles.planetContainer,
          large ? styles.planetContainerLarge : undefined,
        ]}>
        <RNImage source={eImages.earth} style={styles.earth} />

        {/!* TODO: Add redesigned text component *!/}
        <Text
          style={[
            styles.text,
            footprintRounded < 0 ? styles.negativeFootprint : undefined,
          ]}
          allowFontScaling={false}>
          {footprintRounded}
        </Text>

        {byline && (
          <Text style={styles.byline} allowFontScaling={false}>
            <Trans
              // t('journey.impact-score.label_default')
              // t('journey.impact-score.label_doingGreat')
              // t('journey.impact-score.label_reachYourGoal')
              i18nKey={`journey.impact-score.label_${byline}`}
              components={{
                br: <Text children="\n" />,
              }}
            />
          </Text>
        )}
      </View>
    </View>
  );
});

export default ImpactScorePieChart;

const styles = StyleSheet.create({
  container: {
    height: 300,
  },
  containerLarge: {
    height: 350,
  },
  chart: {
    width: '100%',
    // position: "absolute",
    height: 300,
  },
  chartLarge: {
    height: 350,
  },
  planetContainer: {
    width: 180,
    height: 285,
    position: 'absolute',
    top: 7.5,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  planetContainerLarge: {
    top: 32.5,
  },
  earth: {
    position: 'absolute',
    width: 178,
    height: 178,
  },
  text: {
    fontSize: 30,
    color: palette.white,
    textAlign: 'center',
  },
  byline: {
    fontSize: 11,
    color: palette.white,
    textAlign: 'center',
    paddingRight: 15,
    paddingLeft: 15,
    textTransform: 'uppercase',
  },
  negativeFootprint: {
    paddingRight: 16,
  },
});
*/
