import React from 'react';
import {BASE_PIXEL, fontsMap} from './ui';
import C, {extend} from 'consistencss';

// Import the functions you need from the SDKs you need
import {FirebaseAppProvider} from 'reactfire';
import {RecoilRoot} from 'recoil';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {GoogleOAuthProvider} from '@react-oauth/google';

import {SessionContextProvider} from '@supabase/auth-helpers-react';
import {supabaseWeb} from './api/api';
import {TooltipProvider} from './modals/useModal';
import 'firebase/compat/auth';
import {palette} from 'oralify-common';
import {WebNavigator} from './routes/useGNav.web';
import Toast from 'react-native-toast-message';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {firebaseConfig} from './store/firebaseInit';
import {SpecialityProvider} from './types/SpecialityProvider';

/*import {configDotenv} from 'dotenv';*/
export const GOOGLE_CLIENT_ID =
  '414603217613-1k574fddh5lm7c332cia57emsujihpp2.apps.googleusercontent.com';

/*import ReactNativeFeatureFlags from 'react-native/Libraries/ReactNative/ReactNativeFeatureFlags';

// enable the JS-side of the w3c PointerEvent implementation
ReactNativeFeatureFlags.shouldEmitW3CPointerEvents = () => true;

// enable hover events in Pressibility to be backed by the PointerEvent implementation
ReactNativeFeatureFlags.shouldPressibilityUseW3CPointerEventsForHover = () => true;*/

/*
headerScreens.experts.path
    .replace('/:speciality', specialityKey ? `/${specialityKey}` : '')
    .replace('/:format', serviceFormat ? `/${serviceFormat}` : '');
*/

extend({
  fonts: fontsMap,
  sizing: {base: BASE_PIXEL},
  colors: palette,
});
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optiona

/*const linking = {
  prefixes: ['https://oralify.com', 'https://www.oralify.com', 'oralify://', 'https://!*.oralify.com'],
  config: {
    screens: Object.fromEntries(Object.entries(headerScreens).map(([name, {path}]) => [name, path])),
  },
};*/

export const App = () => {
  const queryClient = new QueryClient();
  /* const [supabaseClient] = useState(() =>
          createClient({supabaseUrl: supabaseUrl, supabaseKey: supabaseAnonKey}),
        );*/
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <SessionContextProvider
            supabaseClient={
              supabaseWeb
            } /*initialSession={pageProps.initialSession}*/
          >
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                  <SpecialityProvider>
                    <TooltipProvider>
                      <SafeAreaProvider style={C.flex}>
                        <WebNavigator />
                      </SafeAreaProvider>
                    </TooltipProvider>
                  </SpecialityProvider>
                </QueryClientProvider>
              </RecoilRoot>
            </FirebaseAppProvider>
          </SessionContextProvider>
        </GoogleOAuthProvider>
      </LocalizationProvider>
      <Toast />
    </>
  );
};
