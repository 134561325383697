import useResponsive from '../ui/helpers/useResponsive';
import React, {useState} from 'react';
import {
  Alignment,
  customText,
  eColor,
  EeSize,
  eLayout,
  gImages,
  onTop,
  orangeGrad,
  TextType,
  vSizes,
} from '../ui';
import {Image, Linking, Pressable, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import LinearGradient from 'react-native-linear-gradient';
import {GIcon, GText} from '../atoms';
import {trans} from '../store/language';
import {GAvatar} from '../molecules/GAvatar';
import {docs, links} from '../types/mockLanding';
import {ButtonSize, ButtonType, GButton} from '../molecules';
import {currentUserAtom} from '../store/useUser';
import {useRecoilState} from 'recoil';
import {useModal} from '../modals/useModal';
import {GSearchBarLanding} from './GSearchBarLanding';
import {palette, RoleKey} from 'oralify-common';
import {headerNav, modalScreens} from '../routes/useGNav.web';
import DropDownPicker from 'react-native-dropdown-picker';
import GSpinner, {EmptyResults} from '../atoms/GSpinner';
import {settingsSteps} from '../types/stepTypes';
import {useNavigate} from 'react-router-dom';

export const LandingHeader = ({
  /*current = headerScreens.talkers.name,*/
  onPress,
  withBg = true,
}: {
  current?: string;
  withBg?: boolean;
  onPress?: () => void;
}) => {
  const {isSmall, isHuge, wrapStyle, resp} = useResponsive();
  /*const resetUser = useResetRecoilState(currentUserAtom);*/
  const [openAccountDropdown, setOpenAccountDropdown] = useState(false);

  const [currUser, setUser] = useRecoilState(currentUserAtom);
  //const {TooltipWrapper: AccountTooltip, setShowTooltip} =
  // usePressTooltip({});
  const {
    showModal: showBurgerModal,
    closeModal: closeBurgerModal,
    ModalComponent: BurgerModal,
  } = useModal(modalScreens.burger);
  const {showModal: showLoginModal} = useModal(modalScreens.login);

  const navigate = useNavigate();

  /*const {showModal: showServiceModal} = useModal(modalScreens.addService);*/

  const profileStepsList = Object.values(settingsSteps)
    .filter(
      ({isExpert}) =>
        (isExpert &&
          (currUser?.role === RoleKey.Expert || currUser?.services?.length)) ||
        !isExpert,
    )
    .map(val => ({
      ...val,
      label: val.title,
      icon: () => <GIcon icon={val.icon} name={val.icon} />,
      value: val.key,
    }));

  /*const accountMap: /!*CommonType & {path?: string; iconCircular?: boolean}*!/ (IconTextProps & {
              path?: string;
              iconCircular?: boolean;
            })[] = [
              {...headerScreens.profile, iconCircular: true, icon: currUser?.avatar},
              {
                name: currUser?.is_connected ? 'Disponible' : 'Offline',
                icon: 'circle',
                color: currUser?.is_connected ? palette.greenPrim : palette.lightGrey,
                onPress: () => {
                  if (currUser && currUser.id) {
                    setUser({...currUser, is_connected: !currUser.is_connected});
                    //updateConnectionStatus(currUser?.id, !currUser.is_connected);
                  }
                },
              },
              {
                ...headerScreens.services,
                onPress: () => showServiceModal(),
                title: '(3)',
                iconCircular: true,
              },
              headerScreens.bookings,
              headerScreens.payments,
              {
                name: 'Cerrar Sesión',
                icon: 'log-out',
                //color: palette.redSec,
                onPress: () => {} /!*currUser && currUser.id && authLogout(currUser,
                 resetUser*!/,
              },
            ];*/
  const handlePress = (path?: string) => {
    if (isSmall) closeBurgerModal();
    onPress && onPress();
    path ? navigate(path) : showLoginModal({isSpecialist: true});
  };

  const showSearchBar = isHuge && withBg;
  const BurgerContent = (
    <TouchableOpacity
      onPress={() => {
        if (openAccountDropdown) setOpenAccountDropdown(false);
      }}
      style={[C.itemsCenter, !isSmall && C.row]}
    >
      {headerNav.map(({name, path, icon}, index) =>
        index === 1 /** Main CTA*/ ? (
          <GButton
            key={name}
            onPress={() => handlePress(path)}
            icon={icon}
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            text={name}
          />
        ) : (
          <GText
            key={name}
            style={C.p3}
            color={isSmall ? palette.orangeSec : palette.white}
            onPress={() => handlePress(path)}
            type={TextType.bodyBold}
          >
            {name}
          </GText>
        ),
      )}

      <View
        style={[
          C.ml2,
          !isSmall && C.row,
          /*C.bgBlue,*/
          C.itemsCenter,
          C.justifyCenter,
        ]}
      >
        {currUser && (
          <DropDownPicker
            open={openAccountDropdown}
            zIndexInverse={100}
            style={[
              C.w12,
              /*C.radius90,
                                                                                        C.maxh5,
                                                                                        C.shadowMd,
                                                                                       ,*/
              C.bgTransparent,
              eColor('transparent').border,
            ]}
            TickIconComponent={() => <GIcon icon={'check'} />}
            CloseIconComponent={() => <GIcon icon={'chevron-down'} />}
            ArrowIconComponent={() => <GIcon icon={'hamburger'} />}
            listItemLabelStyle={[customText(TextType.body)]}
            placeholderStyle={[customText(TextType.bodyBold)]}
            placeholder={''}
            onPress={() => {}}
            dropDownContainerStyle={[
              !isSmall && C.right_40,
              !isSmall && C.absolute,
              isSmall ? C.mt4 : C.mt1,
              C.w40,
              C.radius4,
              /* eColor(palette.border).bg,*/
              C.shadowMd,
              eColor(palette.whiteFour).border,
            ]}
            /*listMode={'MODAL'}
                                                                        mode={'DEFAULT'}*/
            onSelectItem={item => {
              if (currUser) navigate('/profile/' + item.key);
              else showLoginModal();
            }}
            ActivityIndicatorComponent={() => <GSpinner />}
            ListEmptyComponent={() => <EmptyResults />}
            value={''}
            items={profileStepsList}
            setOpen={setOpenAccountDropdown}
            setValue={() => {}}
            /*setItems={setItems}*/
          />
        )}
        <GAvatar
          wrapStyle={[
            /*openAccountDropdown
                                                                          ? eColor(palette.white, 2).borderTop
                                                                          :*/ eColor(
              palette.white,
              2,
            ).border,
            eColor(palette.border).bg,
            C.pxHairline,
            /*openAccountDropdown ? C.radius4 :*/ C.radius10,
          ]}
          showName
          /* onHover={() => setOpenAccountDropdown(true)}*/
          color={isSmall ? palette.orangeSec : palette.white}
          onPress={() =>
            currUser
              ? setOpenAccountDropdown(
                  true,
                ) /*navigate(headerScreens.profile.pathMain)*/
              : showLoginModal()
          }
          size={EeSize.iconWrapper}
          horiz
          online={currUser?.is_connected}
          image={currUser?.avatar}
          name={currUser?.nameShort || 'Acceder'}
        />
      </View>
    </TouchableOpacity>
  );
  const Content = (
    <View style={[wrapStyle, C.row, C.justifyCenter, C.itemsCenter]}>
      <Pressable
        style={[
          C.itemsCenter,
          C.row,
          C.radius12,
          showSearchBar ? C.mr4 : C.flex,
        ]}
        onPress={() => navigate('/')}
      >
        <GIcon
          wrapStyle={[C.mr1]}
          icon={gImages.oralifyWhite}
          size={EeSize.iconWrapper}
        />
        <GText type={TextType.h1Logo} style={C.mr2} color={palette.white}>
          {trans('Oralify')}
        </GText>
      </Pressable>
      <View style={C.flex} />

      {isHuge && withBg && (
        <View style={[C.flex, C.itemsCenter, C.selfCenter, C.justifyCenter]}>
          <GSearchBarLanding compact />
        </View>
      )}

      {/**Black overlay*/}
      {isSmall ? (
        /*<TooltipBurger
                                                                                          TooltipTrigger={*/
        <>
          <View style={[C.itemsCenter, C.justifyEnd, C.row, C.flex, C.mr4]}>
            <GIcon
              onPress={() => {
                showBurgerModal();
              }}
              wrapStyle={[C.mr1]}
              circular
              icon={'menu'}
              size={EeSize.iconWrapper}
              color={palette.white}
            />
          </View>
          <BurgerModal>
            <View style={[C.itemsCenter, resp(0.7).height, resp(0.7).width]}>
              {BurgerContent}
            </View>
          </BurgerModal>
        </>
      ) : (
        /*}
                                                                                                    onPress={() => {}}
                                                                                                    TooltipContent={BurgerContent}
                                                                                                    wrapStyle={[resp(0.9).width, C.left6, C.top16]}
                                                                                                  />*/
        BurgerContent
      )}
    </View>
  );
  const wrapper = [
    C.row,
    withBg && [C.radiusbottom10, eColor(palette.white, 2).borderBottom],
    C.itemsCenter,
    C.justifyCenter,
    C.px10,
    C.py4,
  ];
  return (
    <View style={[[eLayout.sticky, C.top0, onTop()]]}>
      {withBg ? (
        <LinearGradient
          colors={orangeGrad}
          style={wrapper}
          angle={120}
          useAngle
        >
          {Content}
        </LinearGradient>
      ) : (
        <View style={wrapper}>{Content}</View>
      )}
    </View>
  );
};

export const LandingFooter = () => {
  const {isSmall, resp} = useResponsive();

  return (
    <LinearGradient
      useAngle
      angle={150}
      colors={orangeGrad}
      style={[
        C.p4,
        C.bgPrimaryBlue,
        C.radiustop10,
        C.itemsCenter,
        !isSmall && [C.row, C.py12, C.pl10, C.justifyBetween],
        C.wFull,
      ]}
    >
      {/**Logo*/}
      <View style={[!isSmall && [C.justifyBetween], C.itemsCenter]}>
        {/** @ts-ignore*/}
        <Image
          source={gImages.oralifyWhite}
          resizeMode={'contain'}
          style={[vSizes.lg]}
        />
        <GText type={TextType.h4} color={palette.white} style={[C.my4]}>
          {trans('Oralify 2023')}
        </GText>
      </View>

      {/**Contact*/}
      <View
        style={[
          C.justifyBetween,
          C.flex,
          !isSmall && [C.mx16, resp(0.4).maxWidth],
        ]}
      >
        {/*{!isSmall && (
          <View>
            <GText type={TextType.h4} style={[C.textWhite, C.mb4]}>
              {trans('¡Te avisamos cuando Oralify esté disponible!')}
            </GText>
            <WaitingListMail />
          </View>
        )}*/}

        {/** Esade batch*/}

        <View style={[C.row, C.itemsCenter]}>
          <GIcon
            /*isResponsive*/
            resizeMode={'contain'}
            wrapStyle={vSizes.logo}
            icon={'https://www.veteasy.es/images/esade.png'}
          />
          <GIcon
            /*isResponsive*/
            resizeMode={'contain'}
            wrapStyle={[vSizes.xl, C.ml4]}
            tint={palette.white}
            color={palette.white}
            icon="http://www.barcinno.com/wp-content/uploads/2023/03/eworksaccelerator_13364_829415.png"
          />
        </View>

        <View style={[C.row, C.mt10]}>
          <GText
            type={TextType.link}
            color={palette.white}
            onPress={() => Linking.openURL(docs.cookies, '_blank')}
          >
            {trans('Política de cookies')}
          </GText>
          <GText
            type={TextType.link}
            style={[C.textWhite, C.mx5]}
            onPress={() => Linking.openURL(docs.privacy, '_blank')}
          >
            {trans('Política de privacidad')}
          </GText>
          <GText
            type={TextType.link}
            style={[C.textWhite]}
            onPress={() => Linking.openURL(docs.legal, '_blank')}
          >
            {trans('Aviso legal')}
          </GText>
        </View>
      </View>

      <View style={isSmall ? [C.itemsCenter, C.my3] : C.justifyStart}>
        <GText type={TextType.h4} color={palette.white}>
          {trans('¡Síguenos!')}
        </GText>
        <View style={[C.row, C.itemsCenter]}>
          <GIcon
            icon={'facebook'}
            color={palette.white}
            size={EeSize.iconWrapper}
            onPress={() => Linking.openURL(links.instagram)}
          />
          <GIcon
            icon={'logo-instagram'}
            wrapStyle={C.m2}
            size={EeSize.iconWrapper}
            color={palette.white}
            onPress={() => Linking.openURL(links.instagram)}
          />
          <GIcon
            icon={'logo-linkedin'}
            color={palette.white}
            size={EeSize.iconWrapper}
            onPress={() => Linking.openURL(links.liknedin)}
          />
        </View>
        <GText
          align={Alignment.Left}
          color={palette.white}
          style={[C.my5, !isSmall && resp(0.2).width]}
        >
          {trans(
            'Hecho con amor para que puedas resolver tus dudas al instante',
          )}
        </GText>
      </View>
    </LinearGradient>
  );
};
