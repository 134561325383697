import C from 'consistencss';
import React, {useEffect} from 'react';
import {FlatList, Pressable, View} from 'react-native';

import {
  GroupedBubbles,
  Highlight,
  LandingWrapper,
} from '../components/LandingWrapper';
import {Alignment, EeSize, gImages, TextType} from '../ui';

import {trans} from '../store/language';
import {oralifyLandingData} from '../types/mockLanding';
import {useModal} from '../modals/useModal';
import {GIcon, GText} from '../atoms';
import {ButtonType, GButton} from '../molecules';
import {
  SideAbsoluteContainer,
  SideContainer,
} from '../components/SideContainer';
import {GSearchBarLanding} from '../components/GSearchBarLanding';
import useResponsive from '../ui/helpers/useResponsive';
import {CategorySolutions} from '../components/CategorySolutions';
import {HowToDiagram} from '../components/HowToDiagram';
import {CheckboxGrad} from '../components/Cards';
import {
  gSpecialities,
  palette,
  specialityCategories,
  SpecialityKey,
} from 'oralify-common';
import {GPill} from '../molecules/gPill';
import {
  headerScreens,
  modalScreens,
  routeQueryParam,
} from '../routes/useGNav.web';
import {useNavigate, useParams} from 'react-router-dom';
import {gLogEvent} from '../store/firebaseInit';
import {useRecoilState} from 'recoil';
import {currentUserAtom} from '../store/useUser';
import {GoogleCalendarSelector} from '../FormSelector/GoogleCalendarSelector';

export const Landing = () => {
  const {showModal: showLoginModal} = useModal(modalScreens.login);

  /*const [currUser, setCurrUser] = useRecoilState(currentUserAtom);*/
  /*const {ModalComponent: AddServicesModal /!*showModal: showAddServicesModal*!/} =
    useModal(modalScreens.addService);*/
  const {isSmall, wrapStyle, resp, isHuge} = useResponsive();
  const navigate = useNavigate();
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  const onOpenLoginModal = () => {
    showLoginModal();
    gLogEvent('openLoginModal');
  };
  const {login} = useParams();
  useEffect(() => {
    console.log('rereho ', login, currUser);
    if (login && !currUser) {
      onOpenLoginModal();
      /*navigate(`/login/${login}`);*/
    }
  }, [login, currUser]);
  return (
    <LandingWrapper
      HeaderChild={
        <View style={[C.wFull, C.pt6]}>
          <SideAbsoluteContainer img={isHuge /*!randomUser*/ && gImages.women}>
            <GSearchBarLanding />
          </SideAbsoluteContainer>
        </View>
      }
    >
      {/*{currentUser && <ServiceList user={currentUser} />}*/}
      {isSmall && (
        <GButton
          text={'¡Crea tu perfil gratis!'}
          style={[C.my8]}
          onPress={onOpenLoginModal}
        />
      )}
      {/** Formato */}
      {/*{isSmall && <SideContainer
        imgRatio={1.6}
        img={gImages.iaSpecialistMessi}
        style={!isSmall && C.px10}
      >
        <GText type={TextType.h4} style={C.mb4}>
          {trans(
            'Escoge entre cientos de expertos,' +
              '\nDisponibles cuándo y cómo lo necesites',
          )}
        </GText>
        <GIconRow
          color={palette.purpleSec}
          icon={'🌟'}
          name={'Puntuación'}
          title={'Reviews' + ' fiables'}
        />
        <GIconRow icon={'🌟'} name={'Puntuación'} title={'Reviews fiables'} />
        <GIconRow icon={'🌟'} name={'Puntuación'} title={'Reviews fiables'} />
        <TimelineCalendar viewMode="week" />
        {isSmall && (
          <GButton
            text={'¡Crea tu perfil gratis!'}
            style={C.mb12}
            onPress={onOpenLoginModal}
          />
        )}
         <DurationSelector />
        <PricingCards />
      </SideContainer>}*/}

      {/**Highlights*/}
      <SideContainer>
        <h2>
          <View style={[C.itemsCenter, C.mt_12]}>
            <GText align={Alignment.Center} type={TextType.h2}>
              {trans('Categorías más populares')}
            </GText>
          </View>
        </h2>
        <GoogleCalendarSelector />
        <CategorySolutions />

        <GroupedBubbles
          /*condensed*/
          onPress={(key: SpecialityKey) => {
            navigate(
              routeQueryParam(headerScreens.expertSearch.path, {
                category: 'all',
                specialityKey: key,
              }),
            );
          }}
          maxShown={isHuge ? 18 : 7}
          list={Object.entries(gSpecialities)}
        />
      </SideContainer>

      {/** How to use */}
      <SideContainer>
        <GText
          align={Alignment.Center}
          style={[C.mt4, C.flex]}
          type={TextType.h1}
        >
          {trans('Tengo una duda ... ¿y ahora qué hago?')}
        </GText>
        <View style={[!isSmall && C.row, C.itemsCenter]}>
          {oralifyLandingData.highlights.map((props, index) => (
            <Highlight key={index} {...props} />
          ))}
        </View>
      </SideContainer>

      <SideContainer>
        <View style={[C.itemsCenter]}>
          <GText align={Alignment.Center} type={TextType.h1}>
            {'Conecta con expertos en cualquier area'}
          </GText>

          <GText align={Alignment.Center} style={[C.my5]}>
            {'Encuentra expertos, conecta en cualquier momento y lugar'}
          </GText>
          {/* <View style={centerRow}>*/}
          <GIcon icon={gImages.onlineQuestions} size={EeSize.imageBig} />
          {/*<GIcon icon={gImages.iaSpecialistMessi} size={EeSize.imageBig} />
          </View>*/}
          <FlatList
            horizontal
            data={Object.entries(specialityCategories)}
            style={[C.my2, C.wFull]}
            renderItem={({item: [currkey, currVal]}) => {
              /*const selected = currentCategoryKey === currkey;*/
              return (
                <Pressable
                  style={[C.itemsCenter, C.mr4, C.mb4, C.p1]}
                  key={currkey}
                  onPress={() =>
                    navigate(
                      routeQueryParam(headerScreens.expertSearch.path, {
                        category: 'all',
                        specialityKey: currVal.services[0],
                      }),
                    )
                  }
                >
                  <View
                    style={[
                      C.mb2,
                      C.shadowMd,
                      C.bgWhite,
                      C.radius10,
                      C.w15,
                      C.h15,
                      C.pt1,
                      C.selfCenter,
                      C.itemsCenter,
                      C.justifyCenter,
                    ]}
                  >
                    <GIcon icon={currVal.emoji} size={EeSize.emoji} />
                  </View>
                  <GText
                    align={Alignment.Center}
                    color={palette.secondaryDarkGrey}
                    type={TextType.tabSelected}
                  >
                    {currVal.name}
                  </GText>
                  <FlatList
                    style={[C.mt4]}
                    contentContainerStyle={[C.itemsCenter]}
                    data={currVal.services}
                    renderItem={({item}) => {
                      const {name, emoji} =
                        gSpecialities[item as SpecialityKey];
                      /*const selected = currentSpecialityKey === item;*/
                      return (
                        <GPill
                          /*selected={selected}*/
                          long
                          /*transp={transparent}*/
                          text={name}
                          color={palette.greyish}
                          icon={emoji}
                          onPress={() => {
                            navigate(
                              routeQueryParam(headerScreens.expertSearch.path, {
                                category: 'all',
                                specialityKey: item,
                              }),
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Pressable>
              );
            }}
          />

          {/** Gamification - Speakers*/}
          <View
            style={[
              wrapStyle,
              C.mt4,
              C.itemsCenter,
              !isSmall && [C.row, C.justifyCenter, C.wFull],
            ]}
          >
            <View>
              <GText
                type={TextType.h4}
                /*color={palette.orangeSec}*/
                style={[C.mb2]}
              >
                {trans('Para individuales o empresas')}
              </GText>
              {/*<GIcon icon={gImages.onlineQuestions} size={EeSize.image} />*/}
              {/*<GroupedBubbles list={speakerLevels} />*/}

              <GButton
                icon={'lightning-bolt'}
                text={'Resuelve dudas específicas'}
                type={ButtonType.secondary}
                onPress={onOpenLoginModal}
                style={[C.selfStart, C.mt4]}
              />
            </View>

            {/** Experts*/}
            <View style={[C.mt2, isSmall ? C.mt10 : C.ml28]}>
              <GText
                type={TextType.h4}
                /*color={palette.orangeSec}*/
                style={[C.mb2]}
              >
                {trans('Para expertos en cualquier area')}
              </GText>
              {/*<GIcon icon={gImages.onlineQuestions} size={EeSize.image} />*/}
              {/* <GroupedBubbles list={listenerLevels} />*/}

              <GButton
                icon={'account-star'}
                text={'Monetiza tus conocimientos'}
                onPress={onOpenLoginModal}
                style={[C.selfStart, C.mt4]}
              />
            </View>
          </View>
        </View>
      </SideContainer>

      {/**Calendar*/}
      <SideContainer
        colBg={palette.background}
        imgLeft
        img={gImages.iaSpecialist}
        imgStyle={[isSmall ? C.bottom_12 : C.bottom_8]}
        /*imgRatio={0.4}*/
      >
        <GText
          type={TextType.h2}
          align={isSmall ? Alignment.Center : Alignment.Left}
        >
          {'Habla cuando lo necesites o reserva cita'}
        </GText>
        <GText
          style={[C.my5]}
          align={isSmall ? Alignment.Center : Alignment.Left}
        >
          {
            'Selecciona al experto que mejor se adapte a tus necesidades y continúa hablando. ¡Rápido, sencillo y sin costes adicionales!'
          }
        </GText>
        <GButton
          text={'Habla ahora!'}
          icon={'lightning-bolt'}
          onPress={onOpenLoginModal}
          style={[isSmall ? C.selfCenter : C.selfStart, C.mt4]}
        />
      </SideContainer>

      {/**Cards*/}
      {/* <View style={[C.my12, wrapStyle]}>
        <GText style={[fonts.mainTitle, C.mb4]}>{'En cualquier momento, en cualquier lugar'}</GText>
        <FlatList
          keyExtractor={({title}) => title}
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={isSmall && [resp().width, C.selfStart]}
          data={oralifyLandingData.cards}
          renderItem={({item}) => <LandingCard {...item} />}
        />
      </View>*/}

      {/**Mockup*/}
      <SideContainer
        img={gImages.iaMobileStack /*isSmall ? gImages.women : gImages.mockup*/}
        /*imgRatio={0.9}
        imgSize={vSizes.mockup}*/
        imgStyle={[C.top_4]}
      >
        <GText type={TextType.h2}>
          {'¿Tienes conocimientos que otros necesitan?'}
        </GText>
        <GText style={[C.my5]}>
          {'¡Únete a la comunidad online de expertos!'}
        </GText>
        {oralifyLandingData.checks.map(({title, icon}) => (
          <CheckboxGrad
            big
            key={title}
            checked
            circ
            icon={icon}
            text={title}
            wrapStyle={[C.my2]}
          />
        ))}
        <View style={[C.row, C.itemsCenter, C.mt6]}>
          <GButton
            text={'Crea perfil gratis'}
            onPress={onOpenLoginModal}
            style={[C.selfStart]}
          />

          <GText
            type={TextType.h4}
            //onPress={() => navigate(signedIn.VetLanding.name)}
            style={[C.ml10]}
          >
            {trans('Infórmate')}
          </GText>
        </View>
      </SideContainer>

      {/**Oral Coin - Currency & Pricing*/}
      {/*<SideContainer colBg={palette.background} imgLeft img={gImages.mockup}>
        <GText type={TextType.h2}>{'Gana desde 18€/h'}</GText>
        <GText style={[C.my5]}>
          {trans('Empieza a generar ingresos por lo que mejor se te da, escuchar a otras personas')}
        </GText>
        <View style={[C.row, C.itemsCenter]}>
          <OralCoin height={60} width={60} />
          <GText type={TextType.tab} color={palette.orangeSec} style={[C.m2]}>
            {trans('OralCoins ® que obtienes al escuchar para hablar cuando lo necesites')}
          </GText>
        </View>

        <GText style={[C.my5]}>{trans('Escoge qué servicios quieres ofrecer...')}</GText>
        <FlatList
          data={Object.values(serviceMap)}
          keyExtractor={({name, format}, index) => (format || name || '') + index}
          horizontal
          renderItem={({item}) => (
            <TouchableOpacity onPress={showLoginModal} style={[C.selfStart, C.itemsCenter, C.mr5]}>
              <CircWrapper size={vSizes.med} icon={item.icon} />
              <GText type={TextType.tabSelected} style={[C.capitalize, C.my2]} color={palette.orangeSec}>
                {item.name}
              </GText>
            </TouchableOpacity>
          )}
        />
      </SideContainer>*/}

      {/**All Listeners*/}
      {/*<SideContainer imgLeft colBg={palette.background} imgStyle={!isSmall && C.bottom_5} img={gImages.gossipGirls}>
        <GText style={[fonts.mainTitle]}>{'Expresa cualquier duda...'}</GText>
        <GText style={[C.my5]}>
          {trans('Queremos ayudarte a soltar todo aquello que sientes y llevas dentro!')}
        </GText>
      </SideContainer>*/}

      {/*{loginModalVisible && (
        <LoginModalComponent>
          <View style={[!isSmall && C.row, C.itemsCenter, C.justifyCenter]}>
            <View style={[isSmall ? C.mx10 : C.mr20, isSmall && C.mt10]}>
              <h1>
                <GText style={[fonts.mainTitle, eColor(palette.orangeSec).text]}>{trans('🚀 ¡Muy pronto!')}</GText>
              </h1>
              <GText style={[ C.my6]}>
                {trans('¡Ya queda menos para que puedas hablar con gente como tú!')}
              </GText>
              <GText style={[fonts.textBold, C.mb6]}>
                {trans('Déjanos tu correo y te avisaremos cuando esté disponible.')}
              </GText>
              {!isSmall && <WaitingListMail />}
            </View>
            * @ts-ignore
            <Image source={gImages.women} resizeMode={'contain'} style={[isSmall ? C.top4 : C.top5, vSizes.cardBig]} />
          </View>
        </LoginModalComponent>
      )}*/}
      <SideContainer colBg={palette.white}>
        <GText align={Alignment.Center} type={TextType.h1} style={[C.mb6]}>
          {'Transforma tus problemas en soluciones rápidas y efectivas'}
        </GText>
        <HowToDiagram />
      </SideContainer>
      <GIcon
        icon={gImages.iaSpecialistMessi}
        resizeMode={'cover'}
        wrapStyle={!isSmall ? [C.absolute, C.bottom0, C.right0] : C.selfCenter}
        size={EeSize.imageBig}
      />
      <GIcon
        icon={gImages.iaVideoCallAccept}
        wrapStyle={!isSmall && [C.absolute, C.bottom0]}
        size={EeSize.imageBig}
      />

      {/*<PricingCards />*/}
    </LandingWrapper>
  );
};
