import React, {useEffect, useState} from 'react';
import {Linking, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import {ShareQR} from '../organisms/ShareQR';
import UserCard from '../organisms/UserCard';
import {EeSize, gImages, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {trans} from '../store/language';
import {palette, UserType} from 'oralify-common';
import useResponsive from '../ui/helpers/useResponsive';
import GSpinner from '../atoms/GSpinner';
import {getUserInfo} from '../api/userApi';
import {useParams} from 'react-router-dom';
import Toast from 'react-native-toast-message';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {ButtonType, GButton} from '../molecules';
import Clipboard from '@react-native-clipboard/clipboard';
import {GBadge} from '../molecules/gBadge';

export const PublicProfile = ({user}: {user?: UserType}) => {
  const {isSmall} = useResponsive();
  const {username} = useParams();
  const [loading, setLoading] = useState(false);
  const [shownUser, setShownUser] = useState<UserType | undefined>(user);
  /*const getFromMockUser = async (uid: string) => {
          if (mockUsers) {
            const mockUser = mockUsers.find((u: UserType) => u.username === uid);
            if (mockUser) setShownUser(mockUser as UserType);
          }
        };*/
  const iframeCode = (
    isQR = false,
  ) => `<iframe height="500" width="600" frameBorder="0" 
src="https://oralify.com/embed/${shownUser?.username}${isQR ? '/qr' : ''}" />`;
  const code2 =
    'function createStyleObject(classNames, style) {\n' +
    '  return classNames.reduce((styleObject, className) => {\n' +
    '    return {...styleObject, ...style[className]};\n' +
    '  }, {});\n' +
    '}\n' +
    '\n' +
    'function createClassNameString(classNames) {\n' +
    "  return classNames.join(' ');\n" +
    '}\n' +
    '\n' +
    "// this comment is here to demonstrate an extremely long line length, well beyond what you should probably allow in your own code, though sometimes you'll be highlighting code you can't refactor, which is unfortunate but should be handled gracefully\n" +
    '\n' +
    'function createChildren(style, useInlineStyles) {\n' +
    '  let childrenCount = 0;\n' +
    '  return children => {\n' +
    '    childrenCount += 1;\n' +
    '    return children.map((child, i) => createElement({\n' +
    '      node: child,\n' +
    '      style,\n' +
    '      useInlineStyles,\n' +
    '      key:`code-segment-${childrenCount}-${i}`\n' +
    '    }));\n' +
    '  }\n' +
    '}';

  const personalURL = `https://guillesierra.com`;
  const handleCopyCode = (isQR = false) => {
    Clipboard.setString(iframeCode(isQR));
    Toast.show({text1: '¡Código copiado al portapapeles!'});
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      setLoading(true);
      if (username) {
        setShownUser(await getUserInfo(username));
        /*if (!shownUser) {
                                  await getFromMockUser(username);
                                }*/
      } /*else if (currentUser && currentUser.id) {
                setShownUser(await getUserInfo(currentUser.id));
            }*/
      setLoading(false);
    };
    if (!user) getCurrentUser();
  }, [username]);

  const CodeWrapper = ({isQR = false}) => {
    return (
      <TouchableOpacity style={[C.mt4]} onPress={() => handleCopyCode(isQR)}>
        <GBadge
          wrapStyle={[C.absolute, C.left3]}
          selected
          fontColor={palette.purpleSec}
          icon="code"
          text="Copiar"
        />
        <SyntaxHighlighter
          customStyle={{
            borderRadius: 12,
            borderWidth: 1,
            borderColor: palette.purpleSec,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            padding: 12,
            overflow: 'hidden',
          }}
          language="cshtml"
          wrapLines
          style={{
            // Colores de fondo y texto generales
            hljs: {
              display: 'block',
              overflowX: 'auto',
              padding: '0.5em',
              color: palette.secondaryDarkGrey,
              background: palette.greyish + '40', // naranja de fondo
            },
            // Definir otros elementos específicos del lenguaje aquí, por ejemplo:
            keyword: {
              color: '#ffffff',
              fontWeight: 'bold',
            },
          }}
        >
          {iframeCode(isQR)}
        </SyntaxHighlighter>
      </TouchableOpacity>
    );
  };
  return shownUser && !loading ? (
    <View>
      <View
        style={[
          C.justifyCenter,
          C.selfCenter,
          /*resp(0.6).minHeight,*/
          !isSmall && [C.itemsStart, C.row],
          /*eLayout.gap4,
                                        C.mb4,*/
        ]}
      >
        <View style={[C.mb4]}>
          <GText style={C.mb12} type={TextType.h3Orange}>
            {trans(
              shownUser ? 'Así te veran los usuarios' : 'Usuario no encontrado',
            )}
          </GText>
          <UserCard showRibbon user={shownUser} showInitialTab />
        </View>
        <View>
          <GText style={C.mb8} type={TextType.h3Orange}>
            {trans('Comparte tu perfil y hazte viral')}
          </GText>
          {shownUser && <ShareQR user={shownUser} />}
          <CodeWrapper isQR />
        </View>
      </View>
      {!isSmall && (
        <View style={C.itemsCenter}>
          <GText style={C.mb2} type={TextType.h3Orange}>
            {trans('Incrustalo en cualquier otra web')}
          </GText>
          <View style={[C.row, C.itemsCenter, C.justifyBetween]}>
            <TouchableOpacity onPress={() => Linking.openURL(personalURL)}>
              <GIcon
                resizeMode="contain"
                icon={gImages.shareMockup}
                size={EeSize.mockup}
                wrapStyle={C.radius10}
              />
              {/*{false && (
              <iframe
                style={{
                  borderRadius: 20,
                  width: 1600 deviceWidth * 0.9,
                  height: 1300 deviceWidth * 0.6,
                }}
                src={personalURL}
              />
            )}*/}
            </TouchableOpacity>

            <View style={[C.itemsCenter, C.ml4]}>
              <GText style={C.mb4}>
                {trans('Copia y pega el siguiente código en tu sitio web: ')}
              </GText>
              <CodeWrapper />
              <View style={[C.row, C.itemsCenter]}>
                <GButton
                  onPress={() => Linking.openURL(personalURL)}
                  type={ButtonType.primary}
                  text={'Ver Demo'}
                  icon={'developer-mode'}
                />
                <GButton
                  onPress={handleCopyCode}
                  type={ButtonType.secondary}
                  text={'Copiar Código'}
                  icon={'copy'}
                />
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  ) : (
    <GSpinner />
  );
};
