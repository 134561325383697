import {ImageSourcePropType, ImageStyle} from 'react-native';
import AntSet from 'react-native-vector-icons/AntDesign';
import EntypoSet from 'react-native-vector-icons/Entypo';
import IconFontawesome5 from 'react-native-vector-icons/FontAwesome5';
import FontistoSet from 'react-native-vector-icons/Fontisto';
import AntDGlyphs from 'react-native-vector-icons/glyphmaps/AntDesign.json';
import EntypoGlyphs from 'react-native-vector-icons/glyphmaps/Entypo.json';
import EvilIconsGlyphs from 'react-native-vector-icons/glyphmaps/EvilIcons.json';
import FeatherGlyphs from 'react-native-vector-icons/glyphmaps/Feather.json';
import FontAwesomeGlyphs from 'react-native-vector-icons/glyphmaps/FontAwesome.json';
import FontAwesome5Glyphs from 'react-native-vector-icons/glyphmaps/FontAwesome5Free.json';
import FontAwesomeProGlyphs from 'react-native-vector-icons/glyphmaps/FontAwesome5Pro.json';
import FontistoGlyphs from 'react-native-vector-icons/glyphmaps/Fontisto.json';
import FoundationGlyphs from 'react-native-vector-icons/glyphmaps/Foundation.json';
import IoniconsGlyphs from 'react-native-vector-icons/glyphmaps/Ionicons.json';
import MaterialCommunityIconsGlyphs from 'react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json';
import MaterialIconsGlyphs from 'react-native-vector-icons/glyphmaps/MaterialIcons.json';
import OcticonsGlyphs from 'react-native-vector-icons/glyphmaps/Octicons.json';
import SimpleLineIconsGlyphs from 'react-native-vector-icons/glyphmaps/SimpleLineIcons.json';
import ZocialGlyphs from 'react-native-vector-icons/glyphmaps/Zocial.json';
import IonSet from 'react-native-vector-icons/Ionicons';
import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import IconMaterial from 'react-native-vector-icons/MaterialIcons';
import OctiSet from 'react-native-vector-icons/Octicons';
import {EeSize, StylesArray, SVGTypes} from '../ui';

export const eMojiRegex = new RegExp(
  '(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])',
);

export const uriRegex = new RegExp(
  '[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
);

export const GLYPH_MAPS = {
  AntD: AntDGlyphs,
  Entypo: EntypoGlyphs,
  EvilIcons: EvilIconsGlyphs,
  Feather: FeatherGlyphs,
  FontAwesome: FontAwesomeGlyphs,
  FontAwesome5: FontAwesome5Glyphs,
  FontAwesome5Pro: FontAwesomeProGlyphs,
  Fontisto: FontistoGlyphs,
  Foundation: FoundationGlyphs,
  Ionicons: IoniconsGlyphs,
  MaterialIcons: MaterialIconsGlyphs,
  MaterialCommunityIcons: MaterialCommunityIconsGlyphs,
  Octicons: OcticonsGlyphs,
  SimpleLineIcons: SimpleLineIconsGlyphs,
  Zocial: ZocialGlyphs,
};

export enum IconSource {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
  AntDesign,
  Octicons,
  Fontisto,
  Entypo,
  //FontAwesome5Pro,
}

export function getKeyByValue(
  value: number,
  iconMap = iconSetsMap[IconSource.MaterialCommunityIcons] || {
    Set: {},
    glyph: '',
  },
) {
  const indexOfS = Object.values(iconMap.Set).indexOf(iconMap.glyph);

  return indexOfS === -1 ? undefined : Object.keys(iconMap)[indexOfS];
}

export type EmojiType = typeof eMojiRegex;
export type URLType = typeof uriRegex;

export type CommonIconType =
  | ImageSourcePropType
  | EmojiType
  | URLType
  | string
  | SVGTypes;

export const iconSetsMap: Partial<
  Record<IconSource, {Set: any; glyph: Record<string, number>}>
> = {
  [IconSource.MaterialCommunityIcons]: {
    Set: IconMaterialCommunity,
    glyph: GLYPH_MAPS.MaterialCommunityIcons,
  },
  [IconSource.MaterialIcons]: {
    Set: IconMaterial,
    glyph: GLYPH_MAPS.MaterialIcons,
  },
  /*[IconSource.FontAwesome]: {
      Set: IconFontawesome,
      glyph: GLYPH_MAPS.FontAwesome,
      },*/
  [IconSource.FontAwesome5]: {
    Set: IconFontawesome5,
    glyph: GLYPH_MAPS.FontAwesome5,
  },
  [IconSource.Ionicons]: {Set: IonSet, glyph: GLYPH_MAPS.Ionicons},
  [IconSource.AntDesign]: {Set: AntSet, glyph: GLYPH_MAPS.AntD},
  [IconSource.Octicons]: {Set: OctiSet, glyph: GLYPH_MAPS.Octicons},
  [IconSource.Fontisto]: {Set: FontistoSet, glyph: GLYPH_MAPS.Fontisto},
  [IconSource.Entypo]: {Set: EntypoSet, glyph: GLYPH_MAPS.Entypo},
  //[IconSource.FontAwesome5Pro]: {Set: ProIcons , glyph: GLYPH_MAPS.FontAwesome5Pro},
};

export type FullIconType = CommonIconType | FullIconSet;

export type FullIconSet =
  | keyof typeof GLYPH_MAPS.Ionicons
  | keyof typeof GLYPH_MAPS.AntD
  | keyof typeof GLYPH_MAPS.MaterialCommunityIcons
  | keyof typeof GLYPH_MAPS.MaterialIcons
  //| keyof typeof GLYPH_MAPS.FontAwesome5
  | keyof typeof GLYPH_MAPS.FontAwesome
  | keyof typeof GLYPH_MAPS.Entypo;

export type IconImageProps = ImageStyle & {
  icon: FullIconType;
  isResponsive?: boolean;
  circular?: boolean;
  rounded?: boolean;
  iconSource?: IconSource;
  size?: EeSize;
  color?: string;
  tint?: string;
  wrapStyle?: StylesArray;
  onPress?: () => void;
  onError?: () => void;
};
//& (MaterialType | IonType | FontistoType | EnType | yetAnotherType);
