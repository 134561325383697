import {eColor, TextType} from '../ui';
import C from 'consistencss';
import React, {ReactNode} from 'react';
import {
  StyleProp,
  TouchableOpacity,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import {GIcon, GText} from '../atoms';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

export type EeRowProps = {
  title?: string;
  withDivider?: boolean;
  onPress?: () => void;
  RightComp?: ReactNode;
  LeftComp?: ReactNode;
  icon?: FullIconType;
  style?: StyleProp<ViewStyle>;
} & ViewProps;

/* A generic for a "renderable" object, with props + component in one object */
/** TODO figure out better solution */
export type Renderable<Props> = Props & {
  Component: React.FC<Props>;
};

export function EeRow({
  title,
  RightComp,
  LeftComp,
  icon,
  children,
  style,
  onPress,
  withDivider = true,
  ...rest
}: EeRowProps) {
  const Wrapper: Renderable<any> = onPress ? TouchableOpacity : View;

  return (
    <Wrapper
      onPress={onPress}
      style={[
        C.py4,
        withDivider && eColor(palette.primaryMercuryGrey).borderBottom,
      ]}
    >
      <View style={[C.row, C.itemsCenter, C.justifyBetween, style]} {...rest}>
        {icon && <GIcon icon={icon} />}
        <View style={[C.flex, C.py4]}>
          {LeftComp ||
            (title && (
              <GText
                type={TextType.h3Orange}
                style={children ? C.mb4 : undefined}
              >
                {title}
              </GText>
            ))}
        </View>
        {RightComp}
      </View>
      {children}
    </Wrapper>
  );
}

export default EeRow;
