import React, {useState} from 'react';
import {EeSize, IS_WEB} from '../ui';
import C from 'consistencss';
import {TouchableOpacity, View} from 'react-native';
import {useGenderSelector} from '../filters/PillFilters';
import {GAvatar} from '../molecules/GAvatar';
import useResponsive from '../ui/helpers/useResponsive';
import {
  ButtonSize,
  formTypes,
  GButton,
  GFormInput,
  userFormFields,
} from '../molecules';
import {useSpeciality} from '../types/SpecialityProvider';
import {FormField, useUserForm} from '../store/useGForm';
import {currentUserAtom} from '../store/useUser';
import {useTabs} from '../filters/useTabs';
import {useRecoilState} from 'recoil';
import {useModal} from './useModal';
import {apiCaller, cleanObjDB, TYPES_API} from '../api/api';
import {Api_Routes, palette, RoleKey, roleMap, UserType} from 'oralify-common';
import {modalScreens} from '../routes/useGNav.web';
import ImageUploading from 'react-images-uploading';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {useLangSelector} from '../FormSelector/LanguageSelector';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {BACKEND_BIRTHDAY} from '../types/utils/dates';
import {GIconRow} from '../molecules/GIconRow';

/** Indeed Add User Modals*/
export const AddProfileData = ({
  isSpecialist = false,
  isEdit = false,
  user,
}: {
  isSpecialist?: boolean;
  isEdit?: boolean;
  user?: UserType;
}) => {
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  const [editUser, setEditUser] = useState<UserType>(user || currUser || {});
  const [addUserLoading, setUserLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [birthday, setBirthday] = useState(dayjs('09-12-1992'));
  const {closeModal: closeLoginModal} = useModal(modalScreens.login);
  const {showModal: showServiceModal} = useModal(modalScreens.addService);

  const {randomSpeciality} = useSpeciality();

  /*const [setUsernameLoading] = useState(false);*/

  const [GenderSelectorComp, selectedGender] = useGenderSelector(editUser.sex);
  const {isSmall, isHuge, resp} = useResponsive();
  const {LanguageSelector, currLang} = useLangSelector({
    initialLang: editUser?.languages || [],
  });
  /*const [showSpecialities, setShowSpecialities] = useState(false);*/

  const {TabHeaders, selectedTab} = useTabs<RoleKey>(
    roleMap,
    isSpecialist || editUser.role === RoleKey.Expert
      ? RoleKey.Expert
      : RoleKey.Curious,
  );
  const isCurrSpecialist = selectedTab === RoleKey.Expert;

  /*const {mutate: addUserDB, isLoading: isAddUserLoading} = useMutation(
                        upsertUser,
                        {
                          onSuccess: () => {
                            console.log('hore TOD GENIAL: ', editUser);
                            Toast.show({
                              type: 'success',
                              text1:
                                'Bienvenido ' +
                                editUser.name +
                                '. Hemos añadido esto: ' +
                                JSON.stringify(editUser), //trans('user.add.success'),
                            });
                          },
                        },
                      );*/

  const [formState, updateFormState, isFormValid] = useUserForm(
    editUser,
    userFormFields,
  );

  const renderFormInput = (field: FormField) => {
    const {
      key,
      formType: currFormType,
      prefix,
      isRequired,
      forSpecialist,
      autoCapitalize,
      icon,
      hint,
      placeholder,
    } = field;
    const handleSetText = /*key === 'name'
              ? handleNameChange
              : */ (text: string) =>
      updateFormState(key as keyof UserType, text);
    const extraProps = currFormType ? formTypes[currFormType] : {};
    return (
      (!forSpecialist || isCurrSpecialist) && (
        <GFormInput
          formType={currFormType}
          hint={hint}
          isReq={isRequired}
          disabled={key === 'email' && editUser.email_verified}
          autoCapitalize={key === 'name' ? 'words' : 'sentences'}
          prefix={prefix}
          /*loading={key === 'username' && isUsernameLoading}*/
          /*icon={icon}*/
          value={formState[key] as string}
          /*placeholder={placeholder}*/
          onSetText={handleSetText}
          {...extraProps}
          {...field}
        />
      )
    );
  };
  const isValid =
    isFormValid; /*&& (isCurrSpecialist ? !!foundSpeciality : true)*/
  const addUser = async () => {
    if (isValid) {
      if (editUser) {
        setUserLoading(true);
        console.log('Before: ', editUser, formState);
        const updatedUser = {
          /*...editUser,*/
          ...formState,
          avatar: editUser.avatar,
          birthday: birthday.format(BACKEND_BIRTHDAY),
          languages: currLang?.map(({value}) => value),
          role: isCurrSpecialist ? RoleKey.Expert : RoleKey.Curious,
          sex: selectedGender,
        } as UserType;
        setCurrUser(updatedUser);
        /** Adds local storage*/
        setEditUser(updatedUser);
        /** Adds comp State*/
        /*console.log('After: ', updatedUser, formState, cleanObjDB(updatedUser));*/
        /*addUserDB(cleanObjDB(updatedUser));*/
        if (editUser.id) {
          const {data: currRes} = await apiCaller({
            type: TYPES_API.POST,
            url: Api_Routes.user.setDataUser.replace(':id', editUser?.id),
            params: cleanObjDB(updatedUser),
          });
          /*console.log('hreoe esto devuelve', currRes);*/
          if (currRes) setCurrUser(currRes);
          closeLoginModal();
        }
        setUserLoading(false);
      }
    }
    closeLoginModal();
    showServiceModal();
  };
  /*const {PhoneVerificationCodeInput, phoneNumber} = usePhoneLogin();*/
  const singleColumn = isSmall || !isCurrSpecialist;
  return (
    <View style={[resp(0.8).maxWidth]}>
      <TabHeaders valExtractor={(item: RoleKey) => roleMap[item]} />
      <View
        style={[
          !isSmall && [C.row, C.itemsCenter, C.m4],
          !isCurrSpecialist && C.m4,
          C.justifyCenter,
        ]}
      >
        {IS_WEB && (
          <ImageUploading
            multiple={false}
            value={[]} // inicia con un array vacío o puedes poner las imágenes existentes aquí
            onChange={async imageList => {
              setImgLoading(true);
              const imageFile = imageList[0].file;
              if (imageFile) {
                const storage = getStorage();
                const storageRef = ref(
                  storage,
                  'avatars/' +
                    (editUser.username || editUser.id || 'guilleMaximiliano'),
                );
                const {ref: currRef, metadata} = await uploadBytes(
                  storageRef,
                  imageFile,
                );
                const downloadURL = await getDownloadURL(currRef);
                console.log('URL descargable:', downloadURL);

                setImgLoading(false);
                setEditUser(prevUser => ({
                  ...prevUser,
                  avatar: downloadURL,
                }));
                console.log(
                  'horescence got this image url: ',
                  downloadURL,
                  currRef,
                  metadata,
                );
              }
            }}
            maxNumber={1} // solo permite una imagen
            dataURLKey="dataURL"
          >
            {({onImageUpload, isDragging}) => (
              <TouchableOpacity
                onPress={onImageUpload}
                style={[
                  C.p4,
                  C.justifyCenter,
                  isHuge &&
                    isFormValid &&
                    isCurrSpecialist && [C.left_12, C.ml_14],
                  C.itemsCenter /*, isDragging ? C.bgBlue :
                 C.bgRed*/,
                ]}
              >
                <GAvatar
                  isLoading={imgLoading}
                  /*onPress={onImageUpload}*/
                  size={/*isHuge ? EeSize.imageBig : */ EeSize.avatarBig}
                  color={isDragging ? palette.orangeSec : palette.greyish}
                  onAddImg={onImageUpload}
                  /*onPress={onImageUpload}*/
                  online={!!editUser.is_connected}
                  image={editUser.avatar}
                  isBig
                  gender={selectedGender}
                  //color={currSpeciality?.color || palette.purpleSec}
                />
              </TouchableOpacity>
            )}
          </ImageUploading>
        )}
        {/*</CustomDropzone>*/}
        <View style={[C.ml4, !singleColumn && [C.row, C.itemsCenter]]}>
          {singleColumn ? (
            userFormFields.map(renderFormInput)
          ) : (
            <>
              <View>
                {userFormFields
                  .slice(0, userFormFields.length / 2)
                  .map(renderFormInput)}
              </View>
              <View>
                {userFormFields
                  .slice(userFormFields.length / 2)
                  .map(renderFormInput)}
              </View>
            </>
          )}
        </View>
        {/*{!editUser.phone_verified && <PhoneVerificationCodeInput />}*/}
      </View>
      {isFormValid && (
        <View style={[C.m4, C.row, C.justifyBetween]}>
          <View
            style={[isCurrSpecialist ? [C.top_2, C.mr4] : C.row, C.itemsCenter]}
          >
            <GenderSelectorComp />

            {/** Birthday*/}
            {/*<FormWrapper placeholder="Fecha de nacimiento" extraStyles={C.mb4}>*/}
            <DatePicker
              label="Fecha de nacimiento"
              value={dayjs(birthday)}
              maxDate={dayjs().subtract(18, 'years')}
              minDate={dayjs().subtract(99, 'years')}
              onChange={newValue => setBirthday(dayjs(newValue))}
            />
            {/* </FormWrapper>*/}
          </View>
          {/*<GText
              type={TextType.h3Orange8
            >{`¿En qué idiomas puedes atender?`}</GText>
          </View>*/}
          {isCurrSpecialist && LanguageSelector}
          {/*{JSON.stringify(currLang)}*/}
        </View>
      )}

      {/*<GText>{JSON.stringify(editUser)}</GText>
      <GText>{JSON.stringify(currUser)}</GText>*/}
      {currUser?.email_verified && (
        <GIconRow
          text={`Cuenta confirmada a través de ${
            currUser?.provider === 'google.com' ? 'Google' : 'Email'
          }`}
          icon={currUser?.provider === 'google.com' ? 'google' : 'email'}
        />
      )}
      {currUser?.phone_verified && (
        <GIconRow
          text="Cuenta confirmada con teléfono"
          icon={currUser?.provider === 'phone' ? 'phone' : 'verified'}
        />
      )}
      {/** Add user*/}
      <GButton
        onPress={addUser}
        disabled={!isValid}
        loading={addUserLoading}
        icon={randomSpeciality?.emoji || '🚀'}
        size={ButtonSize.large}
        text={`${isEdit ? 'Editar' : 'Añadir'} ${
          formState?.name ? 'a ' + formState?.name : ''
        } ${randomSpeciality?.name ? ' en ' + randomSpeciality?.name : ''}`}
      />
    </View>
  );
};
