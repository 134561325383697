import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';

import en from '../../../public/assets/translations/en/translation.json';
import {currentLocale} from './countryMap';

export const trans = (text: string /*, lang?: LocaleCode*/) => text;

// TODO: Add language detection
i18n.use(initReactI18next).init({
  resources: {
    en: {translation: en},
  },
  lng: currentLocale,
  fallbackLng: currentLocale,
  debug: false,
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
});

export {i18n};
