import React from 'react';
import C from 'consistencss';
import {View} from 'react-native';
import {useRecoilState} from 'recoil';
import {currentUserAtom} from '../store/useUser';
import UseResponsive from '../ui/helpers/useResponsive';
import LoginModalContent from './LoginModalContent';
import {AddProfileData} from './AddProfileData';

export const AddUserWrapper = ({isModal = true}) => {
  const {isSmall} = UseResponsive();
  const showHoriz = isModal || isSmall;
  const [user] = useRecoilState(currentUserAtom);
  return (
    <View style={[C.my4, C.flex, !showHoriz && C.row]}>
      {!user?.id ? <LoginModalContent /> : <AddProfileData />}
    </View>
  );
};
