import React, {ReactNode, useState} from 'react';

import {EeCollapseRow} from '../ee-collapse-row';
import {SelectFieldModal} from './select-field-modal';
import {FullIconType} from '../../types/icon-types';
import GDateRangePicker from './gDateRangePicker';

export type SelectDatesProp = {
  hasDateRange?: boolean;
};
export type SelectOptionsProps<Item> = {
  options: Item[];
  renderOptionPrefix?: (item: Item) => ReactNode;
  selectionExtractor?: (item: Item) => string;
  valueExtractor: (item: Item | string) => string;
  iconExtractor?: (item: Item) => string;
  keyExtractor?: (item: Item | string) => string;
  labelExtractor?: (item: Item | string) => string;
};

export type SelectFieldProps = {
  isCalendar?: boolean;
  shouldConfirm?: boolean;
  label?: string;
  modalSubtitle?: string;
  modalTitle?: string;
  onChange?: (value: string) => void;
  onGoBack?: () => void;
  value?: string;
  icon?: FullIconType;
};

export type GlobalSelector<Item> = SelectFieldProps &
  (SelectDatesProp & SelectOptionsProps<Item>);

export function GPicker<Item>({
  keyExtractor,
  label,
  labelExtractor = key => key as string,
  hasDateRange = true,
  modalSubtitle,
  modalTitle = label,
  onChange,
  onGoBack,
  options,
  renderOptionPrefix,
  icon,
  selectionExtractor,
  value,
  valueExtractor = key => key as string,
  iconExtractor,
  shouldConfirm = false,
  ...rest
}: GlobalSelector<Item>) {
  const [isVisible, toggle] = useState(false);
  const [currValue, setValue] = useState(value);

  const toggleOn = () => toggle(true);
  const toggleOff = () => toggle(false);

  const selectedItem = options?.find(
    item => valueExtractor(item) === currValue,
  );
  const handleGoingBack = () => {
    toggleOff();
    onGoBack && onGoBack();
  };
  const handleOnSet = (val: React.SetStateAction<string | undefined>) => {
    setValue(val);
    onChange && val && onChange(val as string);
  };

  const currentValue = options
    ? selectedItem
      ? (selectionExtractor || labelExtractor)(selectedItem)
      : undefined
    : value;

  return (
    <>
      <EeCollapseRow
        icon={icon}
        label={label}
        onPress={toggleOn}
        value={currentValue || ''}
        {...rest}
      />
      {options ? (
        <SelectFieldModal<Item>
          keyExtractor={keyExtractor}
          labelExtractor={labelExtractor}
          onChange={handleOnSet}
          onGoBack={handleGoingBack}
          options={options}
          renderOptionPrefix={renderOptionPrefix}
          subtitle={modalSubtitle}
          title={modalTitle}
          value={value}
          shouldConfirm={shouldConfirm}
          valueExtractor={valueExtractor}
          iconExtractor={iconExtractor}
          isVisible={isVisible}
        />
      ) : (
        <GDateRangePicker
          onSet={handleOnSet}
          onGoBack={handleGoingBack}
          title={modalTitle}
          startValue={value}
          icon={icon}
          hasDateRange={hasDateRange}
          isVisible={isVisible}
        />
      )}
    </>
  );
}
