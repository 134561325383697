import C, {boxShadow} from 'consistencss';
import {
  Dimensions,
  Platform,
  RecursiveArray,
  StyleProp,
  StyleSheet,
} from 'react-native';
import {Theme} from 'react-native-calendars/src/types';
//import {isTablet} from 'react-native-device-info';
import {fontsMap} from './typography';
import {eColor} from './gPalette';
import {Styles} from 'react-native-svg/lib/typescript/xml';
import {palette} from 'oralify-common';

export const BASE_PIXEL = 4;

export type StylesArray =
  | StyleProp<any>
  | RecursiveArray<Styles>
  | StyleProp<Styles>
  | Array<StyleProp<Styles> | string>;

/** Default padding used throughout the app. */
export const eDisplay = StyleSheet.create({
  onTop: {zIndex: BASE_PIXEL * 25},
  underneath: {zIndex: -BASE_PIXEL * 25},
});
export const IPHONE_X_HEIGHT = 812;
export const IPHONE_11_WIDTH = 390;
/** Space between input fields. */
export const deviceHeight = Dimensions.get('window').height;
export const deviceWidth = Dimensions.get('window').width;

export const DESKTOP_HUGE = 1440;
export const DESKTOP = 1024;
export const TABLET = 760;
export const MOBILE = 380;

/** Section divider height. */
export const IS_IOS = Platform.OS === 'ios';
export const IS_WEB = Platform.OS === 'web';
export const IS_NARROW_DEVICE = deviceWidth < IPHONE_11_WIDTH;
export const HAS_NOTCH = IS_IOS && deviceHeight >= IPHONE_X_HEIGHT; //Todo improve using deviceInfo.hasNotch()
export const HALF_BOTTOM_SHEET = BASE_PIXEL * 86; //344;
export const ROW_HEIGHT = BASE_PIXEL * 14; // 56px
export const rowHeight = C.h11; // 56px
export const buttonWidth = C.w60; // 56px

// Custom configuration
interface breakPoint {
  screenWidth: number;
  columns: number;
}

/**Shadows*/
export const vShadow = {
  highlightSel: boxShadow(12, 0, 10, 24, palette.bluePrim, 0.12),
  highlight: boxShadow(12, 0, 10, 24, palette.whiteThree, 0.2),
  regular: boxShadow(12, 0, 10, 10, palette.whiteThree, 0.2),
};

export const onTop = (over = true) => ({
  zIndex: over ? BASE_PIXEL * 5 : -BASE_PIXEL * 5,
});

/**Most important container*/

export const breakPoints: Record<string, breakPoint> = {
  xs: {
    screenWidth: 0,
    columns: 4,
  },
  sm: {
    screenWidth: 411,
    columns: 4,
  },
  md: {
    screenWidth: 568,
    columns: 6,
  },
  lg: {
    screenWidth: TABLET,
    columns: 8,
  },
  xl: {
    screenWidth: DESKTOP,
    columns: 10,
  },
  xxl: {
    screenWidth: DESKTOP,
    columns: 12,
  },
};

// Parsing layout for consistent CSS
export const layout: Record<keyof typeof breakPoints, number> = Object.keys(
  breakPoints,
).reduce((prevLayout, breakPoint) => {
  return {...prevLayout, [breakPoint]: breakPoints[breakPoint].screenWidth};
}, {});

/** Shadows */
export const eShadow = StyleSheet.create({
  default: IS_IOS
    ? {
        shadowColor: palette.shadow,
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: BASE_PIXEL,
      } //todo Ideally dropShadow => bottom & Right. Blur = 4
    : {
        elevation: BASE_PIXEL,
      },
});

/** Just as idea to be defined with design */
export const ePad = {
  regular: C.mx2,
};

/** Images sizes */
export enum EeSize {
  xsm,
  sm,
  md,
  lg,
  icon,
  iconWrapper,
  avatar,
  emoji,
  sideCard,
  avatarBig,
  image,
  imageBig,
  mockup,
}

/** Images sizes */
export const eSizes = {
  [EeSize.xsm]: [C.h2, C.w2], //8x8
  [EeSize.sm]: [C.h4, C.w4], // regular icon  => 12x12
  [EeSize.md]: [C.h5, C.w5], // regular icon  => 16x16
  [EeSize.lg]: [C.h7, C.w7], // regular icon  => 16x16
  [EeSize.icon]: [C.h9, C.w9], //avatar  => 36x36
  [EeSize.iconWrapper]: [C.h12, C.w12], //avatar  => 48x48
  [EeSize.emoji]: [C.h15, C.w15], // 28x28
  [EeSize.avatar]: [C.h20, C.w20], //=>40x40
  [EeSize.sideCard]: [C.h25, C.w25], //=> 100 x 100
  [EeSize.avatarBig]: [C.h35, C.w35], //=>140x140
  [EeSize.image]: [C.h45, C.w45], //=>180x180
  [EeSize.imageBig]: [C.w80, C.h80], //=>180x180
  [EeSize.mockup]: [C.w160, C.h120], //=>180x180
};

export const eDivider = [
  eColor(palette.primaryMercuryGrey).borderBottom,
  C.wFull,
  C.mb6,
];
export const eDividerVert = [
  C.wHairline,
  eColor(palette.greyish + '80').bg,
  C.my4,
  C.radius2,
  C.shadowMd,
  C.mx2,
];

export const vSizes = {
  dot: [C.w2, C.h2],
  bubble: [C.w3, C.h3],
  box: [C.w5, C.h5],
  icon: [C.w6, C.h6],
  emoji: [C.w7, C.h7],
  sm: [C.w8, C.h8],
  input: [C.w10, C.h10],
  card: [C.w80, C.h80],
  cardBig: [C.w92, C.h66],
  cardHuge: [C.w120, C.h120],
  cardHero: [C.w100, C.h130],
  mockup: [C.w100, C.h180],
  med: [C.w12, C.h12],
  lg: [C.w15, C.h15],
  xl: [C.w24, C.h24],
  emojiSm: C.font5,
  emojiLg: C.font7,
  logo: /*isWide ? [C.w37, C.h37] :*/ [C.w20, C.h20],
};

/** Flex */
export const eLayout = StyleSheet.create({
  grow1: {flexGrow: 1},
  reverse: {flexDirection: 'row-reverse'},
  //@ts-ignore
  sticky: {position: 'sticky'},
  gap4: {flexGrow: BASE_PIXEL * 4},
  gap2: {flexGrow: BASE_PIXEL * 2},
  gap: {flexGrow: BASE_PIXEL},
  grow6: {flexGrow: 6},
  shrink: {flexShrink: 1},
  minWidth43: {minWidth: '43%'},
  maxW65: {maxWidth: '65%'},
  maxW85: {maxWidth: '85%'},
});

export const calendarTheme: Theme = {
  backgroundColor: palette.white,
  calendarBackground: palette.white,
  textSectionTitleColor: palette.orangeLight,
  textSectionTitleDisabledColor: palette.greyDark,
  selectedDayBackgroundColor: palette.orangeSec,
  selectedDayTextColor: palette.white,
  todayTextColor: palette.orangeSec,
  dayTextColor: palette.greyDark,
  textDisabledColor: palette.primaryMercuryGrey,
  dotColor: palette.redSec,
  selectedDotColor: palette.white,
  arrowColor: palette.orangeSec,
  disabledArrowColor: palette.primaryMercuryGrey,
  monthTextColor: palette.orangeSec,
  indicatorColor: palette.orangeSec,
  textDayFontFamily: fontsMap.body,
  textMonthFontFamily: fontsMap.title,
  textDayHeaderFontFamily: fontsMap.body,
  textMonthFontWeight: 'bold',
};
