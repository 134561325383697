import React, {useState} from 'react';
import {GPill} from '../molecules/gPill';
import {
  palette,
  ServiceFormatKey,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import C from 'consistencss';
import {TouchableOpacity, View} from 'react-native';
import {eColor} from '../ui';
import {GIcon, GText} from '../atoms';
import useResponsive from '../ui/helpers/useResponsive';

type FilterContainerProps = {
  users: UserType[] | undefined;
  category?: string;
  specialityKey?: SpecialityKey;
  formatKey?: ServiceFormatKey;
};

type FilterContainerReturnType = {
  FilterComponents: JSX.Element;
  matchingUsers?: UserType[];
  showConnected?: boolean;
  showTopRated?: boolean;
  showAvailableSoon?: boolean;
  showReal?: boolean;
  onReset?: () => void;
};
export const useFilterContainer = (/*{
  category,
  specialityKey,
  users,
  formatKey,
}: FilterContainerProps*/): FilterContainerReturnType => {
  const [showConnected, setShowConnected] = useState(false);
  const [showTopRated, setTopRated] = useState(false);
  const [showAvailableSoon, setShowAvailable] = useState(false);
  const [showReal, setShowReal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  /*const connectedUsers = users?.filter(
      ({is_connected = false}: UserType) => !!is_connected,
    );
    const matchingConnectedUsers = showConnected ? connectedUsers : users;
    const matchingRealUsers = /!*showReal
      ?*!/ matchingConnectedUsers;
    /!*: _.concat(matchingConnectedUsers, mockUsers);*!/
    const topRatedUsers = matchingRealUsers
      ?.filter(({reviews}: UserType) => reviews && reviews >= 4.5)
      .sort((a, b) => (b.reviews && a.reviews ? b.reviews - a.reviews : 0));
    const matchingTopRatedUsers = showTopRated
      ? topRatedUsers
      : matchingConnectedUsers;
  
    const availableSoon = (matchingTopRatedUsers || [])
      .filter(({timeslots}) => timeslots?.some(isAvailableSoon))
      .sort((a, b) =>
        dayjs(timeStampToDate(a.timeslots?.find(isAvailableSoon))).diff(
          timeStampToDate(b.timeslots?.find(isAvailableSoon)),
        ),
      );
    const sortedAvailableSoonUsers = showAvailableSoon
      ? availableSoon
      : matchingTopRatedUsers || [];*/

  const onReset = () => {
    /* resetSpec && resetSpec();
        resetService && resetService();
        resetListeners && resetListeners();*/
    setShowAvailable(false);
    setTopRated(false);
    setShowReal(false);
    setShowConnected(false);
  };
  const wrapStyle = [C.radius2, C.mt2, C.p4];
  const {isSmall, resp, isHuge} = useResponsive();
  const FilterComponents = (
    /*<ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[C.row, C.selfCenter, C.mb4, C.justifyStart]}
        >*/
    <TouchableOpacity
      onPress={() => setShowFilters(!showFilters)}
      style={[
        isHuge ? C.row : C.p2,
        !showFilters && C.py3,
        C.itemsCenter,
        eColor(palette.whiteFour).border,
        C.radius2,
      ]}
    >
      <View style={[C.row]}>
        <GIcon
          icon={!showFilters ? 'filter-list' : 'filter-off'}
          wrapStyle={C.px2}
          //onPress={() => setShowFilters(!showFilters)}
          color={/*showFilters ? palette.orangeSec :*/ palette.greyish}
        />
        {!isSmall && (
          <GText style={[C.pr2]} color={palette.greyish}>
            {'Más filtros'}
          </GText>
        )}
      </View>
      {showFilters && (
        <View style={[isHuge ? C.row : C.flex]}>
          <GPill
            icon={'moon-full'}
            text={'Online' + (showConnected ? ' ahora' : '')}
            color={palette.greenPrim}
            selected={showConnected}
            wrapStyle={wrapStyle}
            long
            onPress={() => setShowConnected(wasSelected => !wasSelected)}
            /*quantity={formatNumber(connectedUsers?.length)}*/
          />
          <GPill
            icon={showReal ? 'user' : 'user-secret'}
            text={'Reales'}
            color={palette.orangeSec}
            selected={showReal}
            long
            wrapStyle={wrapStyle}
            onPress={() => setShowReal(prev => !prev)}
            /* quantity={formatNumber(matchingRealUsers?.length)}*/
          />
          <GPill
            icon={'star-rate'}
            text={'Top Rated'}
            color={palette.yellowSec}
            long
            wrapStyle={wrapStyle}
            selected={showTopRated}
            onPress={() => setTopRated(prev => !prev)}
            /*quantity={formatNumber(topRatedUsers?.length)}*/
          />
          <GPill
            icon={'calendar-today'}
            text={'Disp. Próx. 3 días'}
            color={palette.softPink}
            long
            wrapStyle={wrapStyle}
            selected={showAvailableSoon}
            onPress={() => setShowAvailable(prev => !prev)}
            /*quantity={formatNumber(availableSoon?.length)}*/
          />
          {/*<FlatList data={lang} />*/}
          {/*<SpecialityFilter />
      <ServiceFilter />*/}
          {/* <ListenerLevelFilter />*/}
        </View>
      )}
    </TouchableOpacity>
  );
  return {
    FilterComponents,
    /*sortedAvailableSoonUsers,*/
    showConnected,
    showAvailableSoon,
    showReal,
    showTopRated,
    onReset,
  };
};
