import {atom} from 'recoil';
import {ServiceType} from 'oralify-common';
import {persistAtom} from '../store/useUser';
import {FullIconType} from './icon-types';
import {ServiceFormatSelector} from '../FormSelector/ServiceFormatSelector';
import {ServiceDurationSelector} from '../FormSelector/ServiceDurationSelector';
import {ServiceDetailsSelector} from '../FormSelector/ServiceDetailsSelector';
import {AvailabilitySelector} from '../FormSelector/AvailabilitySelector';
import {AddProfileData} from '../modals/AddProfileData';
import {PublicProfile} from '../screens/PublicProfile';
import {ServiceList} from '../screens/ServiceList';
import {WeekCalendarTimeSlots} from '../FormSelector/CalendarAvailabilitySelector';
import HistoryList from '../components/HistoryList';

export const newServiceAtom = atom<ServiceType | undefined>({
  key: 'newService',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

// Definiendo un Enum para los Profile screens
export enum SETTINGS_STEPS {
  PROFILE = 'PROFILE',
  SERVICES = 'SERVICES',
  AVAILABILITY = 'AVAILABILITY',
  VISIBILITY = 'VISIBILITY',
  HISTORY = 'HISTORY',
  PAYMENT = 'PAYMENT',
  LOGOUT = 'LOGOUT',
}

export const settingsSteps: Record<
  SETTINGS_STEPS,
  {
    key: SETTINGS_STEPS; // Ajustado para que el key sea del tipo del enum
    icon?: FullIconType;
    isExpert?: boolean;
    title?: string;
    subtitle?: string;
    Component?: (params?: any) => JSX.Element;
  }
> = {
  [SETTINGS_STEPS.PROFILE]: {
    key: SETTINGS_STEPS.PROFILE,
    Component: AddProfileData,
    icon: 'account-star',
    title: 'Perfil',
    subtitle: 'Selecciona tu campo de especialización',
  },
  [SETTINGS_STEPS.SERVICES]: {
    key: SETTINGS_STEPS.SERVICES,
    Component: ServiceList,
    isExpert: true,
    icon: 'color-wand',
    title: 'Servicios',
    subtitle: '¿Cómo ofreces tus servicios?',
  },
  [SETTINGS_STEPS.VISIBILITY]: {
    key: SETTINGS_STEPS.VISIBILITY,
    Component: PublicProfile,
    isExpert: true,
    icon: 'address-card',
    title: 'Perfil público',
    subtitle: 'Escoge duración, luego podrás ajustar el precio',
  },
  [SETTINGS_STEPS.AVAILABILITY]: {
    key: SETTINGS_STEPS.AVAILABILITY,
    Component: WeekCalendarTimeSlots, //AvailabilitySelector,
    isExpert: true,
    icon: 'calendar-range',
    title: 'Disponibilidad',
    subtitle: 'Escoge duración, luego podrás ajustar el precio',
  },

  [SETTINGS_STEPS.HISTORY]: {
    key: SETTINGS_STEPS.HISTORY,
    Component: HistoryList,
    icon: 'history',
    title: 'Historial visitas',
    subtitle: 'Añade información adicional sobre tus servicios',
  },
  [SETTINGS_STEPS.PAYMENT]: {
    key: SETTINGS_STEPS.PAYMENT,
    Component: PublicProfile, //AvailabilitySelector,
    icon: 'bank',
    title: 'Pagos',
    subtitle: 'Cada cuanto puedes ofrecer tus servicios?',
  },
  [SETTINGS_STEPS.LOGOUT]: {
    key: SETTINGS_STEPS.LOGOUT,
    /*Component: AvailabilitySelector,*/
    icon: 'logout',
    title: 'Cerrar Sesión',
    subtitle: 'Cierra totalmente la sesión en todos tus dispositivos',
  },
};

// Definiendo un Enum para los Pasos de Servicio
export enum SERVICE_STEPS {
  SPECIALITY = 'speciality',
  SERVICE_FORMAT = 'serviceFormat',
  SERVICE_DURATION = 'serviceDuration',
  SERVICE_DETAILS = 'serviceDetails',
  SERVICE_AVAILABILITY = 'serviceAvailability',
}

// Usando el Enum Definido
export const serviceSteps: Record<
  SERVICE_STEPS,
  {
    key: SERVICE_STEPS; // Ajustado para que el key sea del tipo del enum
    icon?: FullIconType;
    title?: string;
    subtitle?: string;
    Component?: (params?: any) => JSX.Element;
  }
> = {
  [SERVICE_STEPS.SPECIALITY]: {
    key: SERVICE_STEPS.SPECIALITY,
    // Component: AddSpeciality,
    icon: 'account-star',
    title: 'Especialidad',
    subtitle: 'Selecciona tu campo de especialización',
  },
  [SERVICE_STEPS.SERVICE_FORMAT]: {
    key: SERVICE_STEPS.SERVICE_FORMAT,
    Component: ServiceFormatSelector,
    icon: 'video-call',
    title: 'Formato',
    subtitle: '¿Cómo ofreces tus servicios?',
  },
  [SERVICE_STEPS.SERVICE_DURATION]: {
    key: SERVICE_STEPS.SERVICE_DURATION,
    Component: ServiceDurationSelector,
    icon: 'clock',
    title: 'Duración',
    subtitle: 'Escoge duración, luego podrás ajustar el precio',
  },
  [SERVICE_STEPS.SERVICE_DETAILS]: {
    key: SERVICE_STEPS.SERVICE_DETAILS,
    Component: ServiceDetailsSelector,
    icon: 'euro',
    title: 'Precio',
    subtitle: 'Añade información adicional sobre tus servicios',
  },
  [SERVICE_STEPS.SERVICE_AVAILABILITY]: {
    key: SERVICE_STEPS.SERVICE_AVAILABILITY,
    Component: AvailabilitySelector,
    icon: 'calendar-range',
    title: 'Disponibilidad',
    subtitle: 'Cada cuanto puedes ofrecer tus servicios?',
  },
};

export const currentStepAtom = atom<SERVICE_STEPS>({
  key: 'currentStep',
  default: SERVICE_STEPS.SPECIALITY,
  effects_UNSTABLE: [persistAtom],
});
