import {forwardRef, useState} from 'react';
import {
  Keyboard,
  KeyboardTypeOptions,
  TextInput,
  TextInputProps,
  TextStyle,
} from 'react-native';
import {Units} from '../types/units';
import {numberToUnits} from '../types/utils/localize';
import {palette} from 'oralify-common';

export interface InputProps extends TextInputProps {
  initialValue?: string | number | undefined;
  active?: boolean;
  keyboardType?: KeyboardTypeOptions;
  unit?: Units;
  onChangeText: (value: string) => void;
  isValid?: boolean;
  size?: 'large' | 'medium' | 'small';
  background?: string;
}

export type SizeType = {
  large: TextStyle;
  medium: TextStyle;
  small: TextStyle;
};

/**@deprecated*/
export const EeTextInput = forwardRef<TextInput, InputProps>(
  (
    {
      onChangeText,
      placeholder,
      initialValue,
      unit,
      style,
      keyboardType /* = "number-pad" */,
      isValid = true,
      size,
      background,
      ...rest
    },
    ref,
  ) => {
    const [isSelected, setIsSelected] = useState(false);
    const currentValue =
      typeof initialValue === 'number'
        ? initialValue?.toString()
        : initialValue || '';
    const valueWithUnits = numberToUnits(currentValue, unit);
    /* useEffect(() => {
      const input = (ref as MutableRefObject<TextInput>).current;

      if (active) input.focus();
      else input.blur();

      return input.blur;
    }, [active, ref]); */
    const onSubmit = () => {
      setIsSelected(false);
      Keyboard.dismiss();
    };

    return (
      <TextInput
        ref={ref}
        value={isSelected ? currentValue : valueWithUnits}
        // placeholder={valueWithUnits}
        autoComplete="off"
        /* maxLength={12} */
        onChangeText={onChangeText}
        keyboardType={keyboardType}
        autoCorrect={false}
        blurOnSubmit={false}
        disableFullscreenUI
        /*enablesReturnKeyAutomatically*/
        placeholderTextColor={palette.greyDark}
        onEndEditing={onSubmit}
        onFocus={() => setIsSelected(true)}
        onSubmitEditing={onSubmit}
        placeholder={placeholder}
        onBlur={onSubmit}
        style={style}
        returnKeyType="done" // Todo add translations!
        {...rest}
      />
    );
  },
);
