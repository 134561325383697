import {Slider as OldSlider} from '@miblanchard/react-native-slider';
import C from 'consistencss';
import React, {useState} from 'react';
import {View} from 'react-native';
import {Alignment, eColor, EeSize, orangeGrad, TextType} from '../ui';
import {centerRow} from './gInfoRow';
import {GIcon, GText} from '../atoms';
import {GLinearGradient} from '../components/WebCompat';
import chroma from 'chroma-js';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';

interface SliderProps {
  value?: number | number[];
  disabled?: boolean;
  min?: number;
  max?: number;
  formatFunc?: (val: number) => string;
  step?: number;
  color?: string;
  colors?: string[];
  icon?: FullIconType;
  onSet?: (val: number) => void;
  onSetMinRange?: (val: number) => void;
  style?: any;
}

function valuetext(value: number) {
  return `${value}°C`;
}

const Input = styled(MuiInput)`
  width: 42px;
`;

export function PriceInputSlider() {
  const [value, setValue] = React.useState(30);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <Box sx={{width: 250}}>
      <Typography id="input-slider" gutterBottom>
        Volume
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const minDistance = 1;

export function MinimumDistanceSlider() {
  const [value1, setValue1] = React.useState<number[]>([20, 37]);

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const [value2, setValue2] = React.useState<number[]>([20, 37]);

  const handleChange2 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue as number[]);
    }
  };

  return (
    <Box sx={{width: 300}}>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={value1}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
      />
      <Slider
        getAriaLabel={() => 'Minimum distance shift'}
        value={value2}
        onChange={handleChange2}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
      />
    </Box>
  );
}

export function GradSlider({
  value = 6,
  disabled,
  min = 1,
  formatFunc,
  step = 1,
  color = palette.orangeSec,
  colors = orangeGrad, //emotions.map(({color}) => color),
  max = 10,
  icon = 'euro',
  onSet,
  onSetMinRange,
  style,
}: SliderProps) {
  const [val, setVal] = useState<number | number[]>(value);

  let markInterval = Math.round((max - min) / 4);
  markInterval = Math.ceil(markInterval / step) * step;
  const marks = Array.from({length: 5}, (_, i) =>
    Math.max(min + markInterval * i - 1, 1),
  );

  if (!marks.includes(1)) {
    marks.unshift(1);
  }

  return (
    <View style={[centerRow, style]}>
      <GLinearGradient
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        colors={onSetMinRange ? [color] : colors}
        style={[C.p1, C.flex, C.h1, !onSetMinRange && C.m4, C.radius4]}
      >
        <OldSlider
          // @ts-ignore
          containerStyle={[C.flex, onSetMinRange ? C.h2 : C.p4, C.radius5]}
          renderAboveThumbComponent={() =>
            /**/
            onSetMinRange && (
              <GIcon
                wrapStyle={[C.bgWhite, C.radius5]}
                icon={'chevron-right'}
              />
            )
          }
          rendeBelowThumbComponent={() =>
            onSetMinRange && (
              <GIcon
                wrapStyle={[C.top_2, C.left_1]}
                icon={'account-arrow-right'}
              />
            )
          }
          step={step}
          minimumValue={min}
          trackClickable
          trackMarks={marks}
          renderTrackMarkComponent={index => {
            const colorScale = chroma
              .scale(colors)
              .domain([1, marks.length - 1]);
            const currColor = colors ? colorScale(index).hex() : color;
            return (
              <GText
                style={[
                  C.top2,
                  onSetMinRange ? C.left_6 : C.left_2,
                  C.absolute,
                ]}
                type={TextType.label}
                color={currColor}
              >
                {formatFunc ? formatFunc(marks[index]) : marks[index]}
              </GText>
            );
          }}
          minimumTrackTintColor={onSetMinRange ? color : 'transparent'}
          maximumTrackTintColor={
            onSetMinRange ? palette.orangeLight : 'transparent'
          }
          maximumValue={max}
          // @ts-ignore
          trackStyle={[C.h6, C.radius5]}
          renderThumbComponent={() =>
            !onSetMinRange && (
              <View style={[C.top_2, C.p1, C.ml_1]}>
                <View
                  style={[
                    C.p1,
                    C.bgWhite,
                    C.radius8,
                    eColor(palette.orangeSec).border,
                  ]}
                >
                  <GIcon
                    color={palette.orangeSec}
                    size={EeSize.md}
                    icon={icon}
                  />
                </View>
              </View>
            )
          }
          disabled={disabled}
          value={val}
          /*onSlidingComplete={() => onRelease && onRelease()}*/
          onValueChange={currValue => {
            if (typeof currValue === 'number') {
              // Ajusta el valor al paso más cercano
              let newValue = currValue;
              if (currValue !== 1) {
                newValue = Math.round(currValue / step) * step;
                if (newValue < min) {
                  newValue = min;
                } else if (newValue > max) {
                  newValue = max;
                }
              }
              setVal(newValue);
              onSet && onSet(newValue);
            } else {
              setVal(currValue);
              onSetMinRange && onSetMinRange(currValue[0]);
              onSet && onSet(currValue[1]);
            }
          }}
        />
      </GLinearGradient>
      {/*<GText color={palette.greyish} style={[C.absolute, C.top2, C.right2]}>
        {max}
      </GText>*/}

      {/*<GText color={palette.orangeSec} type={TextType.tabSelected} style={[C.ml2, C.w12]}>
        {val?.toFixed(0)} €
      </GText>*/}
    </View>
  );
}

function GSlider({
  value = 6,
  disabled,
  min = 1,
  formatFunc,
  color = palette.orangeSec,
  colors,
  style,
  step = 1,
  max = 10,
  icon = 'euro',
  onSet,
  onSetMinRange,
}: SliderProps) {
  const [val, setVal] = useState<number | number[]>(value);

  let markInterval = Math.round((max - min) / 4);
  markInterval = Math.ceil(markInterval / step) * step;
  const marks = Array.from({length: 5}, (_, i) =>
    Math.max(min + markInterval * i - 1, 1),
  );

  if (!marks.includes(1)) {
    marks.unshift(1);
  }

  return (
    <View style={[C.flex, C.m2, C.py6, C.itemsStretch, C.justifyCenter, style]}>
      <OldSlider
        // @ts-ignore
        containerStyle={[C.w70, C.maxh2]}
        thumbTouchSize={{height: 60, width: 60}}
        renderAboveThumbComponent={(a: number) => {
          const currVal = Array.isArray(val) ? val[a] : val;
          return (
            <View style={[C.ml_4, C.top_3]}>
              <GText
                type={TextType.label}
                color={palette.white}
                style={[eColor(color).bg, C.p1, C.radius8, C.mt2]}
              >
                {formatFunc ? formatFunc(currVal) : currVal}
              </GText>
              <GIcon
                wrapStyle={[C.bottom_3, C.absolute, C.selfCenter]}
                icon={'triangle-down'}
                color={color}
              />
            </View>
          );
        }}
        step={step}
        minimumValue={min}
        trackMarks={marks}
        renderTrackMarkComponent={index => {
          const colorScale = chroma.scale(colors).domain([1, marks.length - 1]);
          const currColor = colors ? colorScale(index).hex() : color;
          return (
            <GText
              style={[C.top4, C.left_1, C.selfCenter, C.absolute]}
              align={Alignment.Center}
              type={TextType.label}
              color={currColor}
            >
              {formatFunc ? formatFunc(marks[index]) : marks[index]}
            </GText>
          );
        }}
        trackClickable
        minimumTrackTintColor={color}
        maximumTrackTintColor={color + '20'}
        maximumValue={max}
        // @ts-ignore
        trackStyle={[C.h2, C.radius5]}
        renderThumbComponent={() => (
          <GIcon
            wrapStyle={[eColor(color).bg, C.p1, C.radius8]}
            color={palette.white}
            size={EeSize.md}
            icon={icon}
          />
        )}
        disabled={disabled}
        value={val}
        onValueChange={currValue => {
          if (Array.isArray(currValue)) {
            const [start, end] = currValue;
            if (!end) {
              /*let newValue = start;
              if (start !== 1) {
                newValue = start < min ? min : newValue > max ? max : Math.round(start / step) * step;
              }*/
              setVal(start);
              onSet && onSet(start);
            } else {
              setVal(currValue);
              onSetMinRange && onSetMinRange(start);
              onSet && onSet(end);
            }
          }
        }}
      />
    </View>
  );
}

export default GSlider;
