import C from 'consistencss';
import React, {useEffect, useState} from 'react';
import {Pressable} from 'react-native';
import {eColor, orangeGrad, StylesArray, TextType, vSizes} from '../ui';
import {GIcon, GText} from '../atoms';
import LinearGradient from 'react-native-linear-gradient';
import {FullIconType} from '../types/icon-types';
import {palette} from 'oralify-common';

export const dotWrap = [
  C.radius15,
  eColor(palette.orangeSec).border,
  vSizes.lg,
  C.itemsCenter,
  C.justifyCenter,
  C.m4,
  {borderStyle: 'dashed'},
];

export const ToggleIcon = ({
  selected = false,
  icon = 'check',
  color = palette.purpleSec,
  ...rest
}) => {
  const currColor = selected ? color : palette.greyish;
  return (
    <GIcon
      color={currColor}
      {...rest}
      icon={icon}
      wrapStyle={[eColor(currColor).border, C.p2, C.radius1, C.mx2]}
    />
  );
};
export const CheckboxGrad = ({
  onPress,
  checked = true,
  icon = 'check',
  circ,
  withBorder,
  big = false,
  title,
  text,
  onHover,
  wrapStyle,
}: {
  onPress?: () => void;
  checked?: boolean;
  icon?: FullIconType;
  circ?: boolean;
  withBorder?: boolean;
  big?: boolean;
  title?: string;
  text?: string;
  onHover?: () => void;
  wrapStyle?: StylesArray;
}) => {
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    if (isHover && onHover) onHover();
  }, [isHover, onHover]);
  const iconStyle = [
    circ ? C.radius10 : C.radius1,
    C.itemsCenter,
    C.justifyCenter,
    big ? C.p2 : vSizes.icon,
  ];
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        C.row,
        C.itemsCenter,
        C.mx2,
        wrapStyle,
        withBorder && [
          eColor(checked ? palette.orangeSec : palette.greyish).border,
          C.radius2,
          C.p1,
          C.m2,
        ],
      ]}
      onPress={onPress}
    >
      {!checked ? (
        <GIcon
          icon={icon}
          wrapStyle={[iconStyle, eColor(palette.greyish).border, C.radius1]}
        />
      ) : (
        <LinearGradient
          colors={orangeGrad}
          useAngle
          angle={120}
          style={[iconStyle, C.shadowMd]}
        >
          {/*{checked && <View style={[C.absolute, C.bgWhite, C.radius3, C.p1]} />}*/}
          <GIcon circular icon={icon} color={palette.white} />
        </LinearGradient>
      )}
      {text && (
        <GText type={title ? TextType.tab : TextType.body} style={[C.ml3]}>
          {text}
        </GText>
      )}
    </Pressable>
  );
};

export const Tag = ({text = '', col = palette.orangeSec}) => (
  <GText
    type={TextType.label}
    color={palette.white}
    style={[eColor(col).bg, C.p1, C.mx1, C.radius1]}
  >
    {text}
  </GText>
);
