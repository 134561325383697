import React, {useState} from 'react';
import {View} from 'react-native';
import C from 'consistencss';
import {round2} from '../types/units';
import {FullIconType} from '../types/icon-types';
import {FormWrapper} from './gFormInput';
import Slider from '@mui/material/Slider';
import {Input} from '@mui/material';
import {GText} from '../atoms';
import Grid from '@mui/material/Grid';
import {palette} from 'oralify-common';

interface SliderInputProps {
  initialValue?: number;
  min?: number;
  max?: number;
}

interface SliderInputReturn {
  value: number;
  increment?: () => void;
  decrement?: () => void;
  setValue: (value: number) => void;
  Slider: React.FC<SliderInputComponentProps>;
}

interface SliderInputComponentProps {
  max?: number;
  min?: number;
  step?: number;
  placeholder?: string;
  icon?: FullIconType;
  descr?: string;
  formatFunc?: (value: number) => string;
}

export const useSliderInput = ({
  initialValue = 15 /*, min = 1, max = 240*/,
}: SliderInputProps): SliderInputReturn => {
  const [value, setValue] = useState<number>(initialValue);

  const SliderComponent = ({
    formatFunc = (current: number) => round2(current) + '€',
    icon,
    min = 1,
    max = 100,
    step = 1,
    descr,
    placeholder,
  }: SliderInputComponentProps) => {
    /*const increment = () => {
      if (value + step <= max) {
        setValue(prevValue => prevValue + step);
      }
    };

    const decrement = () => {
      if (value - step >= min) {
        setValue(prevValue => prevValue - step);
      }
    };*/
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };
    return (
      <FormWrapper
        color={palette.orangeSec}
        placeholder={placeholder + ': ' + descr}
        extraStyles={[C.p2, C.pt4]}
      >
        <View style={[C.row, C.itemsCenter, C.justifyBetween]}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                aria-label="Always visible"
                onChange={
                  (ev, newVal) => setValue(newVal as number)
                  /*!isArray(newVal) && setValue(newVal)*/
                }
                getAriaLabel={val => formatFunc(val as number)}
                step={step}
                style={{width: '100%', flex: 1}}
                name={'precio'}
                color={'warning'}
                /*style={[C.mx2, C.my1]}*/
                /*style={[C.mx2, C.my4]}*/
                /*color={palette.orangeSec}*/
                /*style={[C.mx2, C.my4]}*/
                //colors={[palette.redSec, palette.orangeSec, palette.greenSec]}
                /*icon={icon}*/
                value={value}
                /*onSet={setValue}*/
                min={1}
                max={max}
                aria-labelledby="Precio"
              />
            </Grid>
            <Grid item>
              <Input
                value={value}
                size="small"
                type="number"
                onChange={handleInputChange}
                onBlur={() =>
                  setValue(
                    value < min
                      ? min
                        ? value > max
                          ? max
                          : value
                        : value
                      : value,
                  )
                }
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
              <GText>€</GText>
            </Grid>
          </Grid>
          {/* <PriceInputSlider />*/}
          {/*<GSlider
            formatFunc={formatFunc}
            step={step}
            style={[C.mx2, C.my4]}
            //colors={[palette.redSec, palette.orangeSec, palette.greenSec]}
            icon={icon}
            value={value}
            onSet={setValue}
            min={1}
            max={max}
          />*/}

          <View style={[C.row, C.itemsCenter, C.justifyBetween]}>
            {/*<View style={[C.row, C.itemsCenter]}>
              <GIcon
                icon={'chevron-down'}
                color={value > 1 ? palette.orangeSec : palette.greyish}
                onPress={decrement}
              />
              <GPill selected wrapStyle={C.m1} color={palette.orangeSec} icon={icon} text={formatFunc(value)} />
              <GIcon
                icon={'chevron-up'}
                color={value < max ? palette.orangeSec : palette.greyish}
                onPress={increment}
              />
            </View>*/}
            {/*<GText type={TextType.label} color={palette.greyish}>
            {descr}
          </GText>*/}
          </View>
        </View>
      </FormWrapper>
    );
  };

  return {value /*, increment, decrement,*/, setValue, Slider: SliderComponent};
};
