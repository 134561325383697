import {eColor, TextType} from '../ui';
import C from 'consistencss';
import React, {ComponentPropsWithoutRef} from 'react';
import {Pressable, StyleProp, ViewStyle} from 'react-native';

import {GText} from '../atoms';
import {palette} from 'oralify-common';

export type TopTabProps = {
  title: string;
  isTabPressed: boolean;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
} & Omit<ComponentPropsWithoutRef<typeof Pressable>, 'onPress' | 'style'>;

export function GTab({
  title,
  isTabPressed,
  onPress,
  style,
  ...rest
}: TopTabProps) {
  // const colorTheme = useColorTheme(); //TODO: Implement

  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      style={({pressed}) => [
        C.py3,
        C.itemsCenter,
        eColor(
          pressed || isTabPressed
            ? palette.blueSec
            : palette.primaryMercuryGrey,
          2,
        ).borderBottom,
        style,
      ]}
      {...rest}
    >
      {({pressed}) => (
        <GText
          type={pressed || isTabPressed ? TextType.tabSelected : TextType.tab}
        >
          {title}
        </GText>
      )}
    </Pressable>
  );
}

export default GTab;
