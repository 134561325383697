import DropDownPicker, {
  DropDownPickerProps,
} from 'react-native-dropdown-picker';
import {Alignment, customText, eColor, EeSize, gImages, TextType} from '../ui';
import {palette} from 'oralify-common';
import C from 'consistencss';
import {GIcon, GText} from '../atoms';
import React, {ReactNode, useContext, useState} from 'react';
import {View} from 'react-native';

const DropdownContext = React.createContext({
  openDropdownKey: null,
  setOpenDropdownKey: (key?: string) => {},
});

export const useDropdownContext = () => useContext(DropdownContext);

export const DropdownProvider = ({children}: {children: ReactNode}) => {
  const [openDropdownKey, setOpenDropdownKey] = useState(null);

  return (
    <DropdownContext.Provider value={{openDropdownKey, setOpenDropdownKey}}>
      {children}
    </DropdownContext.Provider>
  );
};

export const dropdownStyles: Partial<DropDownPickerProps<any>> = ({
  selectColor = palette.orangeSec,
}) => ({
  /*=  ({circ = false})=> {
      const currRadius = circ ? C.radius10     : C.radius1;
      return ({*/
  searchable: true,
  addCustomItem: false,
  closeAfterSelecting: true,
  showArrowIcon: true,
  showBadgeDot: true,
  showTickIcon: true,
  zIndex: 1000,
  zIndexInverse: 10000,
  /*badgeSeparatorStyle: [C.bgBlue],*/
  TickIconComponent: () => (
    <GIcon icon={'check'} size={EeSize.md} color={selectColor} />
  ),
  CloseIconComponent: () => <GIcon icon={'close'} />,
  /*ArrowIconComponent: () => <GIcon icon={'hamburger'} />,*/
  ArrowDownIconComponent: () => (
    <GIcon icon={'chevron-down'} color={palette.greyish} />
  ),
  ArrowUpIconComponent: () => (
    <GIcon icon={'chevron-up'} color={palette.greyish} />
  ),
  /* dropDownDirection: 'AUTO',*/
  containerStyle: [
    eColor('transparent').border,
    /*C.w60,*/
    /* C.h8,*/
    /*C.mb4,*/
    C.radius2,
  ],
  ListEmptyComponent: () => (
    <View style={[C.itemsCenter, C.p2]}>
      <GText align={Alignment.Center} color={palette.greyish}>
        {'No se encontraron resultados'}
      </GText>
      <GIcon
        resizeMode={'contain'}
        icon={gImages.oralify404b}
        size={EeSize.avatarBig}
        wrapStyle={[C.flex, C.my2, C.radius12, C.selfCenter]}
      />
    </View>
  ),
  dropDownContainerStyle: [
    C.radius2,
    C.mt1,
    C.shadowMd,
    C.maxh60,
    C.w80,
    eColor(palette.whiteFour).border,
  ],
  placeholderStyle: [customText(TextType.body), eColor(palette.greyish).text],
  labelStyle: [customText(TextType.body), eColor(selectColor).text],
  listItemLabelStyle: [customText(TextType.body), eColor(palette.greyish).text],
  selectedItemLabelStyle: [customText(TextType.body), eColor(selectColor).text],
  textStyle: [customText(TextType.body)],
  searchTextInputStyle: [
    customText(TextType.body),
    C.radius2,
    eColor(palette.whiteFour).border,
  ],
  selectedItemContainerStyle: [C.shadowSm, C.radius2, C.p1],
  searchPlaceholder: 'Buscar... ',
  searchContainerStyle: [
    eColor('white').border,
    C.shadowMd,
    /*C.bgBlue,*/
    C.radius0,
  ],

  searchTextInputProps: {
    autoFocus: false,
    selectionColor: selectColor,
  },
}); /*})*/
export type GDropdownTypes = DropDownPickerProps<any> & {
  selectColor?: string;
};
export const useGDropdown = ({
  initialValue = undefined,
  multiple = false,
  key,
}: {
  initialValue?: string | string[];
  multiple?: boolean;
  key: string;
}) => {
  const {openDropdownKey, setOpenDropdownKey} = useDropdownContext();
  const [value, setValue] = useState(multiple ? [initialValue] : initialValue);
  const isOpen = openDropdownKey === key;

  const setOpenPicker = (open?: boolean) => {
    if (open) {
      setOpenDropdownKey(key);
    } else if (isOpen) {
      setOpenDropdownKey('');
    }
  };

  const GDropdown = ({
    items,
    selectColor = palette.purpleSec,
    ...props
  }: GDropdownTypes) => (
    <View style={[C.mr4, C.justifyStart, isOpen ? C.maxh80 : C.mb0]}>
      <DropDownPicker
        {...dropdownStyles({selectColor})}
        {...props}
        open={isOpen}
        items={items}
        value={value}
        setValue={setValue}
        setOpen={setOpenPicker}
        multiple={multiple}
        style={[
          eColor(palette.whiteFour).border,
          /*C.h8,*/
          isOpen ? C.mb70 : C.mb0,
        ]}
      />
    </View>
  );
  return {GDropdown, value, setValue, isOpen, setOpenPicker};
};
