import React, {useEffect, useState} from 'react';
import {FlatList, SafeAreaView, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import {
  Api_Routes,
  AvailabilitySettingsType,
  DayKey,
  palette,
  RecurrenceKey,
  ServiceType,
  UserType,
} from 'oralify-common';
import {ButtonType, GCard} from '../molecules';
import {Alignment, eColor, EeSize, eSizes, TextType} from '../ui';
import {GText} from '../atoms';
import {useModal} from '../modals/useModal';
import {modalScreens} from '../routes/useGNav.web';
import {
  AvailabilitySelector,
  weekDayNames,
} from '../FormSelector/AvailabilitySelector';
import {CheckboxGrad} from '../components/Cards';
import {getUserInfo} from '../api/userApi';
import {useRecoilState} from 'recoil';
import {currentUserAtom} from '../store/useUser';
import {RecurrenceSpanish} from '../FormSelector/CalendarAvailabilitySelector';
import {GBadge} from '../molecules/gBadge';
import {timeToHourUTC} from '../types/utils/dates';
import {apiCaller, TYPES_API} from '../api/api';
import GButton from '../molecules/GButton';

export const AvailabilityDetails = ({
  currAvailab,
  showDetails,
  onPress,
}: {
  currAvailab: AvailabilitySettingsType;
  showDetails?: boolean;
  onPress?: () => void;
}) => {
  const {name, id, startTime, endTime, recurrence, breakTime, dayOfWeek} =
    currAvailab;
  return showDetails ? (
    <View style={[C.m4]}>
      <GCard
        style={[C.mx4, C.flex]}
        key={id}
        onPress={() => {
          if (onPress) onPress();
        }}
        image={'⏳'}
        text={name}
        title={RecurrenceSpanish[recurrence as RecurrenceKey]}
        subtitle={timeToHourUTC(startTime) + ' - ' + timeToHourUTC(endTime)}
        icon={'💰'}
      >
        <FlatList
          data={dayOfWeek}
          numColumns={3}
          contentContainerStyle={[C.m2, C.itemsCenter]}
          keyExtractor={([key]) => key}
          renderItem={({item: dayKey}) => {
            const currColor = palette.orangeSec;
            return (
              <TouchableOpacity
                onPress={() => {
                  if (onPress) onPress();
                }}
                style={[
                  C.itemsCenter,
                  C.justifyCenter,
                  C.radius10,
                  C.m2,
                  eSizes[EeSize.icon],
                  eColor(currColor).border,
                  C.shadowMd,
                ]}
              >
                <GText
                  type={TextType.tabSelected}
                  color={currColor}
                  align={Alignment.Center}
                  style={[
                    C.capitalize,
                    /*C.py2,*/
                    C.m1,
                  ]}
                >
                  {weekDayNames[dayKey as DayKey]?.slice(0, 3)}
                </GText>
              </TouchableOpacity>
            );
          }}
        />
        <CheckboxGrad
          wrapStyle={C.py2}
          checked={!!(breakTime && breakTime > 0)}
          /*onPress={() => setBreak(!withBreak)}*/
          text={'Descansos de 15 min'}
        />
      </GCard>
    </View>
  ) : (
    <GBadge
      actionIcon={'pencil'}
      onPress={() => {
        if (onPress) onPress();
      }}
      text={dayOfWeek /*.split(',')*/
        .map(day => weekDayNames[day as DayKey]?.slice(0, 3))
        .join(', ')}
      selected
      icon={'clock'}
      color={palette.orangeSec}
      fontColor={palette.orangeSec}
      symbol={timeToHourUTC(startTime) + ' - ' + timeToHourUTC(endTime)}
    />
  );
};

export const AvailabilityList = ({user}: {user: UserType}) => {
  const {showModal, ModalComponent, closeModal} = useModal(
    modalScreens.availabilityCalendar,
  );
  const [shouldDelete, setShouldDelete] = useState(false);
  /* const [selectedAvailability, setSelectedAvailability] = useState();*/

  const [currentUser, setUser] = useRecoilState(currentUserAtom);
  const [showDetails, setShowDetails] = useState(false);

  const [currAvailab, setCurrAvailab] = useState<AvailabilitySettingsType>();
  useEffect(() => {
    if (user.id) {
      getUserInfo(user.id).then(completeUser => setUser(completeUser));
    }
  }, [currAvailab]);
  const updatedUser = currentUser || user;

  const availability =
    updatedUser.availabilityexpert as AvailabilitySettingsType[];

  /*const handleDeleteConfirmation = availabilityId => {
                        setCurrAvailab(availabilityId);
                        setShouldDelete(true);
                    };*/

  const handleDelete = async () => {
    if (currAvailab?.id) {
      // Llama a tu API para eliminar
      await apiCaller({
        url: Api_Routes.availabilityExpert.deleteAvailabilityExpert.replace(
          ':id',
          currAvailab.id,
        ),
        type: TYPES_API.DELETE,
      });
      closeModal();
    }
  };
  return (
    <SafeAreaView style={[C.bgWhite, C.m5]}>
      <FlatList ///*<FlashList*/
        data={availability as AvailabilitySettingsType[]}
        /* numColumns={5}*/
        horizontal
        //estimatedItemSize={matchingUsers?.length || 13000}
        /*extraData={[currService, currSpeciality, isConnected]}*/
        keyExtractor={({id}: ServiceType) => id}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={C.selfStart}
        ListFooterComponent={
          <View style={[!showDetails && C.row, C.itemsCenter]}>
            {!showDetails ? (
              <GBadge
                style={[C.my7, C.flex]}
                icon={'plus'}
                selected
                color={palette.orangeSec}
                fontColor={palette.orangeSec}
                /*image={'⏳'}*/
                onPress={() => {
                  showModal();
                  setShouldDelete(false);
                }}
                symbol={'Añadir'}
                text={'disponibilidad'}
              />
            ) : (
              <GCard
                style={[C.my7, C.flex]}
                icon={'plus'}
                image={'⏳'}
                onPress={() => {
                  showModal();
                  setShouldDelete(false);
                }}
                title={'Añadir horario'}
                subtitle={'Otra disponibilidad...'}
                text={'También puedes copiar o duplicar otras disponibilidades'}
              />
            )}
            <GBadge
              color={palette.greyish}
              fontColor={palette.greyish}
              text={(showDetails ? 'Menos' : 'Más') + ' detalles'}
              icon={showDetails ? 'chevron-up' : 'chevron-down'}
              onPress={() => setShowDetails(!showDetails)}
            />
          </View>
        }
        renderItem={({
          item: currentAvailabilty,
        }: {
          item: AvailabilitySettingsType;
        }) => {
          return (
            <AvailabilityDetails
              showDetails={showDetails}
              currAvailab={currentAvailabilty}
              onPress={() => {
                showModal();
                setCurrAvailab(currentAvailabilty);
                setShouldDelete(true);
              }}
            />
          );
        }}
      />
      <ModalComponent>
        {shouldDelete ? (
          <View style={[C.itemsCenter]}>
            <GText type={TextType.h4} style={[C.my4]}>
              {'¿Editar o eliminar?'}
            </GText>
            <GText>{'¿Estás seguro de eliminar esta disponibilidad?'}</GText>
            {currAvailab && (
              <AvailabilityDetails showDetails currAvailab={currAvailab} />
            )}
            <GButton
              text={'Editar la disponibilidad'}
              icon={'pencil'}
              type={ButtonType.secondary}
              onPress={() => setShouldDelete(false)}
            />
            <GButton
              text={'Eliminar completamente '}
              type={ButtonType.simple}
              color={palette.redSec}
              icon={'delete'}
              onPress={() => handleDelete()}
            />
            <GButton
              text={'Cancelar '}
              /*descr={'No eliminar la disponibilidad'}*/
              icon={'close'}
              color={palette.greyish}
              type={ButtonType.simple}
              onPress={() => {
                closeModal();
              }}
            />
          </View>
        ) : (
          <AvailabilitySelector isEdit currAvailability={currAvailab} />
        )}
      </ModalComponent>
    </SafeAreaView>
  );
};
