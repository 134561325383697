import {getAllCountries} from 'country-locale-map';

export const countries = getAllCountries().filter(({continent}) => continent === 'Europe');
export const countryCodes = countries.map(({alpha2}) => alpha2);
/*// Enum para los códigos de países
export enum CountryCode {
  US = 'US',
  ES = 'ES',
  FR = 'FR',
  DE = 'DE',
  IT = 'IT',
  CN = 'CN',
  RU = 'RU',
  SA = 'SA',
  PT = 'PT',
  IN = 'IN',
  JP = 'JP',
  NL = 'NL',
  SE = 'SE',
  NO = 'NO',
}

// Enum para los códigos de idiomas
export enum LocaleCode {
  'en-US' = 'en-US',
  'es-ES' = 'es-ES',
  'ca-ES' = 'ca-ES',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'it-IT' = 'it-IT',
  'zh-CN' = 'zh-CN',
  'ru-RU' = 'ru-RU',
  'ar-SA' = 'ar-SA',
  'pt-PT' = 'pt-PT',
  'hi-IN' = 'hi-IN',
  'ja-JP' = 'ja-JP',
  'nl-NL' = 'nl-NL',
  'sv-SE' = 'sv-SE',
  'no-NO' = 'no-NO',
}
/!*
export type LangType = {
  flag: string;
  name: string;
  locale: string;
  countryCode: string;
};*!/*/

export const languageData /*: Record<string, {name: string; locale: string}> */ = {
  US: {name: 'English', locale: 'en-US'},
  ES: {name: 'Spanish', locale: 'es-ES'},
  CA: {name: 'Catalan', locale: 'ca-ES'},
  FR: {name: 'French', locale: 'fr-FR'},
  DE: {name: 'German', locale: 'de-DE'},
};

export const currentLocale = languageData.ES.locale;

export const formatNumber = (number?: number, locale = currentLocale) => {
  return number ? new Intl.NumberFormat(locale).format(number) : '';
};
export const useTrans = () => {
  return {t: (name: string) => name};
};

export interface CountryType {
  name: string;
  alpha2: string;
  alpha3: string;
  numeric: string;
  locales: string[];
  default_locale: string;
  currency: string;
  currency_name: string;
  languages: string[];
  capital: string;
  emoji: string;
  emojiU: string;
  fips: string;
  internet: string;
  continent: string;
  region: string;
  alternate_names?: string[];
}
