import {useState} from 'react';
import {formTypes, FormTypes, InputProps} from '../molecules';
import {FullIconType} from '../types/icon-types';
import {UserType} from 'oralify-common';

export type FormField = {
  key: keyof UserType;
  formType?: FormTypes;
  initialValue?: string;
  icon: FullIconType;
  prefix?: string;
  placeholder: string;
  isRequired?: boolean;
  forSpecialist?: boolean;
} & InputProps;

type UseUserForm = [
  Partial<UserType>,
  (key: keyof UserType, value: string | number | any) => void,
  boolean,
];

export const useUserForm = (
  initialState: Partial<UserType>,
  formFields: FormField[],
): UseUserForm => {
  const [state, setState] = useState(initialState);

  const updateField = (key: keyof UserType, value: string | number | any) => {
    setState(prev => ({...prev, [key]: value}));
  };

  const requiredFields = formFields
    .filter(field => field.isRequired)
    .map(field => field.key);

  const isValid = requiredFields.every(key => {
    const field = formFields.find(field => field.key === key);
    const formType = field?.formType || FormTypes.default;
    const onCheck = formTypes[formType]?.onCheck;

    return !!state[key] && (!onCheck || onCheck(state[key] as string));
  });

  return [state, updateField, isValid];
};
