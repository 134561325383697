/*todo add feat localized
    export const LOCAL_SEPARATOR = (locale = 'en-US') => locale
export const unitSystem = {å
  metric: {separator: ',', helper: Intl.NumberFormat('en-US')},
  imperic: ''
} */
export const addSeparators = (value: number | string) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); /* unitSystem.metric.helper.format(value) */ //
export const numberToUnits = (
  value: string,
  unit?: string, //  addSeparators(value) + " " + unit ? unit : "";
) => addSeparators(value) + ' ' + (unit || '');
