import {ButtonSize, FormTypes, GButton, GFormInput} from '../molecules';
import {FormField} from '../store/useGForm';
import React, {useState} from 'react';
import {useSliderInput} from '../molecules/SliderInput';
import {View} from 'react-native';
import C from 'consistencss';
import {formatDuration} from '../types/utils/dates';
import useResponsive from '../ui/helpers/useResponsive';
import {Api_Routes, ServiceFormatKey, ServiceType} from 'oralify-common';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {
  currentStepAtom,
  newServiceAtom,
  SERVICE_STEPS,
} from '../types/stepTypes';
import {round2} from '../types/units';
import {currentUserAtom} from '../store/useUser';
import {apiCaller, TYPES_API} from '../api/api';
import Toast from 'react-native-toast-message';
import {useModal} from '../modals/useModal';
import {modalScreens} from '../routes/useGNav.web';
import {isEmpty} from 'lodash';

export const serviceFormFields = [
  {
    key: 'name',
    icon: 'stars',
    initialValue: 'Servicio de audio',
    placeholder: 'Nombre',
    isRequired: true,
  },
  {
    key: 'location',
    /*formType: FormTypes.location,*/
    initialValue: 'Arago, 254, Barcelona, Spain',
    icon: 'location',
    /*suffix: 'min',*/
    placeholder: 'Ubicación',
    /*isRequired: true,*/
  } /*{
    key: 'duration',
    formType: FormTypes.number,
    initialValue: 10,
    icon: 'clock',
    suffix: 'min',
    step: 5,
    max: 120,
    placeholder: 'Duración',
    isRequired: true,
  },*/,
  {
    key: 'price',
    formType: FormTypes.price,
    initialValue: 12,
    icon: 'euro',
    suffix: '€',
    max: 120 * 4,
    placeholder: 'Precio',
    isRequired: true,
  },
] as FormField[];

type ServiceFormProps = {
  onAddService?: (service: ServiceType) => void;
  service?: ServiceType;
};

export const ServiceDetailsSelector: React.FC<ServiceFormProps> = ({}) => {
  const {closeModal} = useModal(modalScreens.addService);
  const [newService, setNewService] = useRecoilState(newServiceAtom);
  const [currUser, setCurrUser] = useRecoilState(currentUserAtom);
  const {
    duration = 1,
    price = 1,
    name = '',
    format = ServiceFormatKey.audio,
  } = newService || {};
  const initialMaxPrice = Math.min(price, duration * 8); // initialMaxPrice
  // no puede ser mayor que duration * 4
  //const initialMaxDuration = Math.max(duration, price / 4); // initialMaxDuration no puede ser menor que price / 4
  const setCurrStepKey = useSetRecoilState(currentStepAtom);
  const [currName, setName] = useState(name);
  const [location, setLocation] = useState('Arago, 254, Barcelona, Spain');
  const [loading, setLoading] = useState(false);
  //const [maxPrice, setMaxPrice] = useState(initialMaxPrice);
  const {
    value: currPrice,
    setValue: setPrice,
    Slider: PriceSlider,
  } = useSliderInput({initialValue: price /*, min: 1, max: maxPrice*/});

  const onSubmitService = async () => {
    setLoading(true);
    const serviceDetails = {
      ...newService,
      name: currName,
      price: currPrice,
      address: format === ServiceFormatKey.person ? location : undefined,
      expertId: currUser?.id,
    };
    setNewService(serviceDetails);
    console.log(serviceDetails);
    console.log('user: ', currUser);

    const {data: userResponse, error} = await apiCaller({
      url: Api_Routes.service.setService,
      params: serviceDetails,
      type: TYPES_API.POST,
    });
    if (error) {
      setLoading(false);
      Toast.show({
        text1:
          'No tienes permisos para añadir este servicio, por favor' +
          ' registrate antes',
        type: 'error',
      });
      closeModal();
      return;
    }
    /** @ts-ignore*/
    /*setCurrUser(prevUser => ({
      ...prevUser,
      services: prevUser?.services
        ? [...prevUser?.services, serviceDetails]
        : [serviceDetails],
    }));*/
    Toast.show({
      text1: 'Nuevo servicio añadido: ' + JSON.stringify(newService),
      type: 'success',
    });
    setLoading(false);
    setCurrStepKey(currUser?.availabilityexpert ? SERVICE_STEPS.SPECIALITY : SERVICE_STEPS.SERVICE_AVAILABILITY);
    if (!isEmpty(currUser?.availabilityexpert)) {
      closeModal();
      setNewService(undefined);
      //setAvailabilityForUser(userResponse.user);
    }
    /*setNewService(undefined);*/
    /*closeModal();*/
  };

  const {isHuge, isSmall} = useResponsive();
  const priceMin = (currPrice / duration) * 0.85;
  const priceHour = priceMin * 60;
  return (
    <View style={[C.itemsCenter]}>
      <View style={[C.itemsCenter, C.top_4, C.flex, isHuge && C.row]}>
        <View>
          <GFormInput
            key={'name'}
            initialValue={currName}
            icon={'stars'}
            placeholder={'Nombre del servicio'}
            style={C.w60}
            value={currName}
            onSetText={text => setName(text)}
          />
          {/**location*/}
          {format === ServiceFormatKey.person && (
            <GFormInput
              key={'location'}
              initialValue={'Arago, 254, Barcelona, Spain'}
              icon={'pin'}
              placeholder={'Dirección'}
              style={[C.w60, C.pt_4]}
              value={location}
              onSetText={text => setLocation(text)}
            />
          )}
        </View>
        <PriceSlider
          max={initialMaxPrice < 20 ? 20 : initialMaxPrice * 2}
          icon={'euro'}
          suffix={'€'}
          descr={round2(priceHour) + '€/h'}
          placeholder={'Precio, ganarías por hora'}
          value={currPrice}
          setValue={setPrice}
        />
      </View>
      <GButton
        icon={'plus'}
        loading={loading}
        size={ButtonSize.medium}
        text={`Añadir Servicio ${formatDuration(duration)} por ${currPrice}€`}
        onPress={() => onSubmitService()}
      />
    </View>
  );
};
