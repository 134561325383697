// CRUD para los servicios

/** TODO discuss about OralCoin®*/
export const minutePacks = [
  {
    price: 0.8,
    minutes: 1,
  },
  {
    price: 3,
    minutes: 5,
  }, // 5'
  {
    price: 29,
    minutes: 60,
    highlight: true,
  }, // 1h
  {
    price: 79,
    minutes: 180,
  }, // 3h
];

/*export const createMockServices = (): ServiceMapType => {
  // Obtén una cantidad aleatoria de servicios (entre 1 y 5)
  const numOfServices = _.random(1, 5);
  // Obtén una cantidad aleatoria de ServiceKeys
  const selectedServiceKeys = _.sampleSize(
    Object.values(ServiceFormatKey),
    numOfServices,
  );
  return selectedServiceKeys.reduce((acc, key) => {
    // Genera una cantidad aleatoria de servicios para cada formato
    const numOfFormatServices = _.random(1, 5);
    const services = [];
    for (let i = 0; i < numOfFormatServices; i++) {
      services.push(createMockService({format: key}));
    }
    acc[key as ServiceFormatKey] = services;
    return acc;
  }, {} as ServiceMapType);
};

export const createMockService = (service: ServiceType = {}): ServiceType => {
  const {
    price = _.random(1, 100),
    duration = _.random(1, 120),
    name = faker.commerce.productName(),
    format = faker.helpers.arrayElement(Object.values(ServiceFormatKey)),
  } = service;

  const availability = faker.helpers.arrayElements(
    [createMockAvailability()],
    _.random(1, 3),
  );
  return {
    price,
    duration,
    name,
    format,
    availability,
  };
};

export const createMockAvailability = (
  availability: Partial<AvailabilitySettingsType> = {},
): AvailabilitySettingsType => {
  const {
    startTime = faker.date.future(),
    endTime = _.random(0, 3),
    recurrence = faker.helpers.arrayElement(Object.values(Recurrence)),
  } = availability;

  return {
    startTime,
    duration,
    recurrence,
  };
};

export const addServiceDB = async (
  userId: string,
  serviceKey: ServiceFormatKey,
  price: number,
  lengthMinutes: number,
) => {
  const {data, error} = await supabaseWeb.from(DB_TABLE.services).insert([
    {
      user_id: userId,
      service_key: serviceKey,
      price,
      length_minutes: lengthMinutes,
    },
  ]);

  if (error) {
    console.error('Error al crear servicio:', error.message);
    throw error;
  }

  return data;
};

export const updateService = async (
  serviceId: string,
  updatedFields: Partial<ServiceType>,
) => {
  const {data, error} = await supabaseWeb
    .from(DB_TABLE.services)
    .update(updatedFields)
    .eq('id', serviceId);

  if (error) {
    console.error('Error al actualizar servicio:', error.message);
    throw error;
  }

  return data;
};*/

/*export const deleteService = async (serviceId: string) => {
  const {error} = await supabaseWeb
    .from(DB_TABLE.services)
    .delete()
    .eq('id', serviceId);

  if (error) {
    console.error('Error al eliminar servicio:', error.message);
    throw error;
  }
};

// CRUD para las fechas/horas disponibles

export const createAvailableSlot = async (
  serviceId: string,
  startTime: Date,
  duration: number,
) => {
  const {data, error} = await supabaseWeb
    .from('available_slots')
    .insert([{service_id: serviceId, start_time: startTime, duration}]);

  if (error) {
    console.error('Error al crear tiempo disponible:', error.message);
    throw error;
  }

  return data;
};

export const updateAvailableSlot = async (
  slotId: string,
  updatedFields: Partial<ServiceAvailability>,
) => {
  const {data, error} = await supabaseWeb
    .from('available_slots')
    .update(updatedFields)
    .eq('id', slotId);

  if (error) {
    console.error('Error al actualizar tiempo disponible:', error.message);
    throw error;
  }

  return data;
};

export const deleteAvailableSlot = async (slotId: string) => {
  const {error} = await supabaseWeb
    .from('available_slots')
    .delete()
    .eq('id', slotId);

  if (error) {
    console.error('Error al eliminar tiempo disponible:', error.message);
    throw error;
  }
};*/
