import {StyleSheet, useWindowDimensions} from 'react-native';
import {DESKTOP, DESKTOP_HUGE, MOBILE, TABLET} from '../constants';
import C from 'consistencss';

const useResponsive = () => {
  const {width, height} = useWindowDimensions();

  const isPortrait = height > width;
  const isLandscape = !isPortrait;

  const isDesktopHuge = width >= DESKTOP_HUGE;
  const isDesktop = width >= DESKTOP && width < DESKTOP_HUGE;
  const isTablet = width >= TABLET && width < DESKTOP;
  const isSmall = width < TABLET;
  const isBig = isTablet || isDesktop || isDesktopHuge;
  const isLarge = isDesktop || isDesktopHuge;
  const isNarrow = isSmall && width < MOBILE;
  const resp = (ratio = 1) =>
    StyleSheet.create({
      height: {height: height * ratio},
      minHeight: {minHeight: height * ratio},
      maxHeight: {maxHeight: height * ratio},
      width: {width: width * ratio},
      maxWidth: {maxWidth: width * ratio},
      minWidth: {minWidth: width * ratio},
    });
  const wrapStyle = [
    C.selfCenter,
    isBig
      ? resp(0.85).width
      : [resp().width, isTablet ? C.px8 : isSmall ? C.pl7 : C.px6],
  ];

  return {
    width,
    height,
    isPortrait,
    isLandscape,
    isHuge: isDesktopHuge,
    isSmall,
    isTablet,
    isBig,
    isLarge,
    isNarrow,
    wrapStyle,
    resp,
  };
};

export default useResponsive;
