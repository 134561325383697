import {
  BASE_PIXEL,
  eColor,
  EeSize,
  orangeGrad,
  rowHeight,
  TextType,
} from '../ui';
import C from 'consistencss';
import React, {useState} from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {GIcon, GText} from '../atoms';
// @ts-ignore
//import GradRedOrange from '../../public/assets/gradients/redOrangeGradient.svg';
import {FullIconType} from '../types/icon-types';
import LinearGradient from 'react-native-linear-gradient';
import {palette} from 'oralify-common';

/*export const RedOrangeGrad = () => (
  <GradRedOrange width="100%" height="100%" preserveAspectRatio="none" />
);*/

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  simple = 'simple',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export type EeButtonProps = {
  text?: string;
  onPress?: () => void;
  descr?: string;
  color?: string;
  icon?: FullIconType;
  type?: ButtonType;
  loading?: boolean;
  size?: ButtonSize;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
};

export function GButton({
  text,
  onPress,
  icon,
  descr,
  type = ButtonType.primary,
  loading,
  size = ButtonSize.medium,
  disabled = false,
  color = palette.white,
  style,
}: EeButtonProps) {
  const isLarge = size === ButtonSize.large;
  const isSecond = type === ButtonType.secondary;
  const isSimple = type === ButtonType.simple;
  const currColor = disabled
    ? palette.secondaryDarkGrey
    : isSecond
    ? palette.purpleSec
    : color;
  const [isHover, setIsHover] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      disabled={disabled}
      style={[
        isLarge ? [C.selfStretch, C.radius10] : [C.selfCenter, C.radius6],
        C.m2, // same as hitSlop
        !isSimple && C.bgWhite,
        isLarge ? rowHeight : C.py2,
        (isHover || disabled) && C.bgPrimaryMercuryGrey,
        isSimple && eColor(currColor, 1).border,
        C.overflowHidden,
        style,
      ]}
      hitSlop={BASE_PIXEL * 2} // same as margin
    >
      {({pressed}) => (
        <>
          {!disabled && !isSimple && (
            <LinearGradient
              colors={orangeGrad}
              useAngle
              angle={120}
              style={[
                StyleSheet.absoluteFill,
                (pressed || loading || isHover) && C.opacity40,
              ]}
            />
          )}
          {isSecond && (
            <View
              style={[
                StyleSheet.absoluteFill,
                {margin: 2},
                isLarge ? C.radius6 : C.radius10,
                C.bgWhite,
              ]}
            />
          )}

          <View
            style={[
              C.flex,
              C.row,
              C.itemsCenter,
              C.selfCenter,
              C.justifyCenter,
              size === ButtonSize.small || isSimple
                ? C.px4
                : text
                ? C.px8
                : C.px3,
            ]}
          >
            {icon && (
              <GIcon
                size={size === ButtonSize.small ? EeSize.md : EeSize.icon}
                icon={icon}
                wrapStyle={[!!text && C.mr2]}
                color={currColor}
              />
            )}
            {!!text && (
              <GText
                numberOfLines={1}
                webGrad={isSecond}
                selectable={false}
                // @ts-ignore
                type={
                  size === ButtonSize.small
                    ? TextType.h6BodySmall
                    : TextType.bodyBold
                }
                color={isSecond /*&& !disabled*/ ? 'transparent' : currColor}
              >
                {text}
              </GText>
            )}
            {descr && (
              <GText
                numberOfLines={1}
                selectable={false}
                style={C.mx2}
                type={TextType.tab}
                color={
                  type === ButtonType.secondary && !disabled
                    ? palette.orangeSec
                    : color
                }
              >
                {descr}
              </GText>
            )}
            {loading && (
              <ActivityIndicator
                size={'small'}
                color={
                  type === ButtonType.secondary ? palette.orangeSec : color
                }
                style={[
                  !!text && C.mx2,
                  type === ButtonType.secondary && [C.bgWhite, C.opacity40],
                ]}
              />
            )}
          </View>
        </>
      )}
    </Pressable>
  );
}

export default GButton;
