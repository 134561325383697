import {FlatList, View} from 'react-native';
import C from 'consistencss';
import {GText} from '../atoms';
import {Alignment, eText, TextType} from '../ui';
import dayjs from 'dayjs';
import {
  ServiceType,
  statusInfoMapping,
  TimeslotStatus,
  TimeSlotType,
} from 'oralify-common';
import {GBadge} from '../molecules/gBadge';
import React from 'react';
import {DATE_CALENDAR, DATE_FORMAT_SHORT} from '../types/utils/dates';

export default ({
  groupedTimeslots,
  services,
}: {
  groupedTimeslots: Record<string, TimeSlotType[]>;
  services?: ServiceType[];
}) => {
  // Renderizado de la lista de días de la semana
  const renderDayItem = ({
    item: [dayDate, slots],
  }: {
    item: [string, TimeSlotType[]];
  }) => {
    // Aquí item es el grupo de time_slots para un día específico
    return (
      <View style={[C.ml4, C.itemsCenter]}>
        <GText
          align={Alignment.Center}
          style={[C.capitalize]}
          type={TextType.h6BodySmall}
        >
          {dayjs(dayDate, DATE_CALENDAR).format(DATE_FORMAT_SHORT)}
        </GText>
        <FlatList
          data={slots}
          renderItem={renderTimeSlotItem}
          keyExtractor={item => item.id}
        />
      </View>
    );
  };

  // Renderizado de los time_slots individuales
  const renderTimeSlotItem = ({item}: {item: TimeSlotType}) => {
    const {color, icon, nameEs} = statusInfoMapping[item.status];
    return (
      <View>
        <GBadge
          text={dayjs(item.startTime).format('HH:mm')}
          textStyle={item.status === TimeslotStatus.booked && eText.strike}
          /*selected={false}*/
          /*icon={icon}*/
          selected={item.status !== TimeslotStatus.unavailable}
          /*color={color}*/
          fontColor={color}
          /*fontColor={
            item.status
              ? statusColorMapping[item.status as TimeslotStatus]
              : palette.greyish
          }*/
        />
      </View>
    );
  };

  return (
    <View>
      <FlatList
        horizontal
        style={[/*C.maxwHalf, */ C.selfCenter]}
        data={Object.entries(groupedTimeslots)}
        renderItem={renderDayItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};
