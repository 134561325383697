import C from 'consistencss';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
  Animated,
  Easing,
  Pressable,
  TouchableOpacity,
  View,
} from 'react-native';
import {ButtonType, GButton} from '../molecules';
import {useFormatFilter} from '../filters/PillFilters';
import {CheckboxGrad} from './Cards';
import useResponsive from '../ui/helpers/useResponsive';
import {
  getRandomSpecialityKey,
  useSpeciality,
  useSpecialityCategorySelector,
} from '../types/SpecialityProvider';
import {Alignment, eDividerVert, eLayout, orangeGrad, TextType} from '../ui';
import {GIcon, GText} from '../atoms';
import {useGNav} from '../routes/useGNav';
import {useModal} from '../modals/useModal';
import {SpecialityDropdown} from './SpecialityDropdown';
import UserCard from '../organisms/UserCard';
import {trans} from '../store/language';
import {
  gSpecialities,
  palette,
  ServiceFormatKey,
  serviceFormatMap,
  SpecialityKey,
  UserType,
} from 'oralify-common';
import {GCarrousel} from '../organisms/GCarrousel';
import {centerRow} from '../molecules/gInfoRow';
import {
  headerScreens,
  modalScreens,
  routeQueryParam,
} from '../routes/useGNav.web';
import GSpinner from '../atoms/GSpinner';
import {gLogEvent} from '../store/firebaseInit';
import {apiCaller, expertFilterURL} from '../api/api';
import {putoCuaresma} from '../filters/useTabs';

export const GSearchBarLanding = ({compact = false}: {compact?: boolean}) => {
  const [ServiceFilterComp, currFormatKey] = useFormatFilter({
    transp: true,
    showSummary: false,
  });
  /*const {currSpeciality, CategoriesGroupSelector} = useSpecialitySelector();*/
  const currFormat = currFormatKey
    ? serviceFormatMap[currFormatKey as ServiceFormatKey]
    : serviceFormatMap.video;
  const navigate = useGNav();
  const {isSmall, resp, isLarge, isTablet} = useResponsive();
  const [loading, setLoading] = useState(false);
  /*const {TooltipWrapper: SpecialityTooltip, showTooltip} = usePressTooltip({});*/
  /*const {SpecialityFilterComp} = useSpecialityFilter({});*/

  /* const {randomSpecialityKey, randomSpeciality} = useSpeciality();*/
  const {currSpecialityKey, setSpecialityKey} = useSpeciality();
  const [randomSpecialityKey, setRandomSpecialityKey] = useState<SpecialityKey>(
    getRandomSpecialityKey(),
  );

  const {
    selectedSpecialityKey,
    currentCategoryKey,
    selectedKeywords,
    CategoriesGroupSelector,
  } = useSpecialityCategorySelector({
    /*fontColor: palette.white,*/
    transparent: true,
    showCategoryPills: true,
  });
  const shownSpecialityKey =
    selectedSpecialityKey || currSpecialityKey || randomSpecialityKey;
  const shownSpeciality = gSpecialities[shownSpecialityKey];

  const showCompact = isSmall || compact;
  const searchInputs = [
    {
      label: 'Formato',
      list: Object.values(serviceFormatMap),
      current: currFormat,
    },
    {
      label: isSmall || isTablet ? 'Especialidad' : '¿Qué especialidad?',
      list: Object.values(gSpecialities),
      current: shownSpeciality,
    },
    {label: '¿Dónde?', icon: 'locate', placeholder: 'Cerca de mí'},
  ];
  const questions = searchInputs.slice(
    0,
    currFormatKey !== ServiceFormatKey.person || showCompact ? 2 : undefined,
  );

  const {showModal: showLoginModal} = useModal(modalScreens.login);
  const {showModal: showBurgerModal, ModalComponent: BurgerModal} = useModal(
    modalScreens.tooltip,
  );
  const positionAnim = useRef(new Animated.Value(-50)).current;
  const opacityAnim = useRef(new Animated.Value(0)).current;
  const [experts, setExperts] = useState<UserType[]>([]);

  /** Fetch matching experts*/
  useEffect(() => {
    const getMatchingExperts = async () => {
      setLoading(true);
      const {data: usersDB} = await apiCaller({
        url: expertFilterURL({
          speciality: selectedSpecialityKey,
          category: currentCategoryKey,
          keywords: selectedKeywords.map(({value}) => value || ''),
        }),
      });
      setExperts(usersDB);
      setLoading(false);
    };
    getMatchingExperts();
  }, [selectedSpecialityKey, selectedKeywords, currentCategoryKey]);

  useEffect(() => {
    positionAnim.setValue(-50);
    opacityAnim.setValue(0);
    Animated.parallel([
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true,
        easing: Easing.out(Easing.quad),
      }),
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
        easing: Easing.in(Easing.quad),
      }),
    ]).start();
  }, [opacityAnim, positionAnim, shownSpecialityKey]);

  const animatedStyle = {
    transform: [{translateY: positionAnim}],
    opacity: opacityAnim,
  };

  const onSearchQuery = (withFormat = true) => {
    const queryFormat = withFormat
      ? (currFormat?.format as ServiceFormatKey) || ServiceFormatKey.call
      : undefined;
    gLogEvent('onSearchBar', {
      category: currentCategoryKey,
      speciality: shownSpecialityKey,
      format: queryFormat,
      keywords: selectedKeywords.map(({value}) => value || ''),
    });
    navigate(
      routeQueryParam(headerScreens.expertSearch.path, {
        category: currentCategoryKey || 'all',
        specialityKey: shownSpecialityKey,
        serviceFormat: queryFormat,
        keywords: selectedKeywords.map(({value}) => value || '').join(','),
      }),
    );
  };

  const {showModal: showAddServiceModal} = useModal(modalScreens.addService);
  return (
    <>
      {!compact && (
        <View style={C.my4}>
          <h1 style={resp(isSmall ? 0.7 : 0.5).maxWidth}>
            <GText
              style={[C.mt0, isSmall && C.capitalize]}
              type={TextType.h2}
              color={palette.white}
            >
              {trans(
                (!isSmall ? 'Conecta con ' : '') +
                  'expertos' +
                  (shownSpeciality?.name ? ' en ' : ''),
              )}
              {shownSpeciality?.name && (
                <Animated.View
                  style={[
                    animatedStyle,
                    C.ml2 /*, vShadow.regular, C.radius8, C.p2, C.px4*/,
                  ]}
                >
                  <GText
                    key={shownSpeciality?.name}
                    onPress={() => onSearchQuery(false)}
                    style={[
                      C.shadowMd,
                      C.px5,
                      C.py1,
                      C.bgTransp,
                      C.radius8,
                      /*eColor(palette.white).borderBottom,*/
                    ]}
                    type={TextType.h2}
                    //blur
                    color={palette.white}
                  >
                    {shownSpeciality?.emoji || ''}
                    <GText
                      key={shownSpeciality?.name}
                      //blur
                      //webGrad
                      onPress={() => onSearchQuery(false)}
                      style={C.ml2}
                      type={TextType.h2}
                      color={palette.white}
                    >
                      {shownSpeciality?.name}
                    </GText>
                  </GText>
                </Animated.View>
              )}
            </GText>
          </h1>
          <GText
            type={TextType.bodyBig}
            style={[C.mb2, !isSmall && resp(0.3).maxWidth]}
            color={palette.white}
          >
            {trans(
              'Resuelve tus dudas personalizadas' +
                (isSmall ? '' : ',\nen cualquier momento y' + ' lugar'),
            )}
          </GText>
        </View>
      )}
      {/**SearchBar*/}
      <View style={[!isSmall && C.row]}>
        <View style={[compact ? C.p0 : isSmall ? C.py4 : C.py10]}>
          {!compact && <ServiceFilterComp />}
          {/** SearchBar*/}
          <View
            style={[
              C.row,
              C.itemsCenter,
              !compact && [C.mb6, isSmall ? [C.py1, C.mt2] : C.mt4],
            ]}
          >
            {currFormat.format === ServiceFormatKey.audio && (
              <CheckboxGrad
                wrapStyle={[compact ? C.p1 : C.p2, C.radius10, C.bgWhite]}
                /* onPress={handleOnPress}*/
                icon={'mic'}
                circ
                big
              />
            )}
            <Pressable
              style={[
                C.radius25,
                /*!showCompact && resp(isLarge ? 0.5 : 0.6).maxWidth,*/
                C.px2,
                C.bgWhite,
                C.row,
                C.justifyBetween,
                C.shadowMd,
                C.itemsCenter,
              ]}
            >
              <View style={[C.row, eLayout.grow1]}>
                {questions.map(
                  ({current, label, emoji, icon, placeholder}, index, arr) => {
                    const currIcon =
                      emoji || icon || current?.emoji || current?.icon;
                    const currName =
                      current?.format || current?.name || placeholder;
                    return (
                      <Fragment key={currName}>
                        {/*<SpecialityTooltip
                          disabled={!showTooltip || index !== 1}
                          TooltipTrigger={*/}
                        <TouchableOpacity
                          onPress={() => {
                            if (isSmall) showBurgerModal();
                            else onSearchQuery();
                          }}
                          style={[
                            C.radius25,
                            C.justifyCenter,
                            eLayout.grow1,
                            compact || isSmall
                              ? C.p2
                              : !isLarge
                              ? [C.itemsCenter, C.mx2, C.flex, isTablet && C.p2]
                              : [C.mx5, C.p3],
                          ]}
                        >
                          {!showCompact && (
                            <GText
                              color={orangeGrad[index]}
                              align={Alignment.Left}
                              type={TextType.tabSelected}
                              style={[C.selfStart, C.mr2]}
                            >
                              {label}
                            </GText>
                          )}

                          <View
                            style={[C.selfStart, C.itemsCenter, C.minh8, C.row]}
                          >
                            {currIcon && (
                              <GIcon
                                color={orangeGrad[index]}
                                icon={currIcon}
                                wrapStyle={[C.pr2]}
                              />
                            )}
                            <GText
                              numberOfLines={1}
                              style={[
                                C.capitalize,
                                resp(showCompact ? 0.2 : 0.23).maxWidth,
                              ]}
                            >
                              {currName}
                            </GText>
                          </View>
                        </TouchableOpacity>
                        {/*}
                          TooltipContent={
                            index === 1 ? <SpecialityDropdown /> : <View />
                          }
                        />*/}
                        {index < arr.length - 1 && (
                          <View style={[eDividerVert]} />
                        )}
                        {isSmall && (
                          <BurgerModal>
                            <SpecialityDropdown />
                          </BurgerModal>
                        )}
                      </Fragment>
                    );
                  },
                )}
                {!isLarge || compact ? (
                  <CheckboxGrad
                    onPress={onSearchQuery}
                    wrapStyle={C.mr0}
                    icon={'search'}
                    circ
                    big
                  />
                ) : (
                  <GButton
                    onPress={onSearchQuery}
                    style={[C.h12, C.radius10]}
                    text={showCompact ? undefined : 'Buscar'}
                    icon={'search'}
                  />
                )}
              </View>
            </Pressable>
          </View>

          {/**Pills*/}
          {!compact && CategoriesGroupSelector}
          {/*<SelectFieldModal<Item>
            keyExtractor={keyExtractor}
            labelExtractor={labelExtractor}
            onChange={handleOnSet}
            onGoBack={handleGoingBack}
            options={options}
            renderOptionPrefix={renderOptionPrefix}
            subtitle={modalSubtitle}
            title={modalTitle}
            value={value}
            shouldConfirm={shouldConfirm}
            valueExtractor={valueExtractor}
            iconExtractor={iconExtractor}
            isVisible={isVisible}
          />*/}
        </View>

        {!compact && !isSmall && (
          /*experts?.length > 0 &&*/ <View
            style={[C.top_50, C.ml25, C.mb_50, C.bg]}
          >
            {loading ? (
              <GSpinner full={false} wrapStyle={C.mtHalf} />
            ) : (
              <GCarrousel
                withPagination
                autoLoop={!selectedSpecialityKey}
                data={experts || putoCuaresma}
                onSnapToItem={userIndex => {
                  if (
                    experts &&
                    userIndex < experts?.length &&
                    experts[userIndex]
                  )
                    setRandomSpecialityKey(
                      experts[userIndex]?.speciality?.[0] ||
                        SpecialityKey.legalAdvisor,
                    );
                }}
                renderItem={({
                  item: currUser,
                  index,
                }: {
                  item: UserType;
                  index: number;
                }) => {
                  return (
                    currUser && (
                      <UserCard
                        key={(currUser.id || ' ') + index}
                        showInitialTab
                        user={currUser}
                      >
                        <View style={[centerRow]}>
                          <GButton
                            icon={'calendar-sharp'}
                            text={'Agendar Cita'}
                            onPress={() => {
                              showLoginModal();
                              gLogEvent('openModalBookingElseLogin');
                            }}
                          />
                          <GButton
                            type={ButtonType.secondary}
                            icon={'account-star'}
                            text={'¡Soy experto!'}
                            onPress={() => {
                              showAddServiceModal();
                              gLogEvent('openModalExpertCreate');
                            }}
                          />
                        </View>
                      </UserCard>
                    )
                  );
                }}
              />
            )}
          </View>
        )}
      </View>
    </>
  );
};
